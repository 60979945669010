export const annuityPeriods = [
  [1, "mensuelle", "mois"],
  [3, "trimestrielle", "trimestre"],
  [6, "semestrielle", "semestre"],
  [12, "annuelle", "an"]
];

// used to record and calculate third-party payments with priority
// - first column (int): priority (the lower the value, the higher the priority) in the victim's preferential right
// - second column (str): label of the tpp type
// - third column (bool): subject to the victim's preferential right
// - fourth column (bool): available in line third-party payments
// - fifth column (bool): available in entry third-party payments
// export const tppTypes = [
//   [10, "prestation (art. 29 loi 1985)", true, true, true],
//   [20, "avance sur indemnités (art. 33 loi 1985)", true, true, true],
//   [30, "provision", false, false, true]
// ];

export const helpEntries = {
  preferenceRight: "Indiquez à quel taux de pourcentage le responsable sera tenu à l'égard de la victime. Ce taux peut être réduit en raison d'une faute de la victime, d'une perte de chance d'éviter des séquelles, etc. Il affecte l'ensemble du dossier. Notez que Quantum tient compte automatiquement du droit de préférence de la victime.",
  disableCascade:
    "Gagnez du temps ! Quantum impute automatiquement les reliquats de paiement de tiers payeurs successivement sur les postes de PGPF et IP (par ex : un capital invalidité). Désactivez cette fonction et ces éventuels reliquats ne seront pas imputés automatiquement.",
  tppType: "A préciser",
  durationHeader: "Le calcul de la durée inclut le premier et le dernier jour et tient compte des années bisextiles.",
  lossOfOpportunity: "Renseignez la perte de chance affectant ce préjudice (par ex : perte de chance d'obtenir un diplôme). A ne pas confondre avec la réduction du droit à indemnisation en en-tête, qui réduit la dette du responsable (par ex : faute de la victime ou perte de chance d'éviter un événement défavorable en matière médicale) et affecte l'ensemble du dossier.",
  workIncomeHeader: "Renseignez les revenus liés à une activité professionnelle durant la période de PGP concernée (par ex : temps partiel) ou toute somme non soumise à recours.",
  referenceIncomeHeader: "Renseignez le revenu perçu avant l'accident.",
  datePeriodHeader: "Renseignez la date (dans un des deux champs) ou la période.",
  dftDateHeader: "Lors de l'ajout d'une nouvelle période, Quantum renseigne automatiquement la date correspondant au lendemain de la période précédente. Gagnez du temps ! Listez vos périodes chronologiquement plutôt que par taux de DFP.",
  tptDateHeader: "Renseignez la date (dans un des deux champs) ou la période de besoin en tierce personne.",
  dftLabelHeader: "Apportez une précision sur la période ou la date concernée (par ex : \"Période d'hospitalisation\", \"présence au centre de rééducation\",...)",
  pgpaLabelHeader: 'Apporter des précisions sur cette période de PGP.',
  arrFuture: "Période entre le jour de la liquidation du dossier (jugement, arrêt, transaction, etc.) et le terme du calcul (viager, âge de la retraite, etc.)",
  arrPast: "Période entre le jour de la consolidation et le jour de la liquidation du dossier (jugement, arrêt, transaction, etc.)",
  copyDFT:
    "Gagnez du temps ! Ajouter en un clic dans la TPP l'ensemble les périodes déjà saisies sur le DFT. Attention : si vous répétez l'opération, les dates déjà insérées ne seront pas modifiées, mais les dates de DFT seront à nouveau insérées dans la TPP.",
  component:
    "Renseignez-le ou les éléments constituants ce préjudice (facultatif). Commencez par un article (le, la les, un, une...) pour une fusion parfaite.",
  hours:
  "Pour un nombre d'heures non entier, veuillez saisir sous forme décimale. Ex. : pour 1h45, saisissez 1,75."
};

export const videoDemos = {
  DSA: "https://www.youtube.com/embed/C7QZpMW_VMc",
  AUT1: "https://www.youtube.com/embed/9NjDAC3flug",
  TPT: "https://www.youtube.com/embed/d9ePzFqffu0",
  PGPA: "https://www.youtube.com/embed/vFQpR5uqRFY",
  AUT3: "https://www.youtube.com/embed/5bX5LccbgWA",
}


export const familyLinkTypes = {
  spouse: ["conjoint", "conjointe"],
  partner: ["partenaire", "partenaire"],
  live_in_partner: ["concubin", "concubine"],
  child: ["fils", "fille"],
  sibling: ["frère", "soeur"],
  parent: ["père", "mère"],
  grandParent: ["grand-père", "grand-mère"],
  grandChild: ["petit-fils", "petite-fille"],
  uncleAunt: ["oncle", "tante"],
  nephew: ["neveu", "nièce"],
  cousin: ["cousin", "cousine"],
  parent_in_law: ["beau-père", "belle-mère"],
  child_in_law: ["gendre", "belle-fille"],
  other: ["autre (précisez)", "autre (précisez)"]
}