import React from 'react';
import PropTypes from "prop-types";
import DeleteForever from '@material-ui/icons/DeleteForever';

const BtnRemoveLine = props => {
  const {removeLine, index, label} = props;
  let btnLabel = "Supprimer "

  if (label) btnLabel += label;
  else btnLabel += "cette ligne";

  return (
    <button
      type="button"
      className="btn btn-outline-danger btn-sm p-0"
      onClick={()=>removeLine(index)}
      title={btnLabel}
    >
      <DeleteForever />
    </button>
  )
}

BtnRemoveLine.propTypes = {
    removeLine: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    label: PropTypes.string,
  }


export default BtnRemoveLine;