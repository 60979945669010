import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field } from "redux-form";
import NumberFormat from "react-number-format";
import ReferenceDuration from "./ReferenceDuration";
import Append from "./Append";

const RefNumber = props => {
  const {
    input,
    readOnly,
    placeholder,
    append,
    disabled,
    id,
    className,
    line,
    periodType
  } = props;

  return (
    <div className={`input-group input-group-sm flex-nowrap`}>
      <NumberFormat
        {...input}
        disabled={disabled}
        displayType="input"
        thousandSeparator=" "
        decimalSeparator=","
        decimalScale={2}
        allowNegative={false}
        type="text"
        className={`form-control text-right ${className ? className : ""}`}
        placeholder={placeholder ? placeholder : 0}
        readOnly={readOnly}
        id={id}
      />
      {append && <Append append={append} />}
      <div className="input-group-append">
        <span className="input-group-text">&nbsp;par&nbsp;</span>
      </div>
      <Field
        name={`${line}.durationType`}
        component="select"
        className="custom-select durationType"
        disabled={readOnly}
      >
        <option value="days" default>jour</option>
        <option value="weeks">semaine</option>
        <option value="months">mois</option>
      </Field>
      <ReferenceDuration
        periodType={periodType}
        line={line}
        readOnly={readOnly}
      />
    </div>
  );
};

RefNumber.propTypes = {
  input: PropTypes.object,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  append: PropTypes.string,
  id: PropTypes.string,
  line: PropTypes.string,
  className: PropTypes.string,
  periodType: PropTypes.string,
};

export default connect()(RefNumber);