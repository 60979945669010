import React from "react";
import PropTypes from "prop-types";

function Append({ append, className }) {
  return (
    <div className={`input-group-append${className ? className : ""}`}>
      <span className="input-group-text text-left p-0">{append}</span>
    </div>
  );
}

Append.propTypes = {
  append: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default Append;
