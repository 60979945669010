import React, {memo} from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import Append from "./Append";
import BtnDisplayDFPScales from "../compensation_scales/BtnDisplayDFPScales";

const AmountField = props => {
  const { input, fieldValue, readOnly, className, disabled, entry, lineIndex } = props;

  return (
    <div className={`input-group input-group-sm flex-nowrap${className ? className : ""}`}>
      {!input ? (
        <NumberFormat
          displayType="input"
          thousandSeparator=" "
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale={true}
          type="text"
          value={fieldValue}
          className="form-control text-right"
          placeholder="0,00"
          readOnly
        />
      ) : (
          <NumberFormat
            {...input}
            displayType="input"
            thousandSeparator=" "
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            type="text"
            className="form-control text-right"
            placeholder="0,00"
            readOnly={readOnly}
            disabled={disabled}
          />
        )}

      <Append append="€" />
      {entry === "DFP0" && <BtnDisplayDFPScales lineIndex={lineIndex} disabled={disabled} />}
    </div>
  );
};

AmountField.propTypes = {
  input: PropTypes.object,
  fieldValue: PropTypes.number,
  readOnly: PropTypes.bool
};

export default memo(AmountField);
