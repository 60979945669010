import React from "react";
import PropTypes from "prop-types";

const RenderTitleInput = ({
  input,
  label,
  type,
  placeholder,
  mandatory,
  meta: { touched, error, warning }
}) => {
  return (
    <div
      className={`form-group${touched && (error || warning) ? " has-danger" : ""
        }`}
    >
      <label className="control-label" htmlFor={input.name}>
        {label}
        {mandatory && <span className="text-danger"> *</span>}
        {touched && (error || warning) && (
          <span className="invalid-feedback ml-3" style={{ display: "inline" }}>{error || warning}</span>
        )}
      </label>
      <input
        {...input}
        placeholder={placeholder}
        type={type}
        id={input.name}
        className={`form-control${touched && (error || warning) ? " is-invalid" : ""}`}
      />
    </div>
  );
};

RenderTitleInput.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  mandatory: PropTypes.bool,
  meta: PropTypes.object
};

export default RenderTitleInput;
