import React, {useEffect, Fragment} from 'react';
import PropTypes from "prop-types";
import { Field, change } from "redux-form";
import { useDispatch, connect } from "react-redux";
import AmountField from '../../../fields/AmountField';
import BtnAddItemLine from '../../../buttons/BtnAddItemLine';
import BtnRemoveLine from '../../../buttons/BtnRemoveLine';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from "@material-ui/icons/Help";
import DisplayDiscountingForm from '../../../discounting/DisplayDiscountingForm';
import { getYearlyIncome, getIncomeArrayTotal } from "./calculations"
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import NumberFormat from "react-number-format";

const renderIncome = props => {
  const { fields, incomeType, incomeId, meta: { touched, error, submitFailed } } = props;

  const dispatch = useDispatch();

  const addLine = () => {
    let newLine = { durationType:"months" };
    if (fields.length > 0 && fields.get(fields.length - 1).durationType)
      newLine.durationType = fields.get(fields.length - 1).durationType;
    fields.push(newLine);
  }
  
  const removeLine = index => {
    fields.remove(index);
  };


  const removeDiscounting = (line, incomeLineId, incomeId) => {
    let newLineValues = line;
    delete newLineValues.discounting;    
    dispatch(change("caseDataForm", `householdIncome[${incomeId}].originalIncome[${incomeLineId}]`, newLineValues));
  }



  const incomeTypes = {
    original: {
                title: "Etape 1 : reconstituer le revenu passé du foyer",
                info: "Renseignez ici les revenus qui auraient été perçus en l'absence de décès"
              },
    residual: {
                title: "Etape 3 : calculer le revenu résiduel du foyer",
                info: "Renseignez les revenus des différents membres du foyer postérieurement au décès de la victime directe"
              },
  }

  let incomeTypeDetails = incomeTypes.original;
  if (incomeType !== "original") incomeTypeDetails = incomeTypes[incomeType]

  useEffect(() => {
    if (fields.length === 0) addLine();
  }, []);

  return (
    <table className="table table-hover table-sm">
      <caption>
        <h3>
          {incomeTypeDetails.title}&nbsp;{incomeTypeDetails.info && 
            <Tooltip title={incomeTypeDetails.info} leaveDelay={200}>
              <HelpIcon fontSize="small" variant="contained" className="text-secondary smallIcon mb-1" />
            </Tooltip>
          }
        </h3>
      </caption>
      <thead>
        <tr style={{color: "#999"}}>
          <th />
          <th />
          <th />
          <th scope="col" className="col-amount" colSpan="2">Montant annuel</th>
        </tr>
      </thead>
      <tbody>
        {fields.map((line, index) => 
        <Fragment key={index}>
          <tr>
            <th scope="row" className="col-num">{fields.length > 1 && <>{index + 1}.</>}</th>
            <td className="refAmount">
              <div className="input-group input-group-sm flex-nowrap">
                <Field
                  name={`${line}.refAmount`}
                  component={AmountField}
                />
                <div className="input-group input-group-sm flex-nowrap">
                  <div className="input-group-append">
                    <span className="input-group-text">&nbsp;par&nbsp;</span>
                  </div>
                  <Field
                    name={`${line}.durationType`}
                    component="select"
                    className="custom-select refAmountDurationType"
                  >
                    <option value="days" default>jour</option>
                    <option value="weeks">semaine</option>
                    <option value="months">mois</option>
                    <option value="years">an</option>
                  </Field>
                  {incomeType === "original" && 
                    <DisplayDiscountingForm
                      lineValues={fields.get(index)}
                      amount={fields.get(index).refAmount ? parseFloat(fields.get(index).refAmount.replace(",", ".").replace(/\s/g, "")) : 0}
                      householdIncome={{incomeId: incomeId, incomeLineId: index}}
                    />
                  }
                </div>   
              </div>          
            </td>
            <td>
              <div className="input-group input-group-sm flex-nowrap anncap">
                <label htmlFor={`${line}.description`} className="control-label text-nowrap mb-0 pr-0">au titre</label>
                <Field
                  name={`${line}.description`}
                  component="input"
                  className="form-control"
                  placeholder="du revenu de Monsieur XXX ou de la pension de Madame YYY..."
                />
              </div>
            </td>
            <td className="col-amount">
              <AmountField fieldValue={getYearlyIncome(fields.get(index))} />  
            </td>
            <td className="col-act">
              <BtnRemoveLine removeLine={removeLine} index={index} />
            </td>
          </tr>
        {fields.get(index).discounting && fields.get(index).discounting.indexValue &&
          <tr>
            <td className="col-num pt-0 pb-0" />
            <td className="text-right pt-0">
              <em>Revenu actualisé</em> :&nbsp;<NumberFormat
                                                displayType="text"
                                                thousandSeparator=" "
                                                className="discountedTotal pr-1"
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                type="text"
                                                value={fields.get(index).refAmount ? parseFloat(fields.get(index).refAmount.replace(",", ".").replace(/\s/g, ""))*fields.get(index).discounting.indexValue : 0}
                                                suffix=" €"
                                            />
              <button
                type="button"
                className="btn btn-outline-danger btn-sm p-0 ml-1"
                onClick={()=>removeDiscounting(fields.get(index), index, incomeId)}
                title="Supprimer l'actualisation"
              >
                <HighlightOffIcon />
              </button>
            </td>
            <td></td>
          </tr>
        }</Fragment>
      )}
      </tbody>
      <tfoot>
        <tr>
          <td />
          <td>
            <div className="float-left">
              <BtnAddItemLine addLine={() => addLine()} label="un revenu" iconSize="small" />
            </div>
          </td>
          <th scope="row" style={{color: "#999"}}>
            {fields.length > 1 && "Total"}
          </th>
          <td>{fields.length > 1 && <AmountField fieldValue={getIncomeArrayTotal(fields.getAll())} />}</td>
          <td />
        </tr>
      </tfoot>
    </table>
  )
}

renderIncome.propTypes = {
  fields: PropTypes.object,
  incomeType: PropTypes.string.isRequired,
  meta: PropTypes.object,
}

export default connect()(renderIncome);