import React, { Fragment } from "react";
import PropTypes from "prop-types";
import AmountField from "../../fields/AmountField";
import { Field } from "redux-form";
import NumberField from "../../fields/NumberField";
import RenderSwitch from "../../fields/RenderSwitch";
const RenderLossOfOpportunity = props => {
  const { colSpan, lineAmount, active, idPrefix } = props;

  return (
    <tr>
      <td className="col-num" />
      <td colSpan={colSpan} className="align-bottom">
        <div className="d-sm-flex anncap">
          <div className="pl-0 d-sm-flex mr-0 pr-0 col-4">
            <Field name="check" component={RenderSwitch} label="Perte de chance" />
          </div>
          {active && (
            <Fragment>
              <div className="d-sm-flex mr-0 pr-0 col-5 input-group-sm">
                <label className="control-label" htmlFor={`${idPrefix}.num`}>
                  Taux de perte de chance
                </label>
                <Field
                  name="num"
                  id={`${idPrefix}.num`}
                  component={NumberField}
                  max={100}
                  step={1}
                  append="%"
                />
              </div>
              <div className="ml-auto mr-0 pr-0 col-3 text-right text-nowrap">
                <em>Montant après perte de chance :</em>
              </div>
            </Fragment>
          )}
        </div>
      </td>
      <td className="col-amount text-right">{active && <AmountField fieldValue={lineAmount} />}</td>
      <td />
    </tr>
  );
};

RenderLossOfOpportunity.propTypes = {
  colSpan: PropTypes.number,
  lineAmount: PropTypes.number,
  active: PropTypes.bool,
  idPrefix: PropTypes.string
};
export default RenderLossOfOpportunity;
