import axios from "axios";
import {
    ADD_CASE,
    AUTH_ERROR,
    CASES_LOADING,
    CASES_RECEIVED,
    DELETE_CASE,
    DUPLICATE_CASE,
    GET_CASE,
    IS_CASE_LIST,
    IS_NOT_CASE_LIST, UPDATE_CASE_TITLE,
    UPDATE_CASE_VERSION
} from "./types";
import {tokenConfig} from "./auth";
import {createMessage} from "./messages";
import {returnErrors} from "./errors";

// GET CASES
export const getCases = () => (dispatch, getState) => {
    dispatch({type: CASES_LOADING});
    dispatch({type: IS_CASE_LIST});
    axios
        .get("/api/", tokenConfig(getState))
        .then(res => {
            dispatch({type: CASES_RECEIVED, payload: res.data});
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
};

// SET IS NOT CASE LIST
export const setIsNotCaseList = () => (dispatch) => {
    dispatch({type: IS_NOT_CASE_LIST});
};

// DELETE CASE
export const deleteCase = id => (dispatch, getState) => {
    axios
        .delete(`/api/${id}/`, tokenConfig(getState))
        .then(() => {
            dispatch(createMessage({deleteCase: "Dossier supprimé"}));
            dispatch({type: DELETE_CASE, payload: id});
        })
        .catch(err => console.log(err));
};

// GET CASE
export const getCase = id => (dispatch, getState) => {
    dispatch({type: CASES_LOADING});
    axios
        .get(`/api/${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({type: GET_CASE, payload: res.data});
        })
        .catch(err => console.log(err));
};

// ADD CASE
export const addCase = c => (dispatch, getState) => {
    axios
        .post("/api/", c, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({addCase: "Dossier créé"}));
            dispatch({type: ADD_CASE, payload: res.data});
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

// UPDATE CASE
export const updateCase = c => (dispatch, getState) => {
    return axios
        .put(`/api/${c.id}/`, c, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({updateCase: "Modifications enregistrées"}));
            dispatch({type: UPDATE_CASE_VERSION, payload: res.data.version});
            dispatch({type: UPDATE_CASE_TITLE, payload: res.data.title});
        })
        .catch(err => {
            console.log(err);
            if (err && err.response && err.response.status === 409) {
                dispatch(createMessage({recordConflict: "Echec de l'enregistrement. Votre dossier est vraisemblablement ouvert à deux emplacements distincts." }));
                return
            }
            if (err && err.response && err.response.status === 401) {
                dispatch({type: AUTH_ERROR});
                dispatch(createMessage({disconnected: "Votre session a expiré, vous avez été déconnecté(e)"}));
                // dispatch(createMessage({recordConflict: "Echec de l'enregistrement. Le dossier a été supprimé." }));
                return
            }
            dispatch(returnErrors(err.response.data, err.response.status))
            // dispatch(createMessage({disconnected: "Votre session a expiré, vous avez été déconnecté(e)"}));
        });
};


// DUPLICATE CASE
export const duplicateCase = id => (dispatch, getState) => {
    axios
        .post(`/api/${id}/duplicate/`, {}, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({addCase: "Dossier copié"}));
            dispatch({type: DUPLICATE_CASE, payload: res.data});
        })
        .catch(err => console.log(err));
};
