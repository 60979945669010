import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { interval } from "../../utils";
import { annuityPeriods } from "../constants";

import { Field, arrayPush, FieldArray, change } from "redux-form";
import { connect } from "react-redux"

import AmountField from "../../fields/AmountField";
import NumberField from "../../fields/NumberField";
import RenderInput from "../creation_wizard/RenderInput";
import DatePicker, {
  formatDates,
  normalizeDates,
} from "../creation_wizard/RenderDateInput";
import RenderActionsCol from "./renderActionsCol"
import RenderTPP from "./renderTPP"
import RenderSwitch from "../../fields/RenderSwitch"

import * as capiTables from "../../capitalisation_tables/capitalisationTables";
import {
  getPERFromCapiTable,
  getLineAmount,
  capLineResponsiblePartyAmount,
  capLineVictimAmount,
  capLineTPPRecourse,
  getPastTppSurplus,
  tppTotal,
  getPastTppCascadable,
} from "../calculations";

import Append from "../../fields/Append"

let AnnuityCapitalisation = props => {
  const {
    colSpan,
    item,
    values,
    entry,
    lineIndex,
    victim,
    lineCapitalisedAmount,
    dispatch,
    line,
    meta
  } = props;

  let startAge = -1;

  const pastTppSurplus = getPastTppSurplus(values, entry);

  if (values[entry][lineIndex]) {
    let birthDate = victim.birthDate;
    let startDate = values[entry][lineIndex].startDate;
    if (startDate && startDate.length === 10)
      if (birthDate <= startDate) {
        startAge = Math.floor(interval(birthDate, startDate, "years", false));
      }
  }

  const addTPPLine = () => {
    let newLine = {};
    dispatch(arrayPush("caseDataForm", `${entry}[${lineIndex}].tppCap`, newLine));
  };

  let pastToNonAffected = 0;
  let futureNonAffectedTotal = 0;
  let cascadeToPast = false;
  const pastTppCascadable = getPastTppCascadable(values, entry, lineIndex, item, victim)
  const selectedCapiTable = values[entry][lineIndex].capitalisationTable;
  const selectedEndAge = parseInt(values[entry][lineIndex].capitalisationEndAge);
  const gender = victim.gender
  const responsiblePartyAmount = capLineResponsiblePartyAmount(values, entry, lineIndex, item, victim)
  let victimAmount = capLineVictimAmount(values, entry, lineIndex, item, victim);
  let thirdPartyPayersAmount = capLineTPPRecourse(values, entry, lineIndex, item, victim);
  const damageAmount = lineCapitalisedAmount.capitalisedAmountAfterLOP;
  if (damageAmount - responsiblePartyAmount > tppTotal(values[entry][lineIndex].tppCap)) {
    futureNonAffectedTotal = damageAmount - responsiblePartyAmount - tppTotal(values[entry][lineIndex].tppCap);
    pastToNonAffected = Math.min(pastTppCascadable, futureNonAffectedTotal);
    pastToNonAffected = Math.round(pastToNonAffected * 100) / 100;
  }
  const annuity = values[entry][lineIndex].annuity



  let entryId = entry.slice(0, -1)
  if ((entryId === "PGPF" || entryId === "TPF") &&
    values._meta &&
    values._meta[entryId] &&
    values._meta[entryId].items &&
    values._meta[entryId].items[1]
  ) {
    // if (values._meta[entry.id].items[1].annuityTotal)
    //   annuityTotal = values._meta[entry.id].items[1].annuityTotal;
    // if (values._meta[entry.id].items[1].annuityDetails)
    //   annuityDetails = values._meta[entry.id].items[1].annuityDetails;
    // if (values._meta[entry.id].items[1].responsiblePartyTotal)
    //   responsiblePartyTotal = values._meta[entry.id].items[1].responsiblePartyTotal;
    if (values._meta[entryId].items[1].victimTotal)
      victimAmount = values._meta[entryId].items[1].victimTotal;
    // if (values._meta[entry.id].items[1].hasTPP)
    //   hasTPP = values._meta[entry.id].items[1].hasTPP;
    if (values._meta[entryId].items[1].thirdPartyPayersTotal >= 0)
      thirdPartyPayersAmount = values._meta[entryId].items[1].thirdPartyPayersTotal;
    if (values._meta[entryId].items[1].pastFutureSummary && values._meta[entryId].items[1].pastFutureSummary.past.absorbed_tpp_surplus > 0)
      cascadeToPast = true;
    // if (values._meta[entry.id].items[1].afterTPPTotal)
    //   afterTPPTotal = values._meta[entry.id].items[1].afterTPPTotal;
  }


  return (
    <Fragment>
      <tr>
        <td className="col-num" />
        <td colSpan={colSpan} className="small align-bottom">
          <div className="d-sm-flex">
            <div className="pl-0 d-sm-flex form-group main-form anncap input-group input-group-sm">
              <Field
                name={`${line}.startDate`}
                component={DatePicker}
                parse={normalizeDates}
                format={formatDates}
                label="A compter du"
                className=" d-flex input-group-sm mr-0"
                onChange={(event, newValue) => {
                  let PER = 0;
                  if (selectedCapiTable && selectedCapiTable !=="PERdirectInput" && gender && selectedEndAge && newValue && newValue.length === 10)
                    {
                      PER = getPERFromCapiTable(
                        selectedCapiTable,
                        gender,
                        Math.floor(interval(victim.birthDate, newValue, "years", false)),
                        selectedEndAge
                      )
                      dispatch(change("caseDataForm", `${entry}[${lineIndex}].capitalisationPER`, PER.toString().replace(".", ",")))
                    }
                  }
                }
              />
              {startAge >= 0 && <Append append={`(${startAge} an${startAge >= 2 ? "s" : ""})`} className=" pl-1 pr-2" />}
              <label
                className="control-label"
                htmlFor={`${entry}[${lineIndex}].capitalisationTable`}
              >Table de capitalisation :</label>
              <Field
                name={`${line}.capitalisationTable`}
                component="select"
                className="custom-select captable-select"
                id={`${entry}[${lineIndex}].capitalisationTable`}
                onChange={(event, newValue) => {
                  let PER = "";
                  if (newValue && newValue !== "PERdirectInput"
                    && gender && startAge >= 0 && selectedEndAge >= 0)
                    PER = getPERFromCapiTable(
                      newValue,
                      gender,
                      startAge,
                      selectedEndAge
                    );
                  dispatch(change("caseDataForm", `${entry}[${lineIndex}].capitalisationPER`, PER.toString().replace(".", ",")))
                  !newValue && dispatch(change("caseDataForm", `${entry}[${lineIndex}].capitalisationPER`, ""))
                }
                }
              >
                <option value=""></option>
                <option value="PERdirectInput">Libre - Saisir un PER</option>
                {capiTables.tableList.map(table => (
                  <option key={table.id} value={table.id}>
                    {table.author}
                    {table.year && " " + table.year}
                    {table.rate && " " + table.rate}
                  </option>
                ))}
              </Field>
              {startAge >= 0 &&
                selectedCapiTable &&
                selectedCapiTable.length > 0 &&
                (selectedCapiTable !== "PERdirectInput" ?
                  (<Fragment>
                    <label
                      className="control-label pl-2"
                      htmlFor={`${entry}[${lineIndex}].capitalisationEndAge`}
                    >Âge de fin</label>
                    <Field
                      name={`${line}.capitalisationEndAge`}
                      component="select"
                      className="custom-select endage-select"
                      id={`${entry}[${lineIndex}].capitalisationEndAge`}
                      onChange={(event, newValue) => {
                        let PER = 0
                        if (selectedCapiTable && gender && startAge >= 0)
                          PER = getPERFromCapiTable(
                            selectedCapiTable,
                            gender,
                            startAge,
                            newValue
                          )
                        dispatch(change("caseDataForm", `${entry}[${lineIndex}].capitalisationPER`, PER.toString().replace(".", ",")))
                      }
                      }
                    >
                      <option value=""></option>
                      {victim.gender &&
                        capiTables[selectedCapiTable]
                          .get(victim.gender)
                          .filter(ent => ent[0] === startAge)
                          .map(entry => (
                            <option key={entry[1]} value={entry[1]}>
                              {entry[1] === 999 ? "viager" : entry[1] + " ans"}
                            </option>
                          ))}
                    </Field>
                    {selectedEndAge > 0 && (
                      <React.Fragment>
                        <label
                          className="control-label pl-2 ml-auto"
                          htmlFor={`${entry}[${lineIndex}].capitalisationPER`}
                          title="Prix de l'euro de rente"
                        >
                          PER :{" "}
                        </label>
                        <Field
                          name={`${line}.capitalisationPER`}
                          id={`${entry}[${lineIndex}].capitalisationPER`}
                          component="input"
                          type="text"
                          className="form-control per-input"
                          readOnly
                        />
                      </React.Fragment>
                    )}
                  </Fragment>
                  )
                  :
                  (
                    <React.Fragment>
                      <label
                        className="control-label pl-2"
                        htmlFor={`${entry}[${lineIndex}].capitalisationPER`}
                        title="Prix de l'euro de rente"
                      >
                        PER :{" "}
                      </label>
                      <Field
                        name={`${line}.capitalisationPER`}
                        id={`${entry}[${lineIndex}].capitalisationPER`}
                        component="input"
                        type="text"
                        className="form-control per-input"
                      />
                    </React.Fragment>
                  ))
              }
            </div>
          </div>
        </td>
        <td className="col-amount text-left align-bottom">
          <em>Montant capitalisé :</em>
          <AmountField fieldValue={lineCapitalisedAmount.capitalisedAmountAfterLOP} />
        </td>
        <RenderActionsCol
          item={item}
          lineAmount={getLineAmount(values, entry, lineIndex, item).toFixed(2)}
          line={line}
          addTPPLine={() => addTPPLine(lineIndex)}
          capitalTPP={true}
          secondaryLine={true}
        />
      </tr>
      {values[entry][lineIndex].tppCap &&
        values[entry][lineIndex].tppCap.length > 0 && (
          <FieldArray
            name={`${line}.tppCap`}
            colSpan={colSpan}
            line={line}
            component={RenderTPP}
            entry={entry}
            lineIndex={lineIndex}
            tppValues={values[entry][lineIndex].tppCap}
            entryMeta={meta[entry]}
            annCap={item.annuityCapitalisation}
            lineAmount={lineCapitalisedAmount.capitalisedAmount}
            tppCap={true}
            caption={"le montant capitalisé"}
          />
        )}
      {parseInt(victim.rateOfCompensationEntitlement) < 100 && (
        <tr>
          <td className="col-num pt-0 pb-0" />
          <th scope="row" colSpan={colSpan} className="text-right align-bottom pt-0 pb-0">
            Dette du/des responsable(s)
          </th>
          <td className="col-amount text-left align-bottom pt-0 pb-0">
            <AmountField
              readOnly
              fieldValue={responsiblePartyAmount}
            />
          </td>
          <td className="col-act pt-0 pb-0" />
        </tr>
      )}
      <tr>
        <td className="col-num pt-0 pb-0" />
        <th scope="row" colSpan={colSpan} className="text-right align-bottom pt-0 pb-0">
          <div className="d-sm-flex float-left">
            <Field
              name={`${line}.annuity`}
              component={RenderSwitch}
              label="Verser sous forme de rente"
              className=" pl-0 anncap text-nowrap"
            />
          </div>
          Créance de la victime{pastToNonAffected > 0 &&
            (
              <em className="amount-label-append">, dont {Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR"
              }).format(pastToNonAffected)} de dette du responsable non affectée à l'indemnisation de la victime sur les arrérages échus
              </em>
            )}
        </th>
        <td className="col-amount text-left pt-0 pb-0">
          <AmountField readOnly fieldValue={Math.max(victimAmount, 0)} />
        </td>
        <td className="col-act pt-0 pb-0" />
      </tr>
      {annuity && <Fragment>
        <tr>
          <td className="col-num pt-0 pb-0" />
          <td colSpan={colSpan} className="text-right pt-0 pb-0">
            <div className="input-group input-group-sm flex-nowrap float-right anncapLabels">
              <div className="input-group-prepend">
                <span className="input-group-text pr-0">Versée à</span>
              </div>
              <Field
                name={`${entry}[${lineIndex}].annuityPercentage`}
                component={NumberField}
                placeholder={"0"}
                max={100}
                append={"%"}
                className="refNumber font-weight-bold"
              />
              <div className="input-group-append">
                <span className="input-group-text">&nbsp;sous forme de rente&nbsp;</span>
              </div>
              <Field
                name={`${line}.annuityPeriodicity`}
                component="select"
                className="custom-select periodicity-select font-weight-bold"
                id={`${entry}[${lineIndex}].annuityPeriodicity`}
              >
                <option value=""></option>
                {annuityPeriods.map((annPer, i) => (
                  <option key={i} value={annPer[0]}>
                    {annPer[1]}
                  </option>
                ))}
              </Field>
            </div>
          </td>
          <td className="col-amount text-left pt-0 pb-0">

            <AmountField readOnly 
            fieldValue=
            {Math.max((victimAmount || 0) / (lineCapitalisedAmount.PER || 1) * parseInt(values[entry][lineIndex].annuityPeriodicity || 0) / 12 * parseFloat(values[entry][lineIndex].annuityPercentage ? values[entry][lineIndex].annuityPercentage.toString().replace(",", "."): 0) / 100, 0)} />
          </td>
          <td className="p-0">
            {values[entry][lineIndex].annuityPeriodicity &&
              <div className="input-group input-group-sm flex-nowrap">
                <div className="input-group-append">
                  <span className="input-group-text">/ {annuityPeriods.filter(period => period[0] === parseInt(values[entry][lineIndex].annuityPeriodicity))
                    .map(entry => entry[2])}</span>
                </div>
              </div>}
          </td>
        </tr>
        <tr>
          <td className="col-num pt-0 pb-0" />
          <td colSpan={colSpan} className="text-right pt-0 pb-0">
            <div className="input-group input-group-sm flex-nowrap float-right anncapLabels">
              <div className="input-group-prepend">
                <span className="input-group-text">Reliquat versé en capital</span>
              </div></div>
          </td>
          <td className="col-amount text-left pt-0 pb-0">
            <AmountField readOnly fieldValue={Math.max(victimAmount, 0) * (100 - parseFloat(values[entry][lineIndex].annuityPercentage ? values[entry][lineIndex].annuityPercentage.toString().replace(",", ".")        : 0)) / 100} />
          </td>
          <td className="col-act pt-0 pb-0" />
        </tr>
      </Fragment>}
      {((values[entry][lineIndex].tppCap &&
        values[entry][lineIndex].tppCap.length > 0) || pastTppSurplus > 0) && (
          <Fragment>
            <tr>
              <td className="col-num pt-0 pb-0" />
              <th scope="row" colSpan={colSpan} className="text-right pt-0 pb-0">
                Recours du/des tiers payeur(s){pastTppSurplus > 0 && `, y compris le report de ${Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(pastTppSurplus)} des arrérages échus`}
              </th>
              <td className="col-amount text-left align-bottom pt-0 pb-0">
                <AmountField
                  readOnly
                  fieldValue={thirdPartyPayersAmount}
                />
              </td>
              <td className="col-act pt-0 pb-0" />
            </tr>
            {victimAmount < 0 && (cascadeToPast || (entryId !== "PGPF" && entryId !== "TPF")) && (
              <tr>
                <td className="col-num pt-0 pb-0" />
                <td colSpan={colSpan + 1} className="text-right align-bottom pt-0 pb-0">
                  <small className="text-danger">
                    Le reliquat de paiement de tiers payeur(s) non imputé de{" "}{Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(-victimAmount)}
                    {(entryId === "PGPF" || entryId === "TPF")
                      ? " est reporté automatiquement sur les arrérages échus."
                      : " n'est pas reporté automatiquement sur les autres dépenses ou les autres postes de préjudice."
                    }
                    .
                  </small>
                </td>
                <td className="col-act pt-0 pb-0" />
              </tr>
            )}
          </Fragment>
        )}
    </Fragment>
  );
};

AnnuityCapitalisation.propTypes = {
  colSpan: PropTypes.number,
  values: PropTypes.object,
  entry: PropTypes.string,
  lineIndex: PropTypes.number,
  victim: PropTypes.object,
  lineCapitalisedAmount: PropTypes.object,
  lineAmount: PropTypes.number,
  line: PropTypes.string
};

export default connect()(AnnuityCapitalisation);
