import React from "react";
import PropTypes from "prop-types";
import AmountField from "../../fields/AmountField";
import { useDispatch } from "react-redux";
import { change } from "redux-form";
import {
  lineResponsiblePartyAmount,
  lineVictimAmount,
  lineThirdPartyPayersAmount,
  lineVictimDiscountedAmount
} from "../calculations";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';


const LineSummary = props => {
  const { colSpan, values, entry, lineIndex, item, victim, arrerageType, arrerageIndex } = props;
  const dispatch = useDispatch();
  
  let lineValues = {};
  if (!arrerageType) lineValues = values[entry][lineIndex]
  else lineValues = values[entry][lineIndex].arr[arrerageType][arrerageIndex]
  
  
  const removeDiscounting = (lineValues) => {
    let newLineValues = lineValues;
    delete newLineValues.discounting;
    dispatch(change("caseDataForm", `${entry}[${lineIndex}]`, newLineValues));
  }


  const victimAmount = lineVictimAmount(values, entry, lineIndex, item, victim, arrerageType, arrerageIndex);

  return (
    <React.Fragment>
      {parseInt(victim.rateOfCompensationEntitlement) < 100 && entry !== "DNAPRDI0" && entry !== "PROV0" && (values[entry].length > 1 || item.itemType === "DSA" || entry.indexOf("TPF") === 0 || entry.indexOf("AUT") === 0) && (
        <tr>
          <td className="col-num pt-0 pb-0" />
          <td colSpan={colSpan} className="text-right pt-0 pb-0">
            <em>Dette du/des responsable(s){lineValues.discounting && lineValues.discounting.indexValue && !(entry.indexOf("PGP") === 0) && " avant actualisation"}</em>
          </td>
          <td className="col-amount text-left pt-0 pb-0">
            <AmountField
              readOnly
              fieldValue={lineResponsiblePartyAmount(values, entry, lineIndex, item, victim, arrerageType, arrerageIndex)}
            />
          </td>
          <td className="col-act pt-0 pb-0" />
        </tr>
      )}
      {lineValues.tpp && lineValues.tpp.length > 0 && !(values[entry].length == 1 && entry.indexOf("PGPA") === 0) &&
        <tr>
          <td className="col-num pt-0 pb-0" />
          <td colSpan={colSpan} className="text-right pt-0 pb-0">
            <em>Créance de la victime{lineValues.discounting && lineValues.discounting.indexValue && " avant actualisation"}</em>
          </td>
          <td className="col-amount text-left pt-0 pb-0">
            <AmountField readOnly fieldValue={Math.max(victimAmount, 0)} />
          </td>
          <td className="col-act pt-0 pb-0" />
        </tr>
      }
      {lineValues.discounting && lineValues.discounting.indexValue && entry.indexOf("PGP") !==0 &&
        <tr>
          <td className="col-num pt-0 pb-0" />
          <td colSpan={colSpan} className="text-right pt-0 pb-0">
            <em>Créance actualisée de la victime</em>
          </td>
          <td className="col-amount text-left pt-0 pb-0">
            <AmountField readOnly fieldValue={lineVictimDiscountedAmount(values, entry, lineIndex, item, victim)} />
          </td>
          <td className="col-act pt-0 pb-0">
          <button
                type="button"
                className="btn btn-outline-danger btn-sm p-0"
                onClick={()=>removeDiscounting(lineValues)}
                title="Supprimer l'actualisation"
              >
                <HighlightOffIcon />
              </button>
          </td>
        </tr>
      }
      {lineValues.tpp && lineValues.tpp.length > 0 &&
        <tr>
          <td className="col-num pt-0 pb-0" />
          <td colSpan={colSpan} className="text-right pt-0 pb-0">
            <em>Recours du/des tiers payeur(s)</em>
          </td>
          <td className="col-amount text-left pt-0 pb-0">
            <AmountField
              readOnly
              fieldValue={lineThirdPartyPayersAmount(values, entry, lineIndex, item, victim, arrerageType, arrerageIndex)}
            />
          </td>
          <td className="col-act pt-0 pb-0" />
        </tr>
      }
      {lineValues.tpp && lineValues.tpp.length > 0 && entry !== "DNAPRDI0" && entry !== "PROV0" && lineValues.discounting && lineValues.discounting.indexValue &&
        <tr>
          <td className="col-num pt-0 pb-0" />
          <td colSpan={colSpan} className="text-right pt-0 pb-0">
            <em>Dette du/des responsable(s) après actualisation</em>
          </td>
          <td className="col-amount text-left pt-0 pb-0">
            <AmountField readOnly fieldValue={lineVictimDiscountedAmount(values, entry, lineIndex, item, victim) + lineThirdPartyPayersAmount(values, entry, lineIndex, item, victim, arrerageType, arrerageIndex)} />
          </td>
          <td className="col-act pt-0 pb-0" />
        </tr>
      }
      {lineValues.tpp && lineValues.tpp.length > 0 && victimAmount < 0 && !item.annuityCapitalisation && (values[entry].length > 1 || item.nonCascadingLines || entry.indexOf("PGPF") === 0) && (
        <tr>
          <td className="col-num pt-0 pb-0" />
          <td colSpan={colSpan + 1} className="text-right align-bottom pt-0 pb-0">
            <small className="text-danger">
              Le reliquat de paiement de tiers payeur(s) non imputé de{" "}{Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(-victimAmount)}
              {item.nonCascadingLines
                ? ` n'est pas reporté automatiquement sur les autres dépenses ou les autres postes de préjudice`
                : ` est reporté automatiquement sur les autres ${item.itemType.indexOf("PGP") === 0 ? `périodes${entry.indexOf("PGPF") === 0 ? " (échues et à échoir)" : ""}` : "dépenses du poste"}`}
              .
            </small>
          </td>
          <td className="col-act pt-0 pb-0" />
        </tr>
      )}
    </React.Fragment>
  );
};

LineSummary.propTypes = {
  colSpan: PropTypes.number,
  values: PropTypes.object,
  entry: PropTypes.string,
  lineIndex: PropTypes.number,
  item: PropTypes.object,
  victim: PropTypes.object
};
export default LineSummary;
