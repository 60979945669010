import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import cases from "./cases";
import errors from "./errors";
import messages from "./messages";
import auth from "./auth";

export default combineReducers({
  cases: cases,
  errors: errors,
  messages: messages,
  auth: auth,
  form: formReducer
});
