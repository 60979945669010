import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form"
import DurationNumberField from "./DurationNumberField"
import { getFrenchDuration } from "../utils"


const ReferenceDuration = props => {
    const {
        periodType,
        line,
        readOnly,
        additionalText
    } = props;

    return (
        <Fragment>
            <div className="input-group-prepend pl-1">
                <span className="input-group-text">&nbsp;sur la base de</span>
            </div>
            <Field
                name={`${line}.daysPerYear`}
                component={DurationNumberField}
                readOnly={readOnly}
                disabled={readOnly}
            />
            <div className="input-group-append">
                <span className="input-group-text">jours par an{additionalText ? additionalText
                    : ""}</span>
            </div>
        </Fragment>
    );
};

ReferenceDuration.propTypes = {
    periodType: PropTypes.string,
    line: PropTypes.string,
    readOnly: PropTypes.bool,
    additionalText: PropTypes.string,
};

export default ReferenceDuration;