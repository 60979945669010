import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux"
import PropTypes from "prop-types";
import { Field, formValueSelector, change, FieldArray } from "redux-form";
import { House } from '@material-ui/icons';
import AmountField from "../../fields/AmountField";
import NumberField from "../../fields/NumberField";
import BtnRemoveLine from "../../buttons/BtnRemoveLine";
import DatePicker, {
  formatDates,
  normalizeDates } from "../creation_wizard/RenderDateInput";
import { getHouseholdIncomesAllocation,
         getHouseholdIncomesAllocationDetails,
         getAllocationDetailsStr,
         getHouseholdIncomesList,
         getVictAmount,
         getPastAmount,
         getFutureAmount,
         getTotalAmount,
         getPastDurationStr,
         getPRevLineRespPartyAmount,
         getMainIndirectVictId,
         getVictimClaim,
         getTppRecourse
       } from "./household_income/calculations";
import { getIndirectVictimFullName } from "../../utils";
import RenderPRevAnnCap from "./RenderPRevAnnCap";
import IndirectItemTableFooter from "./IndirectItemTableFooter";
import RenderTPP from "./renderTPP"
import { tppTotal } from "../calculations";

const RenderPRev = props => {
  const { fields, item, victim, meta, entry, setIncomeFormOpen, householdIncome, indirectVictims, victimId, incomeLossCalcMethod, dispatch } = props;

  const removeLine = index => {
    fields.remove(index);
  };

  const onOptionChange = (e) => {
    if (e.target.value) {
      indirectVictims[victimId].caseData.PRev0.forEach((line,i) => {
        dispatch(
          change(
            "caseDataForm",
            `indirectVictims[${victimId}].caseData.PRev0[${i}].victStatus`,
            e.target.value
          )
        );}
      );
    }
  }

  let mainIndirectVict = getMainIndirectVictId(indirectVictims);

  const householdIncomesList = getHouseholdIncomesList(householdIncome);
 
  const rateOfCompensationEntitlement = parseInt(victim.rateOfCompensationEntitlement) || 100;
  
  const addLine = () => {
    let newLine = { refVictim: "indirect" };
    if (householdIncomesList.length === 1)
      newLine.householdLoss = householdIncomesList[0].incomeId;
    if (incomeLossCalcMethod === "redistribution" && fields.get(0) && fields.get(0).victStatus)
      newLine.victStatus = fields.get(0).victStatus;
    fields.push(newLine);
  };


  const householdIncomesAllocation = getHouseholdIncomesAllocation(householdIncomesList, indirectVictims, incomeLossCalcMethod);
  const allocationDetails = getHouseholdIncomesAllocationDetails(householdIncomesAllocation, indirectVictims, householdIncomesList);

  let PRevTpp = null;
  if (indirectVictims[victimId].caseData.TPP && indirectVictims[victimId].caseData.TPP.PRev0)
    PRevTpp = indirectVictims[victimId].caseData.TPP.PRev0;

  let changeCheckArray = [householdIncome,
                          indirectVictims[victimId].caseData.PRev0,
                          PRevTpp,
                          victim,
                          null];

  useEffect(() => {
    if (fields && fields.length === 0) addLine();
    // indirectVictims.forEach((indirectVictim,i) => {}
    //   // dispatch(change("caseDataForm",
    //   //                 `indirectVictims[${i}].caseData._meta.PRev.items[0]`,
    //   //                 getPRevEntryTotal(householdIncomesList, victim, indirectVictims, i)
    //   //               ));}
    // )
  }, changeCheckArray);

  const hasTPP = (index) => {
    return (fields.get(index) && fields.get(index).tpp && fields.get(index).tpp.length > 0);
  }

  return (
    <>
    {incomeLossCalcMethod ?
      <>
    {fields.map((line, index) => (
      <Fragment key={index}>
        {fields.length > 1 &&
          <div className={`input-group input-group-sm flex-nowrap${index > 0 ? " mt-4":""}`}>
            <div className="font-weight-bold" style={{ fontSize: '1rem'}}>{index+1}.&nbsp;</div>
            <Field
              className="form-control dsf-line-label mr-2"
              name={`${line}.incomeLossLabel`}
              component="input"
              type="text"
              placeholder="Libellé de la perte de revenus"
            />
            <BtnRemoveLine removeLine={removeLine} index={index} label="cette perte de revenus" />
          </div>
        }
        <table className={`table table-hover table-sm${fields && fields.length > 1 ? " mt-1":""}`}>
          <caption>
            <h6 className="mb-0">Etape 1 : préjudice annuel du foyer</h6>
          </caption>
          <tbody>
            <tr>
              <th scope="row" className="col-num" />
              <td>
                <div className="input-group input-group-sm flex-nowrap">
                  <div className="input-group-sm pr-2">
                    {householdIncomesList && householdIncomesList.length === 1 &&
                      <AmountField
                        readOnly
                        fieldValue={householdIncomesList[0] && householdIncomesList[0].amount ? householdIncomesList[0].amount : 0} className=" householdLossList"
                      />
                    }
                    {householdIncomesList && householdIncomesList.length > 1 &&
                      <Field
                        name={`${line}.householdLoss`}
                        component="select"
                        className="custom-select householdLossList"
                      >
                        <option value="">(sélectionnez un préjudice du foyer)</option>
                        {householdIncomesList.map((householdLoss, i) =>
                          <option key={i} value={householdLoss.incomeId}>
                            {householdLoss.label} ({Intl.NumberFormat("fr-FR", {style: "currency",currency: "EUR"}).format(Math.abs(householdLoss ? householdLoss.amount : 0))})
                          </option>
                        )}
                      </Field>}
                    </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary p-1"
                      onClick={()=>setIncomeFormOpen(true)}
                      title="Déterminer le préjudice économique du foyer"
                    >
                      <House fontSize="small" />&nbsp; {householdIncomesList && householdIncomesList.length > 0 ? "Modifier" : "Déterminer"} le préjudice économique du foyer
                    </button>
                  </div>
                </div>
              </td>
              <td className="col-act" />
            </tr>
            <tr>
              <td />
              <td className="allocation pt-0">
                <span className={`${allocationDetails[fields.get(index).householdLoss] && allocationDetails[fields.get(index).householdLoss].totalUsed > 100 ? "text-danger":""}`}>{getAllocationDetailsStr(allocationDetails, fields.get(index).householdLoss)}</span>
              </td>
              <td />
            </tr>
          </tbody>
        </table>
        <table className="table table-hover table-sm mt-2">
          <caption>
            <h6 className="mb-0">Etape 2 : part du préjudice annuel du foyer revenant à la victime</h6>
            </caption>
          <tbody>
            {incomeLossCalcMethod === "redistribution" &&
              <tr>
                <td className="col-num pb-0" />
                <td colSpan={3} className="pb-0">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"

                        name={`indirectVictims[${victimId}].caseData.PRev0[${index}].victStatus`}
                        value="sideVict"
                        id={`sideVict${index}`}
                        checked={fields.get(index) && fields.get(index).victStatus === "sideVict"}
                        onChange={e => onOptionChange(e)}
                        className="custom-control-input"
                        disabled={index > 0}
                      />
                      <label className="custom-control-label" htmlFor={`sideVict${index}`}>
                        <span className=''>La victime prend une part et réaffecte le surplus à son départ du foyer (ex : les enfants)</span>
                      </label>
                    </div>
                </td>
                <td className="col-act pt-0" />
              </tr>
            }
            {(incomeLossCalcMethod !== "redistribution" || (fields.get(index) && fields.get(index).victStatus === "sideVict")) &&
            <tr>
              <th scope="row" className="col-num pt-0" />
              <td colSpan={3} className={`pt-0${incomeLossCalcMethod === "redistribution" ? " pl-4":""}`}>
                <div className={`input-group input-group-sm flex-nowrap${incomeLossCalcMethod === "redistribution" ? " pl-4 ml-1":""}`}>
                  <div className="col-indirectPercentage">
                    <Field
                      name={`${line}.indirectVictShare`}
                      id={`${line}.indirectVictShare`}
                      component={NumberField}
                      placeholder={"0"}
                      max={100}
                      append={`%, soit ${Intl.NumberFormat("fr-FR", {style: "currency",currency: "EUR"}).format(getVictAmount(fields.get(index), householdIncomesList))}`}
                      className="refNumber font-weight-bold"
                    />
                  </div>
                  <div className="input-group input-group-sm flex-nowrap anncap">
                    <label htmlFor={`${line}.indirectVictShareDesc`} className="control-label text-nowrap mb-0">justifiée par : </label>
                    <Field
                      name={`${line}.indirectVictShareDesc`}
                      id={`${line}.indirectVictShareDesc`}
                      component="input"
                      type="text"
                      className="form-control"
                      placeholder="par ex. : la composition du foyer..."
                    />
                  </div>
                </div>
              </td>
              <td className="col-act pt-0" />
            </tr>
            }
            {incomeLossCalcMethod === "redistribution" &&
            <>
            <tr>
              <td className="col-num" />
              <td colSpan={3}>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    name={`indirectVictims[${victimId}].caseData.PRev0[${index}].victStatus`}
                    value="mainVict"
                    id={`mainVict${index}`}
                    checked={fields.get(index) && fields.get(index).victStatus === "mainVict"}
                    onChange={e => onOptionChange(e)}
                    className="custom-control-input"
                    disabled={(mainIndirectVict !== null && mainIndirectVict !== victimId) || index > 0}
                  />
                  <label className="custom-control-label" htmlFor={`mainVict${index}`}>
                    <span className={`${mainIndirectVict !== null && mainIndirectVict !== victimId ? "strikethrough":""}`}>La victime bénéficie de l'ensemble du préjudice du foyer après déduction des sommes revenant aux autres membres (ex : conjoint survivant)</span>
                  </label>
                </div>
              </td>
              <td className="col-act pt-0" />
            </tr>
            {mainIndirectVict !== null && mainIndirectVict !== victimId &&
              <tr>
                <td />
                <td colSpan={3} className="allocation pt-0 pl-4"><div className="pl-4 ml-1">
                  <span>La victime qui bénéficie de l'ensemble du préjudice du foyer après déduction des sommes revenant aux autres membres est {getIndirectVictimFullName(indirectVictims[mainIndirectVict].victim, mainIndirectVict, 0, true)}</span></div>
                </td>
                <td className="col-act pt-0" />
              </tr>
            }
              </>
            }
          </tbody>
        </table>
        <table className="table table-hover table-sm mt-2">
          <caption>
          <h6 className="mb-0">Etape 3 : calcul du préjudice (sur une base annuelle de {Intl.NumberFormat("fr-FR", {style: "currency",currency: "EUR"}).format(getVictAmount(fields.get(index), householdIncomesList))})</h6>
            </caption>
          <tbody>
            <tr>
              <td className="col-num" />
              <th scope="row" className="text-left arrearsLbl">Arrérages échus : </th>
              <td className="pastStartEnd">
                <div className="input-group input-group-sm flex-nowrap anncap">
                  <label htmlFor={`${line}.pastStartDate`} className="control-label text-nowrap mb-0">du</label>
                  <Field
                    name={`${line}.pastStartDate`}
                    component={DatePicker}
                    parse={normalizeDates}
                    format={formatDates}
                    className="form-control date"
                  />
                  <label htmlFor={`${line}.pastStartDate`} className="control-label text-nowrap ml-2 mb-0">au</label>
                  <Field
                    name={`${line}.pastEndDate`}
                    component={DatePicker}
                    parse={normalizeDates}
                    format={formatDates}
                    className="form-control date"
                  />
                  <div className="input-group-append"><span className="input-group-text">&nbsp;{getPastDurationStr(fields.get(index).pastStartDate, fields.get(index).pastEndDate)}</span></div>
                </div>
              </td>
              <td>
                <div className="input-group input-group-sm flex-nowrap anncap">
                  <label htmlFor={`${line}.pastDesc`} className="control-label text-nowrap mb-0">Précisions : </label>
                  <Field
                    name={`${line}.pastDesc`}
                    component="input"
                    type="text"
                    className="form-control"
                    placeholder="Précisions sur les arrérages échus"
                  />
                </div>
              </td>
              <td className="col-amount">
                <AmountField fieldValue={getPastAmount(fields.get(index), householdIncomesList)} />
              </td>
              <td className="col-act pt-0 pb-0" />
            </tr>
            <RenderPRevAnnCap
              item={item}
              line={line}
              lineIndex={index}
              entry={entry.id}
              lineValues={fields.get(index)}
              victim={victim}
              indirectVictim={indirectVictims[victimId].victim}
              meta={meta}
              victimId={victimId}
              householdIncomesList={householdIncomesList}
              lineAmount={getVictAmount(fields.get(index), householdIncomesList)}
              nbLines={fields.length}
              removeLine={()=>removeLine(index)}
            />
            {(hasTPP(index) || (rateOfCompensationEntitlement < 100) || (fields.length > 1) || (fields.get(index).victStatus === "mainVict")) &&
            <tr>
              <td/>
              <th scope="row" colSpan={3}>
                Total
              </th>
              <td className="col-amount pt-0 pb-0">
                <AmountField fieldValue={getPastAmount(fields.get(index), householdIncomesList) + getFutureAmount(fields.get(index), householdIncomesList)} />
              </td>
              <td className="col-act pt-0 pb-0" />
            </tr>}
            {(incomeLossCalcMethod === "redistribution" && fields.get(index).victStatus === "mainVict") &&
            <tr>
              <td/>
              <th scope="row" colSpan={3}>
                Préjudice total après déduction des préjudices ({Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(getPastAmount(fields.get(index), householdIncomesList) + getFutureAmount(fields.get(index), householdIncomesList) - getTotalAmount(fields.get(index), householdIncomesList, indirectVictims))}) des autres membres du foyer (méthode par réaffectation)
              </th>
              <td className="col-amount pt-0 pb-0">
                <AmountField fieldValue={getTotalAmount(fields.get(index), householdIncomesList, indirectVictims)} />
              </td>
              <td className="col-act pt-0 pb-0" />
            </tr>}
            {(rateOfCompensationEntitlement < 100) &&
            <tr>
              <th scope="row" colSpan={4}>Dette du responsable</th>
              <td className="col-amount pt-0 pb-0">
                <AmountField fieldValue={getPRevLineRespPartyAmount(fields.get(index), householdIncomesList, rateOfCompensationEntitlement, indirectVictims)} />
              </td>
              <td className="col-act pt-0 pb-0" />
            </tr>}
            {hasTPP(index) && (
                <FieldArray
                  name={`${line}.tpp`}
                  colSpan={3}
                  line={line}
                  component={RenderTPP}
                  entry={entry}
                  lineIndex={index}
                  tppValues={fields.get(index).tpp}
                  entryMeta={meta[entry]}
                  lineAmount={getTotalAmount(fields.get(index), householdIncomesList, indirectVictims)}
                  caption={"cette perte de revenus"}
                />
              )}
            {(hasTPP(index) && fields.length > 1) && <>
              <tr>
              <th scope="row" colSpan={4}>Créance de la victime</th>
              <td className="col-amount pt-0 pb-0">
                <AmountField fieldValue={getVictimClaim(fields.get(index), householdIncomesList, rateOfCompensationEntitlement, indirectVictims)} />
              </td>
              <td className="col-act pt-0 pb-0" />
            </tr>
            <tr>
              <th scope="row" colSpan={4}>Recours du/des tiers-payeur(s)</th>
              <td className="col-amount pt-0 pb-0">
                <AmountField fieldValue={getTppRecourse(fields.get(index), householdIncomesList, rateOfCompensationEntitlement, indirectVictims)} />
              </td>
              <td className="col-act pt-0 pb-0" />
            </tr>
            {tppTotal(fields.get(index).tpp) > getTotalAmount(fields.get(index), householdIncomesList, indirectVictims) &&
              <tr>
                <td className="col-num pt-0 pb-0" />
                <td colSpan={4} className="text-right align-bottom pt-0 pb-0">
                  <small className="text-danger">
                    Le reliquat de paiement de tiers payeur(s) non imputé de{" "}{Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(tppTotal(fields.get(index).tpp) - getTotalAmount(fields.get(index), householdIncomesList, indirectVictims))} n'est pas reporté automatiquement sur les autres pertes de revenus ou les autres postes de préjudice.
                  </small>
                </td>
                <td className="col-act pt-0 pb-0" />
              </tr>
            }
            </>}

          </tbody>
        </table>
        <div className="float-left"></div>
      </Fragment>))}
      <table className="table table-hover table-sm">
        <IndirectItemTableFooter
          item={item}
          entry={entry}
          itemIndex={0}
          victim={victim}
          meta={{}}
          victimId={victimId}
          addLine={() => addLine()}
          disableEntryTPP={true}
        />
      </table>
      </>
      :
      <div>Vous devez sélectionner une méthode de calcul afin de pouvoir déterminer la ou les pertes de revenus des proches. La méthode sélectionnée sera ensuite utilisée pour l'ensemble des victimes indirectes du dossier.</div>
      }

    </>

  );
};


const caseSelector = formValueSelector("caseDataForm");

const mapStateToProps = (state) => {
  let householdIncome, indirectVictims, incomeLossCalcMethod;

  if (state.cases && state.cases.case && state.cases.case.json_data) {
    householdIncome = caseSelector(state, "householdIncome");
    indirectVictims = caseSelector(state, "indirectVictims");
    incomeLossCalcMethod = caseSelector(state, "incomeLossCalcMethod")
  }

  return { householdIncome, indirectVictims, incomeLossCalcMethod };
};

RenderPRev.propTypes = {
  fields: PropTypes.object,
  item: PropTypes.object,
  victim: PropTypes.object,
  meta: PropTypes.object,
  dispatch: PropTypes.func,
  entry: PropTypes.object,
  itemId: PropTypes.number,
  setIncomeFormOpen: PropTypes.func,
  householdIncome: PropTypes.array,
  indirectVictims: PropTypes.array,
  victimId: PropTypes.number,
  incomeLossCalcMethod: PropTypes.string,
};

export default connect(mapStateToProps)(RenderPRev);
