import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import RenderInput from "./RenderInput";
import DatePicker, {
  formatDates,
  normalizeDates,
} from "./RenderDateInput";
import RenderTitleInput from "./RenderTitleInput";
import RenderGenderChoice from "./RenderGenderChoice";

const required = value => (value ? undefined : "Champ obligatoire");

let CreateVictim = props => {
  const {
    handleSubmit,
    caseId,
    formType,
    dispatch,
    caseTitle,
    lastName,
  } = props;

  const setTitleLastName = (newValue, previousValue) => {
    if (!caseTitle || caseTitle === previousValue)
      dispatch(change("victim", "title", newValue));
  };

  const setTitleFirstName = (newValue, previousValue) => {
    if (
      !caseTitle ||
      caseTitle === lastName ||
      caseTitle === lastName + " " + previousValue
    )
      dispatch(change("victim", "title", lastName + " " + newValue));
  };

  return (
    <div className="card border-primary wizard-form mx-auto">
      <h3 className="card-header">Victime</h3>
      <form onSubmit={handleSubmit} id="victim-form" className="h-100 d-flex flex-column">
        <div
          className="card-body"
        >
          <Field
            label="Nom"
            name="last_name"
            component={RenderInput}
            optional={true}
            type="text"
            placeholder="Nom de famille"
            onChange={(event, newValue, previousValue) =>
              setTitleLastName(newValue, previousValue)
            }
            wizard={true}
            autoFocus
          />
          <Field
            label="Prénom"
            name="first_name"
            component={RenderInput}
            optional={true}
            type="text"
            placeholder="Prénom"
            onChange={(event, newValue, previousValue) =>
              setTitleFirstName(newValue, previousValue)
            }
            wizard={true}
          />
          <Field
            label="Référence du dossier"
            name="title"
            component={RenderTitleInput}
            type="text"
            placeholder="Référence du dossier"
            validate={required}
            mandatory={true}
          />
          <Field
            label="Date de naissance"
            name="birth_date"
            component={DatePicker}
            parse={normalizeDates}
            format={formatDates}
            validate={required}
            mandatory={true}
            className=" wizard"
            wizard={true}
          />
          <Field
            name="victimGender"
            component={RenderGenderChoice}
            validate={required}
            mandatory={true}
            formType={formType}

          />
          <button
            type="submit"
            className="btn btn-sm btn-primary mt-auto w-100"
            title="Enregistrer les modifications"
          >
            {!caseId ? "Enregistrer et passer à l'étape suivante >>" : "Modifier les données de la victime"}
          </button>
        </div>
      </form>
    </div>
  );
};

CreateVictim = reduxForm({
  form: "victim",
  enableReinitialize: true
})(CreateVictim);

CreateVictim.propTypes = {
  handleSubmit: PropTypes.func,
  caseId: PropTypes.number,
  formType: PropTypes.string,
  caseTitle: PropTypes.string,
  lastName: PropTypes.string,
  dispatch: PropTypes.func,
  initialValues: PropTypes.object
};

const victimSelector = formValueSelector("victim");

const mapStateToProps = state => {
  let initialValues = {};
  let caseTitle = "";
  let lastName = "";

  if (state.cases && state.cases.case && state.cases.case.json_data) {
    initialValues = state.cases.case.json_data.victim;
    initialValues.title = state.cases.case.title;
  }
  caseTitle = victimSelector(state, "title");
  lastName = victimSelector(state, "last_name");

  return { initialValues, caseTitle, lastName };
};

export default connect(mapStateToProps)(CreateVictim);
