import React from "react";
import PropTypes from "prop-types";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import AppsIcon from "@material-ui/icons/Apps";
import PenIcon from "../../layout/icons/PenIcon"
import CalcIcon from "../../layout/icons/CalcIcon"

const BtnInputAmount = props => {
  const { amountDirectInput, switchInputAmount } = props;
  return (
    <button
      type="button"
      className={`btn btn-${amountDirectInput ? "primary" : "secondary"
        } btn-sm p-0 ml-1`}
      onClick={switchInputAmount}
      title={
        amountDirectInput
          ? "Calculer le montant automatiquement"
          : "Saisissez un préjudice librement"
      }
      style={{ height: 24 }}
    >
      {amountDirectInput ? <CalcIcon height="24px" /> : <PenIcon height="24px" />}
    </button>
  );
};

BtnInputAmount.propTypes = {
  amountDirectInput: PropTypes.bool,
  switchInputAmount: PropTypes.func.isRequired
};

export default BtnInputAmount;
