import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm, getFormValues, reset } from "redux-form";
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import { connect } from "react-redux";


let CaseTitle = props => {
  const { handleSubmit, title, currentTitle, dispatch } = props;
  const [edit, setEdit] = React.useState(false);
  const [invalidTitle, setInvalidTitle] = React.useState(false);

  React.useEffect(() => {
    if (!currentTitle)
       setInvalidTitle(true);
    else
      setInvalidTitle(false);
  })

  const handleOpen = () => {
    setEdit(true);
  };

  const handleClose = () => {
      dispatch(reset('caseTitleForm'));
      setEdit(false);
  };


  return (
<>
{edit ?
    <form>
      <div className="d-flex">
        <Field
            label="Nom"
            name="title"
            component="input"
            type="text"
            className="caseTitle titleInput"
            placeholder="Référence du dossier"
        />
        <button
            className="btn btn-primary btn-sm ml-1 smallIcon"
            title="Enregistrer les modifications"
            disabled={invalidTitle}
            onClick={(e)=>
              {
                e.preventDefault();
                handleSubmit();
                handleClose();
              }
          }
          >
            <CheckIcon />
          </button>
        <button
          type="submit"
          className="btn btn-secondary btn-sm smallIcon"
          title="Annuler les modifications"
          onClick={handleClose}
        >
          <BlockIcon />
        </button>
      </div>
    </form>
  :
    <div className="d-flex">
      <h2 className="mb-0 pb-0 pt-2 caseTitle titleInput">{title}</h2>
      <button
        type="button"
        className="btn btn-secondary btn-sm ml-2 smallIcon"
        onClick={handleOpen}
        title="Modifier l'intitulé du dossier"
      >
        <EditIcon />
        </button>
    </div>
}
  </>
  );
};

CaseTitle.propTypes = {
  title: PropTypes.string,
  currentTitle: PropTypes.string,
  handleSubmit: PropTypes.func,
  dispatch: PropTypes.func,
};


const mapStateToProps = state => {
  let initialValues = {};
  let currentTitle;
  let formValues = getFormValues("caseTitleForm")(state);

  if (formValues)
    currentTitle = formValues.title;

  if (state.cases.case && state.cases.case.title)
    initialValues = { title: state.cases.case.title };

  return { initialValues, currentTitle };
};


CaseTitle = reduxForm({
  form: "caseTitleForm",
  enableReinitialize: true
})(CaseTitle);

export default connect(mapStateToProps)(CaseTitle);
