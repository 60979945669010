import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "../../utils";

const CaseMetadata = props => {
  const { date_created, date_modified } = props;
  return (
    <div className="case-metadata mb-1">
      <small>
        {date_created && `Créé le ${formatDate(date_created, 0, true)}`}
        {date_modified &&
          date_created.slice(0, 19) !== date_modified.slice(0, 19) &&
          ` - Dernière modification le ${formatDate(date_modified, 0, true)}
        `}
      </small>
    </div>
  );
};

CaseMetadata.propTypes = {
  date_created: PropTypes.string,
  date_modified: PropTypes.string
};

export default CaseMetadata;
