import React from "react";
import PropTypes from "prop-types";
import Append from "../../fields/Append";
import NumberFormat from "react-number-format";

const RenderCEInput = ({
  input,
  label,
  type,
  placeholder,
  mandatory,
  append,
  className,
  readOnly,
  meta: { touched, error, warning }
}) => {
  return (
    <div
      className={`form-group${touched && (error || warning) ? " has-danger" : ""
        }${className || ""}`}
    >
      <label className="control-label" htmlFor={input.name}>
        {label}
        {mandatory && <span className="text-danger"> *</span>}
        {touched && (error || warning) && (
          <span className="invalid-feedback ml-3" style={{ display: "inline" }}>{error || warning}</span>
        )}
      </label>
      <div className={append ? "d-flex" : ""}>
        <NumberFormat
          {...input}
          displayType="input"
          thousandSeparator=" "
          decimalSeparator=","
          decimalScale={1}
          allowNegative={false}
          type="text"
          readOnly={readOnly}
          className={`form-control text-right ${touched && (error || warning) ? " is-invalid" : ""}`}
          placeholder={placeholder ? placeholder : 0}
          id={input.name}
        />
        {append &&
          <Append append={append} className=" pl-1" />
        }
      </div>
    </div>
  );
};

RenderCEInput.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  mandatory: PropTypes.bool,
  append: PropTypes.string,
  className: PropTypes.string,
  meta: PropTypes.object
};

export default RenderCEInput;
