import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import RenderInput from "./RenderInput";
import RenderCEInput from "./RenderCEInput";
import DatePicker, {
  formatDates,
  normalizeDates,
} from "./RenderDateInput";


const required = value => (value ? undefined : "Champ obligatoire");
const ce1to100 = value =>
  value && (value > 100 || value <= 0) ? "Le droit à indemnisation doit être compris entre 1 et 100 %" : undefined;

let CreateAccident = props => {
  const {
    handleSubmit,
    accidentType
  } = props;

  return (
    <div className="card border-primary wizard-form mx-auto">
      <h3 className="card-header">Accident</h3>
      <form onSubmit={handleSubmit} id="accident-form" className="h-100">
        <div className="card-body h-100 d-flex flex-column">
          <div className="form-group">
            <label className="control-label" htmlFor="accidentType">
              Nature
            </label>
            <Field
              name="accidentType"
              id="accidentType"
              component="select"
              className="custom-select"
              autoFocus
            >
              <option value="accident">
                accident
              </option>
              <option value="aggravation">aggravation</option>
            </Field>
          </div>
          <Field
            label={`Date de l'${accidentType ? accidentType : "accident"}`}
            name="date"
            component={DatePicker}
            parse={normalizeDates}
            format={formatDates}
            className=" wizard"
            wizard={true}
          />
          <Field
            label="Date de consolidation"
            name="consolidationDate"
            component={DatePicker}
            parse={normalizeDates}
            format={formatDates}
            className=" wizard"
            wizard={true}
          />
          <Field
            label="Date de décès"
            name="deathDate"
            component={DatePicker}
            parse={normalizeDates}
            format={formatDates}
            className=" wizard"
            wizard={true}
          />
          <Field
            label="Droit à indemnisation"
            name="rateOfCompensationEntitlement"
            component={RenderCEInput}
            type="text"
            placeholder="0"
            validate={[required, ce1to100]}
            mandatory={true}
            append="%"
            className=" wizard ce-rate"
          />
          <button type="submit" className="btn btn-sm btn-primary mt-auto">
            {"Enregistrer et passer à l'étape suivante >>"}
          </button>
        </div>
      </form>
    </div>
  );
};

CreateAccident = reduxForm({
  form: "accident",
  enableReinitialize: true
})(CreateAccident);

CreateAccident.propTypes = {
  handleSubmit: PropTypes.func,
  accidentType: PropTypes.string
};

const accidentSelector = formValueSelector("accident");

const mapStateToProps = state => {
  let initialValues = { accidentType: "accident", rateOfCompensationEntitlement: 100 };
  let accidentType = initialValues.accidentType;

  if (state.cases && state.cases.case && state.cases.case.json_data) {
    accidentType = accidentSelector(state, "accidentType");
  }

  return {
    initialValues,
    accidentType
  };
};

export default connect(mapStateToProps)(CreateAccident);
