import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {duplicateCase} from "../../actions/cases";
import {connect, useDispatch} from "react-redux";
import PropTypes from "prop-types";
import Tooltip from '@material-ui/core/Tooltip';

const DuplicateCaseButton = props => {
    const {legalCase} = props;
    const dispatch = useDispatch();
    const caseId = legalCase.id;
    const caseTitle = legalCase.title;

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = id => {
        dispatch(duplicateCase(id));
        setOpen(false);

    }

    return (
        <div>
            <Tooltip title="Dupliquer le dossier" placement="left">
                <IconButton color="default" size="small" onClick={handleClickOpen}>
                    <FileCopyIcon/>
                </IconButton>
            </Tooltip>
            {open ? <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Dupliquer le dossier {caseTitle} ?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Souhaitez-vous vraiment dupliquer le dossier {caseTitle} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" size="small" title="Dupliquer le dossier">
                        Annuler
                    </Button>
                    <Button onClick={() => handleConfirm(caseId)} variant="contained" color="default" size="small"
                            startIcon={<FileCopyIcon/>} autoFocus>
                        Confirmer la copie
                    </Button>
                </DialogActions>
            </Dialog> : null}
        </div>
    );
}

DuplicateCaseButton.propTypes = {
    legalCase: PropTypes.object.isRequired,
};


export default connect()(DuplicateCaseButton);
