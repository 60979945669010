import React from "react";
import PropTypes from "prop-types";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";

function BtnAddItemLine({ addLine, label, iconSize }) {
  let btnLabel = "Ajouter ";
  let fontSize = 'default';
  if (iconSize) fontSize = iconSize;
  label ? (btnLabel += label) : (btnLabel += "une dépense");

  return (
    <div className="float-left">
      <button
        type="button"
        className="btn btn-outline-dark btn-sm"
        onClick={() => addLine()}
        title={btnLabel}
      >
        <AddToPhotosIcon fontSize={fontSize} />
        &nbsp; {btnLabel}
      </button>
    </div>
  );
}

BtnAddItemLine.propTypes = {
  addLine: PropTypes.func.isRequired,
  label: PropTypes.string,
  iconSize: PropTypes.string,
};

export default BtnAddItemLine;
