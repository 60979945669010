import { interval } from "../../utils";


export const getPgpaRemainder = (line) => {
  let pgpaRemainder = 0;

  let pgpaLineDamage = 0;
  let workIncome = 0;

  if (line.amountDirectInput)
    pgpaLineDamage = parseFloat(line.lineAmount ? line.lineAmount.replace(",", ".").replace(/\s/g, "") : 0);
  else if (line.discounting && line.discounting.discountedTotal)
    pgpaLineDamage = line.discounting.discountedTotal;
  else if ((line.startDate || line.endDate) && line.periodRate){
    let startDate, endDate;
    if (line.startDate && !line.endDate)
      endDate = startDate = line.startDate;
    else if (!line.startDate && line.endDate)
      endDate = startDate = line.endDate;
    else {
      startDate = line.startDate
      endDate = line.endDate
    }
    const duration = interval(startDate, endDate, line.durationType || "days");
    pgpaLineDamage = duration * parseFloat(line.periodRate.replace(",", ".").replace(/\s/g, ""));
  }

  if (!line.amountDirectInput && pgpaLineDamage > 0 && line.workIncome) {
    workIncome = parseFloat(line.workIncome.replace(",", ".").replace(/\s/g, ""));
    let durationNum = 1;
    if (line.incomeType && line.incomeType !== "period")
      durationNum = interval(line.startDate, line.endDate, line.incomeType);
    workIncome *= durationNum;
  }

  pgpaRemainder = pgpaLineDamage - workIncome;

  return pgpaRemainder;
}

export const getPgpaLineDamage = (line) => {
  let pgpaLineDamage = 0;

  if (line.amountDirectInput)
    pgpaLineDamage = parseFloat(line.lineAmount ? line.lineAmount.replace(",", ".").replace(/\s/g, "") : 0);
  else if (line.discounting && line.discounting.discountedTotal)
    pgpaLineDamage = line.discounting.discountedTotal;
  else if ((line.startDate || line.endDate) && line.periodRate){
    let startDate, endDate;
    if (line.startDate && !line.endDate)
      endDate = startDate = line.startDate;
    else if (!line.startDate && line.endDate)
      endDate = startDate = line.endDate;
    else {
      startDate = line.startDate
      endDate = line.endDate
    }
    const duration = interval(startDate, endDate, line.durationType || "days");
    pgpaLineDamage = duration * parseFloat(line.periodRate.replace(",", ".").replace(/\s/g, ""));
  }
  
  if (!line.amountDirectInput && pgpaLineDamage > 0 && line.workIncome) {
    const workIncome = parseFloat(line.workIncome.replace(",", ".").replace(/\s/g, ""));
    let durationNum = 1;
    if (line.incomeType && line.incomeType !== "period")
      durationNum = interval(line.startDate, line.endDate, line.incomeType);
    pgpaLineDamage = Math.max(0, pgpaLineDamage - workIncome * durationNum);
  }

  return pgpaLineDamage;
}


// to be implemented
export const getPGPFFutureLineAmount = (line) => {
  if (line.amountDirectInput) {
    return parseFloat(
      line.lineAmount
      ? line.lineAmount.replace(",", ".").replace(/\s/g, "")
      : 0
    );
  }

  let refAmount = 0;
  let yearlyAmount = 0;
  let workIncomeAmount = 0;
  let yearlyWorkIncomeAmount = 0;

  if (line.periodRate)
    refAmount = parseFloat(line.periodRate.replace(",", ".").replace(/\s/g, ""));
  
  if (line.discounting && line.discounting.indexValue)
    refAmount *= line.discounting.indexValue;

  refAmount = Math.round(refAmount * 100) / 100;
  
  switch (line.durationType) {
    case "months":
      yearlyAmount = refAmount * 12;
      break;
    case "weeks":
      yearlyAmount = refAmount * 52;
      break;
    case "days":
      yearlyAmount = refAmount * 365;
      break;
    default:
      yearlyAmount = refAmount;
  }


  if (line.workIncome)
    workIncomeAmount = parseFloat(line.workIncome.replace(",", ".").replace(/\s/g, ""));

  switch (line.incomeType) {
    case "months":
      yearlyWorkIncomeAmount = workIncomeAmount * 12;
      break;
    case "weeks":
      yearlyWorkIncomeAmount = workIncomeAmount * 52;
      break;
    case "days":
      yearlyWorkIncomeAmount = workIncomeAmount * 365;
      break;
    default:
      yearlyWorkIncomeAmount = workIncomeAmount;
    }
      
  yearlyAmount -= yearlyWorkIncomeAmount;
  yearlyAmount = Math.max(yearlyAmount,0);

  return yearlyAmount;
};