import React, { useState } from 'react'
import axios from "axios";

export default function CancelSubscription(props) {
    const { active, token } = props;

    const [submitted, setSubmitted] = useState(false);
    const [cancelSuccess, setCancelSuccess] = useState(false);
    const [cancelError, setCancelError] = useState(false);


    const stripeCall = () => {
        const config = {
            headers: {
                "Authorization": `Token ${token}`
            }
        };
        const body = {}

        axios
            .post(`/api/auth/cancel_subscription`, body, config)
            .then(res => {
                setCancelSuccess(true)
                if (res.data && res.data.result === "success")
                    setCancelSuccess(true)
                else
                    setCancelError(true)

            })
            .catch(err => {
                setCancelSuccess(true)
                setCancelError(true)
            });
    }


    const handleCancel = () => {
        setSubmitted(true);
        stripeCall()

    }

    return (
        <div>
            {(!cancelSuccess && !cancelError) ?
                <button className={`btn btn-secondary${active ? "" : " disabled"}`} onClick={handleCancel} disabled={submitted}>
                    {submitted ? <>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Demande en cours de traitement...</>
                        :
                        "Annuler mon abonnement"
                    }
                </button> :
                <div>
                    {cancelError ?
                        <span className="text-danger">Une erreur s'est produite et votre demande d'annulation n'a pas pu être traitée. Veuillez rafracîchir la page et réessayer. Si le problème persiste, vous pouvez nous contacter à <a href="mailto:quantum@juri-solutions.fr">quantum@juri-solutions.fr</a>.</span> :
                        <span>Votre demande d'annulation a bien été prise en compte. Vous pourrez continuer à utiliser Quantum jusqu'à la fin de la période en cours.</span>}

                </div>
            }
        </div>
    )
}
