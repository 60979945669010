import React, {Fragment, memo} from "react";
import { connect } from "react-redux";
import { Field, change } from "redux-form";
import PropTypes from "prop-types";
import Append from "./Append";
import { interval, getFrenchDuration } from "../utils";
import AmountField from "./AmountField";
import NumberField from "./NumberField";
import RefNumber from "./RefNumber";
import TextField from "./TextField";
import ReferenceDuration from "./ReferenceDuration";
import { getHoursPerYear, discountMonthlyAmounts, getDiscountedTotals } from "../physical_injury/calculations";
import DatePicker, {
  formatDates,
  normalizeDates,
} from "../physical_injury/creation_wizard/RenderDateInput";
import DisplayDiscountingFormPGP from "../discounting/DisplayDiscountingFormPGP";
import DisplayDiscountingForm from "../discounting/DisplayDiscountingForm";



const RenderField = props => {
  const { field, line, item, entry, index, values, readOnly, dispatch } = props;

  const updatePGPDiscounting = (newValue, fieldName) => {
    const line = values[entry][index];
    line[fieldName] = newValue;
    let updatedDiscounting = line.discounting;
    updatedDiscounting.discountedRefIncome = discountMonthlyAmounts(line, updatedDiscounting.endIndexValue);
    updatedDiscounting.discountedTotals = getDiscountedTotals(updatedDiscounting.discountedRefIncome);
    updatedDiscounting.discountedTotal = updatedDiscounting.discountedTotals.discountedTotal
    dispatch(change("caseDataForm", `${entry}[${index}].discounting`, updatedDiscounting));
  }

  let duration = 0;
  switch (field.fieldType) {
    case "datePeriod":
      return (
        <Fragment>
          <Field
            name={`${line}.startDate`}
            component={DatePicker}
            parse={normalizeDates}
            format={formatDates}
            className="form-control date"
          />
          <Field
            name={`${line}.endDate`}
            component={DatePicker}
            parse={normalizeDates}
            format={formatDates}
            className="form-control date ml-2"
          />
        </Fragment>
      )
    case "date":
      return (
        <Field
          name={`${line}.${field.name}`}
          component={DatePicker}
          parse={normalizeDates}
          format={formatDates}
          className="form-control date"
          onChange={(event, newValue) => {
            if (item.itemType==="PGPA" && values[entry][index].discounting && values[entry][index].discounting.indexValue)
              updatePGPDiscounting(newValue, field.name)
          }}
        />

      )
    case "duration":
      duration = interval(
        values[entry][index].startDate,
        values[entry][index].endDate,
        values[entry][index].durationType || "days"
      );
      return (
        <div className="input-group-append">
          <span className="input-group-text">{(+duration).toLocaleString("fr-FR", {
            maximumFractionDigits: 3
          })}&nbsp;{line.indexOf("DFT") === 0 ? "jours" : `${getFrenchDuration(values[entry][index].durationType)}${duration >= 2 && values[entry][index].durationType !== "months" ? "s" : ""}`}</span>
        </div>
      );
    case "sevenPtsRate":
      return (
        <Fragment>
          <Field
            name={`${line}.damageRate`}
            component="select"
            className="custom-select sevenPtsRate selectNum"
          //disabled={field.disableOnAmountInput ? readOnly : false}
          >
            <option value="0" default>0</option>
            <option value="0.5">0,5</option>
            <option value="1">1</option>
            <option value="1.5">1,5</option>
            <option value="2">2</option>
            <option value="2.5">2,5</option>
            <option value="3">3</option>
            <option value="3.5">3,5</option>
            <option value="4">4</option>
            <option value="4.5">4,5</option>
            <option value="5">5</option>
            <option value="5.5">5,5</option>
            <option value="6">6</option>
            <option value="6.5">6,5</option>
            <option value="7">7</option>
          </Field>
          <Append append="&nbsp;/7 pts" />
        </Fragment>
      );
    case "periodType":
      return (
        <Fragment>
          <div className="input-group-prepend">
            <span className="input-group-text">par</span>
          </div>
          <Field
            name={`${line}.periodType`}
            component="select"
            className="custom-select periodType"
            disabled={field.disableOnAmountInput ? readOnly : false}
          >
            <option value="days" default>
              jour
            </option>
            <option value="weeks">semaine</option>
            <option value="months">mois</option>
          </Field>
          <ReferenceDuration
            periodType={values[entry][index].periodType}
            line={line}
            readOnly={readOnly}
            additionalText={
              item.itemType === "TPF" && `, soit ${getHoursPerYear(values[entry][index])} par an.`
            }
          />
        </Fragment>
      );
    case "frequency":
      return (
        <Fragment>
          <div className="input-group-prepend">
            <span className="input-group-text">Tous les</span>
          </div>
          <Field
            name={`${line}.frequencyNum`}
            component="input"
            type="number"
            className="form-control text-right mr-1 frequencyNum"
            placeholder="0"
            step="1"
            min="0"
            readOnly={field.disableOnAmountInput ? readOnly : false}
            disabled={field.disableOnAmountInput ? readOnly : false}
          />
          <Field
            name={`${line}.frequencyType`}
            component="select"
            className="custom-select frequencyType"
            disabled={field.disableOnAmountInput ? readOnly : false}
          >
            <option value="12" default>
              ans
            </option>
            <option value="3">trimestres</option>
            <option value="1">mois</option>
          </Field>
        </Fragment>
      );
    case "amount":
      return (
        <Field
          name={`${line}.${field.name}`}
          component={AmountField}
          readOnly={field.disableOnAmountInput ? readOnly : false}
          disabled={field.disableOnAmountInput ? readOnly : false}
          entry={entry}
          lineIndex={entry === "DFP0" && index}
        />
      );
    case "refAmount":
      return (
        <Fragment>
          <Field
            name={`${line}.${field.name}`}
            component={AmountField}
            readOnly={field.disableOnAmountInput ? readOnly : false}
            disabled={field.disableOnAmountInput ? readOnly : false}
            onChange={(event, newValue) => {
              if (item.itemType==="PGPA" && values[entry][index].discounting && values[entry][index].discounting.indexValue)
                updatePGPDiscounting(newValue, field.name)
            }}
          />
          <div className="input-group input-group-sm flex-nowrap">
            <div className="input-group-append">
              <span className="input-group-text">&nbsp;par&nbsp;</span>
            </div>
            <Field
              name={`${line}.durationType`}
              component="select"
              className="custom-select refAmountDurationType"
              disabled={readOnly}
              onChange={(event, newValue) => {
                if (item.itemType==="PGPA" && values[entry][index].discounting && values[entry][index].discounting.indexValue)
                  updatePGPDiscounting(newValue, "durationType")
              }}
            >
              <option value="days" default>
                jour
              </option>
              <option value="weeks">semaine</option>
              <option value="months">mois</option>
              <option value="years">an</option>
            </Field>
            {item.itemType==="PGPA" ?
            <DisplayDiscountingFormPGP
              values={values}
              entry={entry}
              index={index}
              disabled={readOnly}
            />
            :
            <DisplayDiscountingForm
            lineValues={values[entry][index]}
            amount={values[entry][index].periodRate ? parseFloat(values[entry][index].periodRate.replace(",", ".").replace(/\s/g, "")) : 0}
            entry={entry}
            index={index}
            disabled={readOnly}
          />
          }
          </div>
        </Fragment>
      );
    case "workIncome":
      return (
        <Fragment>
          <Field
            name={`${line}.${field.name}`}
            component={AmountField}
            readOnly={field.disableOnAmountInput ? readOnly : false}
            disabled={field.disableOnAmountInput ? readOnly : false}
          />
          <div className="input-group input-group-sm flex-nowrap">&nbsp;
            <Field
              name={`${line}.incomeType`}
              component="select"
              className="custom-select incomeType"
              disabled={readOnly}
            >
              <option value="period" default>sur la période</option>
              <option value="days" >par jour sur la période</option>
              <option value="weeks">par semaine sur la période</option>
              <option value="months">par mois sur la période</option>
              <option value="years">par an sur la période</option>
            </Field>
          </div>
        </Fragment>
      );
    case "workIncomeProj":
        return (
          <Fragment>
            <Field
              name={`${line}.${field.name}`}
              component={AmountField}
              readOnly={field.disableOnAmountInput ? readOnly : false}
              disabled={field.disableOnAmountInput ? readOnly : false}
            />
            <div className="input-group input-group-sm flex-nowrap">&nbsp;
              <Field
                name={`${line}.incomeType`}
                component="select"
                className="custom-select incomeType"
                disabled={readOnly}
              >
                <option value="days" >par jour</option>
                <option value="weeks">par semaine</option>
                <option value="months">par mois</option>
                <option value="years" default>par an</option>
              </Field>
            </div>
          </Fragment>
        );
    case "number":
      return (
        <Field
          name={`${line}.${field.name}`}
          component={NumberField}
          readOnly={field.disableOnAmountInput ? readOnly : false}
          disabled={field.disableOnAmountInput ? readOnly : false}
          placeholder={field.placeholder}
          // step={field.step}
          // max={field.max}
          append={field.append}
          className="numberField"
        />
      );
    case "refNumber":
      return (
        <Fragment>
          <Field
            name={`${line}.${field.name}`}
            component={RefNumber}
            readOnly={field.disableOnAmountInput ? readOnly : false}
            disabled={field.disableOnAmountInput ? readOnly : false}
            placeholder={field.placeholder}
            append={field.append}
            className="refNumber"
            line={line}
            periodType={values[entry][index].durationType}
          />
        </Fragment>
      );
    case "longText":
      return (
        <Field
          name={`${line}.${field.name}`}
          component={TextField}
          readOnly={field.disableOnAmountInput ? readOnly : false}
          disabled={field.disableOnAmountInput ? readOnly : false}
          placeholder={item.textPlaceHolder}
        />
      );
    default:
      return (
        <Fragment>
          <Field
            name={`${line}.${field.name}`}
            component="input"
            type={field.fieldType}
            className="form-control"
            placeholder={field.placeholder}
            step={field.step}
            min={field.min}
            max={field.max}
            readOnly={field.disableOnAmountInput ? readOnly : false}
            disabled={field.disableOnAmountInput ? readOnly : false}
          />
          {field.append && <Append append={field.append} />}
        </Fragment>
      );
  }
};

RenderField.propTypes = {
  item: PropTypes.object,
  entry: PropTypes.string,
  field: PropTypes.object,
  line: PropTypes.string,
  values: PropTypes.object,
  index: PropTypes.number,
  readOnly: PropTypes.bool,
  dispatch: PropTypes.func
};

export default connect()(memo(RenderField));
