import React, {memo} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { change } from "redux-form";
import NumberFormat from "react-number-format";
import Append from "./Append";

const NumberField = props => {
  const {
    input,
    readOnly,
    placeholder,
    append,
    step,
    max,
    disabled,
    id,
    dispatch,
    className
  } = props;

  const setDFTRate = (e) => {
    dispatch(
      change(
        "caseDataForm",
        `${input.name}`,
        e.target.value
      )
    );

  }

  return (
    <div className={`input-group input-group-sm flex-nowrap${input.name.indexOf("DFT") === 0 ? " dftRate" : ""}`}>
      {input.name.indexOf("DFT") === 0 &&
        <select
          name={`${input.name}Class`}
          className="custom-select mr-1"
          onChange={e => setDFTRate(e)}
          value={input.value}
          disabled={disabled}
        >
          <option value="" default>(libre)</option>
          <option value="10">classe I</option>
          <option value="25">classe II</option>
          <option value="50">classe III</option>
          <option value="75">classe IV</option>
          <option value="100">total</option>
        </select>}
      <NumberFormat
        {...input}
        disabled={disabled}
        displayType="input"
        thousandSeparator=" "
        decimalSeparator=","
        decimalScale={2}
        allowNegative={false}
        //type={max ? "number" : "text"}
        type="text" //when type = number, issue with the decimal separator issue #99
        className={`form-control text-right ${className ? className : ""}`}
        placeholder={placeholder ? placeholder : 0}
        step={step}
        max={max}
        readOnly={readOnly}
        id={id}
      />
      {append && <Append append={append} />}
    </div>
  );
};

NumberField.propTypes = {
  input: PropTypes.object,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  append: PropTypes.string,
  step: PropTypes.number,
  max: PropTypes.number,
  id: PropTypes.string,
  dispatch: PropTypes.func
};

//export default NumberField;
export default connect()(memo(NumberField));
