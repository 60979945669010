import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'left',
        maxWidth: "600px",
    },

}));

export default function DisplayWarningMsg() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);    
    const handleClose = () => {
        setOpen(false)
    };

    return (
<div onClick={((e) => e.stopPropagation())}>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Mise à jour Quantum</h2>
                        <p>Suite à un revirement de jurisprudence de la Cour de cassation (Ccass 20 janvier 2023 - n° 21-23.947 et n°20-23.673), la déduction en cascade des rentes en cas de reliquat après imputation sur le poste de PGPF <strong>ne sera plus imputée automatiquement</strong> sur le poste de DFP.</p>
                        <p>Cela affecte <strong>également</strong> les dossiers déjà créés sur Quantum avant ce revirement de jurisprudence.</p>
                        <p>Si vous souhaitez malgré cela imputer un éventuel reliquat sur le DFP après imputation de la rente sur l'IP, il vous faut désormais le faire manuellement comme suit :</p>
                        <ol>
                            <li>copier le montant du reliquat indiqué en rouge sous le poste IP</li>
                            <li>créer une ligne de tiers payeurs dans le poste DFP</li>
                            <li>coller ce montant.</li>
                            </ol>
                        <p>Vous pouvez également et toujours désactiver toutes les déductions en cascade en cochant "Désactiver les déductions en cascade" en haut à droit de l'écran.</p>
                    </div>
                </Fade>
            </Modal></div>
    );
}