import React from 'react';
import PropTypes from 'prop-types';
import {Field, FormSection}  from "redux-form";
import DeleteIndirectVictimButton from '../case_layout/DeleteIndirectVictimButton';
import { getIndirectVictimTitle, getIndirectVictimLink } from '../../utils';
import RenderInput from '../creation_wizard/RenderInput';
import { familyLinkTypes } from '../constants';
import { formatDate, interval } from '../../utils';
import IndirectVictimEntries from './IndirectVictimEntries';

let IndirectVictim = (props) => {
  const { victimId, indirectVictim, directVictim, setIncomeFormOpen, setMethodChoiceOpen } = props;
  const victimTitle = getIndirectVictimTitle(indirectVictim.victim, victimId);
  const victimGender = indirectVictim.victim.victimGender;
  let gender = 0;
  if (victimGender === "F") gender = 1;
  let linkLabel = getIndirectVictimLink(indirectVictim.victim, true);
  if (linkLabel.indexOf("Autre") === 0) {
    linkLabel = "Homme";
    if (gender === 1) linkLabel = "Femme";
  }

  const victimBirthDate = indirectVictim.victim.birth_date;
  let accidentAge, deathAge;
  if (directVictim.date) accidentAge = Math.floor(interval(victimBirthDate, directVictim.date, "years", false))
  if (directVictim.deathDate) deathAge = Math.floor(interval(victimBirthDate, directVictim.deathDate, "years", false))
  const today = new Date();

  return (
    <>
      <FormSection name="victim">
        <div className="card border-light main-form mx-auto mt-2 sticky-top sticky-offset-indirect-victim">
          <div className="card-body">
            <div className="container-fluid main-form-header p-0">
              <div className="d-flex mb-1">
                <Field
                  label="Nom"
                  name="last_name"
                  component={RenderInput}
                  type="text"
                  style={{fontSize: "0.8125rem"}}
                  placeholder="Nom de famille"
                />
                <Field
                  label="Prénom"
                  name="first_name"
                  component={RenderInput}
                  type="text"
                  style={{fontSize: "0.8125rem"}}
                  placeholder="Prénom"
                />
                <div className='mr-3 mt-auto'>
                  {linkLabel}, né{gender === 1 ? "e":""}&nbsp;le&nbsp;{formatDate(victimBirthDate, 0, false, true)} ({Math.floor(interval(victimBirthDate, today, "years", false))} ans)
                  {accidentAge !== undefined && 
                    <>
                    <br/>
                    {accidentAge} ans à la date de l'accident
                    </>
                  }
                  {deathAge !== undefined && 
                    <>
                    <br/>
                    {deathAge} ans à la date du décès
                    </>
                  }
                </div>
                <div className='d-flex'>
                  <div className="form-group flex-fill">
                    <label className="control-label" htmlFor="accidentType">
                        Lien avec la victime directe
                    </label>
                    <Field
                        name="linkType"
                        id="linkType"
                        component="select"
                        className="custom-select form-control accidentType"
                        style={{maxWidth: "10rem"}}
                    >
                        {Object.keys(familyLinkTypes).map((link, key) => <option value={link} key={key} defaultValue={true}>{familyLinkTypes[link][gender]}</option>)}
                    </Field>
                  </div>
                  {indirectVictim.victim.linkType === 'other' &&
                    <Field
                      label="Précision"
                      name="otherLink"
                      component={RenderInput}
                      type="text"
                      className=" linkType"
                      style={{fontSize: "0.8125rem"}}
                    />
                  }
                </div>
                <div className='ml-auto mt-2'><DeleteIndirectVictimButton victimId={victimId} victimTitle={victimTitle} /></div>
              </div>
            </div>
          </div>
        </div>
      </FormSection>
      <h3 className="display-4">Préjudice de la victime indirecte</h3>
      <FormSection name="caseData">
        <IndirectVictimEntries
          victimId={victimId}
          setIncomeFormOpen={setIncomeFormOpen}
          setMethodChoiceOpen={setMethodChoiceOpen}
        />
      </FormSection>
    </>                       
  )
}

IndirectVictim.propTypes = {
  indirectVictim: PropTypes.object.isRequired,
  directVictim: PropTypes.object.isRequired,
  victimId: PropTypes.number,
  setIncomeFormOpen: PropTypes.func,
  setMethodChoiceOpen: PropTypes.func,
};

export default IndirectVictim;