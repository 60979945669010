import React, {memo} from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import {change, reset, FormSection, formValueSelector} from "redux-form";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { getIndirectVictimFullName, getIndirectVictimLink } from "../../utils";
import IndirectVictim from '../case_data_form/IndirectVictim';
import DirectVictimEntries from '../case_data_form/DirectVictimEntries';
import IndirectVictimBtn from './IndirectVictimBtn';
import { indirectVictimModel } from "../../physical_injury/dataModels";

const a11yProps = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper
    },
    tabLabel:{
      textTransform: 'none',
    }
}));



const TabLabel = (props) => {
  const { victim, i } = props;
  const classes = useStyles();

  return (
    <div>
      <Typography variant="button" className={classes.tabLabel}>{getIndirectVictimFullName(victim.victim, i, 30)}</Typography>
      <Typography className={classes.tabLabel}>({getIndirectVictimLink(victim.victim)})</Typography>
    </div>
  );
}

  let VictimTabs = (props) => {
    const { directVictim, indirectVictims, currentVictim, dispatch, nbIndirectVictims, formIndirectVictims, setIncomeFormOpen, setMethodChoiceOpen } = props
    const classes = useStyles();

    const [addVictimFormOpen, setAddVictimFormOpen] = React.useState(false);
    const handleChange = (event, newValue) => {
      dispatch(change("caseDataForm", "currentVictim", newValue));
    };
    const currentTab = Math.min(currentVictim, indirectVictims.length);

    const handleSubmitIndirectVictim = formValues => {
      if (!formValues.linkType) formValues.linkType = "spouse";
      let newIndirectVictim = {
        victim: formValues,
        caseData:{},
        caseDataForm: indirectVictimModel
      }
      let newIndirectVictims = formIndirectVictims;
      newIndirectVictims.push(newIndirectVictim)

      dispatch(change("caseDataForm", "indirectVictims", newIndirectVictims));
      dispatch(change("caseDataForm", "currentVictim", nbIndirectVictims));
      dispatch(reset('indirectVictimForm'));
      setAddVictimFormOpen(false);
    }

    return (
      <div className={classes.root} style={{backgroundColor: 'white',}}>
        <div className="sticky-top sticky-offset-tabs d-flex">
          <div className="w-100">
            <AppBar position="sticky" color="default">
              <Tabs
                value={currentTab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                style={{minHeight:"56px"}}
              >
                <Tab label="Victime directe" {...a11yProps(0)} className={classes.tabLabel} />
                {indirectVictims.map((victim, i) => <Tab key={i} label={<TabLabel victim={victim} i={i} />} {...a11yProps(i+1)} />)}
              </Tabs>
            </AppBar>
          </div>
          <IndirectVictimBtn onSubmit={handleSubmitIndirectVictim} handleAddVictimForm={setAddVictimFormOpen} open={addVictimFormOpen} />
        </div>
        {currentTab === 0 && <div className='mt-2'><DirectVictimEntries victim={directVictim} /></div>}
        {currentTab !== 0 && indirectVictims[currentVictim-1] && indirectVictims[currentVictim-1].victim &&
          <div className="sticky-top sticky-offset-indirect">
            <FormSection
              name={`indirectVictims[${currentVictim-1}]`}
              component={IndirectVictim}
              indirectVictim={indirectVictims[currentVictim-1]}
              victimId={currentVictim-1}
              directVictim={directVictim}
              setIncomeFormOpen={setIncomeFormOpen}
              setMethodChoiceOpen={setMethodChoiceOpen}
            />
          </div>
        }
      </div>
    );
}

VictimTabs.propTypes = {
  indirectVictims: PropTypes.array.isRequired,
  directVictim: PropTypes.object.isRequired,
  currentVictim: PropTypes.number,
  dispatch: PropTypes.func,
  setIncomeFormOpen: PropTypes.func,
  setMethodChoiceOpen: PropTypes.func,
  nbIndirectVictims: PropTypes.number,
  formIndirectVictims: PropTypes.array,
};


const dataSelector = formValueSelector("caseDataForm");

const mapStateToProps = state => {
  let nbIndirectVictims = 0;
  let formIndirectVictims = [];

  if (dataSelector(state, "indirectVictims"))
    formIndirectVictims = dataSelector(state, "indirectVictims");
  if (formIndirectVictims)
    nbIndirectVictims = formIndirectVictims.length + 1;

  return { nbIndirectVictims, formIndirectVictims };
};


export default connect(mapStateToProps)(memo(VictimTabs));
