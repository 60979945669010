import React, {memo, useCallback, useEffect, useState} from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './RenderDateInput.css'
import {SingleDatePicker} from 'react-dates';
import frLocale from 'moment/locale/fr';
import moment from 'moment';

const renderMonthElement = ({month, onMonthSelect, onYearSelect}) => {
    const years = [];
    for (let i = moment().year() + 30; i >= moment().year() - 120; i--) {
        years.push(<option value={i} key={`year-${i}`}>{i}</option>);
    }
    return (
        <div style={{display: "flex", justifyContent: "center"}}>
            <div>
                <select value={month.month()} onChange={e => onMonthSelect(month, e.target.value)}
                        style={{padding: "0.5rem", fontSize: "14px", border: "0px"}}>
                    {moment.months().map((label, value) => (
                        <option value={value} key={value}>{label}</option>
                    ))}
                </select>
            </div>
            <div>
                <select value={month.year()} onChange={e => onYearSelect(month, e.target.value)} style={{
                    marginLeft: "0.5rem",
                    padding: "0.5rem 1rem 0.5rem 0.5rem",
                    fontSize: "14px",
                    border: "0px"
                }}>
                    {years}
                </select>
            </div>
        </div>
    );
};

const isOutsideRange = () => false

function DatePicker({
                        input,
                        meta: {touched, error, warning},
                        placeholder,
                        disabled,
                        required,
                        className,
                        label,
                        optional,
                        mandatory,
                        wizard
                    }) {
// Set the locale at the top level of the function component
    useEffect(() => {
        moment.locale('fr', frLocale);
    }, []);

    const [focused, setFocused] = useState(false);

    const onFocusChange = useCallback(() => {
        setFocused(val => !val);
        input.onFocus();
    },[input]);


    return (
        <div className={`form-group${touched && (error || warning) ? " has-danger" : ""}${className || ""}`}>
            {label && (
                <label className="control-label" htmlFor={input.name}>
                    {label}
                    {optional && <em> (facultatif)</em>}
                    {mandatory && <span className="text-danger"> *</span>}
                    {touched && (error || warning) &&
                        <span className="invalid-feedback ml-3" style={{display: "inline"}}>{error || warning}</span>}
                </label>
            )}
            <div className={wizard ? "" : "input-group input-group-sm"}>
                <SingleDatePicker
                    showClearDate={true}
                    displayFormat="DD/MM/YYYY"
                    numberOfMonths={1}
                    isOutsideRange={isOutsideRange}
                    disabled={disabled}
                    placeholder={placeholder ? placeholder : "jj/mm/aaaa"}
                    date={input.value}
                    onDateChange={input.onChange}
                    focused={focused}
                    onFocusChange={onFocusChange}
                    id={input.name}
                    noBorder={true}
                    renderMonthElement={renderMonthElement}
                />
            </div>
        </div>
    );
}

export const formatDates = value => (value ? moment(value) : null);

export const normalizeDates = value =>
    value ? value.format('YYYY-MM-DD') : null;

export default memo(DatePicker);
