import React, { Fragment } from 'react'
import { connect } from "react-redux";
import { Field, arrayPush, FieldArray, change } from "redux-form"
import BtnAddDsfTPPLine from "../../buttons/BtnAddDsfTPPLine"
import AmountField from "../../fields/AmountField"

import RenderActionsCol from "./renderActionsCol"
import RenderDsfTPP from "./RenderDsfTPP"
import { getDsfPastArrSummary } from "../calculations"
import HelpTip from "../../../layout/HelpTip";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DatePicker, {
    formatDates,
    normalizeDates,
} from "../creation_wizard/RenderDateInput";
import DisplayDiscountingFormDSFPast from '../../discounting/DisplayDiscountingFormDSFPast';

const renderDSFPast = (props) => {
    const { fields, line, item, dispatch, entryMeta, lineIndex, arrValues, tppValues, values, victim, entry } = props;

    const removeDiscounting = (i, lineValues) => {
        const lineRef = `${entry}[${lineIndex}].arr.past[${i}]`;
        let newLineValues = lineValues;
        delete newLineValues.discounting;
        dispatch(change("caseDataForm", lineRef, newLineValues));
      }

    const removeArrLine = index => {
        fields.remove(index);
    }
    
    const addLine = () => {
        let newLine = {};
        fields.push(newLine);
    }

    if (fields.length === 0) addLine();

    const addTPPLine = () => {
        let newLine = {};
        dispatch(arrayPush("caseDataForm", `${line}.arr.past_tpp`, newLine));
    };

    const pastArrSummary = getDsfPastArrSummary(values, entry, lineIndex, item, victim);
    const firstLine = values[entry][lineIndex].arr.past[0];
    const hasDiscounting = firstLine && firstLine.discounting && firstLine.discounting.indexValue;
    


    return (
        <tr>
            <td className="col-num" />
            <td colSpan="4">
                <table className="table table-sm mb-0">
                    {fields.length > 0 && <Fragment>
                        <caption>Arrérages échus <HelpTip helpText="arrPast" /></caption>
                        <thead>
                            <tr>
                                <th />
                                <th className="text-nowrap pl-0 datePeriod" colSpan={2}>Date ou période de la dépense</th>
                                <th className="container-fluid">Commentaire / composante</th>
                                <th colSpan="2" className="text-center">Préjudice</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fields.map((arr, i) => (
                                <Fragment key={i}>
                                    <tr>
                                        <th scope="row" className="col-num">{fields.length > 1 && String.fromCharCode(97 + i)+"."}</th>
                                        <td>

                                            <div className="input-group input-group-sm flex-nowrap date">
                                                <Field name={`${line}.arr.past[${i}].startDate`}
                                                    component={DatePicker}
                                                    parse={normalizeDates}
                                                    format={formatDates}

                                                    className="form-control date"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-group input-group-sm flex-nowrap date">
                                                <Field name={`${line}.arr.past[${i}].endDate`}
                                                    component={DatePicker}
                                                    parse={normalizeDates}
                                                    format={formatDates}

                                                    className="form-control"
                                                />
                                            </div>
                                        </td>
                                        <td className="container-fluid">
                                            <div className="input-group input-group-sm flex-nowrap">
                                                <Field name={`${line}.arr.past[${i}].comment`}
                                                    component="input"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Commentaire"
                                                />
                                            </div>
                                        </td>
                                        <td className="col-amount">
                                            <div className="d-flex">
                                                <Field
                                                    name={`${line}.arr.past[${i}].lineAmount`}
                                                    component={AmountField}
                                                />
                                                {fields.length === 1 &&
                                                    <DisplayDiscountingFormDSFPast
                                                        values={values}
                                                        entry={entry}
                                                        index={lineIndex}
                                                        pastLineIndex={i}
                                                        amount={Math.max(pastArrSummary.victimAmount - pastArrSummary.absorbedFutureTppSurplus, 0)}
                                                        hasTpp={pastArrSummary.hasTPP}
                                                    />
                                                }
                                            </div>
                                        </td>
                                        <RenderActionsCol
                                            item={item}
                                            line={line}
                                            secondaryLine={true}
                                            removeLine={() => removeArrLine(i)}
                                            addTPPLine={() => addTPPLine()}
                                        />
                                    </tr>

                                </Fragment>
                            ))
                            }
                        </tbody>
                    </Fragment>}
                    <tfoot>
                        {pastArrSummary.hasTPP
                            && (
                                <FieldArray
                                    name={`${line}.arr.past_tpp`}
                                    colSpan={3}
                                    line={line}
                                    component={RenderDsfTPP}
                                    entry={fields.name}
                                    lineIndex={lineIndex}
                                    tppValues={tppValues}
                                    entryMeta={entryMeta}
                                    arrerageType="past"
                                />
                            )}
                        <tr>
                            <td className="col-num main-line" />
                            <td colSpan={4} className="text-right main-line">
                                {fields.length > 1 && <BtnAddDsfTPPLine addTPPLine={() => addTPPLine()} />}
                                {(fields.length > 1 || tppValues && tppValues.length > 0) && <h6 className="mb-0">Total arrérages échus dépense n° {lineIndex + 1}</h6>}
                            </td>
                            <td className="main-line" />
                        </tr>
                        {victim.rateOfCompensationEntitlement < 100 && (
                            <tr>
                                <td />
                                <td colSpan={3} className="text-right align-bottom pt-0 pb-0">
                                    <em>Dette du/des responsable(s){hasDiscounting && " avant actualisation"}</em>
                                </td>
                                <td className="col-amount">
                                    <AmountField fieldValue={pastArrSummary.responsiblePartyAmount} />
                                </td>
                                <td />
                            </tr>
                        )}
                        {victim.rateOfCompensationEntitlement < 100 && pastArrSummary.hasTPP && 
                            <tr>
                                <td />
                                <td colSpan={3} className="text-right align-bottom pt-0 pb-0">
                                    <em>Créance de la victime{hasDiscounting && " avant actualisation"}</em>
                                </td>
                                <td className="col-amount">
                                    <AmountField fieldValue={pastArrSummary.victimAmount} />
                                </td>
                                <td />
                            </tr>
                        }
                        {hasDiscounting &&
                            <tr>
                            <td className="col-num pt-0 pb-0" />
                            <td colSpan={3} className="text-right pt-0 pb-0">
                                <em>Créance actualisée de la victime</em>
                            </td>
                            <td className="col-amount text-left pt-0 pb-0">
                                <AmountField readOnly fieldValue={pastArrSummary.victimDiscountedAmount} />
                            </td>
                            <td className="col-act pt-0 pb-0">
                            <button
                                    type="button"
                                    className="btn btn-outline-danger btn-sm p-0"
                                    onClick={()=>removeDiscounting(0, values[entry][lineIndex].arr.past[0])}
                                    title="Supprimer l'actualisation"
                                >
                                    <HighlightOffIcon />
                                </button>
                            </td>
                            </tr>
                        }
                        {(pastArrSummary.hasTPP || pastArrSummary.absorbedFutureTppSurplus > 0) && <tr>
                            <td />
                            <td colSpan={3} className="text-right align-bottom pt-0 pb-0">
                                <em>Recours du/des tiers payeur(s)</em>
                            </td>
                            <td className="col-amount">
                                <AmountField fieldValue={pastArrSummary.tppRecourse} />
                            </td>
                            <td />
                        </tr>
                        }
                        {hasDiscounting && pastArrSummary.hasTPP && victim.rateOfCompensationEntitlement < 100 &&
                            <tr>
                            <td className="col-num pt-0 pb-0" />
                            <td colSpan={3} className="text-right pt-0 pb-0">
                                <em>Dette du/des responsable(s) après actualisation</em>
                            </td>
                            <td className="col-amount text-left pt-0 pb-0">
                                <AmountField readOnly fieldValue={pastArrSummary.responsiblePartyAmountAfterDiscounting} />
                            </td>
                            <td className="col-act pt-0 pb-0" />
                            </tr>
                        }
                        {pastArrSummary.victimAmount < 0 && values[entry][lineIndex].arr.future.length > 0 && (
                            <tr>
                                <td />
                                <td colSpan={4} className="text-right align-bottom pt-0 pb-0">
                                    <small className="text-danger">
                                        Le reliquat de paiement de tiers payeur(s) non imputé de{" "}{Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(-pastArrSummary.victimAmount)} est reporté automatiquement sur les éventuels arrérages à échoir de cette dépense.
                                    </small>
                                </td>
                                <td />
                            </tr>
                        )}
                        {pastArrSummary.absorbedFutureTppSurplus > 0 && (
                            <tr>
                                <td />
                                <td colSpan={4} className="text-right align-bottom pt-0 pb-0">
                                    <small className="text-danger">
                                        Le reliquat de paiement de tiers payeur(s) non imputé sur les arrérages à échoir de {Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(pastArrSummary.futureTppSurplus)} est reporté automatiquement sur les arrérages échus de cette dépense {pastArrSummary.futureTppSurplus !== pastArrSummary.absorbedFutureTppSurplus ? `à hauteur de ${Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(pastArrSummary.absorbedFutureTppSurplus)}` : ""}.
                                    </small>
                                </td>
                                <td />
                            </tr>
                        )}
                    </tfoot>
                </table>
            </td>
            <td />
        </tr>

    )
}

renderDSFPast.propTypes = {

}

export default connect()(renderDSFPast);