import React from "react";
import PropTypes from "prop-types";
import HelpTip from "../../layout/HelpTip";

const RenderSwitch = props => {
  const { input, label, disabled, className, helpText } = props;
  return (
    <div className={`form-group${className ? className : ""}`}>
      <div className="custom-control custom-switch d-flex">
        <input
          type="checkbox"
          className="custom-control-input"
          id={input.name}
          checked={input.value ? true : false}
          onChange={input.onChange}
          disabled={disabled}
        />
        <label className="custom-control-label" htmlFor={input.name}>
          {label}
        </label>
        {helpText && <HelpTip helpText={helpText} />}
      </div>
    </div>
  );
};

RenderSwitch.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  helpText: PropTypes.string
};

export default RenderSwitch;
