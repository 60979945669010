export const compensationScales = {
    DFP: {
        link: ["tableaux indicatifs d'indemnisation", "du déficit fonctionnel permanent"],
        tables: [{
            tabLabel: ["Référentiel Mornet 2020, 2021, 2022, 2023 et 2024", "Recueil des cours d'appel 2020"],
            caption: ["Référentiel publié par Benoît Mornet en septembre 2020, 2021, 2022, 2023 et 2024", "Recueil méthodologique indicatif intercours septembre 2020"],
            header: ["", "0 à 10 ans", "11 à 20 ans", "21 à 30 ans", "31 à 40 ans", "41 à 50 ans", "51 à 60 ans", "61 à 70 ans", "71 à 80 ans", "81 ans et plus"
            ],
            firstColumn: true,
            rows: [
                ["1 à 5 %", "2 310", "2 150", "1 960", "1 770", "1 580", "1 400", "1 210", "1 050", "880"],
                ["6 à 10 %", "2 670", "2 475", "2 255", "2 035", "1 800", "1 560", "1 320", "1 130", "935"],
                ["11 à 15 %", "3 025", "2 800", "2 550", "2 300", "2 025", "1 730", "1 430", "1 210", "990"],
                ["16 à 20 %", "3 380", "3 135", "2 850", "2 560", "2 245", "1 890", "1 540", "1 290", "1 045"],
                ["21 à 25 %", "3 740", "3 465", "3 145", "2 830", "2 465", "2 060", "1 650", "1 375", "1 100"],
                ["26 à 30 %", "4 100", "3 795", "3 445", "3 090", "2 685", "2 220", "1 760", "1 455", "1 155"],
                ["31 à 35 %", "4 455", "4 125", "3 740", "3 355", "2 905", "2 390", "1 870", "1 540", "1 210"],
                ["36 à 40 %", "4 810", "4 455", "4 035", "3 620", "3 125", "2 550", "1 980", "1 620", "1 265"],
                ["41 à 45 %", "5 170", "4 785", "4 335", "3 885", "3 345", "2 715", "2 090", "1 705", "1 320"],
                ["46 à 50 %", "5 530", "5 115", "4 630", "4 150", "3 565", "2 880", "2 200", "1 790", "1 375"],
                ["51 à 55 %", "5 885", "5 445", "4 930", "4 410", "3 785", "3 045", "2 310", "1 870", "1 430"],
                ["56 à 60 %", "6 240", "5 775", "5 225", "4 675", "4 005", "3 210", "2 420", "1 950", "1 485"],
                ["61 à 65 %", "6 600", "6 105", "5 520", "4 940", "4 225", "3 375", "2 530", "2 035", "1 540"],
                ["66 à 70 %", "6 955", "6 435", "5 820", "5 205", "4 445", "3 540", "2 640", "2 115", "1 595"],
                ["71 à 75 %", "7 315", "6 765", "6 115", "5 470", "4 665", "3 705", "2 750", "2 200", "1 650"],
                ["76 à 80 %", "7 670", "7 095", "6 415", "5 730", "4 885", "3 870", "2 860", "2 280", "1 705"],
                ["81 à 85 %", "8 030", "7 425", "6 710", "5 995", "5 105", "4 035", "2 970", "2 365", "1 760"],
                ["86 à 90 %", "8 385", "7 755", "7 005", "6 260", "5 325", "4 200", "3 080", "2 445", "1 815"],
                ["91 à 95 %", "8 745", "8 085", "7 305", "6 525", "5 545", "4 365", "3 190", "2 530", "1 870"],
                ["96 % plus", "9 020", "8 415", "7 600", "6 785", "5 765", "4 530", "3 300", "2 610", "1 925"]
            ]
        },
        {
            tabLabel: ["Référentiel Mornet 2015 et 2018", "Recueil des cours d'appel 2013 et 2016"],
            caption: ["Référentiel publié par Benoît Mornet en septembre 2018", "Recueil méthodologique indicatif intercours 2013 et 2016"],
            header: ["", "0 à 10 ans", "11 à 20 ans", "21 à 30 ans", "31 à 40 ans", "41 à 50 ans", "51 à 60 ans", "61 à 70 ans", "71 à 80 ans", "81 ans et plus"
            ],
            firstColumn: true,
            rows: [
                ["1 à 5 %", "2 100", "1 950", "1 780", "1 610", "1 440", "1 270", "1 100", "950", "800"],
                ["6 à 10 %", "2 425", "2 250", "2 050", "1 850", "1 640", "1 420", "1 200", "1 025", "850"],
                ["11 à 15 %", "2 750", "2 550", "2 320", "2 090", "1 840", "1 570", "1 300", "1 100", "900"],
                ["16 à 20 %", "3 075", "2 850", "2 590", "2 330", "2 040", "1 720", "1 400", "1 175", "950"],
                ["21 à 25 %", "3 400", "3 150", "2 860", "2 570", "2 240", "1 870", "1 500", "1 250", "1 000"],
                ["26 à 30 %", "3 725", "3 450", "3 130", "2 810", "2 440", "2 020", "1 600", "1 325", "1 050"],
                ["31 à 35 %", "4 050", "3 750", "3 400", "3 050", "2 640", "2 170", "1 700", "1 400", "1 100"],
                ["36 à 40 %", "4 375", "4 050", "3 670", "3 290", "2 840", "2 320", "1 800", "1 475", "1 150"],
                ["41 à 45 %", "4 700", "4 350", "3 940", "3 530", "3 040", "2 470", "1 900", "1 550", "1 200"],
                ["46 à 50 %", "5 025", "4 650", "4 210", "3 770", "3 240", "2 620", "2 000", "1 625", "1 250"],
                ["51 à 55 %", "5 350", "4 950", "4 480", "4 010", "3 440", "2 770", "2 100", "1 700", "1 300"],
                ["56 à 60 %", "5 675", "5 250", "4 750", "4 250", "3 640", "2 920", "2 200", "1 775", "1 350"],
                ["61 à 65 %", "6 000", "5 550", "5 020", "4 490", "3 840", "3 070", "2 300", "1 850", "1 400"],
                ["66 à 70 %", "6 325", "5 850", "5 290", "4 730", "4 040", "3 220", "2 400", "1 925", "1 450"],
                ["71 à 75 %", "6 650", "6 150", "5 560", "4 970", "4 240", "3 370", "2 500", "2 000", "1 500"],
                ["76 à 80 %", "6 975", "6 450", "5 830", "5 210", "4 440", "3 520", "2 600", "2 075", "1 550"],
                ["81 à 85 %", "7 300", "6 750", "6 100", "5 450", "4 640", "3 670", "2 700", "2 150", "1 600"],
                ["86 à 90 %", "7 625", "7 050", "6 370", "5 690", "4 840", "3 820", "2 800", "2 225", "1 650"],
                ["91 à 95 %", "7 950", "7 350", "6 640", "5 930", "5 040", "3 970", "2 900", "2 300", "1 700"],
                ["96 % plus", "8 200", "7 650", "6 910", "6 170", "5 240", "4 120", "3 000", "2 375", "1 750"],
            ]
        },
        {
            tabLabel: ["ONIAM 2023"],
            bySex: true,
            caption: {
                M: ["Référentiel indicatif d'indemnisation publié par l'ONIAM en 2023", "Proposition d'indemnisation pour une tranche d'âge et de DFP (hommes)"],
                F: ["Référentiel indicatif d'indemnisation publié par l'ONIAM en 2023", "Proposition d'indemnisation pour une tranche d'âge et de DFP (femmes)"],
            },
            header: ["", "5 %", "15 %", "25 %", "35 %", "45 %", "55 %", "65 %", "75 %", "85 %", "95 %"],
            firstColumn: true,
            rows: {
                M: [
                    ["10 ans", "6 214", "26 517", "57 323", "98 629", "150 438", "212 748", "285 560", "368 873", "462 688", "567 005"],
                    ["20 ans", "6 027", "24 839", "52 660", "89 491", "135 331", "190 181", "254 041", "326 910", "408 789", "499 677"],
                    ["30 ans", "5 845", "23 199", "48 105", "80 563", "120 572", "168 134", "223 248", "285 913", "356 131", "433 901"],
                    ["40 ans", "5 665", "21 577", "43 598", "71 730", "105 971", "146 323", "192 784", "245 355", "304 036", "368 826"],
                    ["50 ans", "5 492", "20 022", "39 279", "63 265", "91 978", "125 419", "163 587", "206 484", "254 108", "306 460"],
                    ["60 ans", "5 335", "18 610", "35 357", "55 577", "79 269", "106 434", "137 071", "171 181", "208 764", "249 819"],
                    ["70 ans", "5 194", "17 339", "31 828", "48 661", "67 836", "89 356", "113 218", "139 424", "167 974", "198 867"],
                    ["80 ans", "5 068", "16 205", "28 676", "42 482", "57 623", "74 098", "91 908", "111 053", "131 532", "153 346"],
                    ["90 ans", "4 980", "15 414", "26 480", "38 178", "50 507", "63 469", "77 063", "91 288", "106 146", "121 635"],
                    ["100 ans ", "4 945", "15 102", "25 612", "36 477", "47 697", "59 271", "71 199", "83 481", "96 118", "109 109"],

                ],
                F: [
                    ["10 ans", "6 330", "27 560", "60 218", "104 304", "159 818", "226 760", "305 130", "394 928", "496 154", "608 808"],
                    ["20 ans", "6 142", "25 871", "55 529", "95 113", "144 625", "204 065", "273 432", "352 727", "441 950", "541 100"],
                    ["30 ans", "5 955", "24 193", "50 867", "85 977", "129 523", "181 505", "241 923", "310 777", "388 067", "473 793"],
                    ["40 ans", "5 771", "22 531", "46 249", "76 926", "114 561", "159 153", "210 704", "269 214", "334 681", "407 107"],
                    ["50 ans", "5 591", "20 917", "41 767", "68 140", "100 037", "137 457", "180 401", "228 869", "282 861", "342 376"],
                    ["60 ans", "5 420", "19 378", "37 492", "59 762", "86 188", "116 769", "151 506", "190 400", "233 449", "280 654"],
                    ["70 ans", "5 257", "17 907", "33 404", "51 750", "72 943", "96 984", "123 872", "153 609", "186 193", "221 625"],
                    ["80 ans", "5 107", "16 559", "29 660", "44 412", "60 813", "78 863", "98 564", "119 914", "142 914", "167 564"],
                    ["90 ans", "4 996", "15 561", "26 889", "38 981", "51 835", "65 452", "79 832", "94 975", "110 881", "127 550"],
                    ["100 ans ", "4 944", "15 092", "25 587", "36 427", "47 613", "59 146", "71 025", "83 249", "95 820", "108 737"],
                ]
            }
        }
        ]
    },
    SE: {
        link: ["échelles indicatives d'indemnisation", "des"],
        tables: [
            {
                tabLabel: ["Référentiel Mornet 2018, 2020, 2021, 2022, 2023 et 2024", "Recueil des CA 2018 et 2020"],
                caption: ["Référentiel publié par Benoît Mornet 2018, 2020, 2021, 2022, 2023 et 2024", "Recueil méthodologique indicatif intercours 2016 et 2020"],
                header: ["Degré", "Qualification", "Montant"],
                rows: [
                    ["1/7", "très léger", "jusqu'à 2 000 €"],
                    ["2/7", "léger", "2 000 à 4 000 €"],
                    ["3/7", "modéré", "4 000 à 8 000 €"],
                    ["4/7", "moyen", "8 000 à 20 000 €"],
                    ["5/7", "assez important", "20 000 à 35 000 €"],
                    ["6/7", "important", "35 000 à 50 000 €"],
                    ["7/7", "très important", "50 000 à 80 000 €"],
                    ["", "exceptionnel", "80 000 € et plus"],
                ]
            },
            {
                tabLabel: ["Recueil des cours d'appel 2013"],
                caption: ["Recueil méthodologique indicatif intercours 2013"],
                header: ["Degré", "Qualification", "Montant"],
                rows: [
                    ["1/7", "très léger", "jusqu'à 1 500 €"],
                    ["2/7", "léger", "1 500 à 3 000 €"],
                    ["3/7", "modéré", "3 000 à 6 000 €"],
                    ["4/7", "moyen", "6 000 à 15 000 €"],
                    ["5/7", "assez important", "15 000 à 30 000 €"],
                    ["6/7", "important", "30 000 à 45 000 €"],
                    ["7/7", "très important ", "45 000 à 70 000 €"],
                    ["", "exceptionnel", "70 000 € et plus"]
                ]
            },
            {
                tabLabel: ["ONIAM 2023"],
                caption: ["Référentiel indicatif d'indemnisation publié par l'ONIAM en 2023"],
                header: ["Degré", "Qualification", "Montant", "Moyenne"],
                rows: [
                    ["1/7", "très léger", "811 à 1 098 €", "955 €"],
                    ["2/7", "léger", "1 572 à 2 126 €", "1 849 €"],
                    ["3/7", "modéré", "3 076 à 4 162 €", "3 619 €"],
                    ["4/7", "moyen", "6 121 à 8 281 €", "7 201 €"],
                    ["5/7", "assez important", "11 502 à 15 561 €", "13 531 €"],
                    ["6/7", "important", "20 014 à 27 078 €", "23 546 €"],
                    ["7/7", "très important ", "32 453 à 43 907 €", "38 180 €"]
                ]
            }
        ]
    },
    PEP: {
        link: ["échelles indicatives d'indemnisation", "du"],
        tables: [
            {
                tabLabel: ["Référentiel Mornet 2018, 2020, 2021, 2022, 2023 et 2024", "Recueil des CA 2018 et 2020"],
                caption: ["Référentiel publié par Benoît Mornet 2018, 2020, 2021, 2022, 2023 et 2024", "Recueil méthodologique indicatif intercours 2016 et 2020"],
                header: ["Degré", "Qualification", "Montant"],
                rows: [
                    ["1/7", "très léger", "jusqu'à 2 000 €"],
                    ["2/7", "léger", "2 000 à 4 000 €"],
                    ["3/7", "modéré", "4 000 à 8 000 €"],
                    ["4/7", "moyen", "8 000 à 20 000 €"],
                    ["5/7", "assez important", "20 000 à 35 000 €"],
                    ["6/7", "important", "35 000 à 50 000 €"],
                    ["7/7", "très important", "50 000 à 80 000 €"],
                    ["", "exceptionnel", "80 000 € et plus"],
                ]
            },
            {
                tabLabel: ["Recueil des cours d'appel 2013"],
                caption: ["Recueil méthodologique indicatif intercours 2013"],
                header: ["Degré", "Qualification", "Montant"],
                rows: [
                    ["1/7", "très léger", "jusqu'à 1 500 €"],
                    ["2/7", "léger", "1 500 à 3 000 €"],
                    ["3/7", "modéré", "3 000 à 6 000 €"],
                    ["4/7", "moyen", "6 000 à 15 000 €"],
                    ["5/7", "assez important", "15 000 à 30 000 €"],
                    ["6/7", "important", "30 000 à 45 000 €"],
                    ["7/7", "très important ", "45 000 à 70 000 €"],
                    ["", "exceptionnel", "70 000 € et plus"]
                ]
            },
            {
                tabLabel: ["ONIAM 2023"],
                caption: ["Référentiel indicatif d'indemnisation publié par l'ONIAM en 2023"],
                header: ["Degré", "Qualification", "Montant", "Moyenne"],
                rows: [
                    ["1/7", "très léger", "811 à 1 098 €", "955 €"],
                    ["2/7", "léger", "1 572 à 2 126 €", "1 849 €"],
                    ["3/7", "modéré", "3 076 à 4 162 €", "3 619 €"],
                    ["4/7", "moyen", "6 121 à 8 281 €", "7 201 €"],
                    ["5/7", "assez important", "11 502 à 15 561 €", "13 531 €"],
                    ["6/7", "important", "20 014 à 27 078 €", "23 546 €"],
                    ["7/7", "très important ", "32 453 à 43 907 €", "38 180 €"]
                ]
            }
        ]
    },
    PSUF: {
        link: ["échelles indicatives d'indemnisation", "du"],
        tables: [
            {
                tabLabel: ["Référentiel Mornet 2023 et 2024"],
           //     caption: ["Référentiel publié par Benoît Mornet en 2023"],
                header: ["", "Montant"],
                rows: [
                    ["Perte de moins d'une année scolaire", "Moitié du SMIC"],
                    ["Perte d'une année pour un écolier", "5 000 €"],
                    ["Perte d'une année pour un collégien", "8 000 €"],
                    ["Perte d'une année pour un lycéen", "10 000 €"],
                    ["Perte d'une année pour un étudiant", "12 000 €"],

                    
                ]
            },
        ]
    },

}