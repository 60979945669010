import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { interval } from "../../utils";
import { Field, arrayPush, change } from "redux-form";
import { connect } from "react-redux"
import AmountField from "../../fields/AmountField";
import DatePicker, {
  formatDates,
  normalizeDates,
} from "../creation_wizard/RenderDateInput";

import * as capiTables from "../../capitalisation_tables/capitalisationTables";
import { getPERFromCapiTable } from "../calculations";
import { getFutureAmount } from "./household_income/calculations";
import Append from "../../fields/Append"
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from "@material-ui/icons/Help";
import BtnAddTPPLine from "../../buttons/BtnAddTPPLine";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

let RenderPRevAnnCap = props => {
  const {
    lineValues,
    entry,
    lineIndex,
    victim,
    dispatch,
    line,
    meta,
    victimId,
    indirectVictim,
    householdIncomesList,
    nbLines,
    removeLine
  } = props;

  let startAge = -1;
  let refVictim = victim;
  const caseROCE = victim.rateOfCompensationEntitlement;
  if (lineValues && lineValues.refVictim === "indirect") {
    refVictim = indirectVictim;
    refVictim.rateOfCompensationEntitlement = caseROCE;
  }
  
  let birthDate = refVictim.birth_date;
  let startDate = lineValues.startDate;
  if (startDate && startDate.length === 10 && birthDate <= startDate)
    startAge = Math.floor(interval(birthDate, startDate, "years", false));
  
  const selectedCapiTable = lineValues.capitalisationTable;
  const selectedEndAge = parseInt(lineValues.capitalisationEndAge);
  const gender = refVictim.victimGender;


  const addTPPLine = (index) => {
    let newLine = {};
    dispatch(arrayPush("caseDataForm", `indirectVictims[${victimId}].caseData.PRev0[${index}].tpp`, newLine));
  };

  return (
    <Fragment>
      <tr>
        <td className="col-num pb-0" />
        <td className="pb-0" />        
        <td colSpan={2} className="pb-0 mb-0">
            <label htmlFor="refVictim" className="refVictimLabel">
              Victime de référence
              <Tooltip title="Choix de la victime de référence pour la capitalisation" leaveDelay={200}>
                <HelpIcon fontSize="small" variant="contained" className="text-secondary smallIcon mb-1" />
              </Tooltip>
            </label>
        </td>
        <td className="pb-0"/>
        <td className="pt-0 pb-0" />
      </tr>
      <tr>
        <td className="col-num pt-0" />
        <th scope="row" className="text-left arrearsLbl pt-0">Arrérages à échoir&nbsp;:</th>
        <td colSpan={2} className="pt-0">
          <div className="d-sm-flex">
            <div className="pl-0 d-sm-flex form-group main-form anncap input-group input-group-sm">
              <Field
                name={`${line}.startDate`}
                component={DatePicker}
                parse={normalizeDates}
                format={formatDates}
                label="à compter du"
                className=" d-flex input-group-sm mr-0 capStartDate"
                onChange={(event, newValue) => {
                    let PER = 0;
                    if (selectedCapiTable && selectedCapiTable !=="PERdirectInput" && gender && parseInt(selectedEndAge) >= 0 && newValue && newValue.length === 10) {
                      startAge = Math.floor(interval(birthDate, newValue, "years", false));
                      PER = getPERFromCapiTable(
                              selectedCapiTable,
                              gender,
                              startAge,
                              selectedEndAge
                            )
                    }
                    dispatch(change("caseDataForm", `indirectVictims[${victimId}].caseData.PRev0[${lineIndex}].capitalisationPER`, PER.toString().replace(".", ",")))
                  }
                }
              />
              <Field
                name={`${line}.refVictim`}
                id="refVictim"
                component="select"
                className={`custom-select victim-select ml-1${startAge < 0 ? " mr-2":""}`}
                onChange={(event, newValue) => {
                  let PER = 0;
                  let refVictimField = victim;
                  if (selectedCapiTable && selectedCapiTable !=="PERdirectInput" && gender && parseInt(selectedEndAge) >= 0 && newValue) {
                      if (newValue === "indirect") refVictimField = indirectVictim;
                      PER = getPERFromCapiTable(
                        selectedCapiTable,
                        refVictimField.victimGender,
                        Math.floor(interval(refVictimField.birth_date, lineValues.startDate, "years", false)),
                        selectedEndAge
                      )
                      dispatch(change("caseDataForm", `indirectVictims[${victimId}].caseData.PRev0[${lineIndex}].capitalisationPER`, PER.toString().replace(".", ",")))
                    }
                  }
                }
              >
                <option value="direct">victime directe</option>
                <option value="indirect">victime indirecte</option>
            </Field>
              {startAge >= 0 && <Append append={`(${startAge} an${startAge >= 2 ? "s" : ""})`} className=" pl-1 pr-2" />}
              <label
                className="control-label"
                htmlFor={`${entry}[${lineIndex}].capitalisationTable`}
              >Table de capitalisation :</label>
              <Field
                name={`${line}.capitalisationTable`}
                component="select"
                className="custom-select captable-select"
                id={`${entry}[${lineIndex}].capitalisationTable`}
                onChange={(event, newValue) => {
                  let PER = 0;
                  if (newValue && newValue !== "PERdirectInput" && gender && startAge >= 0 && selectedEndAge >= 0)
                    PER = getPERFromCapiTable(
                      newValue,
                      gender,
                      startAge,
                      selectedEndAge
                    );
                  dispatch(change("caseDataForm", `indirectVictims[${victimId}].caseData.PRev0[${lineIndex}].capitalisationPER`, PER.toString().replace(".", ",")))
                  !newValue && dispatch(change("caseDataForm", `indirectVictims[${victimId}].caseData.PRev0[${lineIndex}].capitalisationPER`, ""))
                  }
                }
              >
                <option value=""></option>
                <option value="PERdirectInput">Libre - Saisir un PER</option>
                {capiTables.tableList.map(table => (
                  <option key={table.id} value={table.id}>
                    {table.author}
                    {table.year && " " + table.year}
                    {table.rate && " " + table.rate}
                  </option>
                ))}
              </Field>
              {startAge >= 0 &&
                selectedCapiTable &&
                selectedCapiTable.length > 0 &&
                (selectedCapiTable !== "PERdirectInput" ?
                  (<Fragment>
                    <label
                      className="control-label pl-2"
                      htmlFor={`${entry}[${lineIndex}].capitalisationEndAge`}
                    >Âge de fin</label>
                    <Field
                      name={`${line}.capitalisationEndAge`}
                      component="select"
                      className="custom-select endage-select"
                      id={`${entry}[${lineIndex}].capitalisationEndAge`}
                      onChange={(event, newValue) => {
                        let PER = 0
                        if (selectedCapiTable && gender && startAge >= 0)
                          PER = getPERFromCapiTable(
                            selectedCapiTable,
                            gender,
                            startAge,
                            newValue
                          )
                        dispatch(change("caseDataForm", `indirectVictims[${victimId}].caseData.PRev0[${lineIndex}].capitalisationPER`, PER.toString().replace(".", ",")))
                      }
                      }
                    >
                      <option value=""></option>
                      {gender &&
                        capiTables[selectedCapiTable]
                          .get(gender)
                          .filter(ent => ent[0] === startAge)
                          .map(entry => (
                            <option key={entry[1]} value={entry[1]}>
                              {entry[1] === 999 ? "viager" : entry[1] + " ans"}
                            </option>
                          ))}
                    </Field>
                    {selectedEndAge > 0 && (
                      <React.Fragment>
                        <label
                          className="control-label pl-2 ml-auto"
                          htmlFor={`${entry}[${lineIndex}].capitalisationPER`}
                        >
                          PER :{" "}
                        </label>
                        <Field
                          name={`${line}.capitalisationPER`}
                          id={`${entry}[${lineIndex}].capitalisationPER`}
                          component="input"
                          type="text"
                          className="form-control per-input"
                          readOnly
                        />
                      </React.Fragment>
                    )}
                  </Fragment>
                  )
                  :
                  (
                    <React.Fragment>
                      <label
                        className="control-label pl-2"
                        htmlFor={`${entry}[${lineIndex}].capitalisationPER`}
                      >
                        PER :{" "}
                      </label>
                      <Field
                        name={`${line}.capitalisationPER`}
                        id={`${entry}[${lineIndex}].capitalisationPER`}
                        component="input"
                        type="text"
                        className="form-control per-input"
                      />
                    </React.Fragment>
                  ))
              }
            </div>
          </div>
        </td>
        <td className="col-amount pt-0">
          <AmountField fieldValue={getFutureAmount(lineValues, householdIncomesList)} />
        </td>
        <td className="col-act pt-0 pb-0">
          <BtnAddTPPLine addTPPLine={() => addTPPLine(lineIndex)} additionalTitle=" sur cette perte de revenus" />
          {nbLines === 1 && 
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm p-0 mr-1"
                  onClick={removeLine}
                  title="Effacer les données saisies"
                >
                  <DeleteForeverIcon />
                </button>}
        </td>
      </tr>
    </Fragment>
  );
};

RenderPRevAnnCap.propTypes = {
  colSpan: PropTypes.number,
  indirectVictim: PropTypes.object.isRequired,
  victim: PropTypes.object.isRequired,
  entry: PropTypes.string,
  lineIndex: PropTypes.number,
  lineCapitalisedAmount: PropTypes.object,
  lineAmount: PropTypes.number,
  line: PropTypes.string,
  lineValues: PropTypes.object,
  householdIncomesList: PropTypes.array,
  victimId: PropTypes.number,
  dispatch: PropTypes.func,
  nbLines: PropTypes.number,
  removeLine: PropTypes.func
};

export default connect()(RenderPRevAnnCap);
