// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DateInput{
    width: 100px;
}

.CalendarDay__selected {
    background: #9e3386;
    border: 1px solid #5b256e;
  }

.DateInput_input {
    height: calc(1.5em + 0.5rem + 0rem);
    font-weight: inherit;
    font-size: 0.7109375rem;
    color: #666;
    padding:0;
    border-bottom:0;
    background-color: transparent;
}    
    
.DateInput_input__focused {
    border-bottom:0;
}

.SingleDatePickerInput__showClearDate{
    padding-right: 0px;
}

.SingleDatePickerInput_clearDate{
    transform: translate(55%,-50%);
}


.d-flex .SingleDatePickerInput_clearDate {
    top: 35%!important;
}

.DateInput, .SingleDatePickerInput {
    background-color: transparent;
}

.main-form-header .DateInput_input__focused, .main-form-header .DateInput_input {
    border-bottom-width: 4px!important;
    border-bottom-color: transparent;
    border-bottom-style:solid;
}`, "",{"version":3,"sources":["webpack://./src/components/cases/physical_injury/creation_wizard/RenderDateInput.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;EAC3B;;AAEF;IACI,mCAAmC;IACnC,oBAAoB;IACpB,uBAAuB;IACvB,WAAW;IACX,SAAS;IACT,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;AAClC;;;AAGA;IACI,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,kCAAkC;IAClC,gCAAgC;IAChC,yBAAyB;AAC7B","sourcesContent":[".DateInput{\n    width: 100px;\n}\n\n.CalendarDay__selected {\n    background: #9e3386;\n    border: 1px solid #5b256e;\n  }\n\n.DateInput_input {\n    height: calc(1.5em + 0.5rem + 0rem);\n    font-weight: inherit;\n    font-size: 0.7109375rem;\n    color: #666;\n    padding:0;\n    border-bottom:0;\n    background-color: transparent;\n}    \n    \n.DateInput_input__focused {\n    border-bottom:0;\n}\n\n.SingleDatePickerInput__showClearDate{\n    padding-right: 0px;\n}\n\n.SingleDatePickerInput_clearDate{\n    transform: translate(55%,-50%);\n}\n\n\n.d-flex .SingleDatePickerInput_clearDate {\n    top: 35%!important;\n}\n\n.DateInput, .SingleDatePickerInput {\n    background-color: transparent;\n}\n\n.main-form-header .DateInput_input__focused, .main-form-header .DateInput_input {\n    border-bottom-width: 4px!important;\n    border-bottom-color: transparent;\n    border-bottom-style:solid;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
