import React from "react";
import PropTypes from "prop-types";
import { itemTypes } from "../itemTypes";
import HelpTip from "../../../layout/HelpTip";

function ItemTableHeader(props) {
  const { item } = props;
  return (
    <thead>
      <tr>
        <th scope="col" className="col-num" />

        {itemTypes.get(item.itemType).length === 0 ? (
          <td />
        ) : (
          itemTypes.get(item.itemType).map((field, k) => (
            <th key={k} scope="col" className={field.fieldType}>
              {field.name === "label" && item.labelField ? item.labelField : field.label}
              {field.fieldType === "duration" && <HelpTip helpText="durationHeader" />}
              {field.name === "workIncome" && <HelpTip helpText="workIncomeHeader" />}
              {field.fieldType === "refAmount" && <HelpTip helpText="referenceIncomeHeader" />}
              {field.fieldType === "datePeriod" && <HelpTip helpText="datePeriodHeader" />}
              {field.name === "hours" && <HelpTip helpText="hours" />}
              {item.itemType === "TPT" && field.name === "startDate" && <HelpTip helpText="tptDateHeader" />}
              {item.itemType === "DFT" && field.name === "startDate" && <HelpTip helpText="dftDateHeader" />}
              {item.itemType === "DFT" && field.name === "label" && <HelpTip helpText="dftLabelHeader" />}
              {item.itemType === "PGPA" && field.name === "label" && <HelpTip helpText="pgpaLabelHeader" />}
              {item.itemType === "IP" && field.name === "label" && <HelpTip helpText="component" />}
            </th>
          ))
        )}

        <th scope="col" className="col-amount text-center" colSpan="2">
          {item.amountLabel ? item.amountLabel : `Préjudice${item.annuityCapitalisation ? " annuel" : ""}`}
        </th>
      </tr>
    </thead>
  );
}

ItemTableHeader.propTypes = {
  item: PropTypes.object.isRequired
};

export default ItemTableHeader;
