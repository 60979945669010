export const incomeLossCalcMethods = {
    classic: {
        label: "Méthode classique",
        shortLabel: "classique",
        description: "Chaque membre du foyer se voit affecter une part du préjudice annuel du foyer jusqu'à une date pertinente (départ du foyer pour les enfants, retraite ou viager pour le conjoint survivant).\nSi le préjudice est calculé pour une unique victime (par ex : conjoint sans enfant), choisissez la méthode classique et affectez 100% du préjudice à cette victime."
    },
    redistribution: {
        label: "Méthode de réaffectation",
        shortLabel: "par réaffectation",
        description: "Les enfants prennent une part du préjudice économique annuel du foyer, qui est capitalisé jusqu'à leur départ. Ces montants sont déduits de la capitalisation totale du préjudice du conjoint survivant qui est calculé sur un préjudice du total du foyer."
    }
}