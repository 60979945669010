import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, FieldArray, change, formValueSelector } from 'redux-form';
import BtnAddItemLine from '../../../buttons/BtnAddItemLine';
import renderIncome from './renderIncome';
import BtnRemoveLine from '../../../buttons/BtnRemoveLine';
import NumberField from '../../../fields/NumberField';
import { getHouseholdIncomeLoss } from "./calculations";
import TextField from '../../../fields/TextField';
import AmountField from '../../../fields/AmountField';
import BtnInputAmount from '../../../buttons/BtnInputAmount';
import { getHouseholdIncomesList, getHouseholdIncomesAllocation, getHouseholdIncomesAllocationDetails } from './calculations';
import NoDeletionButton from './noDeletionButton';

const renderHouseholdIncome = props => {
  const { fields, dispatch, meta: { touched, error, submitFailed }, indirectVictims } = props;

  const removeLine = index => {
    fields.remove(index);
  };

  const addLine = () => {
    let newLine = {incomeId:"a", originalIncome:[], selfConsumption:{}, residualIncome:[]};
    if (fields.length > 0) {
      const lastId = fields.getAll().slice(-1)[0].incomeId;
      if (lastId) newLine.incomeId = String.fromCharCode(lastId.charCodeAt(0) + 1);
    }
    fields.push(newLine);
  }
  useEffect(() => {
    if (fields.length === 0) addLine();
  }, []);

  const householdIncomesAllocation = getHouseholdIncomesAllocation(getHouseholdIncomesList(fields.getAll()), indirectVictims);
  const allocationDetails = getHouseholdIncomesAllocationDetails(householdIncomesAllocation, indirectVictims, fields.getAll());

  return (
    <div className="w-100">
      <div style={{ overflowY: "scroll", maxHeight: "70vh", minHeight: 400 }}>
      <ul className="pl-3">
        {fields.map((line, index) =>
          <li key={index} className="pb-2 householdIncome" style={{fontWeight: 'bold', fontSize: '1rem', listStyleType: 'none'}}>
            <div style={{fontWeight: 'normal', fontSize: '0.8125rem'}}>
              {fields.length > 1 &&
                <div className="input-group input-group-sm flex-nowrap">
                  <div className="font-weight-bold" style={{ fontSize: '1rem'}}>{index+1}.&nbsp;</div>
                  <Field
                    className="form-control dsf-line-label"
                    name={`${line}.incomeLabel`}
                    component="input"
                    type="text"
                    placeholder="Libellé du revenu du foyer"
                  />
                  {householdIncomesAllocation[fields.get(index).incomeId] && Object.keys(householdIncomesAllocation[fields.get(index).incomeId]).length === 0 ?
                    <BtnRemoveLine removeLine={removeLine} index={index} label="ce revenu du foyer" />
                    :
                    <NoDeletionButton
                      index={index}
                      incomeId={fields.get(index).incomeId}
                      allocationDetails={allocationDetails}
                    />
                  }
                </div>
              }
              <FieldArray
                component={renderIncome}
                name={`householdIncome[${index}].originalIncome`}
                incomeType="original"
                incomeId={index}
              />
              <table className="table table-hover table-sm">
                <caption><h3>Etape 2 : évaluer la part d'autoconsommation de la victime décédée</h3></caption>
                <tbody>
                    <tr>
                      <td className="col-num" />
                      <td className="col-selfPercentage">
                        <div className="input-group input-group-sm flex-nowrap main-form-header">
                          <label htmlFor={`householdIncome[${index}].selfConsumptionPercentage`} className="control-label text-nowrap mb-0">Renseignez la part d'autoconsommation : </label>
                          <Field
                            name={`householdIncome[${index}].selfConsumption.percentage`}
                            id={`householdIncome[${index}].selfConsumptionPercentage`}
                            component={NumberField}
                            placeholder={"0"}
                            max={100}
                            append={"%"}
                            className="refNumber font-weight-bold"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-group input-group-sm flex-nowrap main-form-header">
                          <label htmlFor={`householdIncome[${index}].selfConsumptionDescription`} className="control-label text-nowrap mb-0">justifiée par</label>
                          <div className="input-group input-group-sm flex-nowrap">
                          <Field
                            name={`householdIncome[${index}].selfConsumption.description`}
                            id={`householdIncome[${index}].selfConsumptionDescription`}
                            component="input"
                            className="form-control"
                            placeholder="la composition de la famille ou le train de vie de la victime directe..."
                          />
                          </div>
                        </div>
                      </td>
                    </tr>
                </tbody>
              </table>
              <FieldArray
                component={renderIncome}
                name={`householdIncome[${index}].residualIncome`}
                incomeType="residual"
              />
              <table className="table table-hover table-sm">
                <tbody>
                    <tr>
                      <td className="col-num" />
                      <th scope="row" className="householdLoss align-middle">
                        Préjudice annuel du foyer{fields.length > 1 && ` n° ${index+1}` }
                      </th>
                      <td className="col-amount text-right householdLoss">
                        {fields.get(index).amountDirectInput ?
                          <Field
                            name={`householdIncome[${index}].householdLossAmount`}
                            component={AmountField}
                            readOnly={false}
                          />
                          :
                          <AmountField fieldValue={getHouseholdIncomeLoss(fields.get(index))} />
                        }
                      </td>
                      <td className="col-act">
                        <BtnInputAmount
                          switchInputAmount={() => {
                            dispatch(
                              change(
                                "caseDataForm",
                                `householdIncome[${index}].householdLossAmount`,
                                !fields.get(index).amountDirectInput
                                  ? getHouseholdIncomeLoss(fields.get(index))
                                    .toFixed(2)
                                    .replace(".", ",")
                                  : null
                              )
                            );
                            dispatch(
                              change(
                                "caseDataForm",
                                `householdIncome[${index}].amountDirectInput`,
                                !fields.get(index).amountDirectInput
                              )
                            );
                          }}
                          amountDirectInput={fields.get(index).amountDirectInput}
                        />
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
          </li>
        )}
      </ul>
    </div>
    <BtnAddItemLine
      label="un nouveau calcul de préjudice du foyer"
      addLine={addLine}
    />
    </div>
  )
}

renderHouseholdIncome.propTypes = {
  fields: PropTypes.object,
  meta: PropTypes.object,
  dispatch: PropTypes.func,
  indirectVictims: PropTypes.array
}

const caseSelector = formValueSelector("caseDataForm");

const mapStateToProps = (state) => {
  let indirectVictims;
  if (state.cases && state.cases.case && state.cases.case.json_data) {
    indirectVictims = caseSelector(state, "indirectVictims");
  }
  return { indirectVictims };
};

export default connect(mapStateToProps)(renderHouseholdIncome);
