import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

const DurationNumberField = props => {
  const { input, readOnly } = props;
  return (
    <NumberFormat
      {...input}
      displayType="input"
      thousandSeparator=" "
      decimalSeparator=","
      decimalScale={3}
      allowNegative={false}
      type="text"
      className="form-control text-right durationNum"
      placeholder="0"
      readOnly={readOnly}
      disabled={readOnly}
    />
  );
};

DurationNumberField.propTypes = {
  input: PropTypes.object,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  append: PropTypes.string,
  step: PropTypes.number,
  max: PropTypes.number
};

export default DurationNumberField;
