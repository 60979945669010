import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import AmountField from "../../fields/AmountField";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { tppTotal } from "../calculations";
import DatePicker, {
  formatDates,
  normalizeDates,
} from "../creation_wizard/RenderDateInput";


const IndirectRenderEntryTPP = props => {
  const { fields, colSpan, tppValues, entryName, entry } = props;

  const removeTPP = index => {
    fields.remove(index);
  };

  return (
    <tr>
      <td className="col-num main-line" />
      <td colSpan={colSpan} className="pl-2 main-line">
        <table className="table table-sm mb-0 tpp">
          <caption>Paiements de tiers soumis à recours sur l'ensemble {entry ? `des ${entry}` : "du poste"}</caption>
          <thead>
            <tr>
              <th className="text-nowrap pl-0">Organisme payeur</th>
              <th className="container-fluid">Intitulé</th>
              <th className="text-nowrap datePeriod">Date ou période du paiement</th>
              <th colSpan="2" className="text-center">Montant</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((tpp, index) => (
              <tr key={index}>
                <td className="pl-0 pr-1 pb-0 pt-1">
                  <div className="input-group input-group-sm">
                    <Field
                      name={`TPP[${entryName}][${index}].organisation`}
                      type="text"
                      component="input"
                      className="form-control pl-0"
                    />
                  </div>
                </td>
                <td className="pl-1 pr-1 pb-0 pt-1">
                  <div className="input-group input-group-sm">
                    <Field
                      name={`TPP[${entryName}][${index}].label`}
                      type="text"
                      component="input"
                      className="form-control"
                    />
                  </div>
                </td>
                <td className="pl-1 pr-1 pb-0 pt-1">
                  <div className="d-flex">
                    <div className="input-group input-group-sm date">
                      <Field
                        name={`TPP[${entryName}][${index}].startDate`}
                        component={DatePicker}
                        parse={normalizeDates}
                        format={formatDates}
                        className="form-control mr-1"
                      />
                    </div>
                    <div className="input-group input-group-sm date">
                      <Field
                        name={`TPP[${entryName}][${index}].endDate`}
                        component={DatePicker}
                        parse={normalizeDates}
                        format={formatDates}
                        className="form-control ml-1"
                      />
                    </div>
                  </div>
                </td>
                <td className="col-amount-tpp d-sm-flex pl-1 pr-0 pb-0 pt-1">
                  <Field name={`TPP[${entryName}][${index}].amount`} component={AmountField} />
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm p-0 ml-2"
                    title="Supprimer ce paiement de tiers"
                    onClick={() => removeTPP(index)}
                  >
                    <DeleteForeverIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
          {fields.length > 1 && (
            <tfoot>
              <tr>
                <th scope="row" className="text-right" colSpan="3">
                  Total des paiements de tiers applicable sur l'ensemble {entry ? `des ${entry}` : "du poste"}
                </th>
                <td className="col-amount-tpp d-sm-flex pl-1 pr-0 pb-0 pt-1">
                  <AmountField fieldValue={tppTotal(tppValues)} />
                </td>
                <td></td>
              </tr>
            </tfoot>
          )}
        </table>
      </td>
      <td className="col-amount main-line" />
      <td className="col-act main-line" />
    </tr>
  );
};

IndirectRenderEntryTPP.propTypes = {
  fields: PropTypes.object,
  tppValues: PropTypes.array,
  entryMeta: PropTypes.array,
  colSpan: PropTypes.number,
  dispatch: PropTypes.func
};
export default IndirectRenderEntryTPP;
