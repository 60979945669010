import React, { useState } from 'react';
import axios from "axios";
import LegalFooter from "../layout/LegalFooter";

const PasswordReset = () => {
    const [email, setEmail] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [result, setResult] = useState("");

    const sendResetRequest = email => {
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        const body = JSON.stringify({ email });

        axios
            .post("/api/auth/password_reset/", body, config)
            .then(res => {
                res.data && res.data.status && res.data.status === "OK" && setResult("OK")
            })
            .catch(err => {
                setResult("KO");
            });
    };

    const handleSubmit = e => {
        e.preventDefault();
        setSubmitted(true);
        sendResetRequest(email);
    }
    return (
        <div className="col-md-6 m-auto">
            <div className="card card-body mt-5">
                <h2 className="text-center">Réinitialiser le mot de passe</h2>
                {result.length > 0 ?
                    <div className="pt-3">
                        {result === "OK" ?
                            <div>
                                <p><strong>C'est dans la boîte !</strong></p>
                                <p>Nous venons de vous envoyer un email à <strong>{email}</strong> pour réinitialiser votre mot de passe.</p>
                                <p>Vous ne l'avez pas reçu ? Vérifiez que l'adresse email saisie est bien la bonne, ou que l'email ne s'est pas glissé dans vos indésirables.</p>
                            </div>
                            :
                            <p>Une erreur s'est produite, veuillez réessayer.<br />Si le problème persiste, vous pouvez nous contacter à <a href="mailto:quantum@juri-solutions.fr">quantum@juri-solutions.fr</a></p>
                        }
                    </div>
                    :
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <div className="pt-3 pb-4">
                                <p><strong>HELP ! J'ai oublié mon mot de passe !</strong></p>
                                <p>Pas d'inquiétude, ça arrive à tout le monde :)</p>
                                <p>Pas de temps à perdre, <strong>renseignez l'adresse email</strong> associée à votre compte Quantum, vous recevrez un lien vous permettant de saisir un nouveau mot de passe.</p>
                                <p>&Agrave; vous de jouer !</p>
                            </div>
                            <label><strong>Adresse email</strong></label>
                            <input
                                type="email"
                                className="form-control"
                                name="email"
                                autoFocus
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                                placeholder="prenom.nom@domaine.fr"
                                required
                            />
                        </div>
                        <div className="form-group">
                            {submitted ?
                                <button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                    Demande en cours de traitement...
                                </button>
                                :
                                <button type="submit" className="btn btn-primary">
                                    Réinitialisez votre mot de passe
                            </button>
                            }
                        </div>
                    </form>}
            </div>
            <LegalFooter />
        </div>
    )
}

export default PasswordReset;
