import React, { Fragment } from "react";
//import Form from "./Form";
import Cases from "./Cases";
import LegalFooter from "../layout/LegalFooter";


export default function Dashboard() {
  return (
    <Fragment>
      <Cases />
      <LegalFooter />
    </Fragment>
  );
}
