import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function TpIcon(props) {
    return (
        <SvgIcon {...props} style={{ fontSize: 24 }} viewBox="-2 -2 37 37">
            <path d="M12.664,16.35c0.055-0.125,0.094-0.248,0.102-0.379c0.021-0.336,0.293-4.443-4.287-4.443s-4.309,4.107-4.289,4.443  c0.008,0.131,0.049,0.254,0.104,0.379c-0.258,0.221-0.496,0.602-0.451,1.264c0.049,0.727,0.316,1.203,0.795,1.422  c0.572,2.26,1.998,3.602,3.842,3.602s3.27-1.342,3.84-3.602c0.48-0.219,0.748-0.695,0.797-1.422  C13.16,16.951,12.922,16.568,12.664,16.35z M12.408,17.564c-0.051,0.742-0.354,0.826-0.484,0.861l-0.203,0.057l-0.047,0.207  c-0.223,0.975-0.975,3.238-3.195,3.238s-2.975-2.264-3.195-3.238l-0.047-0.207l-0.203-0.057c-0.131-0.035-0.434-0.119-0.484-0.861  c-0.018-0.25,0.023-0.416,0.088-0.533c0.051,0.113,0.098,0.23,0.137,0.361c0.182,0.617,0.445,0.326,0.445,0.326  c-0.15-1.412,1.029-3.27,1.029-3.27s0.811,0.396,2.23,0.396s2.23-0.396,2.23-0.396s1.18,1.857,1.029,3.27  c0,0,0.264,0.291,0.445-0.326c0.037-0.131,0.086-0.248,0.137-0.361C12.385,17.148,12.424,17.314,12.408,17.564z" />
            <path d="M21.449,27.871c-0.879-1.238-3.137-1.885-3.137-1.885c-0.527,1.35-2.463,2.242-2.463,2.242s-1.936-0.893-2.463-2.242  c0,0-2.258,0.646-3.137,1.885c-0.486,0.684-0.732,1.979-0.732,2.682c0,0,1.246,1.475,6.332,1.475s6.332-1.475,6.332-1.475  C22.182,29.85,21.936,28.555,21.449,27.871z" />
            <path d="M21.678,21.189c0.719,0.93,1.701,1.447,2.844,1.447s2.125-0.518,2.842-1.447c0.932,0.145,1.963,0.824,2.006,0.65  c0.012-0.049-0.396-0.982-0.551-1.951c-0.063-0.396-0.082-0.793-0.086-1.131c0.244-0.262,0.391-0.639,0.426-1.145  c0.039-0.57-0.133-0.932-0.344-1.16c0.029-0.711,0.059-4.926-4.293-4.926s-4.324,4.215-4.293,4.926  c-0.213,0.229-0.383,0.59-0.344,1.16c0.033,0.506,0.182,0.883,0.426,1.145c-0.004,0.338-0.023,0.734-0.086,1.131  c-0.076,0.473-0.211,0.936-0.33,1.293c0.109,0.139,0.207,0.314,0.277,0.527C20.566,21.537,21.137,21.273,21.678,21.189z   M20.66,17.072c0.059,0.074,0.113,0.172,0.156,0.32c0.182,0.617,0.445,0.326,0.445,0.326c-0.15-1.412,1.84-2.873,3.26-2.873  c1.418,0,2.23-0.396,2.23-0.396s1.18,1.857,1.029,3.27c0,0,0.264,0.291,0.445-0.326c0.043-0.148,0.098-0.246,0.156-0.32  c0.053,0.113,0.082,0.268,0.068,0.492c-0.051,0.742-0.355,0.826-0.484,0.861l-0.205,0.057l-0.047,0.207  c-0.221,0.975-0.975,3.238-3.193,3.238c-2.221,0-2.975-2.264-3.195-3.238l-0.047-0.207l-0.203-0.057  c-0.131-0.035-0.434-0.119-0.484-0.861C20.576,17.34,20.607,17.186,20.66,17.072z" />
            <path d="M31.576,24.396c-1.107-1.561-3.953-2.373-3.953-2.373c-0.664,1.699-1.975,1.936-3.102,1.936s-2.438-0.236-3.104-1.936  c0,0-0.496,0.143-1.15,0.42c-0.002,0.031,0,0.061-0.002,0.094c-0.055,0.82-0.389,1.287-0.732,1.551  c-0.158,0.523-0.373,0.988-0.633,1.391c0.754,0.264,2.314,0.914,3.09,2.008c0.406,0.572,0.645,1.393,0.762,2.094  c0.545,0.033,1.133,0.053,1.77,0.053c6.408,0,7.979-1.857,7.979-1.857C32.5,26.889,32.189,25.26,31.576,24.396z" />
            <path d="M12.799,25.479c-0.26-0.402-0.475-0.867-0.633-1.391c-0.344-0.264-0.678-0.73-0.732-1.553  c-0.006-0.063-0.004-0.121-0.004-0.182c-0.486,0.973-1.434,2.043-2.127,2.752l-0.193-0.697c0.479-0.189,0.6-0.596,0.479-0.91  c-0.17-0.443-0.611-0.588-1.109-0.588s-0.939,0.145-1.111,0.588c-0.119,0.314,0.002,0.721,0.48,0.91l-0.195,0.697  c-0.771-0.789-1.865-2.029-2.277-3.082c0,0-2.844,0.813-3.951,2.373C0.811,25.26,0.5,26.889,0.5,27.775c0,0,1.57,1.857,7.979,1.857  c0.156,0,0.309-0.002,0.459-0.004c0.115-0.713,0.355-1.559,0.77-2.143C10.484,26.393,12.045,25.742,12.799,25.479z" />
            <path d="M12.738,23.666c0.467,1.809,1.621,2.881,3.111,2.881s2.643-1.072,3.111-2.881c0.299-0.15,0.594-0.479,0.641-1.174  c0.043-0.631-0.221-0.961-0.469-1.131c-0.021-0.217-0.053-0.418-0.092-0.604c-0.496-2.303-2.904-2.369-3.207-2.369  c-0.273,0-2.68,0.066-3.176,2.369c-0.039,0.186-0.07,0.387-0.092,0.604c-0.248,0.17-0.514,0.5-0.471,1.129  C12.143,23.188,12.439,23.516,12.738,23.666z M13.043,21.904l0.197-0.084l0.016-0.215c0.02-0.258,0.051-0.492,0.096-0.697  c0.051-0.23,0.127-0.432,0.221-0.607c0.428,0.201,1.252,0.514,2.277,0.514c1.023,0,1.85-0.313,2.277-0.514  c0.094,0.176,0.17,0.377,0.221,0.607c0.043,0.205,0.076,0.439,0.094,0.697l0.018,0.215l0.197,0.084  c0.08,0.035,0.268,0.115,0.238,0.539c-0.035,0.537-0.242,0.594-0.33,0.619l-0.203,0.057l-0.049,0.205  c-0.17,0.756-0.754,2.514-2.463,2.514s-2.293-1.758-2.465-2.514l-0.047-0.205l-0.203-0.057c-0.088-0.025-0.295-0.082-0.33-0.619  C12.775,22.02,12.963,21.939,13.043,21.904z" />
            <path d="M16.5,0.973c-2.973,0-5.385,2.412-5.385,5.385s2.412,5.385,5.385,5.385s5.385-2.412,5.385-5.385S19.473,0.973,16.5,0.973z   M20,7.469h-2.389v2.389h-2.225V7.469H13V5.246h2.387V2.857h2.225v2.389H20V7.469z" />
        </SvgIcon>)
}
