import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addCase, updateCase } from "../../../../actions/cases";
import { directVictimModel } from "../../physical_injury/dataModels";
import CreateVictim from "./CreateVictim";
import CreateAccident from "./CreateAccident";
import SmallScreens from "../case_layout/SmallScreens";

class CreateCaseWizard extends Component {
  static propTypes = {
    addCase: PropTypes.func.isRequired
  };

  victimSubmit = values => {
    const victim = values;
    let caseTitle = victim.title || "Dossier sans titre";
    delete victim.title;
    let dataModel =
      victim.victimStatus === "indirect"
        ? indirectVictimModel
        : directVictimModel;
    let c = {
      title: caseTitle,
      json_data: { caseData: {}, caseDataForm: dataModel, victim: victim }
    };

    if (!this.props.case.id) {
      // in creation mode
      this.props.addCase(c);
    } else {
      // the case was already created, the user modifies the victim's data
      c = {
        id: this.props.case.id,
        title: caseTitle,
        json_data: { ...this.props.case.json_data, victim }
      };
      this.props.updateCase(c);
    }
  };

  accidentSubmit = values => {
    const accident = values;
    !accident.accidentType && (accident.accidentType = "accident");

    const caseId = this.props.case.id;

    let c = {
      id: caseId,
      title: this.props.case.title,
      json_data: { ...this.props.case.json_data, accident, victim: { ...this.props.case.json_data.victim, ...accident } }
    };

    // TODO: this (below) can very likely be improved
    let updateCase = this.props.updateCase(c);
    let updatePromise = new Promise(function (resolve) {
      resolve(updateCase);
    });
    updatePromise.then(() => {
      this.redirectToCase(caseId);
    });
  };

  redirectToCase = id => {
    let target = "/dossier/" + id;
    // TODO: there may be a better approach than history.push(), using React Router
    this.props.history.push(target);
  };

  render() {
    // TODO: add a transition to display CreateAccident

    return (
      <>
        <div className="mobile"><SmallScreens /></div>
        <div className="desktop">
          <h2 className={`${this.props.case.id ? "" : "wizard-form mx-auto"}`}>
            {this.props.case.title ? this.props.case.title : "Nouveau dossier"}
          </h2>
          <div className="d-flex">
            <CreateVictim
              onSubmit={this.victimSubmit}
              caseId={this.props.case.id}
              formType="wizard"
            />
            {this.props.case.id && (
              <CreateAccident onSubmit={this.accidentSubmit} formType="wizard" />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  let c = {};
  if (state.cases && state.cases.case) c = state.cases.case;
  return { case: c };
};

export default connect(mapStateToProps, { addCase, updateCase })(
  CreateCaseWizard
);
