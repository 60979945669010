import React, { Component, Fragment } from "react";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export class Alerts extends Component {
  static propTypes = {
    error: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired
  };

  componentDidUpdate(prevProps) {
    const { error, alert, message } = this.props;
    if (error !== prevProps.error) {
      if (error.msg.title) {
        alert.error(`Intitulé : ${error.msg.title.join()}`);
      }
      if (error.msg.non_field_errors) {
        alert.error(error.msg.non_field_errors.join());
      }
      if (error.msg.email) {
        alert.error(error.msg.email.join());
      }
    }
    if (message !== prevProps.message) {
      if (message.deleteCase) {
        alert.success(message.deleteCase);
      }
      if (message.addCase) {
        alert.success(message.addCase);
      }
      if (message.updateCase) {
        alert.success(message.updateCase);
      }
      if (message.passwordsDoNotMatch) {
        alert.error(message.passwordsDoNotMatch);
      }
      if (message.disconnected) {
        alert.error(message.disconnected);
      }
      if(message.recordConflict){
        alert.error(message.recordConflict);
      }
    }
  }

  render() {
    return <Fragment />;
  }
}

const mapStateToProps = state => ({
  error: state.errors,
  message: state.messages
});

export default connect(mapStateToProps)(withAlert(Alerts));
