// contains all the description and data of capitalisation tables
// capitalisation tables are stored as JS maps :
//- the key is the gender ("M" or "F")
//- the data is a arrays whose entries are 3-entries arrays
//- the 3 entries of these last arrays are startAge, endAge and PER

// Potential optimisation: store the data of each capitalisation table separately, 
// and call it only when necessary, after a user action on the frontend.
// (only if we have a big number of capitalisation tables)

export const gazPal2018 = new Map();
gazPal2018.set("M", [
 [0, 999, 63.884],
 [0, 69, 55.8],
 [0, 68, 55.246],
 [0, 67, 54.679],
 [0, 66, 54.1],
 [0, 65, 53.509],
 [0, 64, 52.907],
 [0, 63, 52.294],
 [0, 62, 51.671],
 [0, 61, 51.037],
 [0, 60, 50.393],
 [0, 59, 49.739],
 [0, 55, 47.028],
 [0, 50, 43.45],
 [0, 29, 26.74],
 [0, 25, 23.3],
 [0, 21, 19.779],
 [0, 20, 18.886],
 [0, 18, 17.085],
 [0, 16, 15.264],
 [1, 999, 63.456],
 [1, 69, 55.3],
 [1, 68, 54.74],
 [1, 67, 54.168],
 [1, 66, 53.584],
 [1, 65, 52.988],
 [1, 64, 52.381],
 [1, 63, 51.763],
 [1, 62, 51.133],
 [1, 61, 50.494],
 [1, 60, 49.844],
 [1, 59, 49.184],
 [1, 55, 46.45],
 [1, 50, 42.839],
 [1, 29, 25.98],
 [1, 25, 22.508],
 [1, 21, 18.956],
 [1, 20, 18.055],
 [1, 18, 16.238],
 [1, 16, 14.4],
 [2, 999, 62.793],
 [2, 69, 54.594],
 [2, 68, 54.031],
 [2, 67, 53.456],
 [2, 66, 52.869],
 [2, 65, 52.27],
 [2, 64, 51.659],
 [2, 63, 51.038],
 [2, 62, 50.405],
 [2, 61, 49.762],
 [2, 60, 49.109],
 [2, 59, 48.446],
 [2, 55, 45.696],
 [2, 50, 42.066],
 [2, 29, 25.118],
 [2, 25, 21.628],
 [2, 21, 18.056],
 [2, 20, 17.151],
 [2, 18, 15.324],
 [2, 16, 13.477],
 [3, 999, 62.119],
 [3, 69, 53.877],
 [3, 68, 53.312],
 [3, 67, 52.734],
 [3, 66, 52.143],
 [3, 65, 51.541],
 [3, 64, 50.927],
 [3, 63, 50.303],
 [3, 62, 49.667],
 [3, 61, 49.021],
 [3, 60, 48.364],
 [3, 59, 47.697],
 [3, 55, 44.934],
 [3, 50, 41.285],
 [3, 29, 24.248],
 [3, 25, 20.74],
 [3, 21, 17.15],
 [3, 20, 16.24],
 [3, 18, 14.403],
 [3, 16, 12.547],
 [4, 999, 61.439],
 [4, 69, 53.155],
 [4, 68, 52.586],
 [4, 67, 52.005],
 [4, 66, 51.412],
 [4, 65, 50.807],
 [4, 64, 50.19],
 [4, 63, 49.562],
 [4, 62, 48.923],
 [4, 61, 48.273],
 [4, 60, 47.613],
 [4, 59, 46.943],
 [4, 55, 44.165],
 [4, 50, 40.498],
 [4, 29, 23.373],
 [4, 25, 19.847],
 [4, 21, 16.239],
 [4, 20, 15.323],
 [4, 18, 13.478],
 [4, 16, 11.611],
 [5, 999, 60.753],
 [5, 69, 52.426],
 [5, 68, 51.855],
 [5, 67, 51.271],
 [5, 66, 50.675],
 [5, 65, 50.066],
 [5, 64, 49.446],
 [5, 63, 48.815],
 [5, 62, 48.173],
 [5, 61, 47.52],
 [5, 60, 46.856],
 [5, 59, 46.183],
 [5, 55, 43.391],
 [5, 50, 39.704],
 [5, 29, 22.493],
 [5, 25, 18.949],
 [5, 21, 15.322],
 [5, 20, 14.402],
 [5, 18, 12.547],
 [5, 16, 10.671],
 [6, 999, 60.064],
 [6, 69, 51.694],
 [6, 68, 51.12],
 [6, 67, 50.533],
 [6, 66, 49.934],
 [6, 65, 49.322],
 [6, 64, 48.699],
 [6, 63, 48.064],
 [6, 62, 47.419],
 [6, 61, 46.763],
 [6, 60, 46.096],
 [6, 59, 45.419],
 [6, 55, 42.613],
 [6, 50, 38.907],
 [6, 29, 21.608],
 [6, 25, 18.045],
 [6, 21, 14.4],
 [6, 20, 13.475],
 [6, 18, 11.611],
 [6, 16, 9.725],
 [7, 999, 59.37],
 [7, 69, 50.958],
 [7, 68, 50.381],
 [7, 67, 49.791],
 [7, 66, 49.188],
 [7, 65, 48.574],
 [7, 64, 47.947],
 [7, 63, 47.31],
 [7, 62, 46.661],
 [7, 61, 46.001],
 [7, 60, 45.331],
 [7, 59, 44.65],
 [7, 55, 41.83],
 [7, 50, 38.106],
 [7, 29, 20.718],
 [7, 25, 17.138],
 [7, 21, 13.473],
 [7, 20, 12.544],
 [7, 18, 10.67],
 [7, 16, 8.775],
 [8, 999, 58.672],
 [8, 69, 50.217],
 [8, 68, 49.637],
 [8, 67, 49.044],
 [8, 66, 48.439],
 [8, 65, 47.821],
 [8, 64, 47.191],
 [8, 63, 46.551],
 [8, 62, 45.898],
 [8, 61, 45.235],
 [8, 60, 44.562],
 [8, 59, 43.878],
 [8, 55, 41.043],
 [8, 50, 37.3],
 [8, 29, 19.823],
 [8, 25, 16.225],
 [8, 21, 12.542],
 [8, 20, 11.608],
 [8, 18, 9.724],
 [8, 16, 7.82],
 [9, 999, 57.971],
 [9, 69, 49.473],
 [9, 68, 48.889],
 [9, 67, 48.294],
 [9, 66, 47.685],
 [9, 65, 47.064],
 [9, 64, 46.431],
 [9, 63, 45.787],
 [9, 62, 45.132],
 [9, 61, 44.465],
 [9, 60, 43.788],
 [9, 59, 43.101],
 [9, 55, 40.251],
 [9, 50, 36.489],
 [9, 29, 18.924],
 [9, 25, 15.307],
 [9, 21, 11.606],
 [9, 20, 10.667],
 [9, 18, 8.774],
 [9, 16, 6.859],
 [10, 999, 57.266],
 [10, 69, 48.724],
 [10, 68, 48.138],
 [10, 67, 47.539],
 [10, 66, 46.928],
 [10, 65, 46.304],
 [10, 64, 45.668],
 [10, 63, 45.02],
 [10, 62, 44.361],
 [10, 61, 43.692],
 [10, 60, 43.011],
 [10, 59, 42.32],
 [10, 55, 39.456],
 [10, 50, 35.675],
 [10, 29, 18.02],
 [10, 25, 14.385],
 [10, 21, 10.665],
 [10, 20, 9.721],
 [10, 18, 7.818],
 [10, 16, 5.894],
 [11, 999, 56.557],
 [11, 69, 47.972],
 [11, 68, 47.383],
 [11, 67, 46.781],
 [11, 66, 46.166],
 [11, 65, 45.539],
 [11, 64, 44.9],
 [11, 63, 44.249],
 [11, 62, 43.587],
 [11, 61, 42.914],
 [11, 60, 42.23],
 [11, 59, 41.535],
 [11, 55, 38.657],
 [11, 50, 34.856],
 [11, 29, 17.112],
 [11, 25, 13.458],
 [11, 21, 9.719],
 [11, 20, 8.771],
 [11, 18, 6.858],
 [11, 16, 4.924],
 [12, 999, 55.845],
 [12, 69, 47.216],
 [12, 68, 46.624],
 [12, 67, 46.019],
 [12, 66, 45.401],
 [12, 65, 44.771],
 [12, 64, 44.128],
 [12, 63, 43.474],
 [12, 62, 42.809],
 [12, 61, 42.132],
 [12, 60, 41.445],
 [12, 59, 40.747],
 [12, 55, 37.854],
 [12, 50, 34.034],
 [12, 29, 16.199],
 [12, 25, 12.527],
 [12, 21, 8.768],
 [12, 20, 7.815],
 [12, 18, 5.893],
 [12, 16, 3.949],
 [13, 999, 55.13],
 [13, 69, 46.457],
 [13, 68, 45.862],
 [13, 67, 45.254],
 [13, 66, 44.633],
 [13, 65, 44],
 [13, 64, 43.354],
 [13, 63, 42.696],
 [13, 62, 42.027],
 [13, 61, 41.347],
 [13, 60, 40.656],
 [13, 59, 39.955],
 [13, 55, 37.047],
 [13, 50, 33.208],
 [13, 29, 15.282],
 [13, 25, 11.591],
 [13, 21, 7.813],
 [13, 20, 6.855],
 [13, 18, 4.923],
 [13, 16, 2.969],
 [14, 999, 54.411],
 [14, 69, 45.694],
 [14, 68, 45.096],
 [14, 67, 44.485],
 [14, 66, 43.861],
 [14, 65, 43.224],
 [14, 64, 42.575],
 [14, 63, 41.914],
 [14, 62, 41.242],
 [14, 61, 40.558],
 [14, 60, 39.864],
 [14, 59, 39.159],
 [14, 55, 36.236],
 [14, 50, 32.377],
 [14, 29, 14.36],
 [14, 25, 10.65],
 [14, 21, 6.853],
 [14, 20, 5.89],
 [14, 18, 3.948],
 [14, 16, 1.985],
 [15, 999, 53.693],
 [15, 69, 44.931],
 [15, 68, 44.33],
 [15, 67, 43.715],
 [15, 66, 43.088],
 [15, 65, 42.448],
 [15, 64, 41.795],
 [15, 63, 41.131],
 [15, 62, 40.455],
 [15, 61, 39.768],
 [15, 60, 39.07],
 [15, 59, 38.361],
 [15, 55, 35.424],
 [15, 50, 31.545],
 [15, 29, 13.434],
 [15, 25, 9.705],
 [15, 21, 5.888],
 [15, 20, 4.921],
 [15, 18, 2.969],
 [15, 16, 0.995],
 [16, 999, 52.974],
 [16, 69, 44.166],
 [16, 68, 43.562],
 [16, 67, 42.944],
 [16, 66, 42.313],
 [16, 65, 41.67],
 [16, 64, 41.014],
 [16, 63, 40.346],
 [16, 62, 39.667],
 [16, 61, 38.976],
 [16, 60, 38.274],
 [16, 59, 37.562],
 [16, 55, 34.609],
 [16, 50, 30.71],
 [16, 29, 12.504],
 [16, 25, 8.756],
 [16, 21, 4.919],
 [16, 20, 3.946],
 [16, 18, 1.984],
 [17, 999, 52.256],
 [17, 69, 43.401],
 [17, 68, 42.794],
 [17, 67, 42.173],
 [17, 66, 41.538],
 [17, 65, 40.891],
 [17, 64, 40.232],
 [17, 63, 39.561],
 [17, 62, 38.878],
 [17, 61, 38.184],
 [17, 60, 37.478],
 [17, 59, 36.762],
 [17, 55, 33.793],
 [17, 50, 29.873],
 [17, 29, 11.571],
 [17, 25, 7.802],
 [17, 21, 3.945],
 [17, 20, 2.967],
 [17, 18, 0.995],
 [18, 999, 51.537],
 [18, 69, 42.635],
 [18, 68, 42.024],
 [18, 67, 41.4],
 [18, 66, 40.762],
 [18, 65, 40.112],
 [18, 64, 39.449],
 [18, 63, 38.774],
 [18, 62, 38.088],
 [18, 61, 37.39],
 [18, 60, 36.68],
 [18, 59, 35.96],
 [18, 55, 32.975],
 [18, 50, 29.034],
 [18, 29, 10.633],
 [18, 25, 6.844],
 [18, 21, 2.967],
 [18, 20, 1.983],
 [19, 999, 50.822],
 [19, 69, 41.871],
 [19, 68, 41.257],
 [19, 67, 40.629],
 [19, 66, 39.988],
 [19, 65, 39.334],
 [19, 64, 38.667],
 [19, 63, 37.989],
 [19, 62, 37.298],
 [19, 61, 36.596],
 [19, 60, 35.883],
 [19, 59, 35.159],
 [19, 55, 32.158],
 [19, 50, 28.195],
 [19, 29, 9.692],
 [19, 25, 5.882],
 [19, 21, 1.983],
 [19, 20, 0.994],
 [20, 999, 50.109],
 [20, 69, 41.107],
 [20, 68, 40.49],
 [20, 67, 39.858],
 [20, 66, 39.214],
 [20, 65, 38.556],
 [20, 64, 37.886],
 [20, 63, 37.203],
 [20, 62, 36.509],
 [20, 61, 35.803],
 [20, 60, 35.086],
 [20, 59, 34.357],
 [20, 55, 31.339],
 [20, 50, 27.354],
 [20, 29, 8.747],
 [20, 25, 4.915],
 [20, 21, 0.994],
 [21, 999, 49.394],
 [21, 69, 40.341],
 [21, 68, 39.72],
 [21, 67, 39.085],
 [21, 66, 38.436],
 [21, 65, 37.775],
 [21, 64, 37.101],
 [21, 63, 36.414],
 [21, 62, 35.716],
 [21, 61, 35.006],
 [21, 60, 34.285],
 [21, 59, 33.553],
 [21, 55, 30.517],
 [21, 50, 26.509],
 [21, 29, 7.797],
 [21, 25, 3.943],
 [22, 999, 48.674],
 [22, 69, 39.57],
 [22, 68, 38.945],
 [22, 67, 38.307],
 [22, 66, 37.655],
 [22, 65, 36.989],
 [22, 64, 36.312],
 [22, 63, 35.621],
 [22, 62, 34.919],
 [22, 61, 34.205],
 [22, 60, 33.48],
 [22, 59, 32.743],
 [22, 55, 29.691],
 [22, 50, 25.66],
 [22, 29, 6.841],
 [22, 25, 2.966],
 [23, 999, 47.954],
 [23, 69, 38.797],
 [23, 68, 38.169],
 [23, 67, 37.527],
 [23, 66, 36.871],
 [23, 65, 36.202],
 [23, 64, 35.52],
 [23, 63, 34.826],
 [23, 62, 34.12],
 [23, 61, 33.402],
 [23, 60, 32.672],
 [23, 59, 31.931],
 [23, 55, 28.861],
 [23, 50, 24.808],
 [23, 29, 5.88],
 [23, 25, 1.983],
 [24, 999, 47.23],
 [24, 69, 38.021],
 [24, 68, 37.389],
 [24, 67, 36.743],
 [24, 66, 36.083],
 [24, 65, 35.41],
 [24, 64, 34.725],
 [24, 63, 34.026],
 [24, 62, 33.316],
 [24, 61, 32.594],
 [24, 60, 31.86],
 [24, 59, 31.115],
 [24, 55, 28.027],
 [24, 50, 23.95],
 [24, 29, 4.914],
 [24, 25, 0.994],
 [25, 999, 46.503],
 [25, 69, 37.24],
 [25, 68, 36.605],
 [25, 67, 35.955],
 [25, 66, 35.292],
 [25, 65, 34.615],
 [25, 64, 33.925],
 [25, 63, 33.223],
 [25, 62, 32.509],
 [25, 61, 31.782],
 [25, 60, 31.044],
 [25, 59, 30.295],
 [25, 55, 27.189],
 [25, 50, 23.089],
 [25, 29, 3.942],
 [26, 999, 45.774],
 [26, 69, 36.457],
 [26, 68, 35.818],
 [26, 67, 35.164],
 [26, 66, 34.497],
 [26, 65, 33.816],
 [26, 64, 33.123],
 [26, 63, 32.416],
 [26, 62, 31.698],
 [26, 61, 30.967],
 [26, 60, 30.225],
 [26, 59, 29.471],
 [26, 55, 26.348],
 [26, 50, 22.223],
 [26, 29, 2.965],
 [27, 999, 45.04],
 [27, 69, 35.669],
 [27, 68, 35.026],
 [27, 67, 34.369],
 [27, 66, 33.698],
 [27, 65, 33.013],
 [27, 64, 32.316],
 [27, 63, 31.605],
 [27, 62, 30.882],
 [27, 61, 30.148],
 [27, 60, 29.401],
 [27, 59, 28.643],
 [27, 55, 25.501],
 [27, 50, 21.352],
 [27, 29, 1.983],
 [28, 999, 44.303],
 [28, 69, 34.877],
 [28, 68, 34.23],
 [28, 67, 33.569],
 [28, 66, 32.894],
 [28, 65, 32.206],
 [28, 64, 31.504],
 [28, 63, 30.789],
 [28, 62, 30.062],
 [28, 61, 29.323],
 [28, 60, 28.572],
 [28, 59, 27.81],
 [28, 55, 24.65],
 [28, 50, 20.477],
 [28, 29, 0.994],
 [29, 999, 43.564],
 [29, 69, 34.083],
 [29, 68, 33.432],
 [29, 67, 32.767],
 [29, 66, 32.088],
 [29, 65, 31.396],
 [29, 64, 30.69],
 [29, 63, 29.971],
 [29, 62, 29.24],
 [29, 61, 28.496],
 [29, 60, 27.741],
 [29, 59, 26.974],
 [29, 55, 23.795],
 [29, 50, 19.598],
 [30, 999, 42.82],
 [30, 69, 33.283],
 [30, 68, 32.628],
 [30, 67, 31.96],
 [30, 66, 31.277],
 [30, 65, 30.58],
 [30, 64, 29.87],
 [30, 63, 29.147],
 [30, 62, 28.411],
 [30, 61, 27.663],
 [30, 60, 26.904],
 [30, 59, 26.132],
 [30, 55, 22.935],
 [30, 50, 18.713],
 [31, 999, 42.073],
 [31, 69, 32.479],
 [31, 68, 31.821],
 [31, 67, 31.148],
 [31, 66, 30.461],
 [31, 65, 29.761],
 [31, 64, 29.046],
 [31, 63, 28.319],
 [31, 62, 27.579],
 [31, 61, 26.827],
 [31, 60, 26.063],
 [31, 59, 25.286],
 [31, 55, 22.07],
 [31, 50, 17.823],
 [32, 999, 41.32],
 [32, 69, 31.67],
 [32, 68, 31.008],
 [32, 67, 30.332],
 [32, 66, 29.64],
 [32, 65, 28.936],
 [32, 64, 28.217],
 [32, 63, 27.485],
 [32, 62, 26.741],
 [32, 61, 25.985],
 [32, 60, 25.216],
 [32, 59, 24.435],
 [32, 55, 21.2],
 [32, 50, 16.928],
 [33, 999, 40.566],
 [33, 69, 30.859],
 [33, 68, 30.193],
 [33, 67, 29.512],
 [33, 66, 28.817],
 [33, 65, 28.108],
 [33, 64, 27.385],
 [33, 63, 26.649],
 [33, 62, 25.901],
 [33, 61, 25.14],
 [33, 60, 24.366],
 [33, 59, 23.581],
 [33, 55, 20.326],
 [33, 50, 16.029],
 [34, 999, 39.81],
 [34, 69, 30.044],
 [34, 68, 29.374],
 [34, 67, 28.689],
 [34, 66, 27.99],
 [34, 65, 27.277],
 [34, 64, 26.549],
 [34, 63, 25.809],
 [34, 62, 25.056],
 [34, 61, 24.29],
 [34, 60, 23.512],
 [34, 59, 22.722],
 [34, 55, 19.448],
 [34, 50, 15.125],
 [35, 999, 39.051],
 [35, 69, 29.226],
 [35, 68, 28.552],
 [35, 67, 27.863],
 [35, 66, 27.159],
 [35, 65, 26.442],
 [35, 64, 25.71],
 [35, 63, 24.965],
 [35, 62, 24.208],
 [35, 61, 23.437],
 [35, 60, 22.655],
 [35, 59, 21.86],
 [35, 55, 18.566],
 [35, 50, 14.217],
 [36, 999, 38.288],
 [36, 69, 28.404],
 [36, 68, 27.726],
 [36, 67, 27.033],
 [36, 66, 26.325],
 [36, 65, 25.603],
 [36, 64, 24.867],
 [36, 63, 24.117],
 [36, 62, 23.355],
 [36, 61, 22.58],
 [36, 60, 21.793],
 [36, 59, 20.993],
 [36, 55, 17.679],
 [36, 50, 13.303],
 [37, 999, 37.527],
 [37, 69, 27.581],
 [37, 68, 26.899],
 [37, 67, 26.201],
 [37, 66, 25.489],
 [37, 65, 24.762],
 [37, 64, 24.022],
 [37, 63, 23.268],
 [37, 62, 22.5],
 [37, 61, 21.721],
 [37, 60, 20.928],
 [37, 59, 20.123],
 [37, 55, 16.789],
 [37, 50, 12.386],
 [38, 999, 36.764],
 [38, 69, 26.755],
 [38, 68, 26.069],
 [38, 67, 25.367],
 [38, 66, 24.65],
 [38, 65, 23.919],
 [38, 64, 23.174],
 [38, 63, 22.415],
 [38, 62, 21.643],
 [38, 61, 20.858],
 [38, 60, 20.061],
 [38, 59, 19.251],
 [38, 55, 15.895],
 [38, 50, 11.464],
 [39, 999, 36.001],
 [39, 69, 25.928],
 [39, 68, 25.236],
 [39, 67, 24.53],
 [39, 66, 23.808],
 [39, 65, 23.073],
 [39, 64, 22.323],
 [39, 63, 21.559],
 [39, 62, 20.782],
 [39, 61, 19.992],
 [39, 60, 19.19],
 [39, 59, 18.375],
 [39, 55, 14.997],
 [39, 50, 10.538],
 [40, 999, 35.238],
 [40, 69, 25.098],
 [40, 68, 24.403],
 [40, 67, 23.692],
 [40, 66, 22.965],
 [40, 65, 22.225],
 [40, 64, 21.47],
 [40, 63, 20.701],
 [40, 62, 19.919],
 [40, 61, 19.124],
 [40, 60, 18.316],
 [40, 59, 17.496],
 [40, 55, 14.096],
 [40, 50, 9.607],
 [41, 999, 34.474],
 [41, 69, 24.267],
 [41, 68, 23.566],
 [41, 67, 22.851],
 [41, 66, 22.119],
 [41, 65, 21.374],
 [41, 64, 20.614],
 [41, 63, 19.84],
 [41, 62, 19.053],
 [41, 61, 18.252],
 [41, 60, 17.439],
 [41, 59, 16.613],
 [41, 55, 13.191],
 [41, 50, 8.672],
 [42, 999, 33.713],
 [42, 69, 23.435],
 [42, 68, 22.729],
 [42, 67, 22.009],
 [42, 66, 21.272],
 [42, 65, 20.522],
 [42, 64, 19.756],
 [42, 63, 18.977],
 [42, 62, 18.184],
 [42, 61, 17.378],
 [42, 60, 16.56],
 [42, 59, 15.728],
 [42, 55, 12.282],
 [42, 50, 7.732],
 [43, 999, 32.953],
 [43, 69, 22.601],
 [43, 68, 21.891],
 [43, 67, 21.165],
 [43, 66, 20.424],
 [43, 65, 19.668],
 [43, 64, 18.897],
 [43, 63, 18.112],
 [43, 62, 17.314],
 [43, 61, 16.502],
 [43, 60, 15.677],
 [43, 59, 14.84],
 [43, 55, 11.369],
 [43, 50, 6.787],
 [44, 999, 32.191],
 [44, 69, 21.765],
 [44, 68, 21.05],
 [44, 67, 20.318],
 [44, 66, 19.572],
 [44, 65, 18.81],
 [44, 64, 18.034],
 [44, 63, 17.243],
 [44, 62, 16.439],
 [44, 61, 15.622],
 [44, 60, 14.791],
 [44, 59, 13.947],
 [44, 55, 10.452],
 [44, 50, 5.836],
 [45, 999, 31.433],
 [45, 69, 20.929],
 [45, 68, 20.208],
 [45, 67, 19.471],
 [45, 66, 18.719],
 [45, 65, 17.952],
 [45, 64, 17.169],
 [45, 63, 16.373],
 [45, 62, 15.563],
 [45, 61, 14.739],
 [45, 60, 13.902],
 [45, 59, 13.052],
 [45, 55, 9.53],
 [45, 50, 4.88],
 [46, 999, 30.676],
 [46, 69, 20.091],
 [46, 68, 19.364],
 [46, 67, 18.622],
 [46, 66, 17.864],
 [46, 65, 17.09],
 [46, 64, 16.302],
 [46, 63, 15.5],
 [46, 62, 14.683],
 [46, 61, 13.853],
 [46, 60, 13.01],
 [46, 59, 12.153],
 [46, 55, 8.604],
 [46, 50, 3.918],
 [47, 999, 29.922],
 [47, 69, 19.251],
 [47, 68, 18.519],
 [47, 67, 17.771],
 [47, 66, 17.007],
 [47, 65, 16.227],
 [47, 64, 15.433],
 [47, 63, 14.624],
 [47, 62, 13.801],
 [47, 61, 12.964],
 [47, 60, 12.114],
 [47, 59, 11.251],
 [47, 55, 7.673],
 [47, 50, 2.949],
 [48, 999, 29.173],
 [48, 69, 18.413],
 [48, 68, 17.674],
 [48, 67, 16.92],
 [48, 66, 16.149],
 [48, 65, 15.363],
 [48, 64, 14.562],
 [48, 63, 13.746],
 [48, 62, 12.916],
 [48, 61, 12.073],
 [48, 60, 11.215],
 [48, 59, 10.345],
 [48, 55, 6.737],
 [48, 50, 1.974],
 [49, 999, 28.426],
 [49, 69, 17.573],
 [49, 68, 16.828],
 [49, 67, 16.067],
 [49, 66, 15.29],
 [49, 65, 14.497],
 [49, 64, 13.689],
 [49, 63, 12.866],
 [49, 62, 12.029],
 [49, 61, 11.178],
 [49, 60, 10.313],
 [49, 59, 9.435],
 [49, 55, 5.796],
 [49, 50, 0.991],
 [50, 999, 27.687],
 [50, 69, 16.734],
 [50, 68, 15.982],
 [50, 67, 15.214],
 [50, 66, 14.43],
 [50, 65, 13.629],
 [50, 64, 12.814],
 [50, 63, 11.984],
 [50, 62, 11.139],
 [50, 61, 10.28],
 [50, 60, 9.407],
 [50, 59, 8.521],
 [50, 55, 4.849],
 [51, 999, 26.951],
 [51, 69, 15.894],
 [51, 68, 15.135],
 [51, 67, 14.36],
 [51, 66, 13.568],
 [51, 65, 12.76],
 [51, 64, 11.936],
 [51, 63, 11.098],
 [51, 62, 10.245],
 [51, 61, 9.378],
 [51, 60, 8.497],
 [51, 59, 7.603],
 [51, 55, 3.895],
 [52, 999, 26.225],
 [52, 69, 15.055],
 [52, 68, 14.289],
 [52, 67, 13.505],
 [52, 66, 12.705],
 [52, 65, 11.889],
 [52, 64, 11.058],
 [52, 63, 10.211],
 [52, 62, 9.349],
 [52, 61, 8.473],
 [52, 60, 7.583],
 [52, 59, 6.68],
 [52, 55, 2.935],
 [53, 999, 25.506],
 [53, 69, 14.217],
 [53, 68, 13.442],
 [53, 67, 12.65],
 [53, 66, 11.842],
 [53, 65, 11.017],
 [53, 64, 10.176],
 [53, 63, 9.32],
 [53, 62, 8.45],
 [53, 61, 7.564],
 [53, 60, 6.665],
 [53, 59, 5.751],
 [53, 55, 1.966],
 [54, 999, 24.792],
 [54, 69, 13.376],
 [54, 68, 12.593],
 [54, 67, 11.792],
 [54, 66, 10.974],
 [54, 65, 10.14],
 [54, 64, 9.29],
 [54, 63, 8.425],
 [54, 62, 7.544],
 [54, 61, 6.649],
 [54, 60, 5.74],
 [54, 59, 4.816],
 [54, 55, 0.988],
 [55, 999, 24.085],
 [55, 69, 12.534],
 [55, 68, 11.742],
 [55, 67, 10.931],
 [55, 66, 10.104],
 [55, 65, 9.26],
 [55, 64, 8.4],
 [55, 63, 7.524],
 [55, 62, 6.633],
 [55, 61, 5.728],
 [55, 60, 4.807],
 [55, 59, 3.873],
 [56, 999, 23.386],
 [56, 69, 11.69],
 [56, 68, 10.888],
 [56, 67, 10.068],
 [56, 66, 9.23],
 [56, 65, 8.376],
 [56, 64, 7.505],
 [56, 63, 6.618],
 [56, 62, 5.716],
 [56, 61, 4.799],
 [56, 60, 3.867],
 [56, 59, 2.921],
 [57, 999, 22.692],
 [57, 69, 10.843],
 [57, 68, 10.031],
 [57, 67, 9.2],
 [57, 66, 8.351],
 [57, 65, 7.485],
 [57, 64, 6.603],
 [57, 63, 5.705],
 [57, 62, 4.791],
 [57, 61, 3.862],
 [57, 60, 2.918],
 [57, 59, 1.959],
 [58, 999, 22],
 [58, 69, 9.991],
 [58, 68, 9.167],
 [58, 67, 8.324],
 [58, 66, 7.464],
 [58, 65, 6.587],
 [58, 64, 5.693],
 [58, 63, 4.782],
 [58, 62, 3.856],
 [58, 61, 2.914],
 [58, 60, 1.958],
 [58, 59, 0.986],
 [59, 999, 21.312],
 [59, 69, 9.132],
 [59, 68, 8.297],
 [59, 67, 7.442],
 [59, 66, 6.57],
 [59, 65, 5.68],
 [59, 64, 4.773],
 [59, 63, 3.85],
 [59, 62, 2.911],
 [59, 61, 1.956],
 [59, 60, 0.985],
 [60, 999, 20.628],
 [60, 69, 8.268],
 [60, 68, 7.42],
 [60, 67, 6.553],
 [60, 66, 5.668],
 [60, 65, 4.765],
 [60, 64, 3.844],
 [60, 63, 2.907],
 [60, 62, 1.954],
 [60, 61, 0.985],
 [61, 999, 19.947],
 [61, 69, 7.395],
 [61, 68, 6.534],
 [61, 67, 5.654],
 [61, 66, 4.755],
 [61, 65, 3.838],
 [61, 64, 2.904],
 [61, 63, 1.952],
 [61, 62, 0.984],
 [62, 999, 19.268],
 [62, 69, 6.515],
 [62, 68, 5.64],
 [62, 67, 4.745],
 [62, 66, 3.832],
 [62, 65, 2.9],
 [62, 64, 1.951],
 [62, 63, 0.984],
 [63, 999, 18.588],
 [63, 69, 5.623],
 [63, 68, 4.733],
 [63, 67, 3.824],
 [63, 66, 2.895],
 [63, 65, 1.948],
 [63, 64, 0.983],
 [64, 999, 17.912],
 [64, 69, 4.721],
 [64, 68, 3.816],
 [64, 67, 2.891],
 [64, 66, 1.946],
 [64, 65, 0.982],
 [65, 999, 17.236],
 [65, 69, 3.806],
 [65, 68, 2.885],
 [65, 67, 1.943],
 [65, 66, 0.981],
 [66, 999, 16.568],
 [66, 69, 2.88],
 [66, 68, 1.94],
 [66, 67, 0.98],
 [67, 999, 15.899],
 [67, 69, 1.937],
 [67, 68, 0.979],
 [68, 999, 15.237],
 [68, 69, 0.978],
 [69, 999, 14.576],
 [70, 999, 13.917],
 [71, 999, 13.265],
 [72, 999, 12.617],
 [73, 999, 11.977],
 [74, 999, 11.345],
 [75, 999, 10.723],
 [76, 999, 10.113],
 [77, 999, 9.514],
 [78, 999, 8.93],
 [79, 999, 8.365],
 [80, 999, 7.821],
 [81, 999, 7.287],
 [82, 999, 6.775],
 [83, 999, 6.288],
 [84, 999, 5.824],
 [85, 999, 5.378],
 [86, 999, 4.958],
 [87, 999, 4.563],
 [88, 999, 4.188],
 [89, 999, 3.838],
 [90, 999, 3.512],
 [91, 999, 3.218],
 [92, 999, 2.948],
 [93, 999, 2.696],
 [94, 999, 2.47],
 [95, 999, 2.257],
 [96, 999, 2.054],
 [97, 999, 1.881],
 [98, 999, 1.723],
 [99, 999, 1.58],
 [100, 999, 1.447],
 [101, 999, 1.244],
 [102, 999, 0.965],
 [103, 999, 0.587],
]);
gazPal2018.set("F", [
 [0, 999, 68.332],
 [0, 69, 57.001],
 [0, 68, 56.367],
 [0, 67, 55.725],
 [0, 66, 55.075],
 [0, 65, 54.418],
 [0, 64, 53.753],
 [0, 63, 53.081],
 [0, 62, 52.402],
 [0, 61, 51.716],
 [0, 60, 51.024],
 [0, 59, 50.325],
 [0, 55, 47.465],
 [0, 50, 43.757],
 [0, 29, 26.796],
 [0, 25, 23.335],
 [0, 21, 19.8],
 [0, 20, 18.905],
 [0, 18, 17.1],
 [0, 16, 15.276],
 [1, 999, 67.901],
 [1, 69, 56.476],
 [1, 68, 55.837],
 [1, 67, 55.189],
 [1, 66, 54.534],
 [1, 65, 53.871],
 [1, 64, 53.201],
 [1, 63, 52.523],
 [1, 62, 51.838],
 [1, 61, 51.147],
 [1, 60, 50.449],
 [1, 59, 49.744],
 [1, 55, 46.861],
 [1, 50, 43.121],
 [1, 29, 26.019],
 [1, 25, 22.529],
 [1, 21, 18.965],
 [1, 20, 18.062],
 [1, 18, 16.242],
 [1, 16, 14.403],
 [2, 999, 67.259],
 [2, 69, 55.774],
 [2, 68, 55.131],
 [2, 67, 54.48],
 [2, 66, 53.821],
 [2, 65, 53.155],
 [2, 64, 52.481],
 [2, 63, 51.8],
 [2, 62, 51.112],
 [2, 61, 50.416],
 [2, 60, 49.715],
 [2, 59, 49.006],
 [2, 55, 46.108],
 [2, 50, 42.348],
 [2, 29, 25.156],
 [2, 25, 21.648],
 [2, 21, 18.065],
 [2, 20, 17.157],
 [2, 18, 15.328],
 [2, 16, 13.479],
 [3, 999, 66.607],
 [3, 69, 55.062],
 [3, 68, 54.416],
 [3, 67, 53.762],
 [3, 66, 53.099],
 [3, 65, 52.429],
 [3, 64, 51.752],
 [3, 63, 51.067],
 [3, 62, 50.375],
 [3, 61, 49.677],
 [3, 60, 48.971],
 [3, 59, 48.259],
 [3, 55, 45.346],
 [3, 50, 41.567],
 [3, 29, 24.286],
 [3, 25, 20.76],
 [3, 21, 17.158],
 [3, 20, 16.246],
 [3, 18, 14.407],
 [3, 16, 12.549],
 [4, 999, 65.947],
 [4, 69, 54.343],
 [4, 68, 53.694],
 [4, 67, 53.036],
 [4, 66, 52.371],
 [4, 65, 51.697],
 [4, 64, 51.016],
 [4, 63, 50.328],
 [4, 62, 49.633],
 [4, 61, 48.931],
 [4, 60, 48.221],
 [4, 59, 47.506],
 [4, 55, 44.577],
 [4, 50, 40.779],
 [4, 29, 23.41],
 [4, 25, 19.866],
 [4, 21, 16.246],
 [4, 20, 15.329],
 [4, 18, 13.481],
 [4, 16, 11.613],
 [5, 999, 65.282],
 [5, 69, 53.619],
 [5, 68, 52.967],
 [5, 67, 52.306],
 [5, 66, 51.637],
 [5, 65, 50.96],
 [5, 64, 50.276],
 [5, 63, 49.584],
 [5, 62, 48.885],
 [5, 61, 48.179],
 [5, 60, 47.466],
 [5, 59, 46.747],
 [5, 55, 43.804],
 [5, 50, 39.987],
 [5, 29, 22.529],
 [5, 25, 18.967],
 [5, 21, 15.328],
 [5, 20, 14.407],
 [5, 18, 12.549],
 [5, 16, 10.672],
 [6, 999, 64.614],
 [6, 69, 52.892],
 [6, 68, 52.236],
 [6, 67, 51.572],
 [6, 66, 50.899],
 [6, 65, 50.219],
 [6, 64, 49.531],
 [6, 63, 48.836],
 [6, 62, 48.133],
 [6, 61, 47.424],
 [6, 60, 46.708],
 [6, 59, 45.984],
 [6, 55, 43.026],
 [6, 50, 39.19],
 [6, 29, 21.644],
 [6, 25, 18.063],
 [6, 21, 14.406],
 [6, 20, 13.48],
 [6, 18, 11.613],
 [6, 16, 9.726],
 [7, 999, 63.943],
 [7, 69, 52.161],
 [7, 68, 51.502],
 [7, 67, 50.834],
 [7, 66, 50.158],
 [7, 65, 49.475],
 [7, 64, 48.783],
 [7, 63, 48.084],
 [7, 62, 47.378],
 [7, 61, 46.665],
 [7, 60, 45.945],
 [7, 59, 45.218],
 [7, 55, 42.245],
 [7, 50, 38.389],
 [7, 29, 20.754],
 [7, 25, 17.155],
 [7, 21, 13.479],
 [7, 20, 12.549],
 [7, 18, 10.672],
 [7, 16, 8.776],
 [8, 999, 63.267],
 [8, 69, 51.425],
 [8, 68, 50.763],
 [8, 67, 50.092],
 [8, 66, 49.413],
 [8, 65, 48.725],
 [8, 64, 48.031],
 [8, 63, 47.328],
 [8, 62, 46.619],
 [8, 61, 45.902],
 [8, 60, 45.178],
 [8, 59, 44.448],
 [8, 55, 41.46],
 [8, 50, 37.584],
 [8, 29, 19.859],
 [8, 25, 16.242],
 [8, 21, 12.548],
 [8, 20, 11.612],
 [8, 18, 9.726],
 [8, 16, 7.82],
 [9, 999, 62.588],
 [9, 69, 50.686],
 [9, 68, 50.02],
 [9, 67, 49.346],
 [9, 66, 48.663],
 [9, 65, 47.972],
 [9, 64, 47.274],
 [9, 63, 46.568],
 [9, 62, 45.855],
 [9, 61, 45.135],
 [9, 60, 44.407],
 [9, 59, 43.673],
 [9, 55, 40.67],
 [9, 50, 36.775],
 [9, 29, 18.96],
 [9, 25, 15.324],
 [9, 21, 11.611],
 [9, 20, 10.671],
 [9, 18, 8.775],
 [9, 16, 6.86],
 [10, 999, 61.904],
 [10, 69, 49.943],
 [10, 68, 49.273],
 [10, 67, 48.596],
 [10, 66, 47.909],
 [10, 65, 47.215],
 [10, 64, 46.513],
 [10, 63, 45.804],
 [10, 62, 45.087],
 [10, 61, 44.363],
 [10, 60, 43.632],
 [10, 59, 42.894],
 [10, 55, 39.876],
 [10, 50, 35.961],
 [10, 29, 18.056],
 [10, 25, 14.402],
 [10, 21, 10.67],
 [10, 20, 9.725],
 [10, 18, 7.82],
 [10, 16, 5.895],
 [11, 999, 61.218],
 [11, 69, 49.195],
 [11, 68, 48.523],
 [11, 67, 47.841],
 [11, 66, 47.152],
 [11, 65, 46.454],
 [11, 64, 45.749],
 [11, 63, 45.036],
 [11, 62, 44.315],
 [11, 61, 43.588],
 [11, 60, 42.853],
 [11, 59, 42.111],
 [11, 55, 39.077],
 [11, 50, 35.143],
 [11, 29, 17.147],
 [11, 25, 13.475],
 [11, 21, 9.724],
 [11, 20, 8.774],
 [11, 18, 6.859],
 [11, 16, 4.925],
 [12, 999, 60.529],
 [12, 69, 48.445],
 [12, 68, 47.769],
 [12, 67, 47.085],
 [12, 66, 46.391],
 [12, 65, 45.69],
 [12, 64, 44.981],
 [12, 63, 44.265],
 [12, 62, 43.54],
 [12, 61, 42.809],
 [12, 60, 42.071],
 [12, 59, 41.325],
 [12, 55, 38.276],
 [12, 50, 34.321],
 [12, 29, 16.234],
 [12, 25, 12.543],
 [12, 21, 8.774],
 [12, 20, 7.819],
 [12, 18, 5.894],
 [12, 16, 3.95],
 [13, 999, 59.836],
 [13, 69, 47.691],
 [13, 68, 47.012],
 [13, 67, 46.323],
 [13, 66, 45.627],
 [13, 65, 44.922],
 [13, 64, 44.209],
 [13, 63, 43.489],
 [13, 62, 42.761],
 [13, 61, 42.026],
 [13, 60, 41.284],
 [13, 59, 40.535],
 [13, 55, 37.47],
 [13, 50, 33.495],
 [13, 29, 15.317],
 [13, 25, 11.607],
 [13, 21, 7.818],
 [13, 20, 6.858],
 [13, 18, 4.924],
 [13, 16, 2.97],
 [14, 999, 59.141],
 [14, 69, 46.934],
 [14, 68, 46.251],
 [14, 67, 45.56],
 [14, 66, 44.859],
 [14, 65, 44.151],
 [14, 64, 43.435],
 [14, 63, 42.711],
 [14, 62, 41.979],
 [14, 61, 41.241],
 [14, 60, 40.495],
 [14, 59, 39.741],
 [14, 55, 36.661],
 [14, 50, 32.666],
 [14, 29, 14.395],
 [14, 25, 10.666],
 [14, 21, 6.858],
 [14, 20, 5.893],
 [14, 18, 3.949],
 [14, 16, 1.985],
 [15, 999, 58.443],
 [15, 69, 46.174],
 [15, 68, 45.488],
 [15, 67, 44.792],
 [15, 66, 44.089],
 [15, 65, 43.377],
 [15, 64, 42.657],
 [15, 63, 41.929],
 [15, 62, 41.194],
 [15, 61, 40.451],
 [15, 60, 39.702],
 [15, 59, 38.945],
 [15, 55, 35.849],
 [15, 50, 31.833],
 [15, 29, 13.468],
 [15, 25, 9.72],
 [15, 21, 5.893],
 [15, 20, 4.924],
 [15, 18, 2.969],
 [15, 16, 0.995],
 [16, 999, 57.742],
 [16, 69, 45.411],
 [16, 68, 44.721],
 [16, 67, 44.022],
 [16, 66, 43.314],
 [16, 65, 42.599],
 [16, 64, 41.875],
 [16, 63, 41.144],
 [16, 62, 40.405],
 [16, 61, 39.659],
 [16, 60, 38.905],
 [16, 59, 38.144],
 [16, 55, 35.032],
 [16, 50, 30.996],
 [16, 29, 12.537],
 [16, 25, 8.77],
 [16, 21, 4.923],
 [16, 20, 3.949],
 [16, 18, 1.985],
 [17, 999, 57.04],
 [17, 69, 44.645],
 [17, 68, 43.951],
 [17, 67, 43.249],
 [17, 66, 42.538],
 [17, 65, 41.818],
 [17, 64, 41.091],
 [17, 63, 40.356],
 [17, 62, 39.613],
 [17, 61, 38.863],
 [17, 60, 38.105],
 [17, 59, 37.341],
 [17, 55, 34.213],
 [17, 50, 30.156],
 [17, 29, 11.602],
 [17, 25, 7.815],
 [17, 21, 3.949],
 [17, 20, 2.969],
 [17, 18, 0.995],
 [18, 999, 56.334],
 [18, 69, 43.875],
 [18, 68, 43.178],
 [18, 67, 42.471],
 [18, 66, 41.757],
 [18, 65, 41.034],
 [18, 64, 40.303],
 [18, 63, 39.564],
 [18, 62, 38.817],
 [18, 61, 38.063],
 [18, 60, 37.302],
 [18, 59, 36.533],
 [18, 55, 33.389],
 [18, 50, 29.311],
 [18, 29, 10.662],
 [18, 25, 6.856],
 [18, 21, 2.969],
 [18, 20, 1.984],
 [19, 999, 55.627],
 [19, 69, 43.103],
 [19, 68, 42.403],
 [19, 67, 41.693],
 [19, 66, 40.974],
 [19, 65, 40.248],
 [19, 64, 39.513],
 [19, 63, 38.77],
 [19, 62, 38.019],
 [19, 61, 37.261],
 [19, 60, 36.496],
 [19, 59, 35.723],
 [19, 55, 32.563],
 [19, 50, 28.464],
 [19, 29, 9.717],
 [19, 25, 5.891],
 [19, 21, 1.984],
 [19, 20, 0.995],
 [20, 999, 54.918],
 [20, 69, 42.329],
 [20, 68, 41.624],
 [20, 67, 40.911],
 [20, 66, 40.189],
 [20, 65, 39.458],
 [20, 64, 38.719],
 [20, 63, 37.973],
 [20, 62, 37.218],
 [20, 61, 36.456],
 [20, 60, 35.687],
 [20, 59, 34.91],
 [20, 55, 31.733],
 [20, 50, 27.613],
 [20, 29, 8.768],
 [20, 25, 4.922],
 [20, 21, 0.995],
 [21, 999, 54.207],
 [21, 69, 41.552],
 [21, 68, 40.844],
 [21, 67, 40.126],
 [21, 66, 39.4],
 [21, 65, 38.666],
 [21, 64, 37.923],
 [21, 63, 37.173],
 [21, 62, 36.414],
 [21, 61, 35.648],
 [21, 60, 34.875],
 [21, 59, 34.094],
 [21, 55, 30.9],
 [21, 50, 26.758],
 [21, 29, 7.814],
 [21, 25, 3.948],
 [22, 999, 53.491],
 [22, 69, 40.769],
 [22, 68, 40.057],
 [22, 67, 39.336],
 [22, 66, 38.606],
 [22, 65, 37.868],
 [22, 64, 37.121],
 [22, 63, 36.367],
 [22, 62, 35.604],
 [22, 61, 34.834],
 [22, 60, 34.057],
 [22, 59, 33.272],
 [22, 55, 30.062],
 [22, 50, 25.898],
 [22, 29, 6.855],
 [22, 25, 2.969],
 [23, 999, 52.771],
 [23, 69, 39.983],
 [23, 68, 39.267],
 [23, 67, 38.542],
 [23, 66, 37.809],
 [23, 65, 37.067],
 [23, 64, 36.316],
 [23, 63, 35.558],
 [23, 62, 34.791],
 [23, 61, 34.017],
 [23, 60, 33.236],
 [23, 59, 32.447],
 [23, 55, 29.22],
 [23, 50, 25.034],
 [23, 29, 5.891],
 [23, 25, 1.984],
 [24, 999, 52.048],
 [24, 69, 39.192],
 [24, 68, 38.473],
 [24, 67, 37.744],
 [24, 66, 37.007],
 [24, 65, 36.261],
 [24, 64, 35.507],
 [24, 63, 34.744],
 [24, 62, 33.974],
 [24, 61, 33.196],
 [24, 60, 32.41],
 [24, 59, 31.617],
 [24, 55, 28.373],
 [24, 50, 24.165],
 [24, 29, 4.922],
 [24, 25, 0.995],
 [25, 999, 51.321],
 [25, 69, 38.397],
 [25, 68, 37.674],
 [25, 67, 36.942],
 [25, 66, 36.201],
 [25, 65, 35.451],
 [25, 64, 34.692],
 [25, 63, 33.926],
 [25, 62, 33.152],
 [25, 61, 32.369],
 [25, 60, 31.58],
 [25, 59, 30.782],
 [25, 55, 27.521],
 [25, 50, 23.292],
 [25, 29, 3.948],
 [26, 999, 50.59],
 [26, 69, 37.599],
 [26, 68, 36.872],
 [26, 67, 36.136],
 [26, 66, 35.391],
 [26, 65, 34.637],
 [26, 64, 33.875],
 [26, 63, 33.104],
 [26, 62, 32.326],
 [26, 61, 31.539],
 [26, 60, 30.746],
 [26, 59, 29.944],
 [26, 55, 26.666],
 [26, 50, 22.414],
 [26, 29, 2.969],
 [27, 999, 49.857],
 [27, 69, 36.798],
 [27, 68, 36.067],
 [27, 67, 35.327],
 [27, 66, 34.578],
 [27, 65, 33.82],
 [27, 64, 33.054],
 [27, 63, 32.279],
 [27, 62, 31.497],
 [27, 61, 30.706],
 [27, 60, 29.908],
 [27, 59, 29.102],
 [27, 55, 25.807],
 [27, 50, 21.532],
 [27, 29, 1.984],
 [28, 999, 49.121],
 [28, 69, 35.993],
 [28, 68, 35.258],
 [28, 67, 34.514],
 [28, 66, 33.761],
 [28, 65, 32.999],
 [28, 64, 32.229],
 [28, 63, 31.45],
 [28, 62, 30.663],
 [28, 61, 29.869],
 [28, 60, 29.066],
 [28, 59, 28.256],
 [28, 55, 24.943],
 [28, 50, 20.646],
 [28, 29, 0.995],
 [29, 999, 48.383],
 [29, 69, 35.184],
 [29, 68, 34.446],
 [29, 67, 33.698],
 [29, 66, 32.941],
 [29, 65, 32.175],
 [29, 64, 31.4],
 [29, 63, 30.617],
 [29, 62, 29.827],
 [29, 61, 29.028],
 [29, 60, 28.221],
 [29, 59, 27.407],
 [29, 55, 24.076],
 [29, 50, 19.756],
 [30, 999, 47.639],
 [30, 69, 34.371],
 [30, 68, 33.628],
 [30, 67, 32.876],
 [30, 66, 32.115],
 [30, 65, 31.345],
 [30, 64, 30.567],
 [30, 63, 29.78],
 [30, 62, 28.985],
 [30, 61, 28.182],
 [30, 60, 27.371],
 [30, 59, 26.552],
 [30, 55, 23.204],
 [30, 50, 18.861],
 [31, 999, 46.893],
 [31, 69, 33.554],
 [31, 68, 32.808],
 [31, 67, 32.052],
 [31, 66, 31.286],
 [31, 65, 30.512],
 [31, 64, 29.73],
 [31, 63, 28.938],
 [31, 62, 28.139],
 [31, 61, 27.332],
 [31, 60, 26.516],
 [31, 59, 25.694],
 [31, 55, 22.327],
 [31, 50, 17.961],
 [32, 999, 46.143],
 [32, 69, 32.733],
 [32, 68, 31.982],
 [32, 67, 31.222],
 [32, 66, 30.453],
 [32, 65, 29.675],
 [32, 64, 28.888],
 [32, 63, 28.092],
 [32, 62, 27.289],
 [32, 61, 26.477],
 [32, 60, 25.658],
 [32, 59, 24.83],
 [32, 55, 21.446],
 [32, 50, 17.057],
 [33, 999, 45.391],
 [33, 69, 31.908],
 [33, 68, 31.154],
 [33, 67, 30.39],
 [33, 66, 29.617],
 [33, 65, 28.834],
 [33, 64, 28.043],
 [33, 63, 27.243],
 [33, 62, 26.436],
 [33, 61, 25.62],
 [33, 60, 24.796],
 [33, 59, 23.964],
 [33, 55, 20.562],
 [33, 50, 16.149],
 [34, 999, 44.637],
 [34, 69, 31.081],
 [34, 68, 30.323],
 [34, 67, 29.555],
 [34, 66, 28.777],
 [34, 65, 27.99],
 [34, 64, 27.195],
 [34, 63, 26.391],
 [34, 62, 25.579],
 [34, 61, 24.758],
 [34, 60, 23.93],
 [34, 59, 23.094],
 [34, 55, 19.673],
 [34, 50, 15.236],
 [35, 999, 43.879],
 [35, 69, 30.25],
 [35, 68, 29.488],
 [35, 67, 28.715],
 [35, 66, 27.933],
 [35, 65, 27.143],
 [35, 64, 26.343],
 [35, 63, 25.534],
 [35, 62, 24.718],
 [35, 61, 23.893],
 [35, 60, 23.06],
 [35, 59, 22.219],
 [35, 55, 18.78],
 [35, 50, 14.319],
 [36, 999, 43.119],
 [36, 69, 29.416],
 [36, 68, 28.649],
 [36, 67, 27.872],
 [36, 66, 27.086],
 [36, 65, 26.291],
 [36, 64, 25.487],
 [36, 63, 24.674],
 [36, 62, 23.853],
 [36, 61, 23.024],
 [36, 60, 22.186],
 [36, 59, 21.341],
 [36, 55, 17.883],
 [36, 50, 13.397],
 [37, 999, 42.359],
 [37, 69, 28.579],
 [37, 68, 27.808],
 [37, 67, 27.027],
 [37, 66, 26.236],
 [37, 65, 25.437],
 [37, 64, 24.628],
 [37, 63, 23.811],
 [37, 62, 22.985],
 [37, 61, 22.151],
 [37, 60, 21.309],
 [37, 59, 20.459],
 [37, 55, 16.982],
 [37, 50, 12.472],
 [38, 999, 41.597],
 [38, 69, 27.74],
 [38, 68, 26.964],
 [38, 67, 26.179],
 [38, 66, 25.384],
 [38, 65, 24.58],
 [38, 64, 23.767],
 [38, 63, 22.945],
 [38, 62, 22.115],
 [38, 61, 21.276],
 [38, 60, 20.429],
 [38, 59, 19.574],
 [38, 55, 16.077],
 [38, 50, 11.542],
 [39, 999, 40.835],
 [39, 69, 26.898],
 [39, 68, 26.118],
 [39, 67, 25.329],
 [39, 66, 24.529],
 [39, 65, 23.72],
 [39, 64, 22.903],
 [39, 63, 22.076],
 [39, 62, 21.241],
 [39, 61, 20.398],
 [39, 60, 19.546],
 [39, 59, 18.686],
 [39, 55, 15.169],
 [39, 50, 10.608],
 [40, 999, 40.069],
 [40, 69, 26.053],
 [40, 68, 25.268],
 [40, 67, 24.474],
 [40, 66, 23.67],
 [40, 65, 22.857],
 [40, 64, 22.034],
 [40, 63, 21.203],
 [40, 62, 20.363],
 [40, 61, 19.515],
 [40, 60, 18.658],
 [40, 59, 17.793],
 [40, 55, 14.256],
 [40, 50, 9.669],
 [41, 999, 39.302],
 [41, 69, 25.204],
 [41, 68, 24.415],
 [41, 67, 23.616],
 [41, 66, 22.808],
 [41, 65, 21.99],
 [41, 64, 21.162],
 [41, 63, 20.326],
 [41, 62, 19.481],
 [41, 61, 18.628],
 [41, 60, 17.767],
 [41, 59, 16.897],
 [41, 55, 13.339],
 [41, 50, 8.725],
 [42, 999, 38.535],
 [42, 69, 24.354],
 [42, 68, 23.56],
 [42, 67, 22.756],
 [42, 66, 21.943],
 [42, 65, 21.12],
 [42, 64, 20.288],
 [42, 63, 19.447],
 [42, 62, 18.597],
 [42, 61, 17.739],
 [42, 60, 16.872],
 [42, 59, 15.997],
 [42, 55, 12.418],
 [42, 50, 7.777],
 [43, 999, 37.767],
 [43, 69, 23.5],
 [43, 68, 22.702],
 [43, 67, 21.893],
 [43, 66, 21.075],
 [43, 65, 20.247],
 [43, 64, 19.41],
 [43, 63, 18.564],
 [43, 62, 17.709],
 [43, 61, 16.845],
 [43, 60, 15.973],
 [43, 59, 15.093],
 [43, 55, 11.493],
 [43, 50, 6.824],
 [44, 999, 36.999],
 [44, 69, 22.645],
 [44, 68, 21.842],
 [44, 67, 21.028],
 [44, 66, 20.205],
 [44, 65, 19.372],
 [44, 64, 18.529],
 [44, 63, 17.678],
 [44, 62, 16.818],
 [44, 61, 15.949],
 [44, 60, 15.072],
 [44, 59, 14.186],
 [44, 55, 10.564],
 [44, 50, 5.865],
 [45, 999, 36.232],
 [45, 69, 21.787],
 [45, 68, 20.979],
 [45, 67, 20.16],
 [45, 66, 19.331],
 [45, 65, 18.493],
 [45, 64, 17.646],
 [45, 63, 16.789],
 [45, 62, 15.923],
 [45, 61, 15.049],
 [45, 60, 14.166],
 [45, 59, 13.275],
 [45, 55, 9.63],
 [45, 50, 4.902],
 [46, 999, 35.463],
 [46, 69, 20.926],
 [46, 68, 20.112],
 [46, 67, 19.289],
 [46, 66, 18.455],
 [46, 65, 17.611],
 [46, 64, 16.758],
 [46, 63, 15.896],
 [46, 62, 15.025],
 [46, 61, 14.145],
 [46, 60, 13.257],
 [46, 59, 12.36],
 [46, 55, 8.691],
 [46, 50, 3.934],
 [47, 999, 34.696],
 [47, 69, 20.064],
 [47, 68, 19.245],
 [47, 67, 18.416],
 [47, 66, 17.576],
 [47, 65, 16.727],
 [47, 64, 15.869],
 [47, 63, 15.001],
 [47, 62, 14.124],
 [47, 61, 13.238],
 [47, 60, 12.344],
 [47, 59, 11.441],
 [47, 55, 7.749],
 [47, 50, 2.959],
 [48, 999, 33.929],
 [48, 69, 19.198],
 [48, 68, 18.374],
 [48, 67, 17.539],
 [48, 66, 16.694],
 [48, 65, 15.839],
 [48, 64, 14.975],
 [48, 63, 14.101],
 [48, 62, 13.219],
 [48, 61, 12.327],
 [48, 60, 11.427],
 [48, 59, 10.518],
 [48, 55, 6.801],
 [48, 50, 1.979],
 [49, 999, 33.164],
 [49, 69, 18.331],
 [49, 68, 17.501],
 [49, 67, 16.66],
 [49, 66, 15.81],
 [49, 65, 14.949],
 [49, 64, 14.079],
 [49, 63, 13.199],
 [49, 62, 12.31],
 [49, 61, 11.412],
 [49, 60, 10.506],
 [49, 59, 9.591],
 [49, 55, 5.848],
 [49, 50, 0.993],
 [50, 999, 32.397],
 [50, 69, 17.46],
 [50, 68, 16.624],
 [50, 67, 15.778],
 [50, 66, 14.921],
 [50, 65, 14.054],
 [50, 64, 13.177],
 [50, 63, 12.292],
 [50, 62, 11.397],
 [50, 61, 10.492],
 [50, 60, 9.58],
 [50, 59, 8.658],
 [50, 55, 4.889],
 [51, 999, 31.633],
 [51, 69, 16.587],
 [51, 68, 15.745],
 [51, 67, 14.892],
 [51, 66, 14.029],
 [51, 65, 13.156],
 [51, 64, 12.273],
 [51, 63, 11.381],
 [51, 62, 10.48],
 [51, 61, 9.569],
 [51, 60, 8.649],
 [51, 59, 7.721],
 [51, 55, 3.924],
 [52, 999, 30.87],
 [52, 69, 15.711],
 [52, 68, 14.863],
 [52, 67, 14.004],
 [52, 66, 13.135],
 [52, 65, 12.255],
 [52, 64, 11.366],
 [52, 63, 10.466],
 [52, 62, 9.558],
 [52, 61, 8.641],
 [52, 60, 7.714],
 [52, 59, 6.779],
 [52, 55, 2.954],
 [53, 999, 30.106],
 [53, 69, 14.831],
 [53, 68, 13.977],
 [53, 67, 13.111],
 [53, 66, 12.235],
 [53, 65, 11.349],
 [53, 64, 10.452],
 [53, 63, 9.546],
 [53, 62, 8.631],
 [53, 61, 7.707],
 [53, 60, 6.773],
 [53, 59, 5.831],
 [53, 55, 1.976],
 [54, 999, 29.342],
 [54, 69, 13.948],
 [54, 68, 13.087],
 [54, 67, 12.214],
 [54, 66, 11.331],
 [54, 65, 10.438],
 [54, 64, 9.534],
 [54, 63, 8.621],
 [54, 62, 7.699],
 [54, 61, 6.767],
 [54, 60, 5.826],
 [54, 59, 4.877],
 [54, 55, 0.992],
 [55, 999, 28.581],
 [55, 69, 13.061],
 [55, 68, 12.193],
 [55, 67, 11.313],
 [55, 66, 10.423],
 [55, 65, 9.523],
 [55, 64, 8.612],
 [55, 63, 7.692],
 [55, 62, 6.762],
 [55, 61, 5.822],
 [55, 60, 4.874],
 [55, 59, 3.916],
 [56, 999, 27.818],
 [56, 69, 12.17],
 [56, 68, 11.294],
 [56, 67, 10.407],
 [56, 66, 9.51],
 [56, 65, 8.602],
 [56, 64, 7.683],
 [56, 63, 6.755],
 [56, 62, 5.818],
 [56, 61, 4.87],
 [56, 60, 3.914],
 [56, 59, 2.949],
 [57, 999, 27.054],
 [57, 69, 11.273],
 [57, 68, 10.39],
 [57, 67, 9.496],
 [57, 66, 8.59],
 [57, 65, 7.675],
 [57, 64, 6.749],
 [57, 63, 5.813],
 [57, 62, 4.867],
 [57, 61, 3.912],
 [57, 60, 2.947],
 [57, 59, 1.974],
 [58, 999, 26.291],
 [58, 69, 10.372],
 [58, 68, 9.481],
 [58, 67, 8.579],
 [58, 66, 7.666],
 [58, 65, 6.742],
 [58, 64, 5.808],
 [58, 63, 4.864],
 [58, 62, 3.91],
 [58, 61, 2.946],
 [58, 60, 1.973],
 [58, 59, 0.991],
 [59, 999, 25.526],
 [59, 69, 9.465],
 [59, 68, 8.566],
 [59, 67, 7.656],
 [59, 66, 6.734],
 [59, 65, 5.802],
 [59, 64, 4.86],
 [59, 63, 3.907],
 [59, 62, 2.945],
 [59, 61, 1.973],
 [59, 60, 0.991],
 [60, 999, 24.76],
 [60, 69, 8.551],
 [60, 68, 7.645],
 [60, 67, 6.726],
 [60, 66, 5.796],
 [60, 65, 4.855],
 [60, 64, 3.904],
 [60, 63, 2.943],
 [60, 62, 1.972],
 [60, 61, 0.991],
 [61, 999, 23.995],
 [61, 69, 7.632],
 [61, 68, 6.717],
 [61, 67, 5.789],
 [61, 66, 4.851],
 [61, 65, 3.901],
 [61, 64, 2.941],
 [61, 63, 1.971],
 [61, 62, 0.99],
 [62, 999, 23.229],
 [62, 69, 6.707],
 [62, 68, 5.782],
 [62, 67, 4.846],
 [62, 66, 3.898],
 [62, 65, 2.939],
 [62, 64, 1.97],
 [62, 63, 0.99],
 [63, 999, 22.463],
 [63, 69, 5.774],
 [63, 68, 4.841],
 [63, 67, 3.895],
 [63, 66, 2.937],
 [63, 65, 1.969],
 [63, 64, 0.99],
 [64, 999, 21.695],
 [64, 69, 4.834],
 [64, 68, 3.891],
 [64, 67, 2.935],
 [64, 66, 1.968],
 [64, 65, 0.989],
 [65, 999, 20.927],
 [65, 69, 3.886],
 [65, 68, 2.932],
 [65, 67, 1.966],
 [65, 66, 0.989],
 [66, 999, 20.163],
 [66, 69, 2.929],
 [66, 68, 1.965],
 [66, 67, 0.989],
 [67, 999, 19.396],
 [67, 69, 1.963],
 [67, 68, 0.988],
 [68, 999, 18.632],
 [68, 69, 0.987],
 [69, 999, 17.873],
 [70, 999, 17.11],
 [71, 999, 16.351],
 [72, 999, 15.595],
 [73, 999, 14.845],
 [74, 999, 14.103],
 [75, 999, 13.364],
 [76, 999, 12.634],
 [77, 999, 11.92],
 [78, 999, 11.215],
 [79, 999, 10.522],
 [80, 999, 9.848],
 [81, 999, 9.194],
 [82, 999, 8.558],
 [83, 999, 7.946],
 [84, 999, 7.358],
 [85, 999, 6.793],
 [86, 999, 6.257],
 [87, 999, 5.748],
 [88, 999, 5.263],
 [89, 999, 4.806],
 [90, 999, 4.385],
 [91, 999, 4.002],
 [92, 999, 3.65],
 [93, 999, 3.325],
 [94, 999, 3.027],
 [95, 999, 2.735],
 [96, 999, 2.453],
 [97, 999, 2.216],
 [98, 999, 1.994],
 [99, 999, 1.766],
 [100, 999, 1.537],
 [101, 999, 1.273],
 [102, 999, 0.969],
 [103, 999, 0.584],
]);

export const bcriv2017 = new Map();
bcriv2017.set("M", [
 [0, 999, 40.93],
 [0, 104, 40.93],
 [0, 68, 38.81],
 [0, 67, 38.67],
 [0, 66, 38.52],
 [0, 65, 38.36],
 [0, 64, 38.2],
 [0, 63, 38.02],
 [0, 62, 27.84],
 [0, 61, 37.64],
 [0, 60, 37.44],
 [0, 55, 36.25],
 [0, 25, 22.46],
 [0, 21, 19.4],
 [0, 20, 18.49],
 [0, 18, 16.89],
 [0, 16, 15.2],
 [1, 999, 40.87],
 [1, 104, 40.87],
 [1, 68, 38.74],
 [1, 67, 38.6],
 [1, 66, 38.44],
 [1, 65, 38.28],
 [1, 64, 38.11],
 [1, 63, 27.92],
 [1, 62, 37.73],
 [1, 61, 37.53],
 [1, 60, 37.31],
 [1, 55, 36.07],
 [1, 25, 22.26],
 [1, 21, 19.29],
 [1, 20, 17.79],
 [1, 18, 16.09],
 [1, 16, 14.37],
 [2, 999, 40.81],
 [2, 104, 40.81],
 [2, 68, 38.53],
 [2, 67, 38.38],
 [2, 66, 38.22],
 [2, 65, 38.05],
 [2, 64, 37.87],
 [2, 63, 27.68],
 [2, 62, 37.48],
 [2, 61, 37.27],
 [2, 60, 37.05],
 [2, 55, 35.76],
 [2, 25, 21.61],
 [2, 21, 18.56],
 [2, 20, 16.95],
 [2, 18, 15.26],
 [2, 16, 13.47],
 [3, 999, 40.66],
 [3, 104, 40.66],
 [3, 68, 38.3],
 [3, 67, 38.15],
 [3, 66, 37.98],
 [3, 65, 37.8],
 [3, 64, 37.62],
 [3, 63, 27.42],
 [3, 62, 37.21],
 [3, 61, 37],
 [3, 60, 36.77],
 [3, 55, 35.44],
 [3, 25, 20.86],
 [3, 21, 17.79],
 [3, 20, 16.09],
 [3, 18, 14.38],
 [3, 16, 12.59],
 [4, 999, 40.5],
 [4, 104, 40.5],
 [4, 68, 38.07],
 [4, 67, 37.91],
 [4, 66, 37.73],
 [4, 65, 37.55],
 [4, 64, 37.36],
 [4, 63, 37.15],
 [4, 62, 36.94],
 [4, 61, 36.71],
 [4, 60, 36.47],
 [4, 55, 35.1],
 [4, 25, 20.15],
 [4, 21, 16.95],
 [4, 20, 15.26],
 [4, 18, 13.48],
 [4, 16, 11.66],
 [5, 999, 40.34],
 [5, 104, 40.34],
 [5, 68, 37.83],
 [5, 67, 37.66],
 [5, 66, 37.48],
 [5, 65, 37.29],
 [5, 64, 37.09],
 [5, 63, 16.88],
 [5, 62, 36.65],
 [5, 61, 36.42],
 [5, 60, 36.17],
 [5, 55, 34.75],
 [5, 25, 19.36],
 [5, 21, 16.09],
 [5, 20, 14.37],
 [5, 18, 12.59],
 [5, 16, 10.74],
 [6, 999, 40.18],
 [6, 104, 40.18],
 [6, 68, 37.57],
 [6, 67, 37.4],
 [6, 66, 37.21],
 [6, 65, 37.02],
 [6, 64, 36.81],
 [6, 63, 36.59],
 [6, 62, 36.36],
 [6, 61, 36.11],
 [6, 60, 35.86],
 [6, 55, 34.39],
 [6, 25, 18.55],
 [6, 21, 15.26],
 [6, 20, 13.47],
 [6, 18, 11.66],
 [6, 16, 9.79],
 [7, 999, 40],
 [7, 104, 40],
 [7, 68, 37.31],
 [7, 67, 37.13],
 [7, 66, 36.94],
 [7, 65, 36.73],
 [7, 64, 36.52],
 [7, 63, 36.29],
 [7, 62, 36.05],
 [7, 61, 35.8],
 [7, 60, 35.54],
 [7, 55, 34.01],
 [7, 25, 17.78],
 [7, 21, 14.37],
 [7, 20, 12.59],
 [7, 18, 10.74],
 [7, 16, 8.85],
 [8, 999, 39.82],
 [8, 104, 39.82],
 [8, 68, 37.04],
 [8, 67, 36.86],
 [8, 66, 36.66],
 [8, 65, 36.44],
 [8, 64, 36.22],
 [8, 63, 35.98],
 [8, 62, 35.747],
 [8, 61, 35.48],
 [8, 60, 35.2],
 [8, 55, 33.62],
 [8, 25, 16.94],
 [8, 21, 13.47],
 [8, 20, 11.66],
 [8, 18, 9.79],
 [8, 16, 7.88],
 [9, 999, 39.64],
 [9, 104, 39.64],
 [9, 68, 36.77],
 [9, 67, 36.57],
 [9, 66, 36.36],
 [9, 65, 36.14],
 [9, 64, 35.91],
 [9, 63, 35.67],
 [9, 62, 35.41],
 [9, 61, 35.14],
 [9, 60, 34.86],
 [9, 55, 33.22],
 [9, 25, 16.08],
 [9, 21, 11.69],
 [9, 20, 10.74],
 [9, 18, 8.84],
 [9, 16, 6.9],
 [10, 999, 39.45],
 [10, 104, 39.45],
 [10, 68, 36.48],
 [10, 67, 36.27],
 [10, 66, 36.06],
 [10, 65, 35.83],
 [10, 64, 35.59],
 [10, 63, 35.34],
 [10, 62, 35.07],
 [10, 61, 34.79],
 [10, 60, 34.5],
 [10, 55, 32.81],
 [10, 25, 15.25],
 [10, 21, 10.74],
 [10, 20, 9.79],
 [10, 18, 7.88],
 [10, 16, 5.91],
 [11, 999, 39.25],
 [11, 104, 39.05],
 [11, 68, 36.18],
 [11, 67, 35.97],
 [11, 66, 35.75],
 [11, 65, 35.51],
 [11, 64, 35.26],
 [11, 63, 35],
 [11, 62, 34.72],
 [11, 61, 34.43],
 [11, 60, 34.13],
 [11, 55, 32.39],
 [11, 25, 14.36],
 [11, 21, 9.79],
 [11, 20, 8.84],
 [11, 18, 6.9],
 [11, 16, 4.92],
 [12, 999, 39.05],
 [12, 104, 38.84],
 [12, 68, 35.87],
 [12, 67, 35.66],
 [12, 66, 35.42],
 [12, 65, 35.18],
 [12, 64, 34.92],
 [12, 63, 34.65],
 [12, 62, 34.36],
 [12, 61, 34.06],
 [12, 60, 33.75],
 [12, 55, 32.07],
 [12, 25, 13.46],
 [12, 21, 8.84],
 [12, 20, 7.88],
 [12, 18, 5.91],
 [12, 16, 3.92],
 [13, 999, 38.84],
 [13, 104, 38.62],
 [13, 68, 35.56],
 [13, 67, 35.33],
 [13, 66, 35.09],
 [13, 65, 34.84],
 [13, 64, 34.57],
 [13, 63, 34.29],
 [13, 62, 33.99],
 [13, 61, 33.68],
 [13, 60, 33.36],
 [13, 55, 31.61],
 [13, 25, 12.57],
 [13, 21, 7.88],
 [13, 20, 6.9],
 [13, 18, 4.92],
 [13, 16, 2.92],
 [14, 999, 38.62],
 [14, 104, 38.4],
 [14, 68, 35.23],
 [14, 67, 35],
 [14, 66, 34.75],
 [14, 65, 34.49],
 [14, 64, 34.21],
 [14, 63, 33.92],
 [14, 62, 33.61],
 [14, 61, 33.29],
 [14, 60, 32.95],
 [14, 55, 31.15],
 [14, 25, 11.64],
 [14, 21, 6.9],
 [14, 20, 5.91],
 [14, 18, 3.92],
 [14, 16, 1.92],
 [15, 999, 38.4],
 [15, 104, 38.17],
 [15, 68, 34.9],
 [15, 67, 34.65],
 [15, 66, 34.4],
 [15, 65, 34.12],
 [15, 64, 33.83],
 [15, 63, 33.53],
 [15, 62, 33.22],
 [15, 61, 32.89],
 [15, 60, 32.54],
 [15, 55, 30.67],
 [15, 25, 10.73],
 [15, 21, 5.91],
 [15, 20, 4.91],
 [15, 18, 2.92],
 [15, 16, 0.92],
 [16, 999, 38.17],
 [16, 104, 37.94],
 [16, 68, 34.55],
 [16, 67, 34.3],
 [16, 66, 34.03],
 [16, 65, 33.75],
 [16, 64, 33.45],
 [16, 63, 33.14],
 [16, 62, 32.81],
 [16, 61, 32.47],
 [16, 60, 32.11],
 [16, 55, 30.17],
 [16, 25, 9.77],
 [16, 21, 4.91],
 [16, 20, 3.91],
 [16, 18, 1.92],
 [17, 999, 37.94],
 [17, 104, 37.7],
 [17, 68, 34.2],
 [17, 67, 33.94],
 [17, 66, 33.66],
 [17, 65, 33.37],
 [17, 64, 33.06],
 [17, 63, 32.74],
 [17, 62, 32.4],
 [17, 61, 32.5],
 [17, 60, 31.79],
 [17, 55, 29.67],
 [17, 25, 8.83],
 [17, 21, 3.91],
 [17, 20, 2.92],
 [17, 18, 0.92],
 [18, 999, 37.7],
 [18, 104, 37.46],
 [18, 68, 33.84],
 [18, 67, 33.57],
 [18, 66, 33.28],
 [18, 65, 32.98],
 [18, 64, 32.66],
 [18, 63, 32.33],
 [18, 62, 31.98],
 [18, 61, 31.73],
 [18, 60, 31.34],
 [18, 55, 39.15],
 [18, 25, 7.87],
 [18, 21, 2.92],
 [18, 20, 0.92],
 [19, 999, 37.46],
 [19, 104, 37.22],
 [19, 68, 33.47],
 [19, 67, 33.19],
 [19, 66, 32.9],
 [19, 65, 32.58],
 [19, 64, 32.25],
 [19, 63, 31.91],
 [19, 62, 31.66],
 [19, 61, 31.28],
 [19, 60, 30.88],
 [19, 55, 28.62],
 [19, 25, 6.89],
 [19, 21, 0.92],
 [20, 999, 37.22],
 [20, 104, 36.97],
 [20, 68, 33.1],
 [20, 67, 32.81],
 [20, 66, 32.5],
 [20, 65, 32.18],
 [20, 64, 31.84],
 [20, 63, 31.59],
 [20, 62, 31.22],
 [20, 61, 30.82],
 [20, 60, 30.41],
 [20, 55, 28.08],
 [20, 25, 5.9],
 [20, 21, 0.92],
 [21, 999, 36.97],
 [21, 104, 36.71],
 [21, 68, 32.71],
 [21, 67, 32.41],
 [21, 66, 32.09],
 [21, 65, 31.76],
 [21, 64, 31.52],
 [21, 63, 31.15],
 [21, 62, 30.76],
 [21, 61, 30.35],
 [21, 60, 29.93],
 [21, 55, 27.53],
 [21, 25, 4.91],
 [22, 999, 36.71],
 [22, 104, 36.45],
 [22, 68, 32.31],
 [22, 67, 32],
 [22, 66, 31.67],
 [22, 65, 31.44],
 [22, 64, 31.07],
 [22, 63, 30.69],
 [22, 62, 30.29],
 [22, 61, 29.87],
 [22, 60, 29.43],
 [22, 55, 26.95],
 [22, 25, 3.91],
 [23, 999, 36.45],
 [23, 104, 36.17],
 [23, 68, 31.9],
 [23, 67, 31.58],
 [23, 66, 31.35],
 [23, 65, 30.99],
 [23, 64, 30.61],
 [23, 63, 30.22],
 [23, 62, 29.8],
 [23, 61, 29.37],
 [23, 60, 28.92],
 [23, 55, 26.37],
 [23, 25, 2.91],
 [24, 999, 36.17],
 [24, 104, 35.89],
 [24, 68, 31.48],
 [24, 67, 31.26],
 [24, 66, 30.91],
 [24, 65, 30.53],
 [24, 64, 30.14],
 [24, 63, 29.73],
 [24, 62, 29.31],
 [24, 61, 28.86],
 [24, 60, 28.39],
 [24, 55, 25.86],
 [24, 25, 1.92],
 [25, 999, 35.89],
 [25, 104, 35.6],
 [25, 68, 31.16],
 [25, 67, 30.81],
 [25, 66, 30.45],
 [25, 65, 30.06],
 [25, 64, 29.66],
 [25, 63, 29.23],
 [25, 62, 28.79],
 [25, 61, 28.33],
 [25, 60, 27.85],
 [25, 55, 25.23],
 [26, 999, 35.6],
 [26, 104, 35.3],
 [26, 68, 30.71],
 [26, 67, 30.35],
 [26, 66, 29.97],
 [26, 65, 29.57],
 [26, 64, 29.16],
 [26, 63, 28.72],
 [26, 62, 28.26],
 [26, 61, 27.79],
 [26, 60, 27.29],
 [26, 55, 24.59],
 [27, 999, 35.3],
 [27, 104, 34.99],
 [27, 68, 30.25],
 [27, 67, 29.87],
 [27, 66, 29.48],
 [27, 65, 29.07],
 [27, 64, 28.64],
 [27, 63, 28.19],
 [27, 62, 27.72],
 [27, 61, 27.23],
 [27, 60, 26.72],
 [27, 55, 23.94],
 [28, 999, 34.99],
 [28, 104, 34.67],
 [28, 68, 29.77],
 [28, 67, 29.38],
 [28, 66, 28.98],
 [28, 65, 28.56],
 [28, 64, 28.11],
 [28, 63, 27.65],
 [28, 62, 27.16],
 [28, 61, 26.66],
 [28, 60, 26.13],
 [28, 55, 23.27],
 [29, 999, 34.67],
 [29, 104, 34.34],
 [29, 68, 29.28],
 [29, 67, 28.88],
 [29, 66, 28.46],
 [29, 65, 28.03],
 [29, 64, 27.57],
 [29, 63, 27.09],
 [29, 62, 26.59],
 [29, 61, 26.07],
 [29, 60, 25.62],
 [29, 55, 22.66],
 [30, 999, 34.34],
 [30, 104, 34],
 [30, 68, 28.77],
 [30, 67, 28.36],
 [30, 66, 27.93],
 [30, 65, 27.48],
 [30, 64, 27.01],
 [30, 63, 26.51],
 [30, 62, 26],
 [30, 61, 25.55],
 [30, 60, 24.99],
 [30, 55, 21.95],
 [31, 999, 34],
 [31, 104, 33.65],
 [31, 68, 28.25],
 [31, 67, 27.83],
 [31, 66, 27.38],
 [31, 65, 26.92],
 [31, 64, 26.43],
 [31, 63, 25.92],
 [31, 62, 25.48],
 [31, 61, 24.93],
 [31, 60, 24.35],
 [31, 55, 21.22],
 [32, 999, 33.65],
 [32, 104, 33.29],
 [32, 68, 27.72],
 [32, 67, 27.28],
 [32, 66, 26.82],
 [32, 65, 26.34],
 [32, 64, 25.84],
 [32, 63, 25.41],
 [32, 62, 24.86],
 [32, 61, 24.29],
 [32, 60, 23.7],
 [32, 55, 20.48],
 [33, 999, 33.29],
 [33, 104, 32.92],
 [33, 68, 27.17],
 [33, 67, 26.72],
 [33, 66, 26.24],
 [33, 65, 25.75],
 [33, 64, 25.32],
 [33, 63, 24.78],
 [33, 62, 24.22],
 [33, 61, 23.63],
 [33, 60, 23.02],
 [33, 55, 19.78],
 [34, 999, 32.92],
 [34, 104, 32.53],
 [34, 68, 26.6],
 [34, 67, 26.14],
 [34, 66, 25.65],
 [34, 65, 25.23],
 [34, 64, 24.69],
 [34, 63, 24.14],
 [34, 62, 23.56],
 [34, 61, 22.96],
 [34, 60, 22.41],
 [34, 55, 19],
 [35, 999, 32.53],
 [35, 104, 32.14],
 [35, 68, 26.02],
 [35, 67, 25.54],
 [35, 66, 25.13],
 [35, 65, 24.6],
 [35, 64, 24.05],
 [35, 63, 23.48],
 [35, 62, 22.89],
 [35, 61, 22.34],
 [35, 60, 21.7],
 [35, 55, 18.21],
 [36, 999, 32.14],
 [36, 104, 31.74],
 [36, 68, 25.43],
 [36, 67, 25.02],
 [36, 66, 24.5],
 [36, 65, 23.96],
 [36, 64, 23.4],
 [36, 63, 22.81],
 [36, 62, 22.27],
 [36, 61, 21.63],
 [36, 60, 20.97],
 [36, 55, 17.45],
 [37, 999, 31.74],
 [37, 104, 31.32],
 [37, 68, 24.91],
 [37, 67, 24.4],
 [37, 66, 23.86],
 [37, 65, 23.3],
 [37, 64, 22.72],
 [37, 63, 22.19],
 [37, 62, 21.56],
 [37, 61, 20.91],
 [37, 60, 20.24],
 [37, 55, 16.62],
 [38, 999, 31.32],
 [38, 104, 30.9],
 [38, 68, 24.28],
 [38, 67, 23.76],
 [38, 66, 23.21],
 [38, 65, 22.63],
 [38, 64, 22.11],
 [38, 63, 21.49],
 [38, 62, 20.84],
 [38, 61, 20.17],
 [38, 60, 19.54],
 [38, 55, 15.78],
 [39, 999, 30.9],
 [39, 104, 30.47],
 [39, 68, 23.64],
 [39, 67, 23.1],
 [39, 66, 22.54],
 [39, 65, 22.02],
 [39, 64, 21.4],
 [39, 63, 20.77],
 [39, 62, 20.1],
 [39, 61, 19.48],
 [39, 60, 18.77],
 [39, 55, 14.96],
 [40, 999, 30.47],
 [40, 104, 30.03],
 [40, 68, 22.99],
 [40, 67, 22.43],
 [40, 66, 21.93],
 [40, 65, 21.32],
 [40, 64, 20.69],
 [40, 63, 20.03],
 [40, 62, 19.41],
 [40, 61, 18.71],
 [40, 60, 17.98],
 [40, 55, 14.09],
 [41, 999, 30.03],
 [41, 104, 29.58],
 [41, 68, 22.33],
 [41, 67, 21.82],
 [41, 66, 21.22],
 [41, 65, 20.6],
 [41, 64, 19.95],
 [41, 63, 19.34],
 [41, 62, 18.64],
 [41, 61, 17.92],
 [41, 60, 17.23],
 [41, 55, 13.21],
 [42, 999, 29.58],
 [42, 104, 29.12],
 [42, 68, 21.72],
 [42, 67, 21.12],
 [42, 66, 20.51],
 [42, 65, 19.87],
 [42, 64, 19.26],
 [42, 63, 18.57],
 [42, 62, 17.86],
 [42, 61, 17.17],
 [42, 60, 16.41],
 [42, 55, 12.35],
 [43, 999, 29.12],
 [43, 104, 28.65],
 [43, 68, 21.02],
 [43, 67, 20.41],
 [43, 66, 19.78],
 [43, 65, 19.18],
 [43, 64, 18.5],
 [43, 63, 17.79],
 [43, 62, 17.11],
 [43, 61, 16.36],
 [43, 60, 15.58],
 [43, 55, 11.44],
 [44, 999, 28.65],
 [44, 104, 28.18],
 [44, 68, 20.31],
 [44, 67, 19.69],
 [44, 66, 19.09],
 [44, 65, 18.42],
 [44, 64, 17.72],
 [44, 63, 17.05],
 [44, 62, 16.3],
 [44, 61, 15.53],
 [44, 60, 14.77],
 [44, 55, 10.55],
 [45, 999, 28.18],
 [45, 104, 27.69],
 [45, 68, 19.59],
 [45, 67, 19],
 [45, 66, 18.34],
 [45, 65, 17.65],
 [45, 64, 16.98],
 [45, 63, 16.24],
 [45, 62, 15.47],
 [45, 61, 14.72],
 [45, 60, 13.92],
 [45, 55, 9.62],
 [46, 999, 27.69],
 [46, 104, 27.2],
 [46, 68, 18.91],
 [46, 67, 18.25],
 [46, 66, 17.56],
 [46, 65, 16.9],
 [46, 64, 16.17],
 [46, 63, 15.41],
 [46, 62, 14.67],
 [46, 61, 13.87],
 [46, 60, 13.05],
 [46, 55, 8.69],
 [47, 999, 27.2],
 [47, 104, 26.7],
 [47, 68, 18.15],
 [47, 67, 17.48],
 [47, 66, 16.83],
 [47, 65, 16.1],
 [47, 64, 15.35],
 [47, 63, 14.62],
 [47, 62, 13.82],
 [47, 61, 13],
 [47, 60, 12.19],
 [47, 55, 7.75],
 [48, 999, 26.7],
 [48, 104, 26.19],
 [48, 68, 17.39],
 [48, 67, 16.75],
 [48, 66, 16.03],
 [48, 65, 15.29],
 [48, 64, 14.56],
 [48, 63, 13.77],
 [48, 62, 12.96],
 [48, 61, 12.15],
 [48, 60, 11.3],
 [48, 55, 6.8],
 [49, 999, 26.19],
 [49, 104, 25.68],
 [49, 68, 16.66],
 [49, 67, 15.95],
 [49, 66, 15.22],
 [49, 65, 14.5],
 [49, 64, 13.72],
 [49, 63, 12.91],
 [49, 62, 12.11],
 [49, 61, 11.26],
 [49, 60, 10.42],
 [49, 55, 5.83],
 [50, 999, 25.68],
 [50, 104, 25.16],
 [50, 68, 15.87],
 [50, 67, 15.15],
 [50, 66, 14.44],
 [50, 65, 13.66],
 [50, 64, 12.86],
 [50, 63, 12.07],
 [50, 62, 11.23],
 [50, 61, 10.39],
 [50, 60, 9.5],
 [50, 55, 4.85],
 [51, 999, 25.16],
 [51, 104, 24.64],
 [51, 68, 15.08],
 [51, 67, 14.37],
 [51, 66, 13.6],
 [51, 65, 12.81],
 [51, 64, 12.03],
 [51, 63, 11.19],
 [51, 62, 10.36],
 [51, 61, 9.48],
 [51, 60, 8.6],
 [51, 55, 3.87],
 [52, 999, 26.64],
 [52, 104, 24.12],
 [52, 68, 14.3],
 [52, 67, 13.54],
 [52, 66, 12.76],
 [52, 65, 11.98],
 [52, 64, 11.15],
 [52, 63, 10.32],
 [52, 62, 9.45],
 [52, 61, 8.57],
 [52, 60, 7.67],
 [52, 55, 2.89],
 [53, 999, 24.12],
 [53, 104, 23.59],
 [53, 68, 13.49],
 [53, 67, 12.71],
 [53, 66, 11.94],
 [53, 65, 11.12],
 [53, 64, 10.29],
 [53, 63, 9.42],
 [53, 62, 8.55],
 [53, 61, 7.66],
 [53, 60, 6.73],
 [53, 55, 1.9],
 [54, 999, 23.59],
 [54, 104, 23.06],
 [54, 68, 12.66],
 [54, 67, 11.89],
 [54, 66, 11.08],
 [54, 65, 10.26],
 [54, 64, 9.4],
 [54, 63, 8.53],
 [54, 62, 7.64],
 [54, 61, 6.72],
 [54, 60, 5.78],
 [54, 55, 0.91],
 [55, 999, 23.06],
 [55, 104, 22.52],
 [55, 68, 11.85],
 [55, 67, 11.04],
 [55, 66, 10.22],
 [55, 65, 9.37],
 [55, 64, 8.51],
 [55, 63, 7.62],
 [55, 62, 6.7],
 [55, 61, 5.77],
 [55, 60, 4.82],
 [56, 999, 22.52],
 [56, 104, 21.98],
 [56, 68, 11],
 [56, 67, 10.19],
 [56, 66, 9.34],
 [56, 65, 8.48],
 [56, 64, 7.6],
 [56, 63, 6.69],
 [56, 62, 5.76],
 [56, 61, 4.81],
 [56, 60, 3.85],
 [57, 999, 21.98],
 [57, 104, 21.44],
 [57, 68, 10.16],
 [57, 67, 9.31],
 [57, 66, 8.46],
 [57, 65, 7.59],
 [57, 64, 6.68],
 [57, 63, 5.75],
 [57, 62, 4.81],
 [57, 61, 3.85],
 [57, 60, 2.88],
 [58, 999, 21.44],
 [58, 104, 20.88],
 [58, 68, 9.28],
 [58, 67, 8.4],
 [58, 66, 7.57],
 [58, 65, 6.66],
 [58, 64, 5.74],
 [58, 63, 4.8],
 [58, 62, 3.84],
 [58, 61, 2.88],
 [58, 60, 1.9],
 [59, 999, 20.88],
 [59, 104, 20.32],
 [59, 68, 8.41],
 [59, 67, 7.55],
 [59, 66, 6.65],
 [59, 65, 5.73],
 [59, 64, 4.79],
 [59, 63, 3.84],
 [59, 62, 2.87],
 [59, 61, 1.9],
 [59, 60, 0.91],
 [60, 999, 20.32],
 [60, 104, 19.76],
 [60, 68, 7.53],
 [60, 67, 6.63],
 [60, 66, 5.72],
 [60, 65, 4.78],
 [60, 64, 3.83],
 [60, 63, 2.87],
 [60, 62, 1.9],
 [60, 61, 0.91],
 [61, 999, 19.76],
 [61, 104, 19.19],
 [61, 68, 6.62],
 [61, 67, 5.71],
 [61, 66, 4.78],
 [61, 65, 3.83],
 [61, 64, 2.87],
 [61, 63, 1.9],
 [61, 62, 0.91],
 [62, 999, 19.19],
 [62, 104, 18.6],
 [62, 68, 5.7],
 [62, 67, 4.77],
 [62, 66, 3.82],
 [62, 65, 2.87],
 [62, 64, 1.89],
 [62, 63, 0.91],
 [63, 999, 18.6],
 [63, 104, 18.02],
 [63, 68, 4.76],
 [63, 67, 3.82],
 [63, 66, 2.86],
 [63, 65, 1.89],
 [63, 64, 0.91],
 [64, 999, 18.002],
 [64, 104, 17.42],
 [64, 68, 3.81],
 [64, 67, 2.86],
 [64, 66, 1.89],
 [65, 999, 17.42],
 [65, 104, 16.83],
 [65, 68, 2.85],
 [65, 67, 1.89],
 [65, 66, 0.91],
 [66, 999, 16.83],
 [66, 104, 16.22],
 [66, 68, 1.89],
 [66, 67, 0.91],
 [67, 999, 16.22],
 [67, 104, 15.62],
 [67, 68, 0.91],
 [68, 999, 15.62],
 [69, 999, 15.01],
 [70, 999, 14.39],
 [71, 999, 13.78],
 [72, 999, 13.16],
 [73, 999, 12.55],
 [74, 999, 11.94],
 [75, 999, 11.33],
 [76, 999, 10.74],
 [77, 999, 10.15],
 [78, 999, 9.57],
 [79, 999, 9],
 [80, 999, 8.46],
 [81, 999, 7.92],
 [82, 999, 7.4],
 [83, 999, 6.91],
 [84, 999, 6.43],
 [85, 999, 5.97],
 [86, 999, 5.53],
 [87, 999, 5.12],
 [88, 999, 4.73],
 [89, 999, 4.37],
 [90, 999, 4.03],
 [91, 999, 3.73],
 [92, 999, 3.45],
 [93, 999, 3.2],
 [94, 999, 2.96],
 [95, 999, 2.75],
 [96, 999, 2.54],
 [97, 999, 2.36],
 [98, 999, 2.06],
 [99, 999, 1.92],
 [100, 999, 1.71],
 [101, 999, 1.43],
 [102, 999, 1.05],
 [103, 999, 1.05],
]);
bcriv2017.set("F", [
 [0, 999, 41.9],
 [0, 104, 41.9],
 [0, 68, 39.34],
 [0, 67, 39.18],
 [0, 66, 39.01],
 [0, 65, 38.83],
 [0, 64, 38.63],
 [0, 63, 38.44],
 [0, 62, 38.24],
 [0, 61, 38.02],
 [0, 60, 37.8],
 [0, 55, 36.68],
 [0, 25, 22.46],
 [0, 21, 19.4],
 [0, 20, 18.59],
 [0, 18, 16.98],
 [0, 16, 15.25],
 [1, 999, 41.86],
 [1, 104, 41.86],
 [1, 68, 39.27],
 [1, 67, 39.1],
 [1, 66, 38.92],
 [1, 65, 38.73],
 [1, 64, 38.54],
 [1, 63, 38.33],
 [1, 62, 38.12],
 [1, 61, 37.9],
 [1, 60, 37.67],
 [1, 55, 36.5],
 [1, 25, 21.79],
 [1, 21, 18.65],
 [1, 20, 17.82],
 [1, 18, 16.17],
 [1, 16, 14.41],
 [2, 999, 41.81],
 [2, 104, 41.81],
 [2, 68, 39.07],
 [2, 67, 38.89],
 [2, 66, 38.71],
 [2, 65, 38.51],
 [2, 64, 38.31],
 [2, 63, 38.1],
 [2, 62, 37.88],
 [2, 61, 37.65],
 [2, 60, 37.41],
 [2, 55, 36.2],
 [2, 25, 21.03],
 [2, 21, 17.83],
 [2, 20, 17.03],
 [2, 18, 15.3],
 [2, 16, 13.54],
 [3, 999, 41.7],
 [3, 104, 41.7],
 [3, 68, 38.86],
 [3, 67, 38.68],
 [3, 66, 38.49],
 [3, 65, 38.28],
 [3, 64, 38.08],
 [3, 63, 37.86],
 [3, 62, 37.63],
 [3, 61, 37.39],
 [3, 60, 37.14],
 [3, 55, 35.88],
 [3, 25, 20.26],
 [3, 21, 17.04],
 [3, 20, 16.17],
 [3, 18, 14.41],
 [3, 16, 12.62],
 [4, 999, 41.58],
 [4, 104, 41.58],
 [4, 68, 38.64],
 [4, 67, 38.45],
 [4, 66, 38.25],
 [4, 65, 38.05],
 [4, 64, 37.83],
 [4, 63, 37.6],
 [4, 62, 37.37],
 [4, 61, 37.12],
 [4, 60, 37],
 [4, 55, 35.55],
 [4, 25, 19.46],
 [4, 21, 16.17],
 [4, 20, 15.3],
 [4, 18, 13.54],
 [4, 16, 11.69],
 [5, 999, 41.45],
 [5, 104, 41.45],
 [5, 68, 38.42],
 [5, 67, 38.22],
 [5, 66, 38.01],
 [5, 65, 37.8],
 [5, 64, 37.57],
 [5, 63, 37.34],
 [5, 62, 37.09],
 [5, 61, 36.97],
 [5, 60, 36.71],
 [5, 55, 35.21],
 [5, 25, 18.65],
 [5, 21, 15.3],
 [5, 20, 14.41],
 [5, 18, 12.62],
 [5, 16, 10.75],
 [6, 999, 41.32],
 [6, 104, 41.32],
 [6, 68, 38.18],
 [6, 67, 37.98],
 [6, 66, 37.76],
 [6, 65, 37.54],
 [6, 64, 37.31],
 [6, 63, 37.06],
 [6, 62, 36.93],
 [6, 61, 36.68],
 [6, 60, 36.41],
 [6, 55, 34.86],
 [6, 25, 17.83],
 [6, 21, 14.41],
 [6, 20, 13.54],
 [6, 18, 11.69],
 [6, 16, 9.82],
 [7, 999, 41.19],
 [7, 104, 41.19],
 [7, 68, 37.94],
 [7, 67, 37.73],
 [7, 66, 37.5],
 [7, 65, 37.27],
 [7, 64, 37.03],
 [7, 63, 36.91],
 [7, 62, 36.65],
 [7, 61, 36.38],
 [7, 60, 36.1],
 [7, 55, 34.5],
 [7, 25, 17.03],
 [7, 21, 13.54],
 [7, 20, 12.62],
 [7, 18, 10.75],
 [7, 16, 8.85],
 [8, 999, 41.05],
 [8, 104, 41.05],
 [8, 68, 37.69],
 [8, 67, 37.47],
 [8, 66, 37.24],
 [8, 65, 37],
 [8, 64, 36.88],
 [8, 63, 36.62],
 [8, 62, 36.35],
 [8, 61, 36.07],
 [8, 60, 35.77],
 [8, 55, 34.12],
 [8, 25, 16.17],
 [8, 21, 12.62],
 [8, 20, 11.69],
 [8, 18, 9.82],
 [8, 16, 7.89],
 [9, 999, 40.9],
 [9, 104, 40.9],
 [9, 68, 37.43],
 [9, 67, 37.2],
 [9, 66, 36.96],
 [9, 65, 36.85],
 [9, 64, 36.59],
 [9, 63, 36.32],
 [9, 62, 36.04],
 [9, 61, 35.75],
 [9, 60, 35.44],
 [9, 55, 33.73],
 [9, 25, 15.29],
 [9, 21, 11.69],
 [9, 20, 10.75],
 [9, 18, 8.85],
 [9, 16, 6.91],
 [10, 999, 40.75],
 [10, 104, 40.75],
 [10, 68, 37.16],
 [10, 67, 36.92],
 [10, 66, 36.81],
 [10, 65, 36.55],
 [10, 64, 36.29],
 [10, 63, 36.01],
 [10, 62, 35.72],
 [10, 61, 35.41],
 [10, 60, 35.1],
 [10, 55, 33.33],
 [10, 25, 14.4],
 [10, 21, 10.7],
 [10, 20, 9.82],
 [10, 18, 7.89],
 [10, 16, 5.92],
 [11, 999, 40.6],
 [11, 104, 40.6],
 [11, 68, 36.88],
 [11, 67, 36.77],
 [11, 66, 36.51],
 [11, 65, 36.25],
 [11, 64, 35.97],
 [11, 63, 35.68],
 [11, 62, 35.38],
 [11, 61, 35.07],
 [11, 60, 34.74],
 [11, 55, 32.92],
 [11, 25, 13.54],
 [11, 21, 9.82],
 [11, 20, 8.85],
 [11, 18, 6.91],
 [11, 16, 4.92],
 [12, 999, 40.44],
 [12, 104, 40.44],
 [12, 68, 36.72],
 [12, 67, 36.47],
 [12, 66, 36.21],
 [12, 65, 35.93],
 [12, 64, 35.65],
 [12, 63, 35.35],
 [12, 62, 35.04],
 [12, 61, 34.72],
 [12, 60, 34.38],
 [12, 55, 32.49],
 [12, 25, 12.62],
 [12, 21, 8.85],
 [12, 20, 7.89],
 [12, 18, 5.91],
 [12, 16, 3.92],
 [13, 999, 40.27],
 [13, 104, 40.27],
 [13, 68, 36.43],
 [13, 67, 36.17],
 [13, 66, 35.89],
 [13, 65, 35.61],
 [13, 64, 35.31],
 [13, 63, 35],
 [13, 62, 34.68],
 [13, 61, 34.35],
 [13, 60, 34],
 [13, 55, 32.05],
 [13, 25, 11.69],
 [13, 21, 7.89],
 [13, 20, 6.91],
 [13, 18, 4.92],
 [13, 16, 2.92],
 [14, 999, 40.1],
 [14, 104, 40.1],
 [14, 68, 36.12],
 [14, 67, 35.85],
 [14, 66, 35.57],
 [14, 65, 35.28],
 [14, 64, 34.97],
 [14, 63, 34.65],
 [14, 62, 34.32],
 [14, 61, 33.97],
 [14, 60, 33.61],
 [14, 55, 31.59],
 [14, 25, 10.74],
 [14, 21, 6.91],
 [14, 20, 5.91],
 [14, 18, 3.92],
 [14, 16, 1.92],
 [15, 999, 39.93],
 [15, 104, 39.93],
 [15, 68, 35.81],
 [15, 67, 35.53],
 [15, 66, 35.23],
 [15, 65, 34.93],
 [15, 64, 34.61],
 [15, 63, 34.28],
 [15, 62, 33.94],
 [15, 61, 33.58],
 [15, 60, 33.21],
 [15, 55, 31.12],
 [15, 25, 9.81],
 [15, 21, 5.91],
 [15, 20, 4.91],
 [15, 18, 2.92],
 [15, 16, 0.92],
 [16, 999, 39.75],
 [16, 104, 39.75],
 [16, 68, 35.48],
 [16, 67, 35.19],
 [16, 66, 34.89],
 [16, 65, 34.57],
 [16, 64, 34.24],
 [16, 63, 33.9],
 [16, 62, 33.55],
 [16, 61, 33.18],
 [16, 60, 32.79],
 [16, 55, 30.64],
 [16, 25, 8.85],
 [16, 21, 4.91],
 [16, 20, 3.92],
 [16, 18, 1.92],
 [17, 999, 39.56],
 [17, 104, 39.56],
 [17, 68, 35.14],
 [17, 67, 34.84],
 [17, 66, 34.53],
 [17, 65, 34.21],
 [17, 64, 33.87],
 [17, 63, 33.51],
 [17, 62, 33.14],
 [17, 61, 32.76],
 [17, 60, 32.36],
 [17, 55, 30.14],
 [17, 25, 7.89],
 [17, 21, 3.92],
 [17, 20, 2.92],
 [17, 18, 0.92],
 [18, 999, 39.37],
 [18, 104, 39.37],
 [18, 68, 34.8],
 [18, 67, 34.49],
 [18, 66, 34.16],
 [18, 65, 33.83],
 [18, 64, 33.48],
 [18, 63, 33.11],
 [18, 62, 32.73],
 [18, 61, 32.33],
 [18, 60, 31.92],
 [18, 55, 29.63],
 [18, 25, 6.91],
 [18, 21, 2.92],
 [18, 20, 1.92],
 [19, 999, 39.18],
 [19, 104, 39.18],
 [19, 68, 34.44],
 [19, 67, 34.12],
 [19, 66, 33.79],
 [19, 65, 33.44],
 [19, 64, 33.07],
 [19, 63, 32.69],
 [19, 62, 32.3],
 [19, 61, 31.89],
 [19, 60, 31.47],
 [19, 55, 29.1],
 [19, 25, 5.91],
 [19, 21, 1.92],
 [19, 20, 0.92],
 [20, 999, 38.98],
 [20, 104, 38.98],
 [20, 68, 34.07],
 [20, 67, 33.74],
 [20, 66, 33.4],
 [20, 65, 33.03],
 [20, 64, 32.66],
 [20, 63, 32.27],
 [20, 62, 31.86],
 [20, 61, 31.44],
 [20, 60, 31],
 [20, 55, 28.55],
 [20, 25, 4.91],
 [20, 21, 0.92],
 [21, 999, 38.77],
 [21, 104, 38.77],
 [21, 68, 33.7],
 [21, 67, 33.35],
 [21, 66, 32.99],
 [21, 65, 32.62],
 [21, 64, 32.23],
 [21, 63, 31.83],
 [21, 62, 31.41],
 [21, 61, 30.97],
 [21, 60, 30.52],
 [21, 55, 27.99],
 [21, 25, 3.91],
 [22, 999, 38.55],
 [22, 104, 38.55],
 [22, 68, 33.3],
 [22, 67, 32.95],
 [22, 66, 32.58],
 [22, 65, 32.19],
 [22, 64, 31.79],
 [22, 63, 31.37],
 [22, 62, 30.94],
 [22, 61, 90.49],
 [22, 60, 30.02],
 [22, 55, 27.53],
 [22, 25, 2.92],
 [23, 999, 38.33],
 [23, 104, 38.33],
 [23, 68, 32.9],
 [23, 67, 32.53],
 [23, 66, 32.15],
 [23, 65, 31.75],
 [23, 64, 31.33],
 [23, 63, 30.9],
 [23, 62, 30.45],
 [23, 61, 29.99],
 [23, 60, 29.51],
 [23, 55, 26.93],
 [23, 25, 1.92],
 [24, 999, 38.1],
 [24, 104, 38.1],
 [24, 68, 32.48],
 [24, 67, 32.1],
 [24, 66, 31.7],
 [24, 65, 31.29],
 [24, 64, 30.86],
 [24, 63, 30.42],
 [24, 62, 29.95],
 [24, 61, 29.47],
 [24, 60, 28.98],
 [24, 55, 26.32],
 [24, 25, 0.92],
 [25, 999, 37.86],
 [25, 104, 37.86],
 [25, 68, 32.05],
 [25, 67, 31.66],
 [25, 66, 31.25],
 [25, 65, 30.33],
 [25, 64, 30.38],
 [25, 63, 29.92],
 [25, 62, 29.44],
 [25, 61, 28.94],
 [25, 60, 28.43],
 [25, 55, 25.69],
 [26, 999, 37.62],
 [26, 104, 37.62],
 [26, 68, 31.6],
 [26, 67, 30.77],
 [26, 66, 30.33],
 [26, 65, 29.83],
 [26, 64, 29.88],
 [26, 63, 29.4],
 [26, 62, 28.91],
 [26, 61, 28.4],
 [26, 60, 27.87],
 [26, 55, 25.04],
 [27, 999, 37.37],
 [27, 104, 37.37],
 [27, 68, 31.15],
 [27, 67, 30.29],
 [27, 66, 29.83],
 [27, 65, 29.32],
 [27, 64, 29.36],
 [27, 63, 28.87],
 [27, 62, 28.36],
 [27, 61, 17.84],
 [27, 60, 27.4],
 [27, 55, 24.37],
 [28, 999, 37.11],
 [28, 104, 37.11],
 [28, 68, 30.67],
 [28, 67, 29.79],
 [28, 66, 29.32],
 [28, 65, 28.79],
 [28, 64, 28.83],
 [28, 63, 28.33],
 [28, 62, 27.8],
 [28, 61, 27.37],
 [28, 60, 36.81],
 [28, 55, 23.69],
 [29, 999, 36.84],
 [29, 104, 68.84],
 [29, 68, 30.19],
 [29, 67, 29.27],
 [29, 66, 28.79],
 [29, 65, 28.24],
 [29, 64, 28.29],
 [29, 63, 27.76],
 [29, 62, 27.33],
 [29, 61, 26.77],
 [29, 60, 26.19],
 [29, 55, 22.99],
 [30, 999, 36.56],
 [30, 104, 36.56],
 [30, 68, 29.68],
 [30, 67, 28.74],
 [30, 66, 28.24],
 [30, 65, 27.68],
 [30, 64, 27.72],
 [30, 63, 27.3],
 [30, 62, 26.74],
 [30, 61, 26.16],
 [30, 60, 25.56],
 [30, 55, 22.35],
 [31, 999, 38.28],
 [31, 104, 36.28],
 [31, 68, 29.17],
 [31, 67, 28.19],
 [31, 66, 27.68],
 [31, 65, 27.21],
 [31, 64, 27.25],
 [31, 63, 26.7],
 [31, 62, 26.12],
 [31, 61, 25.53],
 [31, 60, 24.91],
 [31, 55, 21.61],
 [32, 999, 35.98],
 [32, 104, 25.98],
 [32, 68, 28.63],
 [32, 67, 27.63],
 [32, 66, 27.21],
 [32, 65, 26.61],
 [32, 64, 26.65],
 [32, 63, 26.08],
 [32, 62, 25.49],
 [32, 61, 24.88],
 [32, 60, 24.24],
 [32, 55, 20.85],
 [33, 999, 35.67],
 [33, 104, 35.67],
 [33, 68, 28.09],
 [33, 67, 27.16],
 [33, 66, 26.61],
 [33, 65, 25.99],
 [33, 64, 26.04],
 [33, 63, 25.45],
 [33, 62, 24.84],
 [33, 61, 24.21],
 [33, 60, 23.56],
 [33, 55, 20.08],
 [34, 999, 35.36],
 [34, 104, 35.36],
 [34, 68, 27.52],
 [34, 67, 26.56],
 [34, 66, 25.99],
 [34, 65, 25.36],
 [34, 64, 25.41],
 [34, 63, 24.8],
 [34, 62, 24.17],
 [34, 61, 23.53],
 [34, 60, 22.86],
 [34, 55, 19.29],
 [35, 999, 35.04],
 [35, 104, 35.04],
 [35, 68, 27.05],
 [35, 67, 25.95],
 [35, 66, 25.36],
 [35, 65, 24.71],
 [35, 64, 24.76],
 [35, 63, 24.14],
 [35, 62, 23.49],
 [35, 61, 22.83],
 [35, 60, 22.22],
 [35, 55, 18.48],
 [36, 999, 34.71],
 [36, 104, 34.71],
 [36, 68, 26.45],
 [36, 67, 25.31],
 [36, 66, 24.71],
 [36, 65, 24.05],
 [36, 64, 24.09],
 [36, 63, 23.45],
 [36, 62, 22.79],
 [36, 61, 22.19],
 [36, 60, 21.48],
 [36, 55, 17.66],
 [37, 999, 34.37],
 [37, 104, 34.37],
 [37, 68, 25.84],
 [37, 67, 24.67],
 [37, 66, 24.05],
 [37, 65, 23.37],
 [37, 64, 23.41],
 [37, 63, 22.75],
 [37, 62, 22.15],
 [37, 61, 21.45],
 [37, 60, 20.73],
 [37, 55, 16.87],
 [38, 999, 34.02],
 [38, 104, 34.02],
 [38, 68, 25.21],
 [38, 67, 24],
 [38, 66, 23.37],
 [38, 65, 22.67],
 [38, 64, 22.71],
 [38, 63, 22.12],
 [38, 62, 21.42],
 [38, 61, 20.7],
 [38, 60, 19.96],
 [38, 55, 16.02],
 [39, 999, 33.66],
 [39, 104, 33.66],
 [39, 68, 24.56],
 [39, 67, 23.32],
 [39, 66, 22.67],
 [39, 65, 22.04],
 [39, 64, 22.08],
 [39, 63, 21.38],
 [39, 62, 20.67],
 [39, 61, 19.93],
 [39, 60, 19.17],
 [39, 55, 15.15],
 [40, 999, 33.29],
 [40, 104, 33.29],
 [40, 68, 23.9],
 [40, 67, 22.63],
 [40, 66, 22.04],
 [40, 65, 21.3],
 [40, 64, 21.35],
 [40, 63, 20.63],
 [40, 62, 19.9],
 [40, 61, 19.14],
 [40, 60, 18.37],
 [40, 55, 14.27],
 [41, 999, 32.91],
 [41, 104, 32.91],
 [41, 68, 23.22],
 [41, 67, 21.99],
 [41, 66, 21.3],
 [41, 65, 20.56],
 [41, 64, 20.59],
 [41, 63, 19.86],
 [41, 62, 19.11],
 [41, 61, 18.34],
 [41, 60, 17.55],
 [41, 55, 13.41],
 [42, 999, 32.52],
 [42, 104, 32.52],
 [42, 68, 22.53],
 [42, 67, 21.26],
 [42, 66, 20.56],
 [42, 65, 19.79],
 [42, 64, 19.83],
 [42, 63, 19.08],
 [42, 62, 18.31],
 [42, 61, 17.53],
 [42, 60, 16.77],
 [42, 55, 12.5],
 [43, 999, 32.12],
 [43, 104, 32.12],
 [43, 68, 21.9],
 [43, 67, 20.51],
 [43, 66, 19.79],
 [43, 65, 19.01],
 [43, 64, 19.05],
 [43, 63, 18.28],
 [43, 62, 17.5],
 [43, 61, 16.75],
 [43, 60, 15.92],
 [43, 55, 11.58],
 [44, 999, 31.72],
 [44, 104, 21.72],
 [44, 68, 21.17],
 [44, 67, 19.75],
 [44, 66, 19.01],
 [44, 65, 18.22],
 [44, 64, 18.25],
 [44, 63, 17.47],
 [44, 62, 16.72],
 [44, 61, 15.9],
 [44, 60, 15.06],
 [44, 55, 10.65],
 [45, 999, 31.3],
 [45, 104, 31.3],
 [45, 68, 20.43],
 [45, 67, 18.97],
 [45, 66, 18.22],
 [45, 65, 17.41],
 [45, 64, 17.44],
 [45, 63, 16.69],
 [45, 62, 15.88],
 [45, 61, 15.04],
 [45, 60, 14.19],
 [45, 55, 9.73],
 [46, 999, 30.87],
 [46, 104, 30.87],
 [46, 68, 19.67],
 [46, 67, 18.18],
 [46, 66, 17.41],
 [46, 65, 16.64],
 [46, 64, 16.67],
 [46, 63, 15.85],
 [46, 62, 15.02],
 [46, 61, 14.17],
 [46, 60, 13.33],
 [46, 55, 8.78],
 [47, 999, 30.44],
 [47, 104, 30.44],
 [47, 68, 18.9],
 [47, 67, 17.38],
 [47, 66, 16.64],
 [47, 65, 15.8],
 [47, 64, 15.82],
 [47, 63, 14.99],
 [47, 62, 14.15],
 [47, 61, 13.32],
 [47, 60, 12.43],
 [47, 55, 7.83],
 [48, 999, 29.99],
 [48, 104, 29.99],
 [48, 68, 18.11],
 [48, 67, 16.61],
 [48, 66, 15.8],
 [48, 65, 14.95],
 [48, 64, 14.97],
 [48, 63, 14.13],
 [48, 62, 13.3],
 [48, 61, 12.42],
 [48, 60, 11.52],
 [48, 55, 6.86],
 [49, 999, 29.54],
 [49, 104, 29.54],
 [49, 68, 17.31],
 [49, 67, 15.77],
 [49, 66, 14.95],
 [49, 65, 14.08],
 [49, 64, 14.11],
 [49, 63, 13.28],
 [49, 62, 12.4],
 [49, 61, 11.51],
 [49, 60, 10.6],
 [49, 55, 5.88],
 [50, 999, 29.07],
 [50, 104, 29.07],
 [50, 68, 16.54],
 [50, 67, 14.92],
 [50, 66, 14.08],
 [50, 65, 13.24],
 [50, 64, 13.26],
 [50, 63, 12.38],
 [50, 62, 11.49],
 [50, 61, 10.59],
 [50, 60, 9.68],
 [50, 55, 4.89],
 [51, 999, 28.59],
 [51, 104, 28.59],
 [51, 68, 15.71],
 [51, 67, 14.06],
 [51, 66, 13.24],
 [51, 65, 12.35],
 [51, 64, 12.37],
 [51, 63, 11.48],
 [51, 62, 10.57],
 [51, 61, 9.67],
 [51, 60, 8.74],
 [51, 55, 3.9],
 [52, 999, 28.11],
 [52, 104, 28.11],
 [52, 68, 14.87],
 [52, 67, 13.22],
 [52, 66, 12.35],
 [52, 65, 11.45],
 [52, 64, 11.46],
 [52, 63, 10.56],
 [52, 62, 9.66],
 [52, 61, 8.73],
 [52, 60, 7.8],
 [52, 55, 2.9],
 [53, 999, 27.61],
 [53, 104, 27.61],
 [53, 68, 14.01],
 [53, 67, 12.33],
 [53, 66, 11.45],
 [53, 65, 10.53],
 [53, 64, 10.55],
 [53, 63, 9.65],
 [53, 62, 8.72],
 [53, 61, 7.79],
 [53, 60, 6.84],
 [53, 55, 1.91],
 [54, 999, 27.1],
 [54, 104, 27.1],
 [54, 68, 13.17],
 [54, 67, 11.43],
 [54, 66, 10.53],
 [54, 65, 9.63],
 [54, 64, 9.64],
 [54, 63, 8.71],
 [54, 62, 7.78],
 [54, 61, 6.83],
 [54, 60, 5.86],
 [54, 55, 0.92],
 [55, 999, 26.59],
 [55, 104, 26.59],
 [55, 68, 12.29],
 [55, 67, 10.52],
 [55, 66, 9.63],
 [55, 65, 8.69],
 [55, 64, 8.7],
 [55, 63, 7.78],
 [55, 62, 6.83],
 [55, 61, 5.85],
 [55, 60, 4.87],
 [56, 999, 26.05],
 [56, 104, 26.05],
 [56, 68, 11.4],
 [56, 67, 10.51],
 [56, 66, 9.62],
 [56, 65, 7.76],
 [56, 64, 7.77],
 [56, 63, 6.82],
 [56, 62, 5.85],
 [56, 61, 4.87],
 [56, 60, 3.89],
 [57, 999, 25.51],
 [57, 104, 25.51],
 [57, 68, 10.49],
 [57, 67, 9.61],
 [57, 66, 8.68],
 [57, 65, 6.81],
 [57, 64, 6.82],
 [57, 63, 5.85],
 [57, 62, 4.87],
 [57, 61, 3.89],
 [57, 60, 2.9],
 [58, 999, 24.96],
 [58, 104, 24.96],
 [58, 68, 9.59],
 [58, 67, 8.67],
 [58, 66, 7.75],
 [58, 65, 5.84],
 [58, 64, 5.84],
 [58, 63, 4.87],
 [58, 62, 3.88],
 [58, 61, 2.9],
 [58, 60, 1.91],
 [59, 999, 24.39],
 [59, 104, 24.39],
 [59, 68, 8.66],
 [59, 67, 7.74],
 [59, 66, 6.8],
 [59, 65, 4.86],
 [59, 64, 4.86],
 [59, 63, 3.88],
 [59, 62, 2.9],
 [59, 61, 1.91],
 [59, 60, 0.91],
 [60, 999, 23.81],
 [60, 104, 23.81],
 [60, 68, 7.73],
 [60, 67, 6.8],
 [60, 66, 5.83],
 [60, 65, 3.88],
 [60, 64, 3.88],
 [60, 63, 2.9],
 [60, 62, 1.91],
 [60, 61, 0.91],
 [61, 999, 23.22],
 [61, 104, 32.22],
 [61, 68, 6.79],
 [61, 67, 5.83],
 [61, 66, 4.85],
 [61, 65, 2.89],
 [61, 64, 2.9],
 [61, 63, 1.91],
 [61, 62, 0.91],
 [62, 999, 22.62],
 [62, 104, 22.62],
 [62, 68, 5.82],
 [62, 67, 4.85],
 [62, 66, 3.88],
 [62, 65, 1.91],
 [62, 64, 1.91],
 [62, 63, 0.91],
 [63, 999, 22],
 [63, 104, 22],
 [63, 68, 4.85],
 [63, 67, 3.87],
 [63, 66, 2.89],
 [63, 65, 0.91],
 [63, 64, 0.91],
 [64, 999, 21.37],
 [64, 104, 21.37],
 [64, 68, 3.87],
 [64, 67, 2.89],
 [64, 66, 1.91],
 [65, 999, 20.73],
 [65, 104, 20.73],
 [65, 68, 2.89],
 [65, 67, 1.9],
 [65, 66, 0.91],
 [66, 999, 20.09],
 [66, 104, 20.09],
 [66, 68, 1.9],
 [66, 67, 0.91],
 [67, 999, 19.43],
 [67, 104, 19.43],
 [67, 68, 0.91],
 [68, 999, 18.76],
 [69, 999, 18.09],
 [70, 999, 17.4],
 [71, 999, 16.71],
 [72, 999, 16.01],
 [73, 999, 15.32],
 [74, 999, 14.62],
 [75, 999, 13.92],
 [76, 999, 13.22],
 [77, 999, 12.52],
 [78, 999, 11.84],
 [79, 999, 11.16],
 [80, 999, 10.49],
 [81, 999, 9.84],
 [82, 999, 9.21],
 [83, 999, 8.59],
 [84, 999, 8],
 [85, 999, 7.43],
 [86, 999, 6.88],
 [87, 999, 6.35],
 [88, 999, 5.84],
 [89, 999, 5.37],
 [90, 999, 4.93],
 [91, 999, 4.54],
 [92, 999, 4.17],
 [93, 999, 3.84],
 [94, 999, 3.53],
 [95, 999, 3.23],
 [96, 999, 2.95],
 [97, 999, 2.7],
 [98, 999, 2.48],
 [99, 999, 2.24],
 [100, 999, 2.01],
 [101, 999, 1.74],
 [102, 999, 1.43],
 [103, 999, 1.05],
]);

export const gazPal2020tx0 = new Map();
gazPal2020tx0.set("M",
 [
  [0, 999, 78.713],
  [0, 69, 66.113],
  [0, 68, 65.316],
  [0, 67, 64.506],
  [0, 66, 63.684],
  [0, 65, 62.850],
  [0, 64, 62.004],
  [0, 63, 61.148],
  [0, 62, 60.281],
  [0, 61, 59.404],
  [0, 60, 58.517],
  [0, 59, 57.622],
  [0, 55, 53.965],
  [0, 50, 49.262],
  [0, 29, 28.806],
  [0, 25, 24.853],
  [0, 21, 20.889],
  [0, 20, 19.897],
  [0, 18, 17.911],
  [0, 16, 15.923],
  [1, 999, 78.016],
  [1, 69, 65.368],
  [1, 68, 64.568],
  [1, 67, 63.755],
  [1, 66, 62.929],
  [1, 65, 62.092],
  [1, 64, 61.243],
  [1, 63, 60.383],
  [1, 62, 59.513],
  [1, 61, 58.633],
  [1, 60, 57.743],
  [1, 59, 56.844],
  [1, 55, 53.173],
  [1, 50, 48.452],
  [1, 29, 27.917],
  [1, 25, 23.949],
  [1, 21, 19.970],
  [1, 20, 18.974],
  [1, 18, 16.980],
  [1, 16, 14.984],
  [2, 999, 77.037],
  [2, 69, 64.386],
  [2, 68, 63.586],
  [2, 67, 62.772],
  [2, 66, 61.946],
  [2, 65, 61.109],
  [2, 64, 60.260],
  [2, 63, 59.400],
  [2, 62, 58.529],
  [2, 61, 57.649],
  [2, 60, 56.759],
  [2, 59, 55.860],
  [2, 55, 52.187],
  [2, 50, 47.465],
  [2, 29, 26.925],
  [2, 25, 22.955],
  [2, 21, 18.975],
  [2, 20, 17.979],
  [2, 18, 15.984],
  [2, 16, 13.988],
  [3, 999, 76.051],
  [3, 69, 63.397],
  [3, 68, 62.597],
  [3, 67, 61.784],
  [3, 66, 60.958],
  [3, 65, 60.120],
  [3, 64, 59.271],
  [3, 63, 58.411],
  [3, 62, 57.540],
  [3, 61, 56.659],
  [3, 60, 55.769],
  [3, 59, 54.870],
  [3, 55, 51.197],
  [3, 50, 46.473],
  [3, 29, 25.930],
  [3, 25, 21.959],
  [3, 21, 17.979],
  [3, 20, 16.982],
  [3, 18, 14.987],
  [3, 16, 12.991],
  [4, 999, 75.062],
  [4, 69, 62.406],
  [4, 68, 61.606],
  [4, 67, 60.792],
  [4, 66, 59.966],
  [4, 65, 59.128],
  [4, 64, 58.279],
  [4, 63, 57.419],
  [4, 62, 56.548],
  [4, 61, 55.667],
  [4, 60, 54.777],
  [4, 59, 53.878],
  [4, 55, 50.204],
  [4, 50, 45.480],
  [4, 29, 24.933],
  [4, 25, 20.962],
  [4, 21, 16.981],
  [4, 20, 15.984],
  [4, 18, 13.989],
  [4, 16, 11.993],
  [5, 999, 74.071],
  [5, 69, 61.414],
  [5, 68, 60.613],
  [5, 67, 59.800],
  [5, 66, 58.973],
  [5, 65, 58.136],
  [5, 64, 57.286],
  [5, 63, 56.426],
  [5, 62, 55.555],
  [5, 61, 54.674],
  [5, 60, 53.783],
  [5, 59, 52.884],
  [5, 55, 49.210],
  [5, 50, 44.485],
  [5, 29, 23.936],
  [5, 25, 19.965],
  [5, 21, 15.983],
  [5, 20, 14.986],
  [5, 18, 12.991],
  [5, 16, 10.994],
  [6, 999, 73.078],
  [6, 69, 60.420],
  [6, 68, 59.619],
  [6, 67, 58.806],
  [6, 66, 57.979],
  [6, 65, 57.141],
  [6, 64, 56.292],
  [6, 63, 55.431],
  [6, 62, 54.560],
  [6, 61, 53.679],
  [6, 60, 52.789],
  [6, 59, 51.889],
  [6, 55, 48.215],
  [6, 50, 43.490],
  [6, 29, 22.939],
  [6, 25, 18.967],
  [6, 21, 14.985],
  [6, 20, 13.988],
  [6, 18, 11.992],
  [6, 16, 9.995],
  [7, 999, 72.085],
  [7, 69, 59.426],
  [7, 68, 58.625],
  [7, 67, 57.811],
  [7, 66, 56.985],
  [7, 65, 56.147],
  [7, 64, 55.297],
  [7, 63, 54.436],
  [7, 62, 53.565],
  [7, 61, 52.684],
  [7, 60, 51.794],
  [7, 59, 50.894],
  [7, 55, 47.219],
  [7, 50, 42.494],
  [7, 29, 21.941],
  [7, 25, 17.969],
  [7, 21, 13.986],
  [7, 20, 12.989],
  [7, 18, 10.993],
  [7, 16, 8.996],
  [8, 999, 71.091],
  [8, 69, 58.431],
  [8, 68, 57.630],
  [8, 67, 56.816],
  [8, 66, 55.990],
  [8, 65, 55.152],
  [8, 64, 54.302],
  [8, 63, 53.441],
  [8, 62, 52.570],
  [8, 61, 51.689],
  [8, 60, 50.798],
  [8, 59, 49.899],
  [8, 55, 46.224],
  [8, 50, 41.498],
  [8, 29, 20.943],
  [8, 25, 16.970],
  [8, 21, 12.987],
  [8, 20, 11.990],
  [8, 18, 9.994],
  [8, 16, 7.997],
  [9, 999, 70.097],
  [9, 69, 57.436],
  [9, 68, 56.635],
  [9, 67, 55.821],
  [9, 66, 54.994],
  [9, 65, 54.156],
  [9, 64, 53.306],
  [9, 63, 52.446],
  [9, 62, 51.574],
  [9, 61, 50.693],
  [9, 60, 49.802],
  [9, 59, 48.903],
  [9, 55, 45.227],
  [9, 50, 40.501],
  [9, 29, 19.944],
  [9, 25, 15.972],
  [9, 21, 11.989],
  [9, 20, 10.991],
  [9, 18, 8.995],
  [9, 16, 6.997],
  [10, 999, 69.103],
  [10, 69, 56.440],
  [10, 68, 55.639],
  [10, 67, 54.825],
  [10, 66, 53.999],
  [10, 65, 53.160],
  [10, 64, 52.311],
  [10, 63, 51.450],
  [10, 62, 50.579],
  [10, 61, 49.697],
  [10, 60, 48.806],
  [10, 59, 47.907],
  [10, 55, 44.231],
  [10, 50, 39.504],
  [10, 29, 18.946],
  [10, 25, 14.973],
  [10, 21, 10.989],
  [10, 20, 9.992],
  [10, 18, 7.996],
  [10, 16, 5.998],
  [11, 999, 68.108],
  [11, 69, 55.444],
  [11, 68, 54.643],
  [11, 67, 53.829],
  [11, 66, 53.003],
  [11, 65, 52.164],
  [11, 64, 51.314],
  [11, 63, 50.453],
  [11, 62, 49.582],
  [11, 61, 48.701],
  [11, 60, 47.810],
  [11, 59, 46.910],
  [11, 55, 43.234],
  [11, 50, 38.507],
  [11, 29, 17.947],
  [11, 25, 13.974],
  [11, 21, 9.990],
  [11, 20, 8.993],
  [11, 18, 6.996],
  [11, 16, 4.998],
  [12, 999, 67.112],
  [12, 69, 54.448],
  [12, 68, 53.647],
  [12, 67, 52.833],
  [12, 66, 52.006],
  [12, 65, 51.168],
  [12, 64, 50.318],
  [12, 63, 49.457],
  [12, 62, 48.586],
  [12, 61, 47.704],
  [12, 60, 46.813],
  [12, 59, 45.913],
  [12, 55, 42.237],
  [12, 50, 37.510],
  [12, 29, 16.949],
  [12, 25, 12.975],
  [12, 21, 8.991],
  [12, 20, 7.993],
  [12, 18, 5.997],
  [12, 16, 3.999],
  [13, 999, 66.118],
  [13, 69, 53.453],
  [13, 68, 52.652],
  [13, 67, 51.838],
  [13, 66, 51.011],
  [13, 65, 50.172],
  [13, 64, 49.322],
  [13, 63, 48.461],
  [13, 62, 47.590],
  [13, 61, 46.708],
  [13, 60, 45.817],
  [13, 59, 44.918],
  [13, 55, 41.241],
  [13, 50, 36.513],
  [13, 29, 15.950],
  [13, 25, 11.976],
  [13, 21, 7.992],
  [13, 20, 6.994],
  [13, 18, 4.998],
  [13, 16, 2.999],
  [14, 999, 65.126],
  [14, 69, 52.459],
  [14, 68, 51.658],
  [14, 67, 50.843],
  [14, 66, 50.017],
  [14, 65, 49.178],
  [14, 64, 48.328],
  [14, 63, 47.467],
  [14, 62, 46.595],
  [14, 61, 45.714],
  [14, 60, 44.822],
  [14, 59, 43.922],
  [14, 55, 40.246],
  [14, 50, 35.517],
  [14, 29, 14.952],
  [14, 25, 10.977],
  [14, 21, 6.993],
  [14, 20, 5.995],
  [14, 18, 3.998],
  [14, 16, 2.000],
  [15, 999, 64.136],
  [15, 69, 51.467],
  [15, 68, 50.666],
  [15, 67, 49.851],
  [15, 66, 49.024],
  [15, 65, 48.185],
  [15, 64, 47.335],
  [15, 63, 46.474],
  [15, 62, 45.602],
  [15, 61, 44.720],
  [15, 60, 43.829],
  [15, 59, 42.929],
  [15, 55, 39.252],
  [15, 50, 34.522],
  [15, 29, 13.954],
  [15, 25, 9.979],
  [15, 21, 5.994],
  [15, 20, 4.996],
  [15, 18, 2.999],
  [15, 16, 1.000],
  [16, 999, 63.147],
  [16, 69, 50.476],
  [16, 68, 49.675],
  [16, 67, 48.860],
  [16, 66, 48.033],
  [16, 65, 47.194],
  [16, 64, 46.344],
  [16, 63, 45.482],
  [16, 62, 44.610],
  [16, 61, 43.729],
  [16, 60, 42.837],
  [16, 59, 41.937],
  [16, 55, 38.259],
  [16, 50, 33.529],
  [16, 29, 12.957],
  [16, 25, 8.981],
  [16, 21, 4.995],
  [16, 20, 3.997],
  [16, 18, 1.999],
  [17, 999, 62.162],
  [17, 69, 49.488],
  [17, 68, 48.686],
  [17, 67, 47.871],
  [17, 66, 47.044],
  [17, 65, 46.205],
  [17, 64, 45.354],
  [17, 63, 44.493],
  [17, 62, 43.621],
  [17, 61, 42.739],
  [17, 60, 41.847],
  [17, 59, 40.947],
  [17, 55, 37.268],
  [17, 50, 32.536],
  [17, 29, 11.960],
  [17, 25, 7.983],
  [17, 21, 3.996],
  [17, 20, 2.998],
  [17, 18, 1.000],
  [18, 999, 61.181],
  [18, 69, 48.503],
  [18, 68, 47.701],
  [18, 67, 46.886],
  [18, 66, 46.059],
  [18, 65, 45.219],
  [18, 64, 44.369],
  [18, 63, 43.507],
  [18, 62, 42.634],
  [18, 61, 41.752],
  [18, 60, 40.860],
  [18, 59, 39.959],
  [18, 55, 36.279],
  [18, 50, 31.547],
  [18, 29, 10.963],
  [18, 25, 6.986],
  [18, 21, 2.997],
  [18, 20, 1.999],
  [19, 999, 60.207],
  [19, 69, 47.524],
  [19, 68, 46.722],
  [19, 67, 45.906],
  [19, 66, 45.078],
  [19, 65, 44.239],
  [19, 64, 43.387],
  [19, 63, 42.525],
  [19, 62, 41.652],
  [19, 61, 40.770],
  [19, 60, 39.877],
  [19, 59, 38.976],
  [19, 55, 35.295],
  [19, 50, 30.560],
  [19, 29, 9.968],
  [19, 25, 5.988],
  [19, 21, 1.998],
  [19, 20, 0.999],
  [20, 999, 59.238],
  [20, 69, 46.548],
  [20, 68, 45.746],
  [20, 67, 44.930],
  [20, 66, 44.101],
  [20, 65, 43.261],
  [20, 64, 42.410],
  [20, 63, 41.547],
  [20, 62, 40.674],
  [20, 61, 39.791],
  [20, 60, 38.898],
  [20, 59, 37.996],
  [20, 55, 34.313],
  [20, 50, 29.576],
  [20, 29, 8.973],
  [20, 25, 4.992],
  [20, 21, 0.999],
  [21, 999, 58.269],
  [21, 69, 45.573],
  [21, 68, 44.770],
  [21, 67, 43.953],
  [21, 66, 43.125],
  [21, 65, 42.284],
  [21, 64, 41.432],
  [21, 63, 40.569],
  [21, 62, 39.695],
  [21, 61, 38.811],
  [21, 60, 37.918],
  [21, 59, 37.016],
  [21, 55, 33.331],
  [21, 50, 28.591],
  [21, 29, 7.978],
  [21, 25, 3.994],
  [22, 999, 57.303],
  [22, 69, 44.599],
  [22, 68, 43.795],
  [22, 67, 42.979],
  [22, 66, 42.149],
  [22, 65, 41.308],
  [22, 64, 40.456],
  [22, 63, 39.592],
  [22, 62, 38.718],
  [22, 61, 37.834],
  [22, 60, 36.940],
  [22, 59, 36.037],
  [22, 55, 32.350],
  [22, 50, 27.608],
  [22, 29, 6.982],
  [22, 25, 2.996],
  [23, 999, 56.335],
  [23, 69, 43.624],
  [23, 68, 42.820],
  [23, 67, 42.003],
  [23, 66, 41.174],
  [23, 65, 40.332],
  [23, 64, 39.479],
  [23, 63, 38.615],
  [23, 62, 37.740],
  [23, 61, 36.856],
  [23, 60, 35.961],
  [23, 59, 35.058],
  [23, 55, 31.368],
  [23, 50, 26.623],
  [23, 29, 5.986],
  [23, 25, 1.998],
  [24, 999, 55.369],
  [24, 69, 42.650],
  [24, 68, 41.846],
  [24, 67, 41.028],
  [24, 66, 40.198],
  [24, 65, 39.356],
  [24, 64, 38.502],
  [24, 63, 37.638],
  [24, 62, 36.763],
  [24, 61, 35.877],
  [24, 60, 34.983],
  [24, 59, 34.079],
  [24, 55, 30.387],
  [24, 50, 25.639],
  [24, 29, 4.990],
  [24, 25, 0.999],
  [25, 999, 54.404],
  [25, 69, 41.677],
  [25, 68, 40.872],
  [25, 67, 40.054],
  [25, 66, 39.223],
  [25, 65, 38.381],
  [25, 64, 37.527],
  [25, 63, 36.661],
  [25, 62, 35.786],
  [25, 61, 34.900],
  [25, 60, 34.005],
  [25, 59, 33.100],
  [25, 55, 29.406],
  [25, 50, 24.655],
  [25, 29, 3.993],
  [26, 999, 53.439],
  [26, 69, 40.704],
  [26, 68, 39.899],
  [26, 67, 39.080],
  [26, 66, 38.249],
  [26, 65, 37.406],
  [26, 64, 36.551],
  [26, 63, 35.685],
  [26, 62, 34.809],
  [26, 61, 33.923],
  [26, 60, 33.027],
  [26, 59, 32.122],
  [26, 55, 28.425],
  [26, 50, 23.671],
  [26, 29, 2.996],
  [27, 999, 52.477],
  [27, 69, 39.733],
  [27, 68, 38.927],
  [27, 67, 38.108],
  [27, 66, 37.276],
  [27, 65, 36.432],
  [27, 64, 35.577],
  [27, 63, 34.710],
  [27, 62, 33.834],
  [27, 61, 32.947],
  [27, 60, 32.050],
  [27, 59, 31.145],
  [27, 55, 27.445],
  [27, 50, 22.688],
  [27, 29, 1.998],
  [28, 999, 51.514],
  [28, 69, 38.761],
  [28, 68, 37.954],
  [28, 67, 37.135],
  [28, 66, 36.302],
  [28, 65, 35.458],
  [28, 64, 34.602],
  [28, 63, 33.735],
  [28, 62, 32.858],
  [28, 61, 31.970],
  [28, 60, 31.073],
  [28, 59, 30.167],
  [28, 55, 26.465],
  [28, 50, 21.704],
  [28, 29, 0.999],
  [29, 999, 50.553],
  [29, 69, 37.790],
  [29, 68, 36.983],
  [29, 67, 36.162],
  [29, 66, 35.329],
  [29, 65, 34.484],
  [29, 64, 33.628],
  [29, 63, 32.760],
  [29, 62, 31.882],
  [29, 61, 30.994],
  [29, 60, 30.096],
  [29, 59, 29.189],
  [29, 55, 25.485],
  [29, 50, 20.720],
  [30, 999, 49.591],
  [30, 69, 36.819],
  [30, 68, 36.011],
  [30, 67, 35.190],
  [30, 66, 34.357],
  [30, 65, 33.511],
  [30, 64, 32.654],
  [30, 63, 31.786],
  [30, 62, 30.907],
  [30, 61, 30.018],
  [30, 60, 29.119],
  [30, 59, 28.212],
  [30, 55, 24.504],
  [30, 50, 19.736],
  [31, 999, 48.632],
  [31, 69, 35.850],
  [31, 68, 35.041],
  [31, 67, 34.219],
  [31, 66, 33.385],
  [31, 65, 32.538],
  [31, 64, 31.681],
  [31, 63, 30.812],
  [31, 62, 29.932],
  [31, 61, 29.042],
  [31, 60, 28.143],
  [31, 59, 27.235],
  [31, 55, 23.524],
  [31, 50, 18.753],
  [32, 999, 47.672],
  [32, 69, 34.879],
  [32, 68, 34.070],
  [32, 67, 33.247],
  [32, 66, 32.412],
  [32, 65, 31.565],
  [32, 64, 30.707],
  [32, 63, 29.837],
  [32, 62, 28.957],
  [32, 61, 28.066],
  [32, 60, 27.166],
  [32, 59, 26.257],
  [32, 55, 22.544],
  [32, 50, 17.768],
  [33, 999, 46.713],
  [33, 69, 33.909],
  [33, 68, 33.099],
  [33, 67, 32.276],
  [33, 66, 31.440],
  [33, 65, 30.593],
  [33, 64, 29.733],
  [33, 63, 28.863],
  [33, 62, 27.982],
  [33, 61, 27.091],
  [33, 60, 26.190],
  [33, 59, 25.280],
  [33, 55, 21.563],
  [33, 50, 16.783],
  [34, 999, 45.755],
  [34, 69, 32.939],
  [34, 68, 32.128],
  [34, 67, 31.305],
  [34, 66, 30.468],
  [34, 65, 29.620],
  [34, 64, 28.759],
  [34, 63, 27.888],
  [34, 62, 27.006],
  [34, 61, 26.114],
  [34, 60, 25.213],
  [34, 59, 24.302],
  [34, 55, 20.582],
  [34, 50, 15.798],
  [35, 999, 44.800],
  [35, 69, 31.972],
  [35, 68, 31.161],
  [35, 67, 30.336],
  [35, 66, 29.498],
  [35, 65, 28.649],
  [35, 64, 27.788],
  [35, 63, 26.916],
  [35, 62, 26.033],
  [35, 61, 25.141],
  [35, 60, 24.238],
  [35, 59, 23.327],
  [35, 55, 19.603],
  [35, 50, 14.814],
  [36, 999, 43.845],
  [36, 69, 31.004],
  [36, 68, 30.191],
  [36, 67, 29.366],
  [36, 66, 28.528],
  [36, 65, 27.677],
  [36, 64, 26.816],
  [36, 63, 25.943],
  [36, 62, 25.059],
  [36, 61, 24.165],
  [36, 60, 23.262],
  [36, 59, 22.350],
  [36, 55, 18.622],
  [36, 50, 13.829],
  [37, 999, 42.891],
  [37, 69, 30.036],
  [37, 68, 29.223],
  [37, 67, 28.397],
  [37, 66, 27.558],
  [37, 65, 26.707],
  [37, 64, 25.844],
  [37, 63, 24.970],
  [37, 62, 24.086],
  [37, 61, 23.191],
  [37, 60, 22.286],
  [37, 59, 21.373],
  [37, 55, 17.642],
  [37, 50, 12.843],
  [38, 999, 41.942],
  [38, 69, 29.072],
  [38, 68, 28.258],
  [38, 67, 27.430],
  [38, 66, 26.590],
  [38, 65, 25.738],
  [38, 64, 24.874],
  [38, 63, 24.000],
  [38, 62, 23.114],
  [38, 61, 22.218],
  [38, 60, 21.313],
  [38, 59, 20.398],
  [38, 55, 16.663],
  [38, 50, 11.858],
  [39, 999, 40.994],
  [39, 69, 28.108],
  [39, 68, 27.293],
  [39, 67, 26.465],
  [39, 66, 25.624],
  [39, 65, 24.771],
  [39, 64, 23.906],
  [39, 63, 23.030],
  [39, 62, 22.143],
  [39, 61, 21.246],
  [39, 60, 20.340],
  [39, 59, 19.424],
  [39, 55, 15.684],
  [39, 50, 10.873],
  [40, 999, 40.052],
  [40, 69, 27.148],
  [40, 68, 26.332],
  [40, 67, 25.502],
  [40, 66, 24.660],
  [40, 65, 23.805],
  [40, 64, 22.939],
  [40, 63, 22.062],
  [40, 62, 21.174],
  [40, 61, 20.276],
  [40, 60, 19.368],
  [40, 59, 18.451],
  [40, 55, 14.706],
  [40, 50, 9.889],
  [41, 999, 39.110],
  [41, 69, 26.187],
  [41, 68, 25.370],
  [41, 67, 24.539],
  [41, 66, 23.695],
  [41, 65, 22.840],
  [41, 64, 21.972],
  [41, 63, 21.094],
  [41, 62, 20.205],
  [41, 61, 19.305],
  [41, 60, 18.396],
  [41, 59, 17.478],
  [41, 55, 13.727],
  [41, 50, 8.903],
  [42, 999, 38.173],
  [42, 69, 25.230],
  [42, 68, 24.411],
  [42, 67, 23.579],
  [42, 66, 22.734],
  [42, 65, 21.877],
  [42, 64, 21.008],
  [42, 63, 20.128],
  [42, 62, 19.238],
  [42, 61, 18.337],
  [42, 60, 17.426],
  [42, 59, 16.506],
  [42, 55, 12.749],
  [42, 50, 7.917],
  [43, 999, 37.242],
  [43, 69, 24.275],
  [43, 68, 23.455],
  [43, 67, 22.621],
  [43, 66, 21.775],
  [43, 65, 20.916],
  [43, 64, 20.046],
  [43, 63, 19.164],
  [43, 62, 18.272],
  [43, 61, 17.370],
  [43, 60, 16.457],
  [43, 59, 15.536],
  [43, 55, 11.772],
  [43, 50, 6.931],
  [44, 999, 36.315],
  [44, 69, 23.322],
  [44, 68, 22.500],
  [44, 67, 21.665],
  [44, 66, 20.817],
  [44, 65, 19.957],
  [44, 64, 19.085],
  [44, 63, 18.202],
  [44, 62, 17.308],
  [44, 61, 16.404],
  [44, 60, 15.490],
  [44, 59, 14.566],
  [44, 55, 10.795],
  [44, 50, 5.945],
  [45, 999, 35.394],
  [45, 69, 22.373],
  [45, 68, 21.550],
  [45, 67, 20.713],
  [45, 66, 19.863],
  [45, 65, 19.001],
  [45, 64, 18.127],
  [45, 63, 17.242],
  [45, 62, 16.346],
  [45, 61, 15.440],
  [45, 60, 14.524],
  [45, 59, 13.598],
  [45, 55, 9.819],
  [45, 50, 4.958],
  [46, 999, 34.481],
  [46, 69, 21.428],
  [46, 68, 20.602],
  [46, 67, 19.763],
  [46, 66, 18.911],
  [46, 65, 18.047],
  [46, 64, 17.171],
  [46, 63, 16.284],
  [46, 62, 15.386],
  [46, 61, 14.477],
  [46, 60, 13.559],
  [46, 59, 12.632],
  [46, 55, 8.843],
  [46, 50, 3.970],
  [47, 999, 33.573],
  [47, 69, 20.486],
  [47, 68, 19.658],
  [47, 67, 18.816],
  [47, 66, 17.962],
  [47, 65, 17.096],
  [47, 64, 16.217],
  [47, 63, 15.328],
  [47, 62, 14.427],
  [47, 61, 13.516],
  [47, 60, 12.595],
  [47, 59, 11.666],
  [47, 55, 7.866],
  [47, 50, 2.981],
  [48, 999, 32.675],
  [48, 69, 19.548],
  [48, 68, 18.717],
  [48, 67, 17.873],
  [48, 66, 17.017],
  [48, 65, 16.147],
  [48, 64, 15.266],
  [48, 63, 14.374],
  [48, 62, 13.471],
  [48, 61, 12.557],
  [48, 60, 11.634],
  [48, 59, 10.701],
  [48, 55, 6.890],
  [48, 50, 1.990],
  [49, 999, 31.781],
  [49, 69, 18.611],
  [49, 68, 17.778],
  [49, 67, 16.932],
  [49, 66, 16.072],
  [49, 65, 15.200],
  [49, 64, 14.316],
  [49, 63, 13.421],
  [49, 62, 12.515],
  [49, 61, 11.598],
  [49, 60, 10.671],
  [49, 59, 9.736],
  [49, 55, 5.913],
  [49, 50, 0.996],
  [50, 999, 30.896],
  [50, 69, 17.679],
  [50, 68, 16.843],
  [50, 67, 15.993],
  [50, 66, 15.130],
  [50, 65, 14.255],
  [50, 64, 13.368],
  [50, 63, 12.469],
  [50, 62, 11.560],
  [50, 61, 10.640],
  [50, 60, 9.710],
  [50, 59, 8.771],
  [50, 55, 4.934],
  [51, 999, 30.016],
  [51, 69, 16.747],
  [51, 68, 15.908],
  [51, 67, 15.055],
  [51, 66, 14.189],
  [51, 65, 13.310],
  [51, 64, 12.420],
  [51, 63, 11.518],
  [51, 62, 10.605],
  [51, 61, 9.681],
  [51, 60, 8.748],
  [51, 59, 7.805],
  [51, 55, 3.953],
  [52, 999, 29.143],
  [52, 69, 15.818],
  [52, 68, 14.975],
  [52, 67, 14.118],
  [52, 66, 13.249],
  [52, 65, 12.366],
  [52, 64, 11.472],
  [52, 63, 10.566],
  [52, 62, 9.649],
  [52, 61, 8.722],
  [52, 60, 7.785],
  [52, 59, 6.838],
  [52, 55, 2.970],
  [53, 999, 28.279],
  [53, 69, 14.892],
  [53, 68, 14.045],
  [53, 67, 13.185],
  [53, 66, 12.311],
  [53, 65, 11.424],
  [53, 64, 10.526],
  [53, 63, 9.616],
  [53, 62, 8.695],
  [53, 61, 7.763],
  [53, 60, 6.821],
  [53, 59, 5.870],
  [53, 55, 1.984],
  [54, 999, 27.426],
  [54, 69, 13.969],
  [54, 68, 13.118],
  [54, 67, 12.253],
  [54, 66, 11.375],
  [54, 65, 10.484],
  [54, 64, 9.581],
  [54, 63, 8.666],
  [54, 62, 7.740],
  [54, 61, 6.803],
  [54, 60, 5.857],
  [54, 59, 4.900],
  [54, 55, 0.994],
  [55, 999, 26.588],
  [55, 69, 13.052],
  [55, 68, 12.196],
  [55, 67, 11.325],
  [55, 66, 10.442],
  [55, 65, 9.546],
  [55, 64, 8.637],
  [55, 63, 7.717],
  [55, 62, 6.785],
  [55, 61, 5.843],
  [55, 60, 4.891],
  [55, 59, 3.929],
  [56, 999, 25.761],
  [56, 69, 12.137],
  [56, 68, 11.275],
  [56, 67, 10.399],
  [56, 66, 9.510],
  [56, 65, 8.608],
  [56, 64, 7.693],
  [56, 63, 6.767],
  [56, 62, 5.830],
  [56, 61, 4.881],
  [56, 60, 3.923],
  [56, 59, 2.955],
  [57, 999, 24.946],
  [57, 69, 11.224],
  [57, 68, 10.356],
  [57, 67, 9.473],
  [57, 66, 8.578],
  [57, 65, 7.669],
  [57, 64, 6.748],
  [57, 63, 5.816],
  [57, 62, 4.871],
  [57, 61, 3.916],
  [57, 60, 2.951],
  [57, 59, 1.976],
  [58, 999, 24.143],
  [58, 69, 10.312],
  [58, 68, 9.437],
  [58, 67, 8.548],
  [58, 66, 7.646],
  [58, 65, 6.730],
  [58, 64, 5.802],
  [58, 63, 4.862],
  [58, 62, 3.910],
  [58, 61, 2.947],
  [58, 60, 1.974],
  [58, 59, 0.992],
  [59, 999, 23.346],
  [59, 69, 9.399],
  [59, 68, 8.517],
  [59, 67, 7.620],
  [59, 66, 6.710],
  [59, 65, 5.787],
  [59, 64, 4.851],
  [59, 63, 3.903],
  [59, 62, 2.943],
  [59, 61, 1.972],
  [59, 60, 0.991],
  [60, 999, 22.560],
  [60, 69, 8.485],
  [60, 68, 7.595],
  [60, 67, 6.690],
  [60, 66, 5.771],
  [60, 65, 4.840],
  [60, 64, 3.895],
  [60, 63, 2.938],
  [60, 62, 1.970],
  [60, 61, 0.990],
  [61, 999, 21.785],
  [61, 69, 7.570],
  [61, 68, 6.671],
  [61, 67, 5.757],
  [61, 66, 4.829],
  [61, 65, 3.888],
  [61, 64, 2.934],
  [61, 63, 1.968],
  [61, 62, 0.989],
  [62, 999, 21.018],
  [62, 69, 6.651],
  [62, 68, 5.742],
  [62, 67, 4.818],
  [62, 66, 3.881],
  [62, 65, 2.929],
  [62, 64, 1.965],
  [62, 63, 0.989],
  [63, 999, 20.260],
  [63, 69, 5.727],
  [63, 68, 4.808],
  [63, 67, 3.874],
  [63, 66, 2.925],
  [63, 65, 1.963],
  [63, 64, 0.988],
  [64, 999, 19.509],
  [64, 69, 4.798],
  [64, 68, 3.867],
  [64, 67, 2.922],
  [64, 66, 1.961],
  [64, 65, 0.987],
  [65, 999, 18.759],
  [65, 69, 3.859],
  [65, 68, 2.917],
  [65, 67, 1.959],
  [65, 66, 0.986],
  [66, 999, 18.016],
  [66, 69, 2.912],
  [66, 68, 1.957],
  [66, 67, 0.986],
  [67, 999, 17.275],
  [67, 69, 1.954],
  [67, 68, 0.985],
  [68, 999, 16.538],
  [68, 69, 0.984],
  [69, 999, 15.810],
  [70, 999, 15.090],
  [71, 999, 14.381],
  [72, 999, 13.671],
  [73, 999, 12.972],
  [74, 999, 12.282],
  [75, 999, 11.600],
  [76, 999, 10.929],
  [77, 999, 10.272],
  [78, 999, 9.628],
  [79, 999, 9.000],
  [80, 999, 8.395],
  [81, 999, 7.807],
  [82, 999, 7.246],
  [83, 999, 6.705],
  [84, 999, 6.188],
  [85, 999, 5.699],
  [86, 999, 5.242],
  [87, 999, 4.808],
  [88, 999, 4.410],
  [89, 999, 4.039],
  [90, 999, 3.687],
  [91, 999, 3.363],
  [92, 999, 3.055],
  [93, 999, 2.787],
  [94, 999, 2.552],
  [95, 999, 2.333],
  [96, 999, 2.122],
  [97, 999, 1.924],
  [98, 999, 1.754],
  [99, 999, 1.614],
  [100, 999, 1.451],
  [101, 999, 1.248],
  [102, 999, 1.006],
  [103, 999, 0.633]
 ]);
gazPal2020tx0.set("F", [
 [0, 999, 84.782],
 [0, 69, 67.495],
 [0, 68, 66.596],
 [0, 67, 65.690],
 [0, 66, 64.778],
 [0, 65, 63.860],
 [0, 64, 62.937],
 [0, 63, 62.008],
 [0, 62, 61.075],
 [0, 61, 60.137],
 [0, 60, 59.194],
 [0, 59, 58.247],
 [0, 55, 54.423],
 [0, 50, 49.578],
 [0, 29, 28.860],
 [0, 25, 24.887],
 [0, 21, 20.911],
 [0, 20, 19.917],
 [0, 18, 17.927],
 [0, 16, 15.936],
 [1, 999, 84.051],
 [1, 69, 66.709],
 [1, 68, 65.807],
 [1, 67, 64.899],
 [1, 66, 63.984],
 [1, 65, 63.064],
 [1, 64, 62.137],
 [1, 63, 61.206],
 [1, 62, 60.269],
 [1, 61, 59.328],
 [1, 60, 58.382],
 [1, 59, 57.432],
 [1, 55, 53.596],
 [1, 50, 48.736],
 [1, 29, 27.952],
 [1, 25, 23.967],
 [1, 21, 19.978],
 [1, 20, 18.980],
 [1, 18, 16.984],
 [1, 16, 14.987],
 [2, 999, 83.072],
 [2, 69, 65.726],
 [2, 68, 64.824],
 [2, 67, 63.915],
 [2, 66, 63.000],
 [2, 65, 62.079],
 [2, 64, 61.153],
 [2, 63, 60.221],
 [2, 62, 59.284],
 [2, 61, 58.343],
 [2, 60, 57.397],
 [2, 59, 56.447],
 [2, 55, 52.610],
 [2, 50, 47.748],
 [2, 29, 26.959],
 [2, 25, 22.973],
 [2, 21, 18.983],
 [2, 20, 17.985],
 [2, 18, 15.988],
 [2, 16, 13.991],
 [3, 999, 82.085],
 [3, 69, 64.736],
 [3, 68, 63.834],
 [3, 67, 62.925],
 [3, 66, 62.010],
 [3, 65, 61.089],
 [3, 64, 60.162],
 [3, 63, 59.230],
 [3, 62, 58.293],
 [3, 61, 57.352],
 [3, 60, 56.406],
 [3, 59, 55.455],
 [3, 55, 51.618],
 [3, 50, 46.755],
 [3, 29, 25.963],
 [3, 25, 21.976],
 [3, 21, 17.986],
 [3, 20, 16.987],
 [3, 18, 14.991],
 [3, 16, 12.993],
 [4, 999, 81.094],
 [4, 69, 63.743],
 [4, 68, 62.841],
 [4, 67, 61.932],
 [4, 66, 61.017],
 [4, 65, 60.095],
 [4, 64, 59.169],
 [4, 63, 58.237],
 [4, 62, 57.300],
 [4, 61, 56.358],
 [4, 60, 55.412],
 [4, 59, 54.461],
 [4, 55, 50.623],
 [4, 50, 45.760],
 [4, 29, 24.966],
 [4, 25, 20.978],
 [4, 21, 16.988],
 [4, 20, 15.989],
 [4, 18, 13.992],
 [4, 16, 11.994],
 [5, 999, 80.101],
 [5, 69, 62.748],
 [5, 68, 61.846],
 [5, 67, 60.937],
 [5, 66, 60.021],
 [5, 65, 59.100],
 [5, 64, 58.173],
 [5, 63, 57.241],
 [5, 62, 56.304],
 [5, 61, 55.362],
 [5, 60, 54.416],
 [5, 59, 53.466],
 [5, 55, 49.627],
 [5, 50, 44.764],
 [5, 29, 23.968],
 [5, 25, 19.980],
 [5, 21, 15.989],
 [5, 20, 14.991],
 [5, 18, 12.993],
 [5, 16, 10.995],
 [6, 999, 79.106],
 [6, 69, 61.753],
 [6, 68, 60.850],
 [6, 67, 59.941],
 [6, 66, 59.026],
 [6, 65, 58.104],
 [6, 64, 57.178],
 [6, 63, 56.245],
 [6, 62, 55.308],
 [6, 61, 54.366],
 [6, 60, 53.420],
 [6, 59, 52.470],
 [6, 55, 48.631],
 [6, 50, 43.767],
 [6, 29, 22.969],
 [6, 25, 18.981],
 [6, 21, 14.990],
 [6, 20, 13.992],
 [6, 18, 11.994],
 [6, 16, 9.996],
 [7, 999, 78.112],
 [7, 69, 60.757],
 [7, 68, 59.854],
 [7, 67, 58.945],
 [7, 66, 58.030],
 [7, 65, 57.109],
 [7, 64, 56.182],
 [7, 63, 55.249],
 [7, 62, 54.312],
 [7, 61, 53.370],
 [7, 60, 52.424],
 [7, 59, 51.473],
 [7, 55, 47.634],
 [7, 50, 42.770],
 [7, 29, 21.971],
 [7, 25, 17.983],
 [7, 21, 13.991],
 [7, 20, 12.993],
 [7, 18, 10.995],
 [7, 16, 8.997],
 [8, 999, 77.117],
 [8, 69, 59.761],
 [8, 68, 58.859],
 [8, 67, 57.949],
 [8, 66, 57.034],
 [8, 65, 56.113],
 [8, 64, 55.185],
 [8, 63, 54.253],
 [8, 62, 53.316],
 [8, 61, 52.374],
 [8, 60, 51.427],
 [8, 59, 50.477],
 [8, 55, 46.638],
 [8, 50, 41.773],
 [8, 29, 20.972],
 [8, 25, 16.984],
 [8, 21, 12.992],
 [8, 20, 11.994],
 [8, 18, 9.996],
 [8, 16, 7.998],
 [9, 999, 76.122],
 [9, 69, 58.765],
 [9, 68, 57.862],
 [9, 67, 56.953],
 [9, 66, 56.037],
 [9, 65, 55.116],
 [9, 64, 54.189],
 [9, 63, 53.256],
 [9, 62, 52.319],
 [9, 61, 51.377],
 [9, 60, 50.431],
 [9, 59, 49.480],
 [9, 55, 45.640],
 [9, 50, 40.776],
 [9, 29, 19.974],
 [9, 25, 15.985],
 [9, 21, 11.993],
 [9, 20, 10.994],
 [9, 18, 8.996],
 [9, 16, 6.998],
 [10, 999, 75.127],
 [10, 69, 57.769],
 [10, 68, 56.866],
 [10, 67, 55.957],
 [10, 66, 55.041],
 [10, 65, 54.120],
 [10, 64, 53.193],
 [10, 63, 52.260],
 [10, 62, 51.323],
 [10, 61, 50.381],
 [10, 60, 49.434],
 [10, 59, 48.483],
 [10, 55, 44.644],
 [10, 50, 39.779],
 [10, 29, 18.975],
 [10, 25, 14.986],
 [10, 21, 10.994],
 [10, 20, 9.995],
 [10, 18, 7.997],
 [10, 16, 5.998],
 [11, 999, 74.132],
 [11, 69, 56.773],
 [11, 68, 55.870],
 [11, 67, 54.960],
 [11, 66, 54.045],
 [11, 65, 53.123],
 [11, 64, 52.196],
 [11, 63, 51.263],
 [11, 62, 50.326],
 [11, 61, 49.384],
 [11, 60, 48.437],
 [11, 59, 47.486],
 [11, 55, 43.646],
 [11, 50, 38.781],
 [11, 29, 17.976],
 [11, 25, 13.987],
 [11, 21, 9.994],
 [11, 20, 8.996],
 [11, 18, 6.998],
 [11, 16, 4.999],
 [12, 999, 73.137],
 [12, 69, 55.777],
 [12, 68, 54.874],
 [12, 67, 53.964],
 [12, 66, 53.048],
 [12, 65, 52.127],
 [12, 64, 51.200],
 [12, 63, 50.267],
 [12, 62, 49.329],
 [12, 61, 48.387],
 [12, 60, 47.440],
 [12, 59, 46.490],
 [12, 55, 42.649],
 [12, 50, 37.784],
 [12, 29, 16.977],
 [12, 25, 12.988],
 [12, 21, 8.995],
 [12, 20, 7.996],
 [12, 18, 5.998],
 [12, 16, 3.999],
 [13, 999, 72.141],
 [13, 69, 54.780],
 [13, 68, 53.877],
 [13, 67, 52.967],
 [13, 66, 52.052],
 [13, 65, 51.130],
 [13, 64, 50.203],
 [13, 63, 49.270],
 [13, 62, 48.332],
 [13, 61, 47.390],
 [13, 60, 46.443],
 [13, 59, 45.492],
 [13, 55, 41.652],
 [13, 50, 36.786],
 [13, 29, 15.979],
 [13, 25, 11.989],
 [13, 21, 7.996],
 [13, 20, 6.997],
 [13, 18, 4.998],
 [13, 16, 2.999],
 [14, 999, 71.147],
 [14, 69, 53.784],
 [14, 68, 52.881],
 [14, 67, 51.972],
 [14, 66, 51.056],
 [14, 65, 50.134],
 [14, 64, 49.207],
 [14, 63, 48.274],
 [14, 62, 47.336],
 [14, 61, 46.394],
 [14, 60, 45.447],
 [14, 59, 44.496],
 [14, 55, 40.655],
 [14, 50, 35.789],
 [14, 29, 14.980],
 [14, 25, 10.990],
 [14, 21, 6.996],
 [14, 20, 5.997],
 [14, 18, 3.999],
 [14, 16, 2.000],
 [15, 999, 70.154],
 [15, 69, 52.790],
 [15, 68, 51.887],
 [15, 67, 50.977],
 [15, 66, 50.061],
 [15, 65, 49.139],
 [15, 64, 48.212],
 [15, 63, 47.279],
 [15, 62, 46.341],
 [15, 61, 45.399],
 [15, 60, 44.452],
 [15, 59, 43.501],
 [15, 55, 39.659],
 [15, 50, 34.793],
 [15, 29, 13.981],
 [15, 25, 9.991],
 [15, 21, 5.997],
 [15, 20, 4.998],
 [15, 18, 2.999],
 [15, 16, 1.000],
 [16, 999, 69.163],
 [16, 69, 51.796],
 [16, 68, 50.893],
 [16, 67, 49.983],
 [16, 66, 49.067],
 [16, 65, 48.145],
 [16, 64, 47.217],
 [16, 63, 46.284],
 [16, 62, 45.347],
 [16, 61, 44.404],
 [16, 60, 43.457],
 [16, 59, 42.506],
 [16, 55, 38.664],
 [16, 50, 33.797],
 [16, 29, 12.983],
 [16, 25, 8.992],
 [16, 21, 4.998],
 [16, 20, 3.999],
 [16, 18, 2.000],
 [17, 999, 68.172],
 [17, 69, 50.803],
 [17, 68, 49.899],
 [17, 67, 48.989],
 [17, 66, 48.073],
 [17, 65, 47.151],
 [17, 64, 46.224],
 [17, 63, 45.291],
 [17, 62, 44.353],
 [17, 61, 43.410],
 [17, 60, 42.463],
 [17, 59, 41.511],
 [17, 55, 37.669],
 [17, 50, 32.801],
 [17, 29, 11.985],
 [17, 25, 7.993],
 [17, 21, 3.998],
 [17, 20, 2.999],
 [17, 18, 1.000],
 [18, 999, 67.181],
 [18, 69, 49.810],
 [18, 68, 48.907],
 [18, 67, 47.996],
 [18, 66, 47.080],
 [18, 65, 46.158],
 [18, 64, 45.230],
 [18, 63, 44.297],
 [18, 62, 43.359],
 [18, 61, 42.416],
 [18, 60, 41.469],
 [18, 59, 40.517],
 [18, 55, 36.675],
 [18, 50, 31.806],
 [18, 29, 10.986],
 [18, 25, 6.994],
 [18, 21, 2.999],
 [18, 20, 1.999],
 [19, 999, 66.193],
 [19, 69, 48.818],
 [19, 68, 47.915],
 [19, 67, 47.005],
 [19, 66, 46.088],
 [19, 65, 45.166],
 [19, 64, 44.238],
 [19, 63, 43.304],
 [19, 62, 42.366],
 [19, 61, 41.423],
 [19, 60, 40.476],
 [19, 59, 39.524],
 [19, 55, 35.681],
 [19, 50, 30.811],
 [19, 29, 9.988],
 [19, 25, 5.996],
 [19, 21, 1.999],
 [19, 20, 1.000],
 [20, 999, 65.205],
 [20, 69, 47.828],
 [20, 68, 46.924],
 [20, 67, 46.014],
 [20, 66, 45.097],
 [20, 65, 44.174],
 [20, 64, 43.246],
 [20, 63, 42.313],
 [20, 62, 41.374],
 [20, 61, 40.431],
 [20, 60, 39.483],
 [20, 59, 38.532],
 [20, 55, 34.688],
 [20, 50, 29.817],
 [20, 29, 8.990],
 [20, 25, 4.997],
 [20, 21, 1.000],
 [21, 999, 64.221],
 [21, 69, 46.839],
 [21, 68, 45.935],
 [21, 67, 45.024],
 [21, 66, 44.107],
 [21, 65, 43.185],
 [21, 64, 42.256],
 [21, 63, 41.322],
 [21, 62, 40.384],
 [21, 61, 39.440],
 [21, 60, 38.493],
 [21, 59, 37.541],
 [21, 55, 33.696],
 [21, 50, 28.824],
 [21, 29, 7.992],
 [21, 25, 3.998],
 [22, 999, 63.233],
 [22, 69, 45.848],
 [22, 68, 44.944],
 [22, 67, 44.033],
 [22, 66, 43.116],
 [22, 65, 42.193],
 [22, 64, 41.264],
 [22, 63, 40.330],
 [22, 62, 39.392],
 [22, 61, 38.448],
 [22, 60, 37.500],
 [22, 59, 36.548],
 [22, 55, 32.702],
 [22, 50, 27.830],
 [22, 29, 6.994],
 [22, 25, 2.999],
 [23, 999, 62.246],
 [23, 69, 44.857],
 [23, 68, 43.953],
 [23, 67, 43.042],
 [23, 66, 42.124],
 [23, 65, 41.201],
 [23, 64, 40.273],
 [23, 63, 39.339],
 [23, 62, 38.399],
 [23, 61, 37.456],
 [23, 60, 36.508],
 [23, 59, 35.555],
 [23, 55, 31.709],
 [23, 50, 26.835],
 [23, 29, 5.995],
 [23, 25, 1.999],
 [24, 999, 61.259],
 [24, 69, 43.867],
 [24, 68, 42.962],
 [24, 67, 42.051],
 [24, 66, 41.134],
 [24, 65, 40.210],
 [24, 64, 39.282],
 [24, 63, 38.347],
 [24, 62, 37.408],
 [24, 61, 36.464],
 [24, 60, 35.516],
 [24, 59, 34.563],
 [24, 55, 30.716],
 [24, 50, 25.841],
 [24, 29, 4.996],
 [24, 25, 1.000],
 [25, 999, 60.273],
 [25, 69, 42.877],
 [25, 68, 41.972],
 [25, 67, 41.060],
 [25, 66, 40.143],
 [25, 65, 39.219],
 [25, 64, 38.290],
 [25, 63, 37.356],
 [25, 62, 36.416],
 [25, 61, 35.472],
 [25, 60, 34.523],
 [25, 59, 33.571],
 [25, 55, 29.723],
 [25, 50, 24.847],
 [25, 29, 3.998],
 [26, 999, 59.286],
 [26, 69, 41.886],
 [26, 68, 40.981],
 [26, 67, 40.070],
 [26, 66, 39.152],
 [26, 65, 38.228],
 [26, 64, 37.299],
 [26, 63, 36.364],
 [26, 62, 35.424],
 [26, 61, 34.480],
 [26, 60, 33.531],
 [26, 59, 32.578],
 [26, 55, 28.729],
 [26, 50, 23.852],
 [26, 29, 2.998],
 [27, 999, 58.301],
 [27, 69, 40.897],
 [27, 68, 39.992],
 [27, 67, 39.080],
 [27, 66, 38.162],
 [27, 65, 37.238],
 [27, 64, 36.308],
 [27, 63, 35.373],
 [27, 62, 34.433],
 [27, 61, 33.489],
 [27, 60, 32.540],
 [27, 59, 31.586],
 [27, 55, 27.736],
 [27, 50, 22.858],
 [27, 29, 1.999],
 [28, 999, 57.315],
 [28, 69, 39.907],
 [28, 68, 39.001],
 [28, 67, 38.089],
 [28, 66, 37.171],
 [28, 65, 36.247],
 [28, 64, 35.317],
 [28, 63, 34.382],
 [28, 62, 33.442],
 [28, 61, 32.497],
 [28, 60, 31.547],
 [28, 59, 30.594],
 [28, 55, 26.743],
 [28, 50, 21.864],
 [28, 29, 1.000],
 [29, 999, 56.331],
 [29, 69, 38.917],
 [29, 68, 38.012],
 [29, 67, 37.099],
 [29, 66, 36.181],
 [29, 65, 35.257],
 [29, 64, 34.326],
 [29, 63, 33.391],
 [29, 62, 32.451],
 [29, 61, 31.506],
 [29, 60, 30.556],
 [29, 59, 29.602],
 [29, 55, 25.750],
 [29, 50, 20.870],
 [30, 999, 55.348],
 [30, 69, 37.930],
 [30, 68, 37.024],
 [30, 67, 36.111],
 [30, 66, 35.192],
 [30, 65, 34.268],
 [30, 64, 33.337],
 [30, 63, 32.401],
 [30, 62, 31.461],
 [30, 61, 30.515],
 [30, 60, 29.566],
 [30, 59, 28.611],
 [30, 55, 24.758],
 [30, 50, 19.876],
 [31, 999, 54.365],
 [31, 69, 36.941],
 [31, 68, 36.035],
 [31, 67, 35.122],
 [31, 66, 34.203],
 [31, 65, 33.278],
 [31, 64, 32.347],
 [31, 63, 31.411],
 [31, 62, 30.470],
 [31, 61, 29.524],
 [31, 60, 28.574],
 [31, 59, 27.620],
 [31, 55, 23.766],
 [31, 50, 18.882],
 [32, 999, 53.383],
 [32, 69, 35.953],
 [32, 68, 35.047],
 [32, 67, 34.133],
 [32, 66, 33.214],
 [32, 65, 32.289],
 [32, 64, 31.358],
 [32, 63, 30.421],
 [32, 62, 29.480],
 [32, 61, 28.534],
 [32, 60, 27.584],
 [32, 59, 26.629],
 [32, 55, 22.773],
 [32, 50, 17.888],
 [33, 999, 52.402],
 [33, 69, 34.966],
 [33, 68, 34.059],
 [33, 67, 33.145],
 [33, 66, 32.226],
 [33, 65, 31.300],
 [33, 64, 30.369],
 [33, 63, 29.432],
 [33, 62, 28.491],
 [33, 61, 27.544],
 [33, 60, 26.593],
 [33, 59, 25.638],
 [33, 55, 21.782],
 [33, 50, 16.895],
 [34, 999, 51.421],
 [34, 69, 33.979],
 [34, 68, 33.072],
 [34, 67, 32.158],
 [34, 66, 31.238],
 [34, 65, 30.312],
 [34, 64, 29.380],
 [34, 63, 28.443],
 [34, 62, 27.501],
 [34, 61, 26.554],
 [34, 60, 25.603],
 [34, 59, 24.648],
 [34, 55, 20.790],
 [34, 50, 15.901],
 [35, 999, 50.441],
 [35, 69, 32.992],
 [35, 68, 32.084],
 [35, 67, 31.170],
 [35, 66, 30.250],
 [35, 65, 29.323],
 [35, 64, 28.391],
 [35, 63, 27.454],
 [35, 62, 26.512],
 [35, 61, 25.565],
 [35, 60, 24.613],
 [35, 59, 23.657],
 [35, 55, 19.798],
 [35, 50, 14.907],
 [36, 999, 49.462],
 [36, 69, 32.005],
 [36, 68, 31.098],
 [36, 67, 30.183],
 [36, 66, 29.262],
 [36, 65, 28.336],
 [36, 64, 27.403],
 [36, 63, 26.465],
 [36, 62, 25.523],
 [36, 61, 24.575],
 [36, 60, 23.623],
 [36, 59, 22.667],
 [36, 55, 18.806],
 [36, 50, 13.913],
 [37, 999, 48.487],
 [37, 69, 31.022],
 [37, 68, 30.113],
 [37, 67, 29.198],
 [37, 66, 28.277],
 [37, 65, 27.350],
 [37, 64, 26.417],
 [37, 63, 25.479],
 [37, 62, 24.536],
 [37, 61, 23.588],
 [37, 60, 22.635],
 [37, 59, 21.679],
 [37, 55, 17.815],
 [37, 50, 12.920],
 [38, 999, 47.514],
 [38, 69, 30.039],
 [38, 68, 29.130],
 [38, 67, 28.215],
 [38, 66, 27.293],
 [38, 65, 26.365],
 [38, 64, 25.432],
 [38, 63, 24.493],
 [38, 62, 23.549],
 [38, 61, 22.601],
 [38, 60, 21.648],
 [38, 59, 20.691],
 [38, 55, 16.825],
 [38, 50, 11.928],
 [39, 999, 46.543],
 [39, 69, 29.058],
 [39, 68, 28.148],
 [39, 67, 27.232],
 [39, 66, 26.310],
 [39, 65, 25.382],
 [39, 64, 24.448],
 [39, 63, 23.508],
 [39, 62, 22.564],
 [39, 61, 21.615],
 [39, 60, 20.661],
 [39, 59, 19.704],
 [39, 55, 15.836],
 [39, 50, 10.935],
 [40, 999, 45.575],
 [40, 69, 28.077],
 [40, 68, 27.167],
 [40, 67, 26.251],
 [40, 66, 25.328],
 [40, 65, 24.399],
 [40, 64, 23.464],
 [40, 63, 22.524],
 [40, 62, 21.579],
 [40, 61, 20.630],
 [40, 60, 19.675],
 [40, 59, 18.717],
 [40, 55, 14.847],
 [40, 50, 9.942],
 [41, 999, 44.608],
 [41, 69, 27.098],
 [41, 68, 26.187],
 [41, 67, 25.270],
 [41, 66, 24.346],
 [41, 65, 23.416],
 [41, 64, 22.481],
 [41, 63, 21.540],
 [41, 62, 20.595],
 [41, 61, 19.644],
 [41, 60, 18.690],
 [41, 59, 17.731],
 [41, 55, 13.857],
 [41, 50, 8.950],
 [42, 999, 43.644],
 [42, 69, 26.120],
 [42, 68, 25.208],
 [42, 67, 24.290],
 [42, 66, 23.366],
 [42, 65, 22.435],
 [42, 64, 21.499],
 [42, 63, 20.558],
 [42, 62, 19.612],
 [42, 61, 18.660],
 [42, 60, 17.705],
 [42, 59, 16.745],
 [42, 55, 12.868],
 [42, 50, 7.957],
 [43, 999, 42.682],
 [43, 69, 25.142],
 [43, 68, 24.230],
 [43, 67, 23.311],
 [43, 66, 22.386],
 [43, 65, 21.455],
 [43, 64, 20.518],
 [43, 63, 19.576],
 [43, 62, 18.629],
 [43, 61, 17.677],
 [43, 60, 16.720],
 [43, 59, 15.760],
 [43, 55, 11.880],
 [43, 50, 6.964],
 [44, 999, 41.727],
 [44, 69, 24.169],
 [44, 68, 23.255],
 [44, 67, 22.336],
 [44, 66, 21.409],
 [44, 65, 20.477],
 [44, 64, 19.540],
 [44, 63, 18.596],
 [44, 62, 17.648],
 [44, 61, 16.695],
 [44, 60, 15.738],
 [44, 59, 14.776],
 [44, 55, 10.892],
 [44, 50, 5.971],
 [45, 999, 40.776],
 [45, 69, 23.197],
 [45, 68, 22.283],
 [45, 67, 21.362],
 [45, 66, 20.434],
 [45, 65, 19.501],
 [45, 64, 18.562],
 [45, 63, 17.618],
 [45, 62, 16.669],
 [45, 61, 15.715],
 [45, 60, 14.756],
 [45, 59, 13.793],
 [45, 55, 9.905],
 [45, 50, 4.978],
 [46, 999, 39.827],
 [46, 69, 22.226],
 [46, 68, 21.311],
 [46, 67, 20.389],
 [46, 66, 19.460],
 [46, 65, 18.526],
 [46, 64, 17.586],
 [46, 63, 16.640],
 [46, 62, 15.690],
 [46, 61, 14.735],
 [46, 60, 13.775],
 [46, 59, 12.811],
 [46, 55, 8.917],
 [46, 50, 3.984],
 [47, 999, 38.883],
 [47, 69, 21.258],
 [47, 68, 20.341],
 [47, 67, 19.418],
 [47, 66, 18.488],
 [47, 65, 17.552],
 [47, 64, 16.611],
 [47, 63, 15.664],
 [47, 62, 14.712],
 [47, 61, 13.755],
 [47, 60, 12.794],
 [47, 59, 11.829],
 [47, 55, 7.930],
 [47, 50, 2.990],
 [48, 999, 37.944],
 [48, 69, 20.291],
 [48, 68, 19.373],
 [48, 67, 18.448],
 [48, 66, 17.517],
 [48, 65, 16.580],
 [48, 64, 15.637],
 [48, 63, 14.688],
 [48, 62, 13.735],
 [48, 61, 12.777],
 [48, 60, 11.814],
 [48, 59, 10.847],
 [48, 55, 6.942],
 [48, 50, 1.995],
 [49, 999, 37.012],
 [49, 69, 19.327],
 [49, 68, 18.407],
 [49, 67, 17.481],
 [49, 66, 16.548],
 [49, 65, 15.609],
 [49, 64, 14.665],
 [49, 63, 13.715],
 [49, 62, 12.760],
 [49, 61, 11.800],
 [49, 60, 10.835],
 [49, 59, 9.867],
 [49, 55, 5.955],
 [49, 50, 0.998],
 [50, 999, 36.080],
 [50, 69, 18.363],
 [50, 68, 17.441],
 [50, 67, 16.513],
 [50, 66, 15.578],
 [50, 65, 14.638],
 [50, 64, 13.692],
 [50, 63, 12.740],
 [50, 62, 11.783],
 [50, 61, 10.821],
 [50, 60, 9.855],
 [50, 59, 8.885],
 [50, 55, 4.966],
 [51, 999, 35.155],
 [51, 69, 17.401],
 [51, 68, 16.477],
 [51, 67, 15.547],
 [51, 66, 14.611],
 [51, 65, 13.668],
 [51, 64, 12.720],
 [51, 63, 11.766],
 [51, 62, 10.807],
 [51, 61, 9.844],
 [51, 60, 8.876],
 [51, 59, 7.903],
 [51, 55, 3.976],
 [52, 999, 34.233],
 [52, 69, 16.440],
 [52, 68, 15.514],
 [52, 67, 14.582],
 [52, 66, 13.643],
 [52, 65, 12.699],
 [52, 64, 11.748],
 [52, 63, 10.793],
 [52, 62, 9.832],
 [52, 61, 8.866],
 [52, 60, 7.896],
 [52, 59, 6.921],
 [52, 55, 2.985],
 [53, 999, 33.314],
 [53, 69, 15.479],
 [53, 68, 14.551],
 [53, 67, 13.616],
 [53, 66, 12.676],
 [53, 65, 11.729],
 [53, 64, 10.776],
 [53, 63, 9.818],
 [53, 62, 8.855],
 [53, 61, 7.887],
 [53, 60, 6.914],
 [53, 59, 5.937],
 [53, 55, 1.992],
 [54, 999, 32.400],
 [54, 69, 14.519],
 [54, 68, 13.589],
 [54, 67, 12.652],
 [54, 66, 11.708],
 [54, 65, 10.759],
 [54, 64, 9.804],
 [54, 63, 8.843],
 [54, 62, 7.878],
 [54, 61, 6.907],
 [54, 60, 5.932],
 [54, 59, 4.953],
 [54, 55, 0.997],
 [55, 999, 31.494],
 [55, 69, 13.560],
 [55, 68, 12.628],
 [55, 67, 11.688],
 [55, 66, 10.742],
 [55, 65, 9.790],
 [55, 64, 8.832],
 [55, 63, 7.869],
 [55, 62, 6.900],
 [55, 61, 5.927],
 [55, 60, 4.949],
 [55, 59, 3.967],
 [56, 999, 30.591],
 [56, 69, 12.602],
 [56, 68, 11.667],
 [56, 67, 10.724],
 [56, 66, 9.775],
 [56, 65, 8.820],
 [56, 64, 7.859],
 [56, 63, 6.893],
 [56, 62, 5.922],
 [56, 61, 4.945],
 [56, 60, 3.964],
 [56, 59, 2.979],
 [57, 999, 29.691],
 [57, 69, 11.643],
 [57, 68, 10.705],
 [57, 67, 9.759],
 [57, 66, 8.807],
 [57, 65, 7.849],
 [57, 64, 6.885],
 [57, 63, 5.916],
 [57, 62, 4.941],
 [57, 61, 3.962],
 [57, 60, 2.977],
 [57, 59, 1.989],
 [58, 999, 28.799],
 [58, 69, 10.686],
 [58, 68, 9.744],
 [58, 67, 8.795],
 [58, 66, 7.839],
 [58, 65, 6.878],
 [58, 64, 5.910],
 [58, 63, 4.937],
 [58, 62, 3.959],
 [58, 61, 2.976],
 [58, 60, 1.988],
 [58, 59, 0.996],
 [59, 999, 27.909],
 [59, 69, 9.727],
 [59, 68, 8.781],
 [59, 67, 7.828],
 [59, 66, 6.869],
 [59, 65, 5.904],
 [59, 64, 4.933],
 [59, 63, 3.956],
 [59, 62, 2.974],
 [59, 61, 1.987],
 [59, 60, 0.996],
 [60, 999, 27.025],
 [60, 69, 8.767],
 [60, 68, 7.818],
 [60, 67, 6.861],
 [60, 66, 5.898],
 [60, 65, 4.929],
 [60, 64, 3.953],
 [60, 63, 2.973],
 [60, 62, 1.987],
 [60, 61, 0.996],
 [61, 999, 26.144],
 [61, 69, 7.806],
 [61, 68, 6.852],
 [61, 67, 5.891],
 [61, 66, 4.924],
 [61, 65, 3.950],
 [61, 64, 2.971],
 [61, 63, 1.986],
 [61, 62, 0.995],
 [62, 999, 25.268],
 [62, 69, 6.843],
 [62, 68, 5.884],
 [62, 67, 4.919],
 [62, 66, 3.947],
 [62, 65, 2.969],
 [62, 64, 1.985],
 [62, 63, 0.995],
 [63, 999, 24.394],
 [63, 69, 5.877],
 [63, 68, 4.914],
 [63, 67, 3.943],
 [63, 66, 2.967],
 [63, 65, 1.984],
 [63, 64, 0.995],
 [64, 999, 23.523],
 [64, 69, 4.908],
 [64, 68, 3.940],
 [64, 67, 2.964],
 [64, 66, 1.983],
 [64, 65, 0.994],
 [65, 999, 22.658],
 [65, 69, 3.936],
 [65, 68, 2.962],
 [65, 67, 1.981],
 [65, 66, 0.994],
 [66, 999, 21.798],
 [66, 69, 2.960],
 [66, 68, 1.981],
 [66, 67, 0.994],
 [67, 999, 20.937],
 [67, 69, 1.979],
 [67, 68, 0.993],
 [68, 999, 20.079],
 [68, 69, 0.993],
 [69, 999, 19.228],
 [70, 999, 18.384],
 [71, 999, 17.548],
 [72, 999, 16.719],
 [73, 999, 15.900],
 [74, 999, 15.088],
 [75, 999, 14.281],
 [76, 999, 13.488],
 [77, 999, 12.711],
 [78, 999, 11.944],
 [79, 999, 11.190],
 [80, 999, 10.456],
 [81, 999, 9.743],
 [82, 999, 9.053],
 [83, 999, 8.383],
 [84, 999, 7.743],
 [85, 999, 7.131],
 [86, 999, 6.551],
 [87, 999, 6.003],
 [88, 999, 5.487],
 [89, 999, 5.000],
 [90, 999, 4.548],
 [91, 999, 4.130],
 [92, 999, 3.745],
 [93, 999, 3.390],
 [94, 999, 3.061],
 [95, 999, 2.764],
 [96, 999, 2.495],
 [97, 999, 2.229],
 [98, 999, 1.980],
 [99, 999, 1.759],
 [100, 999, 1.523],
 [101, 999, 1.284],
 [102, 999, 1.013],
 [103, 999, 0.613],
]);

export const gazPal2020tx03 = new Map();
gazPal2020tx03.set("M", [
 [0, 999, 69.734],
 [0, 69, 59.796],
 [0, 68, 59.148],
 [0, 67, 58.487],
 [0, 66, 57.814],
 [0, 65, 57.130],
 [0, 64, 56.434],
 [0, 63, 55.727],
 [0, 62, 55.009],
 [0, 61, 54.281],
 [0, 60, 53.542],
 [0, 59, 52.794],
 [0, 55, 49.716],
 [0, 50, 45.703],
 [0, 29, 27.551],
 [0, 25, 23.910],
 [0, 21, 20.216],
 [0, 20, 19.284],
 [0, 18, 17.410],
 [0, 16, 15.524],
 [1, 999, 69.213],
 [1, 69, 59.207],
 [1, 68, 58.554],
 [1, 67, 57.889],
 [1, 66, 57.211],
 [1, 65, 56.522],
 [1, 64, 55.821],
 [1, 63, 55.109],
 [1, 62, 54.387],
 [1, 61, 53.653],
 [1, 60, 52.910],
 [1, 59, 52.157],
 [1, 55, 49.057],
 [1, 50, 45.016],
 [1, 29, 26.740],
 [1, 25, 23.074],
 [1, 21, 19.355],
 [1, 20, 18.416],
 [1, 18, 16.530],
 [1, 16, 14.631],
 [2, 999, 68.439],
 [2, 69, 58.400],
 [2, 68, 57.746],
 [2, 67, 57.078],
 [2, 66, 56.399],
 [2, 65, 55.707],
 [2, 64, 55.004],
 [2, 63, 54.290],
 [2, 62, 53.565],
 [2, 61, 52.829],
 [2, 60, 52.083],
 [2, 59, 51.328],
 [2, 55, 48.218],
 [2, 50, 44.164],
 [2, 29, 25.827],
 [2, 25, 22.150],
 [2, 21, 18.418],
 [2, 20, 17.477],
 [2, 18, 15.584],
 [2, 16, 13.679],
 [3, 999, 67.657],
 [3, 69, 57.586],
 [3, 68, 56.929],
 [3, 67, 56.260],
 [3, 66, 55.578],
 [3, 65, 54.884],
 [3, 64, 54.179],
 [3, 63, 53.462],
 [3, 62, 52.735],
 [3, 61, 51.997],
 [3, 60, 51.249],
 [3, 59, 50.491],
 [3, 55, 47.371],
 [3, 50, 43.304],
 [3, 29, 24.910],
 [3, 25, 21.220],
 [3, 21, 17.477],
 [3, 20, 16.532],
 [3, 18, 14.634],
 [3, 16, 12.722],
 [4, 999, 66.869],
 [4, 69, 56.767],
 [4, 68, 56.108],
 [4, 67, 55.436],
 [4, 66, 54.752],
 [4, 65, 54.057],
 [4, 64, 53.349],
 [4, 63, 52.630],
 [4, 62, 51.901],
 [4, 61, 51.160],
 [4, 60, 50.410],
 [4, 59, 49.649],
 [4, 55, 46.520],
 [4, 50, 42.440],
 [4, 29, 23.988],
 [4, 25, 20.287],
 [4, 21, 16.531],
 [4, 20, 15.584],
 [4, 18, 13.680],
 [4, 16, 11.762],
 [5, 999, 66.078],
 [5, 69, 55.944],
 [5, 68, 55.283],
 [5, 67, 54.609],
 [5, 66, 53.923],
 [5, 65, 53.225],
 [5, 64, 52.516],
 [5, 63, 51.795],
 [5, 62, 51.063],
 [5, 61, 50.320],
 [5, 60, 49.567],
 [5, 59, 48.804],
 [5, 55, 45.665],
 [5, 50, 41.573],
 [5, 29, 23.063],
 [5, 25, 19.350],
 [5, 21, 15.583],
 [5, 20, 14.633],
 [5, 18, 12.722],
 [5, 16, 10.799],
 [6, 999, 65.283],
 [6, 69, 55.118],
 [6, 68, 54.455],
 [6, 67, 53.779],
 [6, 66, 53.091],
 [6, 65, 52.390],
 [6, 64, 51.678],
 [6, 63, 50.955],
 [6, 62, 50.221],
 [6, 61, 49.476],
 [6, 60, 48.721],
 [6, 59, 47.956],
 [6, 55, 44.806],
 [6, 50, 40.702],
 [6, 29, 22.134],
 [6, 25, 18.410],
 [6, 21, 14.631],
 [6, 20, 13.678],
 [6, 18, 11.762],
 [6, 16, 9.832],
 [7, 999, 64.485],
 [7, 69, 54.288],
 [7, 68, 53.623],
 [7, 67, 52.945],
 [7, 66, 52.255],
 [7, 65, 51.552],
 [7, 64, 50.838],
 [7, 63, 50.113],
 [7, 62, 49.376],
 [7, 61, 48.629],
 [7, 60, 47.871],
 [7, 59, 47.104],
 [7, 55, 43.945],
 [7, 50, 39.827],
 [7, 29, 21.203],
 [7, 25, 17.467],
 [7, 21, 13.677],
 [7, 20, 12.720],
 [7, 18, 10.798],
 [7, 16, 8.863],
 [8, 999, 63.684],
 [8, 69, 53.456],
 [8, 68, 52.789],
 [8, 67, 52.109],
 [8, 66, 51.416],
 [8, 65, 50.712],
 [8, 64, 49.995],
 [8, 63, 49.268],
 [8, 62, 48.529],
 [8, 61, 47.779],
 [8, 60, 47.019],
 [8, 59, 46.250],
 [8, 55, 43.081],
 [8, 50, 38.951],
 [8, 29, 20.268],
 [8, 25, 16.521],
 [8, 21, 12.719],
 [8, 20, 11.760],
 [8, 18, 9.831],
 [8, 16, 7.890],
 [9, 999, 62.880],
 [9, 69, 52.620],
 [9, 68, 51.951],
 [9, 67, 51.269],
 [9, 66, 50.575],
 [9, 65, 49.868],
 [9, 64, 49.149],
 [9, 63, 48.419],
 [9, 62, 47.678],
 [9, 61, 46.926],
 [9, 60, 46.164],
 [9, 59, 45.392],
 [9, 55, 42.213],
 [9, 50, 38.071],
 [9, 29, 19.331],
 [9, 25, 15.572],
 [9, 21, 11.758],
 [9, 20, 10.796],
 [9, 18, 8.862],
 [9, 16, 6.914],
 [10, 999, 62.074],
 [10, 69, 51.783],
 [10, 68, 51.111],
 [10, 67, 50.427],
 [10, 66, 49.730],
 [10, 65, 49.021],
 [10, 64, 48.301],
 [10, 63, 47.569],
 [10, 62, 46.825],
 [10, 61, 46.071],
 [10, 60, 45.306],
 [10, 59, 44.532],
 [10, 55, 41.344],
 [10, 50, 37.188],
 [10, 29, 18.390],
 [10, 25, 14.620],
 [10, 21, 10.794],
 [10, 20, 9.829],
 [10, 18, 7.889],
 [10, 16, 5.936],
 [11, 999, 61.264],
 [11, 69, 50.942],
 [11, 68, 50.268],
 [11, 67, 49.582],
 [11, 66, 48.883],
 [11, 65, 48.172],
 [11, 64, 47.449],
 [11, 63, 46.715],
 [11, 62, 45.969],
 [11, 61, 45.212],
 [11, 60, 44.445],
 [11, 59, 43.669],
 [11, 55, 40.470],
 [11, 50, 36.302],
 [11, 29, 17.447],
 [11, 25, 13.665],
 [11, 21, 9.827],
 [11, 20, 8.859],
 [11, 18, 6.913],
 [11, 16, 4.954],
 [12, 999, 60.452],
 [12, 69, 50.098],
 [12, 68, 49.423],
 [12, 67, 48.734],
 [12, 66, 48.033],
 [12, 65, 47.320],
 [12, 64, 46.595],
 [12, 63, 45.858],
 [12, 62, 45.110],
 [12, 61, 44.351],
 [12, 60, 43.582],
 [12, 59, 42.803],
 [12, 55, 39.595],
 [12, 50, 35.413],
 [12, 29, 16.500],
 [12, 25, 12.707],
 [12, 21, 8.858],
 [12, 20, 7.887],
 [12, 18, 5.935],
 [12, 16, 3.969],
 [13, 999, 59.639],
 [13, 69, 49.253],
 [13, 68, 48.575],
 [13, 67, 47.885],
 [13, 66, 47.182],
 [13, 65, 46.466],
 [13, 64, 45.739],
 [13, 63, 45.000],
 [13, 62, 44.250],
 [13, 61, 43.488],
 [13, 60, 42.717],
 [13, 59, 41.935],
 [13, 55, 38.717],
 [13, 50, 34.523],
 [13, 29, 15.551],
 [13, 25, 11.746],
 [13, 21, 7.885],
 [13, 20, 6.911],
 [13, 18, 4.953],
 [13, 16, 2.981],
 [14, 999, 58.825],
 [14, 69, 48.406],
 [14, 68, 47.727],
 [14, 67, 47.034],
 [14, 66, 46.328],
 [14, 65, 45.611],
 [14, 64, 44.881],
 [14, 63, 44.140],
 [14, 62, 43.387],
 [14, 61, 42.624],
 [14, 60, 41.850],
 [14, 59, 41.065],
 [14, 55, 37.838],
 [14, 50, 33.630],
 [14, 29, 14.599],
 [14, 25, 10.783],
 [14, 21, 6.910],
 [14, 20, 5.933],
 [14, 18, 3.968],
 [14, 16, 1.991],
 [15, 999, 58.010],
 [15, 69, 47.559],
 [15, 68, 46.877],
 [15, 67, 46.182],
 [15, 66, 45.474],
 [15, 65, 44.754],
 [15, 64, 44.022],
 [15, 63, 43.279],
 [15, 62, 42.524],
 [15, 61, 41.758],
 [15, 60, 40.981],
 [15, 59, 40.195],
 [15, 55, 36.957],
 [15, 50, 32.736],
 [15, 29, 13.645],
 [15, 25, 9.817],
 [15, 21, 5.931],
 [15, 20, 4.951],
 [15, 18, 2.981],
 [15, 16, 0.997],
 [16, 999, 57.195],
 [16, 69, 46.710],
 [16, 68, 46.026],
 [16, 67, 45.329],
 [16, 66, 44.619],
 [16, 65, 43.897],
 [16, 64, 43.163],
 [16, 63, 42.417],
 [16, 62, 41.659],
 [16, 61, 40.891],
 [16, 60, 40.112],
 [16, 59, 39.323],
 [16, 55, 36.074],
 [16, 50, 31.840],
 [16, 29, 12.689],
 [16, 25, 8.848],
 [16, 21, 4.950],
 [16, 20, 3.967],
 [16, 18, 1.990],
 [17, 999, 56.380],
 [17, 69, 45.861],
 [17, 68, 45.175],
 [17, 67, 44.475],
 [17, 66, 43.763],
 [17, 65, 43.039],
 [17, 64, 42.302],
 [17, 63, 41.554],
 [17, 62, 40.794],
 [17, 61, 40.023],
 [17, 60, 39.241],
 [17, 59, 38.450],
 [17, 55, 35.191],
 [17, 50, 30.943],
 [17, 29, 11.730],
 [17, 25, 7.876],
 [17, 21, 3.966],
 [17, 20, 2.980],
 [17, 18, 0.997],
 [18, 999, 55.566],
 [18, 69, 45.013],
 [18, 68, 44.325],
 [18, 67, 43.623],
 [18, 66, 42.908],
 [18, 65, 42.181],
 [18, 64, 41.442],
 [18, 63, 40.691],
 [18, 62, 39.929],
 [18, 61, 39.156],
 [18, 60, 38.371],
 [18, 59, 37.577],
 [18, 55, 34.308],
 [18, 50, 30.046],
 [18, 29, 10.769],
 [18, 25, 6.902],
 [18, 21, 2.979],
 [18, 20, 1.990],
 [19, 999, 54.757],
 [19, 69, 44.167],
 [19, 68, 43.476],
 [19, 67, 42.772],
 [19, 66, 42.055],
 [19, 65, 41.326],
 [19, 64, 40.584],
 [19, 63, 39.831],
 [19, 62, 39.066],
 [19, 61, 38.290],
 [19, 60, 37.503],
 [19, 59, 36.706],
 [19, 55, 33.425],
 [19, 50, 29.149],
 [19, 29, 9.806],
 [19, 25, 5.926],
 [19, 21, 1.989],
 [19, 20, 0.996],
 [20, 999, 53.949],
 [20, 69, 43.322],
 [20, 68, 42.629],
 [20, 67, 41.923],
 [20, 66, 41.203],
 [20, 65, 40.471],
 [20, 64, 39.727],
 [20, 63, 38.971],
 [20, 62, 38.203],
 [20, 61, 37.424],
 [20, 60, 36.635],
 [20, 59, 35.835],
 [20, 55, 32.543],
 [20, 50, 28.251],
 [20, 29, 8.840],
 [20, 25, 4.947],
 [20, 21, 0.996],
 [21, 999, 53.139],
 [21, 69, 42.475],
 [21, 68, 41.779],
 [21, 67, 41.070],
 [21, 66, 40.348],
 [21, 65, 39.614],
 [21, 64, 38.867],
 [21, 63, 38.108],
 [21, 62, 37.338],
 [21, 61, 36.556],
 [21, 60, 35.764],
 [21, 59, 34.961],
 [21, 55, 31.657],
 [21, 50, 27.351],
 [21, 29, 7.871],
 [21, 25, 3.964],
 [22, 999, 52.329],
 [22, 69, 41.627],
 [22, 68, 40.929],
 [22, 67, 40.217],
 [22, 66, 39.493],
 [22, 65, 38.755],
 [22, 64, 38.006],
 [22, 63, 37.244],
 [22, 62, 36.471],
 [22, 61, 35.687],
 [22, 60, 34.892],
 [22, 59, 34.086],
 [22, 55, 30.770],
 [22, 50, 26.449],
 [22, 29, 6.899],
 [22, 25, 2.979],
 [23, 999, 51.517],
 [23, 69, 40.776],
 [23, 68, 40.075],
 [23, 67, 39.361],
 [23, 66, 38.634],
 [23, 65, 37.894],
 [23, 64, 37.142],
 [23, 63, 36.378],
 [23, 62, 35.602],
 [23, 61, 34.815],
 [23, 60, 34.017],
 [23, 59, 33.208],
 [23, 55, 29.881],
 [23, 50, 25.543],
 [23, 29, 5.924],
 [23, 25, 1.989],
 [24, 999, 50.702],
 [24, 69, 39.922],
 [24, 68, 39.219],
 [24, 67, 38.503],
 [24, 66, 37.773],
 [24, 65, 37.030],
 [24, 64, 36.275],
 [24, 63, 35.508],
 [24, 62, 34.730],
 [24, 61, 33.940],
 [24, 60, 33.139],
 [24, 59, 32.328],
 [24, 55, 28.988],
 [24, 50, 24.635],
 [24, 29, 4.945],
 [24, 25, 0.996],
 [25, 999, 49.886],
 [25, 69, 39.067],
 [25, 68, 38.362],
 [25, 67, 37.642],
 [25, 66, 36.910],
 [25, 65, 36.165],
 [25, 64, 35.407],
 [25, 63, 34.637],
 [25, 62, 33.856],
 [25, 61, 33.063],
 [25, 60, 32.259],
 [25, 59, 31.445],
 [25, 55, 28.093],
 [25, 50, 23.724],
 [25, 29, 3.963],
 [26, 999, 49.068],
 [26, 69, 38.210],
 [26, 68, 37.502],
 [26, 67, 36.780],
 [26, 66, 36.045],
 [26, 65, 35.297],
 [26, 64, 34.536],
 [26, 63, 33.764],
 [26, 62, 32.979],
 [26, 61, 32.184],
 [26, 60, 31.377],
 [26, 59, 30.560],
 [26, 55, 27.196],
 [26, 50, 22.811],
 [26, 29, 2.978],
 [27, 999, 48.250],
 [27, 69, 37.352],
 [27, 68, 36.641],
 [27, 67, 35.916],
 [27, 66, 35.178],
 [27, 65, 34.428],
 [27, 64, 33.664],
 [27, 63, 32.889],
 [27, 62, 32.102],
 [27, 61, 31.303],
 [27, 60, 30.493],
 [27, 59, 29.673],
 [27, 55, 26.297],
 [27, 50, 21.896],
 [27, 29, 1.989],
 [28, 999, 47.429],
 [28, 69, 36.490],
 [28, 68, 35.777],
 [28, 67, 35.049],
 [28, 66, 34.309],
 [28, 65, 33.555],
 [28, 64, 32.789],
 [28, 63, 32.011],
 [28, 62, 31.221],
 [28, 61, 30.419],
 [28, 60, 29.606],
 [28, 59, 28.783],
 [28, 55, 25.394],
 [28, 50, 20.977],
 [28, 29, 0.996],
 [29, 999, 46.607],
 [29, 69, 35.627],
 [29, 68, 34.911],
 [29, 67, 34.181],
 [29, 66, 33.438],
 [29, 65, 32.681],
 [29, 64, 31.912],
 [29, 63, 31.131],
 [29, 62, 30.338],
 [29, 61, 29.533],
 [29, 60, 28.717],
 [29, 59, 27.891],
 [29, 55, 24.489],
 [29, 50, 20.056],
 [30, 999, 45.783],
 [30, 69, 34.761],
 [30, 68, 34.043],
 [30, 67, 33.310],
 [30, 66, 32.564],
 [30, 65, 31.805],
 [30, 64, 31.033],
 [30, 63, 30.249],
 [30, 62, 29.452],
 [30, 61, 28.645],
 [30, 60, 27.826],
 [30, 59, 26.996],
 [30, 55, 23.582],
 [30, 50, 19.131],
 [31, 999, 44.958],
 [31, 69, 33.894],
 [31, 68, 33.173],
 [31, 67, 32.437],
 [31, 66, 31.688],
 [31, 65, 30.926],
 [31, 64, 30.151],
 [31, 63, 29.364],
 [31, 62, 28.565],
 [31, 61, 27.754],
 [31, 60, 26.932],
 [31, 59, 26.100],
 [31, 55, 22.672],
 [31, 50, 18.204],
 [32, 999, 44.130],
 [32, 69, 33.024],
 [32, 68, 32.300],
 [32, 67, 31.561],
 [32, 66, 30.809],
 [32, 65, 30.044],
 [32, 64, 29.267],
 [32, 63, 28.477],
 [32, 62, 27.674],
 [32, 61, 26.860],
 [32, 60, 26.035],
 [32, 59, 25.199],
 [32, 55, 21.759],
 [32, 50, 17.274],
 [33, 999, 43.300],
 [33, 69, 32.152],
 [33, 68, 31.425],
 [33, 67, 30.683],
 [33, 66, 29.929],
 [33, 65, 29.161],
 [33, 64, 28.380],
 [33, 63, 27.587],
 [33, 62, 26.781],
 [33, 61, 25.964],
 [33, 60, 25.136],
 [33, 59, 24.297],
 [33, 55, 20.843],
 [33, 50, 16.341],
 [34, 999, 42.469],
 [34, 69, 31.277],
 [34, 68, 30.547],
 [34, 67, 29.803],
 [34, 66, 29.045],
 [34, 65, 28.274],
 [34, 64, 27.490],
 [34, 63, 26.694],
 [34, 62, 25.885],
 [34, 61, 25.065],
 [34, 60, 24.234],
 [34, 59, 23.391],
 [34, 55, 19.924],
 [34, 50, 15.404],
 [35, 999, 41.639],
 [35, 69, 30.402],
 [35, 68, 29.669],
 [35, 67, 28.922],
 [35, 66, 28.161],
 [35, 65, 27.387],
 [35, 64, 26.600],
 [35, 63, 25.801],
 [35, 62, 24.989],
 [35, 61, 24.165],
 [35, 60, 23.331],
 [35, 59, 22.485],
 [35, 55, 19.004],
 [35, 50, 14.466],
 [36, 999, 40.805],
 [36, 69, 29.523],
 [36, 68, 28.787],
 [36, 67, 28.037],
 [36, 66, 27.274],
 [36, 65, 26.496],
 [36, 64, 25.706],
 [36, 63, 24.904],
 [36, 62, 24.089],
 [36, 61, 23.262],
 [36, 60, 22.424],
 [36, 59, 21.575],
 [36, 55, 18.079],
 [36, 50, 13.524],
 [37, 999, 39.970],
 [37, 69, 28.643],
 [37, 68, 27.904],
 [37, 67, 27.151],
 [37, 66, 26.384],
 [37, 65, 25.604],
 [37, 64, 24.811],
 [37, 63, 24.005],
 [37, 62, 23.186],
 [37, 61, 22.356],
 [37, 60, 21.515],
 [37, 59, 20.662],
 [37, 55, 17.153],
 [37, 50, 12.579],
 [38, 999, 39.138],
 [38, 69, 27.763],
 [38, 68, 27.021],
 [38, 67, 26.265],
 [38, 66, 25.495],
 [38, 65, 24.711],
 [38, 64, 23.914],
 [38, 63, 23.105],
 [38, 62, 22.283],
 [38, 61, 21.450],
 [38, 60, 20.605],
 [38, 59, 19.749],
 [38, 55, 16.225],
 [38, 50, 11.631],
 [39, 999, 38.305],
 [39, 69, 26.881],
 [39, 68, 26.136],
 [39, 67, 25.377],
 [39, 66, 24.603],
 [39, 65, 23.816],
 [39, 64, 23.016],
 [39, 63, 22.204],
 [39, 62, 21.378],
 [39, 61, 20.541],
 [39, 60, 19.693],
 [39, 59, 18.833],
 [39, 55, 15.294],
 [39, 50, 10.681],
 [40, 999, 37.473],
 [40, 69, 26.000],
 [40, 68, 25.251],
 [40, 67, 24.488],
 [40, 66, 23.712],
 [40, 65, 22.921],
 [40, 64, 22.118],
 [40, 63, 21.301],
 [40, 62, 20.473],
 [40, 61, 19.632],
 [40, 60, 18.779],
 [40, 59, 17.916],
 [40, 55, 14.361],
 [40, 50, 9.728],
 [41, 999, 36.640],
 [41, 69, 25.115],
 [41, 68, 24.364],
 [41, 67, 23.597],
 [41, 66, 22.817],
 [41, 65, 22.023],
 [41, 64, 21.216],
 [41, 63, 20.396],
 [41, 62, 19.564],
 [41, 61, 18.719],
 [41, 60, 17.863],
 [41, 59, 16.996],
 [41, 55, 13.425],
 [41, 50, 8.771],
 [42, 999, 35.810],
 [42, 69, 24.232],
 [42, 68, 23.477],
 [42, 67, 22.707],
 [42, 66, 21.923],
 [42, 65, 21.125],
 [42, 64, 20.314],
 [42, 63, 19.491],
 [42, 62, 18.654],
 [42, 61, 17.806],
 [42, 60, 16.946],
 [42, 59, 16.074],
 [42, 55, 12.487],
 [42, 50, 7.812],
 [43, 999, 34.981],
 [43, 69, 23.348],
 [43, 68, 22.589],
 [43, 67, 21.816],
 [43, 66, 21.028],
 [43, 65, 20.227],
 [43, 64, 19.412],
 [43, 63, 18.584],
 [43, 62, 17.744],
 [43, 61, 16.891],
 [43, 60, 16.027],
 [43, 59, 15.151],
 [43, 55, 11.547],
 [43, 50, 6.849],
 [44, 999, 34.155],
 [44, 69, 22.464],
 [44, 68, 21.701],
 [44, 67, 20.924],
 [44, 66, 20.132],
 [44, 65, 19.327],
 [44, 64, 18.508],
 [44, 63, 17.676],
 [44, 62, 16.832],
 [44, 61, 15.975],
 [44, 60, 15.106],
 [44, 59, 14.227],
 [44, 55, 10.604],
 [44, 50, 5.883],
 [45, 999, 33.333],
 [45, 69, 21.581],
 [45, 68, 20.814],
 [45, 67, 20.033],
 [45, 66, 19.237],
 [45, 65, 18.427],
 [45, 64, 17.604],
 [45, 63, 16.768],
 [45, 62, 15.919],
 [45, 61, 15.058],
 [45, 60, 14.185],
 [45, 59, 13.300],
 [45, 55, 9.660],
 [45, 50, 4.914],
 [46, 999, 32.514],
 [46, 69, 20.698],
 [46, 68, 19.928],
 [46, 67, 19.142],
 [46, 66, 18.342],
 [46, 65, 17.528],
 [46, 64, 16.700],
 [46, 63, 15.860],
 [46, 62, 15.006],
 [46, 61, 14.140],
 [46, 60, 13.262],
 [46, 59, 12.373],
 [46, 55, 8.712],
 [46, 50, 3.941],
 [47, 999, 31.700],
 [47, 69, 19.816],
 [47, 68, 19.041],
 [47, 67, 18.251],
 [47, 66, 17.446],
 [47, 65, 16.628],
 [47, 64, 15.796],
 [47, 63, 14.950],
 [47, 62, 14.092],
 [47, 61, 13.221],
 [47, 60, 12.338],
 [47, 59, 11.443],
 [47, 55, 7.762],
 [47, 50, 2.963],
 [48, 999, 30.891],
 [48, 69, 18.936],
 [48, 68, 18.156],
 [48, 67, 17.361],
 [48, 66, 16.552],
 [48, 65, 15.728],
 [48, 64, 14.891],
 [48, 63, 14.040],
 [48, 62, 13.177],
 [48, 61, 12.300],
 [48, 60, 11.412],
 [48, 59, 10.512],
 [48, 55, 6.809],
 [48, 50, 1.981],
 [49, 999, 30.085],
 [49, 69, 18.054],
 [49, 68, 17.270],
 [49, 67, 16.470],
 [49, 66, 15.655],
 [49, 65, 14.827],
 [49, 64, 13.984],
 [49, 63, 13.128],
 [49, 62, 12.259],
 [49, 61, 11.378],
 [49, 60, 10.484],
 [49, 59, 9.578],
 [49, 55, 5.851],
 [49, 50, 0.993],
 [50, 999, 29.284],
 [50, 69, 17.174],
 [50, 68, 16.384],
 [50, 67, 15.579],
 [50, 66, 14.759],
 [50, 65, 13.925],
 [50, 64, 13.077],
 [50, 63, 12.215],
 [50, 62, 11.340],
 [50, 61, 10.453],
 [50, 60, 9.553],
 [50, 59, 8.642],
 [50, 55, 4.890],
 [51, 999, 28.486],
 [51, 69, 16.292],
 [51, 68, 15.497],
 [51, 67, 14.686],
 [51, 66, 13.861],
 [51, 65, 13.021],
 [51, 64, 12.167],
 [51, 63, 11.299],
 [51, 62, 10.419],
 [51, 61, 9.525],
 [51, 60, 8.619],
 [51, 59, 7.701],
 [51, 55, 3.924],
 [52, 999, 27.691],
 [52, 69, 15.410],
 [52, 68, 14.609],
 [52, 67, 13.792],
 [52, 66, 12.961],
 [52, 65, 12.115],
 [52, 64, 11.255],
 [52, 63, 10.381],
 [52, 62, 9.494],
 [52, 61, 8.594],
 [52, 60, 7.681],
 [52, 59, 6.757],
 [52, 55, 2.952],
 [53, 999, 26.905],
 [53, 69, 14.529],
 [53, 68, 13.722],
 [53, 67, 12.899],
 [53, 66, 12.061],
 [53, 65, 11.208],
 [53, 64, 10.342],
 [53, 63, 9.461],
 [53, 62, 8.567],
 [53, 61, 7.660],
 [53, 60, 6.741],
 [53, 59, 5.809],
 [53, 55, 1.975],
 [54, 999, 26.126],
 [54, 69, 13.648],
 [54, 68, 12.834],
 [54, 67, 12.005],
 [54, 66, 11.160],
 [54, 65, 10.300],
 [54, 64, 9.426],
 [54, 63, 8.539],
 [54, 62, 7.637],
 [54, 61, 6.723],
 [54, 60, 5.796],
 [54, 59, 4.857],
 [54, 55, 0.991],
 [55, 999, 25.359],
 [55, 69, 12.770],
 [55, 68, 11.949],
 [55, 67, 11.112],
 [55, 66, 10.259],
 [55, 65, 9.392],
 [55, 64, 8.511],
 [55, 63, 7.615],
 [55, 62, 6.706],
 [55, 61, 5.783],
 [55, 60, 4.848],
 [55, 59, 3.900],
 [56, 999, 24.600],
 [56, 69, 11.891],
 [56, 68, 11.062],
 [56, 67, 10.217],
 [56, 66, 9.357],
 [56, 65, 8.482],
 [56, 64, 7.592],
 [56, 63, 6.687],
 [56, 62, 5.769],
 [56, 61, 4.838],
 [56, 60, 3.894],
 [56, 59, 2.937],
 [57, 999, 23.851],
 [57, 69, 11.013],
 [57, 68, 10.175],
 [57, 67, 9.322],
 [57, 66, 8.452],
 [57, 65, 7.568],
 [57, 64, 6.669],
 [57, 63, 5.756],
 [57, 62, 4.828],
 [57, 61, 3.887],
 [57, 60, 2.933],
 [57, 59, 1.967],
 [58, 999, 23.111],
 [58, 69, 10.133],
 [58, 68, 9.286],
 [58, 67, 8.423],
 [58, 66, 7.545],
 [58, 65, 6.651],
 [58, 64, 5.742],
 [58, 63, 4.818],
 [58, 62, 3.881],
 [58, 61, 2.930],
 [58, 60, 1.965],
 [58, 59, 0.989],
 [59, 999, 22.376],
 [59, 69, 9.249],
 [59, 68, 8.393],
 [59, 67, 7.520],
 [59, 66, 6.631],
 [59, 65, 5.727],
 [59, 64, 4.808],
 [59, 63, 3.874],
 [59, 62, 2.925],
 [59, 61, 1.963],
 [59, 60, 0.988],
 [60, 999, 21.648],
 [60, 69, 8.361],
 [60, 68, 7.495],
 [60, 67, 6.611],
 [60, 66, 5.712],
 [60, 65, 4.797],
 [60, 64, 3.866],
 [60, 63, 2.921],
 [60, 62, 1.961],
 [60, 61, 0.987],
 [61, 999, 20.930],
 [61, 69, 7.470],
 [61, 68, 6.592],
 [61, 67, 5.697],
 [61, 66, 4.786],
 [61, 65, 3.859],
 [61, 64, 2.916],
 [61, 63, 1.959],
 [61, 62, 0.986],
 [62, 999, 20.218],
 [62, 69, 6.573],
 [62, 68, 5.683],
 [62, 67, 4.776],
 [62, 66, 3.852],
 [62, 65, 2.912],
 [62, 64, 1.956],
 [62, 63, 0.986],
 [63, 999, 19.512],
 [63, 69, 5.668],
 [63, 68, 4.766],
 [63, 67, 3.845],
 [63, 66, 2.908],
 [63, 65, 1.954],
 [63, 64, 0.985],
 [64, 999, 18.811],
 [64, 69, 4.755],
 [64, 68, 3.839],
 [64, 67, 2.904],
 [64, 66, 1.953],
 [64, 65, 0.984],
 [65, 999, 18.109],
 [65, 69, 3.831],
 [65, 68, 2.900],
 [65, 67, 1.950],
 [65, 66, 0.984],
 [66, 999, 17.412],
 [66, 69, 2.895],
 [66, 68, 1.948],
 [66, 67, 0.983],
 [67, 999, 16.716],
 [67, 69, 1.945],
 [67, 68, 0.982],
 [68, 999, 16.021],
 [68, 69, 0.981],
 [69, 999, 15.333],
 [70, 999, 14.652],
 [71, 999, 13.979],
 [72, 999, 13.303],
 [73, 999, 12.637],
 [74, 999, 11.978],
 [75, 999, 11.325],
 [76, 999, 10.681],
 [77, 999, 10.049],
 [78, 999, 9.429],
 [79, 999, 8.823],
 [80, 999, 8.237],
 [81, 999, 7.668],
 [82, 999, 7.123],
 [83, 999, 6.597],
 [84, 999, 6.094],
 [85, 999, 5.617],
 [86, 999, 5.170],
 [87, 999, 4.746],
 [88, 999, 4.356],
 [89, 999, 3.992],
 [90, 999, 3.646],
 [91, 999, 3.328],
 [92, 999, 3.025],
 [93, 999, 2.762],
 [94, 999, 2.529],
 [95, 999, 2.314],
 [96, 999, 2.106],
 [97, 999, 1.909],
 [98, 999, 1.742],
 [99, 999, 1.604],
 [100, 999, 1.443],
 [101, 999, 1.242],
 [102, 999, 1.001],
 [103, 999, 0.631]
]);
gazPal2020tx03.set("F", [
 [0, 999, 74.543],
 [0, 69, 60.962],
 [0, 68, 60.231],
 [0, 67, 59.492],
 [0, 66, 58.746],
 [0, 65, 57.993],
 [0, 64, 57.233],
 [0, 63, 56.466],
 [0, 62, 55.693],
 [0, 61, 54.914],
 [0, 60, 54.129],
 [0, 59, 53.338],
 [0, 55, 50.119],
 [0, 50, 45.985],
 [0, 29, 27.601],
 [0, 25, 23.943],
 [0, 21, 20.237],
 [0, 20, 19.303],
 [0, 18, 17.426],
 [0, 16, 15.537],
 [1, 999, 74.004],
 [1, 69, 60.339],
 [1, 68, 59.603],
 [1, 67, 58.860],
 [1, 66, 58.109],
 [1, 65, 57.352],
 [1, 64, 56.587],
 [1, 63, 55.816],
 [1, 62, 55.038],
 [1, 61, 54.254],
 [1, 60, 53.464],
 [1, 59, 52.668],
 [1, 55, 49.429],
 [1, 50, 45.269],
 [1, 29, 26.772],
 [1, 25, 23.091],
 [1, 21, 19.362],
 [1, 20, 18.422],
 [1, 18, 16.534],
 [1, 16, 14.634],
 [2, 999, 73.245],
 [2, 69, 59.535],
 [2, 68, 58.797],
 [2, 67, 58.051],
 [2, 66, 57.298],
 [2, 65, 56.538],
 [2, 64, 55.771],
 [2, 63, 54.997],
 [2, 62, 54.217],
 [2, 61, 53.430],
 [2, 60, 52.637],
 [2, 59, 51.839],
 [2, 55, 48.589],
 [2, 50, 44.416],
 [2, 29, 25.859],
 [2, 25, 22.166],
 [2, 21, 18.425],
 [2, 20, 17.482],
 [2, 18, 15.588],
 [2, 16, 13.681],
 [3, 999, 72.476],
 [3, 69, 58.723],
 [3, 68, 57.982],
 [3, 67, 57.234],
 [3, 66, 56.479],
 [3, 65, 55.716],
 [3, 64, 54.947],
 [3, 63, 54.170],
 [3, 62, 53.388],
 [3, 61, 52.599],
 [3, 60, 51.803],
 [3, 59, 51.002],
 [3, 55, 47.743],
 [3, 50, 43.556],
 [3, 29, 24.941],
 [3, 25, 21.236],
 [3, 21, 17.483],
 [3, 20, 16.537],
 [3, 18, 14.637],
 [3, 16, 12.724],
 [4, 999, 71.701],
 [4, 69, 57.906],
 [4, 68, 57.163],
 [4, 67, 56.412],
 [4, 66, 55.655],
 [4, 65, 54.890],
 [4, 64, 54.118],
 [4, 63, 53.339],
 [4, 62, 52.554],
 [4, 61, 51.762],
 [4, 60, 50.964],
 [4, 59, 50.161],
 [4, 55, 46.891],
 [4, 50, 42.692],
 [4, 29, 24.018],
 [4, 25, 20.302],
 [4, 21, 16.538],
 [4, 20, 15.589],
 [4, 18, 13.682],
 [4, 16, 11.764],
 [5, 999, 70.922],
 [5, 69, 57.084],
 [5, 68, 56.339],
 [5, 67, 55.586],
 [5, 66, 54.826],
 [5, 65, 54.059],
 [5, 64, 53.284],
 [5, 63, 52.503],
 [5, 62, 51.716],
 [5, 61, 50.922],
 [5, 60, 50.121],
 [5, 59, 49.315],
 [5, 55, 46.036],
 [5, 50, 41.823],
 [5, 29, 23.092],
 [5, 25, 19.365],
 [5, 21, 15.589],
 [5, 20, 14.637],
 [5, 18, 12.725],
 [5, 16, 10.800],
 [6, 999, 70.140],
 [6, 69, 56.259],
 [6, 68, 55.512],
 [6, 67, 54.757],
 [6, 66, 53.994],
 [6, 65, 53.225],
 [6, 64, 52.448],
 [6, 63, 51.664],
 [6, 62, 50.874],
 [6, 61, 50.078],
 [6, 60, 49.275],
 [6, 59, 48.467],
 [6, 55, 45.177],
 [6, 50, 40.952],
 [6, 29, 22.163],
 [6, 25, 18.424],
 [6, 21, 14.636],
 [6, 20, 13.682],
 [6, 18, 11.764],
 [6, 16, 9.833],
 [7, 999, 69.355],
 [7, 69, 55.432],
 [7, 68, 54.682],
 [7, 67, 53.925],
 [7, 66, 53.160],
 [7, 65, 52.388],
 [7, 64, 51.609],
 [7, 63, 50.823],
 [7, 62, 50.031],
 [7, 61, 49.232],
 [7, 60, 48.427],
 [7, 59, 47.616],
 [7, 55, 44.316],
 [7, 50, 40.078],
 [7, 29, 21.231],
 [7, 25, 17.481],
 [7, 21, 13.681],
 [7, 20, 12.724],
 [7, 18, 10.800],
 [7, 16, 8.863],
 [8, 999, 68.568],
 [8, 69, 54.602],
 [8, 68, 53.850],
 [8, 67, 53.091],
 [8, 66, 52.323],
 [8, 65, 51.549],
 [8, 64, 50.767],
 [8, 63, 49.979],
 [8, 62, 49.184],
 [8, 61, 48.383],
 [8, 60, 47.575],
 [8, 59, 46.762],
 [8, 55, 43.452],
 [8, 50, 39.201],
 [8, 29, 20.296],
 [8, 25, 16.534],
 [8, 21, 12.723],
 [8, 20, 11.763],
 [8, 18, 9.833],
 [8, 16, 7.891],
 [9, 999, 67.778],
 [9, 69, 53.769],
 [9, 68, 53.015],
 [9, 67, 52.253],
 [9, 66, 51.483],
 [9, 65, 50.707],
 [9, 64, 49.923],
 [9, 63, 49.132],
 [9, 62, 48.335],
 [9, 61, 47.531],
 [9, 60, 46.721],
 [9, 59, 45.905],
 [9, 55, 42.585],
 [9, 50, 38.321],
 [9, 29, 19.358],
 [9, 25, 15.585],
 [9, 21, 11.762],
 [9, 20, 10.799],
 [9, 18, 8.863],
 [9, 16, 6.915],
 [10, 999, 66.986],
 [10, 69, 52.934],
 [10, 68, 52.178],
 [10, 67, 51.413],
 [10, 66, 50.642],
 [10, 65, 49.862],
 [10, 64, 49.076],
 [10, 63, 48.283],
 [10, 62, 47.483],
 [10, 61, 46.677],
 [10, 60, 45.864],
 [10, 59, 45.046],
 [10, 55, 41.715],
 [10, 50, 37.438],
 [10, 29, 18.418],
 [10, 25, 14.633],
 [10, 21, 10.798],
 [10, 20, 9.832],
 [10, 18, 7.890],
 [10, 16, 5.936],
 [11, 999, 66.191],
 [11, 69, 52.096],
 [11, 68, 51.338],
 [11, 67, 50.571],
 [11, 66, 49.797],
 [11, 65, 49.015],
 [11, 64, 48.226],
 [11, 63, 47.431],
 [11, 62, 46.628],
 [11, 61, 45.820],
 [11, 60, 45.005],
 [11, 59, 44.184],
 [11, 55, 40.843],
 [11, 50, 36.553],
 [11, 29, 17.474],
 [11, 25, 13.678],
 [11, 21, 9.831],
 [11, 20, 8.862],
 [11, 18, 6.914],
 [11, 16, 4.954],
 [12, 999, 65.394],
 [12, 69, 51.256],
 [12, 68, 50.495],
 [12, 67, 49.726],
 [12, 66, 48.949],
 [12, 65, 48.165],
 [12, 64, 47.374],
 [12, 63, 46.576],
 [12, 62, 45.772],
 [12, 61, 44.960],
 [12, 60, 44.143],
 [12, 59, 43.319],
 [12, 55, 39.969],
 [12, 50, 35.665],
 [12, 29, 16.528],
 [12, 25, 12.720],
 [12, 21, 8.862],
 [12, 20, 7.889],
 [12, 18, 5.936],
 [12, 16, 3.969],
 [13, 999, 64.595],
 [13, 69, 50.413],
 [13, 68, 49.650],
 [13, 67, 48.878],
 [13, 66, 48.099],
 [13, 65, 47.313],
 [13, 64, 46.519],
 [13, 63, 45.719],
 [13, 62, 44.912],
 [13, 61, 44.098],
 [13, 60, 43.278],
 [13, 59, 42.452],
 [13, 55, 39.091],
 [13, 50, 34.774],
 [13, 29, 15.578],
 [13, 25, 11.758],
 [13, 21, 7.889],
 [13, 20, 6.914],
 [13, 18, 4.954],
 [13, 16, 2.982],
 [14, 999, 63.794],
 [14, 69, 49.568],
 [14, 68, 48.803],
 [14, 67, 48.029],
 [14, 66, 47.247],
 [14, 65, 46.459],
 [14, 64, 45.663],
 [14, 63, 44.860],
 [14, 62, 44.050],
 [14, 61, 43.234],
 [14, 60, 42.411],
 [14, 59, 41.583],
 [14, 55, 38.211],
 [14, 50, 33.881],
 [14, 29, 14.626],
 [14, 25, 10.795],
 [14, 21, 6.913],
 [14, 20, 5.935],
 [14, 18, 3.969],
 [14, 16, 1.991],
 [15, 999, 62.991],
 [15, 69, 48.722],
 [15, 68, 47.954],
 [15, 67, 47.178],
 [15, 66, 46.394],
 [15, 65, 45.603],
 [15, 64, 44.804],
 [15, 63, 43.999],
 [15, 62, 43.187],
 [15, 61, 42.368],
 [15, 60, 41.543],
 [15, 59, 40.712],
 [15, 55, 37.330],
 [15, 50, 32.986],
 [15, 29, 13.672],
 [15, 25, 9.828],
 [15, 21, 5.934],
 [15, 20, 4.953],
 [15, 18, 2.981],
 [15, 16, 0.997],
 [16, 999, 62.188],
 [16, 69, 47.874],
 [16, 68, 47.104],
 [16, 67, 46.325],
 [16, 66, 45.539],
 [16, 65, 44.745],
 [16, 64, 43.944],
 [16, 63, 43.136],
 [16, 62, 42.321],
 [16, 61, 41.500],
 [16, 60, 40.673],
 [16, 59, 39.839],
 [16, 55, 36.446],
 [16, 50, 32.089],
 [16, 29, 12.715],
 [16, 25, 8.859],
 [16, 21, 4.953],
 [16, 20, 3.969],
 [16, 18, 1.991],
 [17, 999, 61.383],
 [17, 69, 47.024],
 [17, 68, 46.251],
 [17, 67, 45.470],
 [17, 66, 44.681],
 [17, 65, 43.885],
 [17, 64, 43.082],
 [17, 63, 42.271],
 [17, 62, 41.454],
 [17, 61, 40.630],
 [17, 60, 39.800],
 [17, 59, 38.964],
 [17, 55, 35.560],
 [17, 50, 31.190],
 [17, 29, 11.754],
 [17, 25, 7.887],
 [17, 21, 3.969],
 [17, 20, 2.981],
 [17, 18, 0.997],
 [18, 999, 60.575],
 [18, 69, 46.172],
 [18, 68, 45.396],
 [18, 67, 44.613],
 [18, 66, 43.822],
 [18, 65, 43.023],
 [18, 64, 42.217],
 [18, 63, 41.404],
 [18, 62, 40.584],
 [18, 61, 39.758],
 [18, 60, 38.925],
 [18, 59, 38.086],
 [18, 55, 34.672],
 [18, 50, 30.288],
 [18, 29, 10.791],
 [18, 25, 6.911],
 [18, 21, 2.981],
 [18, 20, 1.991],
 [19, 999, 59.768],
 [19, 69, 45.318],
 [19, 68, 44.540],
 [19, 67, 43.754],
 [19, 66, 42.961],
 [19, 65, 42.159],
 [19, 64, 41.351],
 [19, 63, 40.535],
 [19, 62, 39.713],
 [19, 61, 38.884],
 [19, 60, 38.048],
 [19, 59, 37.207],
 [19, 55, 33.782],
 [19, 50, 29.384],
 [19, 29, 9.825],
 [19, 25, 5.933],
 [19, 21, 1.990],
 [19, 20, 0.997],
 [20, 999, 58.958],
 [20, 69, 44.463],
 [20, 68, 43.683],
 [20, 67, 42.894],
 [20, 66, 42.098],
 [20, 65, 41.294],
 [20, 64, 40.483],
 [20, 63, 39.665],
 [20, 62, 38.840],
 [20, 61, 38.008],
 [20, 60, 37.170],
 [20, 59, 36.325],
 [20, 55, 32.890],
 [20, 50, 28.478],
 [20, 29, 8.857],
 [20, 25, 4.952],
 [20, 21, 0.997],
 [21, 999, 58.149],
 [21, 69, 43.607],
 [21, 68, 42.824],
 [21, 67, 42.033],
 [21, 66, 41.234],
 [21, 65, 40.427],
 [21, 64, 39.614],
 [21, 63, 38.793],
 [21, 62, 37.965],
 [21, 61, 37.131],
 [21, 60, 36.290],
 [21, 59, 35.443],
 [21, 55, 31.996],
 [21, 50, 27.570],
 [21, 29, 7.885],
 [21, 25, 3.968],
 [22, 999, 57.334],
 [22, 69, 42.746],
 [22, 68, 41.960],
 [22, 67, 41.167],
 [22, 66, 40.365],
 [22, 65, 39.556],
 [22, 64, 38.740],
 [22, 63, 37.917],
 [22, 62, 37.086],
 [22, 61, 36.249],
 [22, 60, 35.406],
 [22, 59, 34.556],
 [22, 55, 31.098],
 [22, 50, 26.658],
 [22, 29, 6.911],
 [22, 25, 2.981],
 [23, 999, 56.518],
 [23, 69, 41.883],
 [23, 68, 41.095],
 [23, 67, 40.299],
 [23, 66, 39.495],
 [23, 65, 38.683],
 [23, 64, 37.864],
 [23, 63, 37.038],
 [23, 62, 36.205],
 [23, 61, 35.365],
 [23, 60, 34.519],
 [23, 59, 33.667],
 [23, 55, 30.198],
 [23, 50, 25.743],
 [23, 29, 5.933],
 [23, 25, 1.990],
 [24, 999, 55.700],
 [24, 69, 41.018],
 [24, 68, 40.227],
 [24, 67, 39.428],
 [24, 66, 38.622],
 [24, 65, 37.808],
 [24, 64, 36.986],
 [24, 63, 36.157],
 [24, 62, 35.322],
 [24, 61, 34.479],
 [24, 60, 33.630],
 [24, 59, 32.775],
 [24, 55, 29.295],
 [24, 50, 24.826],
 [24, 29, 4.952],
 [24, 25, 0.997],
 [25, 999, 54.880],
 [25, 69, 40.150],
 [25, 68, 39.357],
 [25, 67, 38.555],
 [25, 66, 37.746],
 [25, 65, 36.930],
 [25, 64, 36.105],
 [25, 63, 35.274],
 [25, 62, 34.435],
 [25, 61, 33.590],
 [25, 60, 32.739],
 [25, 59, 31.881],
 [25, 55, 28.390],
 [25, 50, 23.906],
 [25, 29, 3.968],
 [26, 999, 54.056],
 [26, 69, 39.279],
 [26, 68, 38.484],
 [26, 67, 37.680],
 [26, 66, 36.868],
 [26, 65, 36.049],
 [26, 64, 35.222],
 [26, 63, 34.388],
 [26, 62, 33.546],
 [26, 61, 32.699],
 [26, 60, 31.844],
 [26, 59, 30.983],
 [26, 55, 27.481],
 [26, 50, 22.983],
 [26, 29, 2.981],
 [27, 999, 53.232],
 [27, 69, 38.407],
 [27, 68, 37.609],
 [27, 67, 36.802],
 [27, 66, 35.988],
 [27, 65, 35.166],
 [27, 64, 34.336],
 [27, 63, 33.499],
 [27, 62, 32.656],
 [27, 61, 31.805],
 [27, 60, 30.948],
 [27, 59, 30.084],
 [27, 55, 26.570],
 [27, 50, 22.058],
 [27, 29, 1.990],
 [28, 999, 52.405],
 [28, 69, 37.531],
 [28, 68, 36.731],
 [28, 67, 35.922],
 [28, 66, 35.105],
 [28, 65, 34.280],
 [28, 64, 33.448],
 [28, 63, 32.608],
 [28, 62, 31.761],
 [28, 61, 30.908],
 [28, 60, 30.048],
 [28, 59, 29.182],
 [28, 55, 25.657],
 [28, 50, 21.129],
 [28, 29, 0.997],
 [29, 999, 51.576],
 [29, 69, 36.654],
 [29, 68, 35.851],
 [29, 67, 35.039],
 [29, 66, 34.219],
 [29, 65, 33.392],
 [29, 64, 32.557],
 [29, 63, 31.715],
 [29, 62, 30.865],
 [29, 61, 30.009],
 [29, 60, 29.146],
 [29, 59, 28.277],
 [29, 55, 24.740],
 [29, 50, 20.198],
 [30, 999, 50.747],
 [30, 69, 35.776],
 [30, 68, 34.970],
 [30, 67, 34.155],
 [30, 66, 33.333],
 [30, 65, 32.503],
 [30, 64, 31.665],
 [30, 63, 30.820],
 [30, 62, 29.968],
 [30, 61, 29.109],
 [30, 60, 28.243],
 [30, 59, 27.371],
 [30, 55, 23.822],
 [30, 50, 19.265],
 [31, 999, 49.914],
 [31, 69, 34.894],
 [31, 68, 34.085],
 [31, 67, 33.268],
 [31, 66, 32.443],
 [31, 65, 31.610],
 [31, 64, 30.769],
 [31, 63, 29.921],
 [31, 62, 29.066],
 [31, 61, 28.204],
 [31, 60, 27.336],
 [31, 59, 26.461],
 [31, 55, 22.901],
 [31, 50, 18.329],
 [32, 999, 49.081],
 [32, 69, 34.010],
 [32, 68, 33.198],
 [32, 67, 32.379],
 [32, 66, 31.551],
 [32, 65, 30.715],
 [32, 64, 29.872],
 [32, 63, 29.021],
 [32, 62, 28.163],
 [32, 61, 27.299],
 [32, 60, 26.427],
 [32, 59, 25.549],
 [32, 55, 21.977],
 [32, 50, 17.390],
 [33, 999, 48.245],
 [33, 69, 33.124],
 [33, 68, 32.310],
 [33, 67, 31.487],
 [33, 66, 30.657],
 [33, 65, 29.818],
 [33, 64, 28.972],
 [33, 63, 28.118],
 [33, 62, 27.258],
 [33, 61, 26.390],
 [33, 60, 25.516],
 [33, 59, 24.635],
 [33, 55, 21.051],
 [33, 50, 16.448],
 [34, 999, 47.408],
 [34, 69, 32.236],
 [34, 68, 31.419],
 [34, 67, 30.593],
 [34, 66, 29.760],
 [34, 65, 28.919],
 [34, 64, 28.070],
 [34, 63, 27.213],
 [34, 62, 26.350],
 [34, 61, 25.479],
 [34, 60, 24.602],
 [34, 59, 23.718],
 [34, 55, 20.122],
 [34, 50, 15.504],
 [35, 999, 46.569],
 [35, 69, 31.345],
 [35, 68, 30.525],
 [35, 67, 29.697],
 [35, 66, 28.861],
 [35, 65, 28.017],
 [35, 64, 27.165],
 [35, 63, 26.305],
 [35, 62, 25.439],
 [35, 61, 24.565],
 [35, 60, 23.685],
 [35, 59, 22.798],
 [35, 55, 19.190],
 [35, 50, 14.556],
 [36, 999, 45.728],
 [36, 69, 30.452],
 [36, 68, 29.629],
 [36, 67, 28.798],
 [36, 66, 27.959],
 [36, 65, 27.112],
 [36, 64, 26.257],
 [36, 63, 25.395],
 [36, 62, 24.526],
 [36, 61, 23.649],
 [36, 60, 22.766],
 [36, 59, 21.876],
 [36, 55, 18.256],
 [36, 50, 13.606],
 [37, 999, 44.888],
 [37, 69, 29.559],
 [37, 68, 28.733],
 [37, 67, 27.899],
 [37, 66, 27.057],
 [37, 65, 26.207],
 [37, 64, 25.350],
 [37, 63, 24.484],
 [37, 62, 23.612],
 [37, 61, 22.732],
 [37, 60, 21.846],
 [37, 59, 20.953],
 [37, 55, 17.320],
 [37, 50, 12.654],
 [38, 999, 44.048],
 [38, 69, 28.664],
 [38, 68, 27.836],
 [38, 67, 26.999],
 [38, 66, 26.154],
 [38, 65, 25.301],
 [38, 64, 24.440],
 [38, 63, 23.572],
 [38, 62, 22.696],
 [38, 61, 21.813],
 [38, 60, 20.924],
 [38, 59, 20.028],
 [38, 55, 16.381],
 [38, 50, 11.699],
 [39, 999, 43.208],
 [39, 69, 27.768],
 [39, 68, 26.937],
 [39, 67, 26.097],
 [39, 66, 25.249],
 [39, 65, 24.392],
 [39, 64, 23.528],
 [39, 63, 22.657],
 [39, 62, 21.778],
 [39, 61, 20.892],
 [39, 60, 20.000],
 [39, 59, 19.100],
 [39, 55, 15.441],
 [39, 50, 10.741],
 [40, 999, 42.367],
 [40, 69, 26.870],
 [40, 68, 26.036],
 [40, 67, 25.193],
 [40, 66, 24.341],
 [40, 65, 23.482],
 [40, 64, 22.615],
 [40, 63, 21.740],
 [40, 62, 20.858],
 [40, 61, 19.969],
 [40, 60, 19.073],
 [40, 59, 18.171],
 [40, 55, 14.498],
 [40, 50, 9.781],
 [41, 999, 41.524],
 [41, 69, 25.970],
 [41, 68, 25.133],
 [41, 67, 24.286],
 [41, 66, 23.432],
 [41, 65, 22.570],
 [41, 64, 21.699],
 [41, 63, 20.821],
 [41, 62, 19.936],
 [41, 61, 19.043],
 [41, 60, 18.144],
 [41, 59, 17.238],
 [41, 55, 13.552],
 [41, 50, 8.817],
 [42, 999, 40.683],
 [42, 69, 25.069],
 [42, 68, 24.228],
 [42, 67, 23.379],
 [42, 66, 22.521],
 [42, 65, 21.656],
 [42, 64, 20.782],
 [42, 63, 19.901],
 [42, 62, 19.012],
 [42, 61, 18.116],
 [42, 60, 17.213],
 [42, 59, 16.304],
 [42, 55, 12.603],
 [42, 50, 7.851],
 [43, 999, 39.840],
 [43, 69, 24.166],
 [43, 68, 23.322],
 [43, 67, 22.470],
 [43, 66, 21.609],
 [43, 65, 20.740],
 [43, 64, 19.862],
 [43, 63, 18.978],
 [43, 62, 18.086],
 [43, 61, 17.186],
 [43, 60, 16.280],
 [43, 59, 15.367],
 [43, 55, 11.652],
 [43, 50, 6.881],
 [44, 999, 39.002],
 [44, 69, 23.264],
 [44, 68, 22.417],
 [44, 67, 21.561],
 [44, 66, 20.696],
 [44, 65, 19.824],
 [44, 64, 18.943],
 [44, 63, 18.055],
 [44, 62, 17.159],
 [44, 61, 16.256],
 [44, 60, 15.346],
 [44, 59, 14.429],
 [44, 55, 10.699],
 [44, 50, 5.909],
 [45, 999, 38.164],
 [45, 69, 22.361],
 [45, 68, 21.510],
 [45, 67, 20.651],
 [45, 66, 19.783],
 [45, 65, 18.906],
 [45, 64, 18.022],
 [45, 63, 17.130],
 [45, 62, 16.230],
 [45, 61, 15.324],
 [45, 60, 14.410],
 [45, 59, 13.490],
 [45, 55, 9.744],
 [45, 50, 4.934],
 [46, 999, 37.328],
 [46, 69, 21.457],
 [46, 68, 20.602],
 [46, 67, 19.739],
 [46, 66, 18.867],
 [46, 65, 17.987],
 [46, 64, 17.099],
 [46, 63, 16.203],
 [46, 62, 15.300],
 [46, 61, 14.389],
 [46, 60, 13.472],
 [46, 59, 12.547],
 [46, 55, 8.786],
 [46, 50, 3.955],
 [47, 999, 36.492],
 [47, 69, 20.551],
 [47, 68, 19.693],
 [47, 67, 18.826],
 [47, 66, 17.950],
 [47, 65, 17.066],
 [47, 64, 16.174],
 [47, 63, 15.275],
 [47, 62, 14.367],
 [47, 61, 13.453],
 [47, 60, 12.531],
 [47, 59, 11.603],
 [47, 55, 7.824],
 [47, 50, 2.972],
 [48, 999, 35.659],
 [48, 69, 19.645],
 [48, 68, 18.783],
 [48, 67, 17.912],
 [48, 66, 17.032],
 [48, 65, 16.144],
 [48, 64, 15.248],
 [48, 63, 14.344],
 [48, 62, 13.433],
 [48, 61, 12.514],
 [48, 60, 11.588],
 [48, 59, 10.656],
 [48, 55, 6.860],
 [48, 50, 1.986],
 [49, 999, 34.830],
 [49, 69, 18.739],
 [49, 68, 17.873],
 [49, 67, 16.998],
 [49, 66, 16.114],
 [49, 65, 15.222],
 [49, 64, 14.321],
 [49, 63, 13.413],
 [49, 62, 12.497],
 [49, 61, 11.574],
 [49, 60, 10.644],
 [49, 59, 9.707],
 [49, 55, 5.893],
 [49, 50, 0.995],
 [50, 999, 33.998],
 [50, 69, 17.830],
 [50, 68, 16.960],
 [50, 67, 16.080],
 [50, 66, 15.192],
 [50, 65, 14.296],
 [50, 64, 13.391],
 [50, 63, 12.478],
 [50, 62, 11.558],
 [50, 61, 10.630],
 [50, 60, 9.695],
 [50, 59, 8.754],
 [50, 55, 4.921],
 [51, 999, 33.171],
 [51, 69, 16.921],
 [51, 68, 16.046],
 [51, 67, 15.162],
 [51, 66, 14.269],
 [51, 65, 13.368],
 [51, 64, 12.459],
 [51, 63, 11.541],
 [51, 62, 10.617],
 [51, 61, 9.684],
 [51, 60, 8.745],
 [51, 59, 7.798],
 [51, 55, 3.946],
 [52, 999, 32.345],
 [52, 69, 16.009],
 [52, 68, 15.130],
 [52, 67, 14.241],
 [52, 66, 13.344],
 [52, 65, 12.438],
 [52, 64, 11.524],
 [52, 63, 10.602],
 [52, 62, 9.672],
 [52, 61, 8.735],
 [52, 60, 7.790],
 [52, 59, 6.839],
 [52, 55, 2.967],
 [53, 999, 31.519],
 [53, 69, 15.095],
 [53, 68, 14.211],
 [53, 67, 13.318],
 [53, 66, 12.416],
 [53, 65, 11.505],
 [53, 64, 10.586],
 [53, 63, 9.659],
 [53, 62, 8.724],
 [53, 61, 7.782],
 [53, 60, 6.832],
 [53, 59, 5.876],
 [53, 55, 1.983],
 [54, 999, 30.695],
 [54, 69, 14.180],
 [54, 68, 13.291],
 [54, 67, 12.392],
 [54, 66, 11.485],
 [54, 65, 10.569],
 [54, 64, 9.645],
 [54, 63, 8.713],
 [54, 62, 7.773],
 [54, 61, 6.825],
 [54, 60, 5.870],
 [54, 59, 4.909],
 [54, 55, 0.994],
 [55, 999, 29.876],
 [55, 69, 13.263],
 [55, 68, 12.369],
 [55, 67, 11.465],
 [55, 66, 10.553],
 [55, 65, 9.632],
 [55, 64, 8.702],
 [55, 63, 7.764],
 [55, 62, 6.819],
 [55, 61, 5.866],
 [55, 60, 4.905],
 [55, 59, 3.937],
 [56, 999, 29.058],
 [56, 69, 12.344],
 [56, 68, 11.444],
 [56, 67, 10.535],
 [56, 66, 9.617],
 [56, 65, 8.690],
 [56, 64, 7.755],
 [56, 63, 6.812],
 [56, 62, 5.860],
 [56, 61, 4.901],
 [56, 60, 3.935],
 [56, 59, 2.961],
 [57, 999, 28.241],
 [57, 69, 11.422],
 [57, 68, 10.516],
 [57, 67, 9.601],
 [57, 66, 8.677],
 [57, 65, 7.745],
 [57, 64, 6.804],
 [57, 63, 5.854],
 [57, 62, 4.897],
 [57, 61, 3.932],
 [57, 60, 2.960],
 [57, 59, 1.980],
 [58, 999, 27.428],
 [58, 69, 10.498],
 [58, 68, 9.586],
 [58, 67, 8.665],
 [58, 66, 7.735],
 [58, 65, 6.796],
 [58, 64, 5.849],
 [58, 63, 4.893],
 [58, 62, 3.930],
 [58, 61, 2.958],
 [58, 60, 1.979],
 [58, 59, 0.993],
 [59, 999, 26.616],
 [59, 69, 9.569],
 [59, 68, 8.652],
 [59, 67, 7.724],
 [59, 66, 6.788],
 [59, 65, 5.843],
 [59, 64, 4.889],
 [59, 63, 3.927],
 [59, 62, 2.956],
 [59, 61, 1.978],
 [59, 60, 0.993],
 [60, 999, 25.807],
 [60, 69, 8.638],
 [60, 68, 7.714],
 [60, 67, 6.780],
 [60, 66, 5.837],
 [60, 65, 4.885],
 [60, 64, 3.924],
 [60, 63, 2.955],
 [60, 62, 1.978],
 [60, 61, 0.993],
 [61, 999, 24.998],
 [61, 69, 7.702],
 [61, 68, 6.771],
 [61, 67, 5.830],
 [61, 66, 4.880],
 [61, 65, 3.921],
 [61, 64, 2.953],
 [61, 63, 1.977],
 [61, 62, 0.992],
 [62, 999, 24.191],
 [62, 69, 6.762],
 [62, 68, 5.823],
 [62, 67, 4.875],
 [62, 66, 3.918],
 [62, 65, 2.951],
 [62, 64, 1.976],
 [62, 63, 0.992],
 [63, 999, 23.385],
 [63, 69, 5.816],
 [63, 68, 4.870],
 [63, 67, 3.914],
 [63, 66, 2.949],
 [63, 65, 1.975],
 [63, 64, 0.992],
 [64, 999, 22.580],
 [64, 69, 4.864],
 [64, 68, 3.910],
 [64, 67, 2.947],
 [64, 66, 1.974],
 [64, 65, 0.991],
 [65, 999, 21.777],
 [65, 69, 3.907],
 [65, 68, 2.945],
 [65, 67, 1.973],
 [65, 66, 0.991],
 [66, 999, 20.977],
 [66, 69, 2.943],
 [66, 68, 1.972],
 [66, 67, 0.991],
 [67, 999, 20.175],
 [67, 69, 1.970],
 [67, 68, 0.990],
 [68, 999, 19.373],
 [68, 69, 0.990],
 [69, 999, 18.575],
 [70, 999, 17.782],
 [71, 999, 16.994],
 [72, 999, 16.211],
 [73, 999, 15.435],
 [74, 999, 14.665],
 [75, 999, 13.898],
 [76, 999, 13.141],
 [77, 999, 12.398],
 [78, 999, 11.663],
 [79, 999, 10.940],
 [80, 999, 10.233],
 [81, 999, 9.545],
 [82, 999, 8.878],
 [83, 999, 8.230],
 [84, 999, 7.609],
 [85, 999, 7.014],
 [86, 999, 6.449],
 [87, 999, 5.916],
 [88, 999, 5.412],
 [89, 999, 4.935],
 [90, 999, 4.492],
 [91, 999, 4.082],
 [92, 999, 3.704],
 [93, 999, 3.356],
 [94, 999, 3.032],
 [95, 999, 2.740],
 [96, 999, 2.475],
 [97, 999, 2.211],
 [98, 999, 1.966],
 [99, 999, 1.747],
 [100, 999, 1.514],
 [101, 999, 1.277],
 [102, 999, 1.009],
 [103, 999, 0.612]
]);

export const bcriv2021 = new Map();
bcriv2021.set("M", [
 [0, 999, 62.7],
 [0, 68, 56.65],
 [0, 67, 56.3],
 [0, 66, 55.92],
 [0, 65, 55.54],
 [0, 64, 55.13],
 [0, 63, 54.71],
 [0, 62, 54.27],
 [0, 61, 53.82],
 [0, 60, 53.34],
 [0, 55, 50.72],
 [0, 25, 24.85],
 [0, 21, 20.89],
 [0, 20, 19.89],
 [0, 18, 17.91],
 [0, 16, 15.92],
 [1, 999, 62.57],
 [1, 68, 56.38],
 [1, 67, 56.01],
 [1, 66, 55.63],
 [1, 65, 55.24],
 [1, 64, 54.82],
 [1, 63, 54.39],
 [1, 62, 53.94],
 [1, 61, 53.47],
 [1, 60, 52.98],
 [1, 55, 50.29],
 [1, 25, 23.95],
 [1, 21, 19.97],
 [1, 20, 18.97],
 [1, 18, 16.98],
 [1, 16, 14.98],
 [2, 999, 62.21],
 [2, 68, 55.89],
 [2, 67, 55.51],
 [2, 66, 55.12],
 [2, 65, 54.71],
 [2, 64, 54.29],
 [2, 63, 53.85],
 [2, 62, 53.38],
 [2, 61, 52.91],
 [2, 60, 52.41],
 [2, 55, 49.66],
 [2, 25, 22.96],
 [2, 21, 18.98],
 [2, 20, 17.98],
 [2, 18, 15.98],
 [2, 16, 13.99],
 [3, 999, 61.83],
 [3, 68, 55.37],
 [3, 67, 54.99],
 [3, 66, 54.59],
 [3, 65, 54.18],
 [3, 64, 53.74],
 [3, 63, 53.29],
 [3, 62, 52.81],
 [3, 61, 52.32],
 [3, 60, 51.82],
 [3, 55, 49],
 [3, 25, 21.96],
 [3, 21, 17.98],
 [3, 20, 16.98],
 [3, 18, 14.99],
 [3, 16, 12.99],
 [4, 999, 61.44],
 [4, 68, 54.85],
 [4, 67, 54.46],
 [4, 66, 54.05],
 [4, 65, 53.62],
 [4, 64, 53.18],
 [4, 63, 52.71],
 [4, 62, 52.23],
 [4, 61, 51.73],
 [4, 60, 51.21],
 [4, 55, 48.33],
 [4, 25, 20.96],
 [4, 21, 16.98],
 [4, 20, 15.98],
 [4, 18, 13.99],
 [4, 16, 11.99],
 [5, 999, 61.04],
 [5, 68, 54.31],
 [5, 67, 53.91],
 [5, 66, 53.49],
 [5, 65, 53.06],
 [5, 64, 52.6],
 [5, 63, 52.13],
 [5, 62, 51.63],
 [5, 61, 51.12],
 [5, 60, 50.59],
 [5, 55, 47.65],
 [5, 25, 19.97],
 [5, 21, 15.98],
 [5, 20, 14.99],
 [5, 18, 12.99],
 [5, 16, 10.99],
 [6, 999, 60.63],
 [6, 68, 53.77],
 [6, 67, 53.36],
 [6, 66, 52.93],
 [6, 65, 52.48],
 [6, 64, 52.01],
 [6, 63, 51.53],
 [6, 62, 51.02],
 [6, 61, 50.5],
 [6, 60, 49.95],
 [6, 55, 46.95],
 [6, 25, 18.97],
 [6, 21, 14.99],
 [6, 20, 13.99],
 [6, 18, 11.99],
 [6, 16, 10],
 [7, 999, 60.22],
 [7, 68, 53.21],
 [7, 67, 52.79],
 [7, 66, 52.35],
 [7, 65, 51.89],
 [7, 64, 51.41],
 [7, 63, 50.91],
 [7, 62, 50.4],
 [7, 61, 49.86],
 [7, 60, 49.3],
 [7, 55, 46.23],
 [7, 25, 17.97],
 [7, 21, 13.99],
 [7, 20, 12.99],
 [7, 18, 10.99],
 [7, 16, 9],
 [8, 999, 59.79],
 [8, 68, 52.63],
 [8, 67, 52.2],
 [8, 66, 51.75],
 [8, 65, 51.28],
 [8, 64, 50.8],
 [8, 63, 50.29],
 [8, 62, 49.76],
 [8, 61, 49.21],
 [8, 60, 48.64],
 [8, 55, 45.5],
 [8, 25, 16.97],
 [8, 21, 12.99],
 [8, 20, 11.99],
 [8, 18, 9.99],
 [8, 16, 8],
 [9, 999, 59.35],
 [9, 68, 52.05],
 [9, 67, 51.61],
 [9, 66, 51.15],
 [9, 65, 50.67],
 [9, 64, 50.17],
 [9, 63, 49.65],
 [9, 62, 49.11],
 [9, 61, 48.55],
 [9, 60, 47.96],
 [9, 55, 44.76],
 [9, 25, 15.97],
 [9, 21, 11.99],
 [9, 20, 10.99],
 [9, 18, 9],
 [9, 16, 7],
 [10, 999, 58.91],
 [10, 68, 51.45],
 [10, 67, 51],
 [10, 66, 50.53],
 [10, 65, 50.04],
 [10, 64, 49.53],
 [10, 63, 48.99],
 [10, 62, 48.44],
 [10, 61, 47.87],
 [10, 60, 47.27],
 [10, 55, 44],
 [10, 25, 14.97],
 [10, 21, 10.99],
 [10, 20, 9.99],
 [10, 18, 8],
 [10, 16, 6],
 [11, 999, 58.46],
 [11, 68, 50.84],
 [11, 67, 50.38],
 [11, 66, 49.9],
 [11, 65, 49.4],
 [11, 64, 48.87],
 [11, 63, 48.33],
 [11, 62, 47.76],
 [11, 61, 47.18],
 [11, 60, 46.57],
 [11, 55, 43.23],
 [11, 25, 13.98],
 [11, 21, 9.99],
 [11, 20, 8.99],
 [11, 18, 7],
 [11, 16, 5],
 [12, 999, 57.99],
 [12, 68, 50.22],
 [12, 67, 49.75],
 [12, 66, 49.26],
 [12, 65, 48.74],
 [12, 64, 48.21],
 [12, 63, 47.65],
 [12, 62, 47.07],
 [12, 61, 46.47],
 [12, 60, 45.85],
 [12, 55, 42.25],
 [12, 25, 12.98],
 [12, 21, 8.99],
 [12, 20, 7.99],
 [12, 18, 6],
 [12, 16, 4],
 [13, 999, 57.52],
 [13, 68, 49.59],
 [13, 67, 49.11],
 [13, 66, 48.6],
 [13, 65, 48.07],
 [13, 64, 47.52],
 [13, 63, 46.96],
 [13, 62, 46.36],
 [13, 61, 45.75],
 [13, 60, 45.12],
 [13, 55, 41.25],
 [13, 25, 11.98],
 [13, 21, 7.99],
 [13, 20, 6.99],
 [13, 18, 5],
 [13, 16, 3],
 [14, 999, 57.04],
 [14, 68, 48.95],
 [14, 67, 48.45],
 [14, 66, 47.93],
 [14, 65, 47.39],
 [14, 64, 46.83],
 [14, 63, 46.25],
 [14, 62, 45.65],
 [14, 61, 45.02],
 [14, 60, 44.37],
 [14, 55, 40.26],
 [14, 25, 10.98],
 [14, 21, 6.99],
 [14, 20, 6],
 [14, 18, 4],
 [14, 16, 2],
 [15, 999, 56.55],
 [15, 68, 48.29],
 [15, 67, 47.78],
 [15, 66, 47.25],
 [15, 65, 46.7],
 [15, 64, 46.13],
 [15, 63, 45.53],
 [15, 62, 44.91],
 [15, 61, 44.28],
 [15, 60, 43.61],
 [15, 55, 39.26],
 [15, 25, 9.98],
 [15, 21, 5.99],
 [15, 20, 5],
 [15, 18, 3],
 [15, 16, 1],
 [16, 999, 56.06],
 [16, 68, 47.62],
 [16, 67, 47.1],
 [16, 66, 46.56],
 [16, 65, 46],
 [16, 64, 45.41],
 [16, 63, 44.8],
 [16, 62, 44.17],
 [16, 61, 43.52],
 [16, 60, 42.84],
 [16, 55, 38.27],
 [16, 25, 8.98],
 [16, 21, 5],
 [16, 20, 4],
 [16, 18, 2],
 [17, 999, 55.55],
 [17, 68, 46.94],
 [17, 67, 46.41],
 [17, 66, 45.86],
 [17, 65, 45.28],
 [17, 64, 44.68],
 [17, 63, 44.06],
 [17, 62, 43.41],
 [17, 61, 42.75],
 [17, 60, 41.87],
 [17, 55, 37.28],
 [17, 25, 7.98],
 [17, 21, 4],
 [17, 20, 3],
 [17, 18, 1],
 [18, 999, 55.05],
 [18, 68, 46.25],
 [18, 67, 45.71],
 [18, 66, 45.14],
 [18, 65, 44.55],
 [18, 64, 43.94],
 [18, 63, 43.31],
 [18, 62, 42.65],
 [18, 61, 41.78],
 [18, 60, 40.89],
 [18, 55, 36.29],
 [18, 25, 6.99],
 [18, 21, 3],
 [18, 20, 2],
 [19, 999, 54.53],
 [19, 68, 45.56],
 [19, 67, 45],
 [19, 66, 44.42],
 [19, 65, 43.82],
 [19, 64, 43.19],
 [19, 63, 42.54],
 [19, 62, 41.68],
 [19, 61, 40.8],
 [19, 60, 39.9],
 [19, 55, 35.31],
 [19, 25, 5.99],
 [19, 21, 2],
 [19, 20, 1],
 [20, 999, 54.01],
 [20, 68, 44.85],
 [20, 67, 44.28],
 [20, 66, 43.68],
 [20, 65, 43.07],
 [20, 64, 42.43],
 [20, 63, 41.58],
 [20, 62, 40.71],
 [20, 61, 39.82],
 [20, 60, 38.92],
 [20, 55, 34.33],
 [20, 25, 4.99],
 [20, 21, 1],
 [21, 999, 53.48],
 [21, 68, 44.13],
 [21, 67, 43.54],
 [21, 66, 42.94],
 [21, 65, 42.31],
 [21, 64, 41.47],
 [21, 63, 40.6],
 [21, 62, 39.73],
 [21, 61, 38.84],
 [21, 60, 37.94],
 [21, 55, 33.35],
 [21, 25, 4],
 [22, 999, 52.94],
 [22, 68, 43.39],
 [22, 67, 42.8],
 [22, 66, 42.18],
 [22, 65, 41.35],
 [22, 64, 40.5],
 [22, 63, 39.63],
 [22, 62, 38.75],
 [22, 61, 37.86],
 [22, 60, 36.97],
 [22, 55, 32.37],
 [22, 25, 3],
 [23, 999, 52.39],
 [23, 68, 42.65],
 [23, 67, 42.04],
 [23, 66, 41.22],
 [23, 65, 40.38],
 [23, 64, 39.52],
 [23, 63, 38.65],
 [23, 62, 37.77],
 [23, 61, 36.89],
 [23, 60, 35.99],
 [23, 55, 31.38],
 [23, 25, 2],
 [24, 999, 51.83],
 [24, 68, 41.89],
 [24, 67, 41.08],
 [24, 66, 40.25],
 [24, 65, 39.4],
 [24, 64, 38.54],
 [24, 63, 37.68],
 [24, 62, 36.8],
 [24, 61, 35.91],
 [24, 60, 35.01],
 [24, 55, 30.4],
 [24, 25, 1],
 [25, 999, 51.26],
 [25, 68, 40.93],
 [25, 67, 40.11],
 [25, 66, 39.27],
 [25, 65, 38.43],
 [25, 64, 37.57],
 [25, 63, 36.7],
 [25, 62, 35.82],
 [25, 61, 34.93],
 [25, 60, 34.03],
 [25, 55, 29.42],
 [26, 999, 50.67],
 [26, 68, 39.96],
 [26, 67, 39.13],
 [26, 66, 38.3],
 [26, 65, 37.45],
 [26, 64, 36.59],
 [26, 63, 35.72],
 [26, 62, 34.84],
 [26, 61, 33.96],
 [26, 60, 33.06],
 [26, 55, 28.44],
 [27, 999, 50.08],
 [27, 68, 38.99],
 [27, 67, 38.16],
 [27, 66, 37.33],
 [27, 65, 36.48],
 [27, 64, 35.62],
 [27, 63, 34.75],
 [27, 62, 33.87],
 [27, 61, 32.98],
 [27, 60, 32.08],
 [27, 55, 27.46],
 [28, 999, 49.47],
 [28, 68, 38.01],
 [28, 67, 37.19],
 [28, 66, 36.35],
 [28, 65, 35.51],
 [28, 64, 34.65],
 [28, 63, 33.78],
 [28, 62, 32.89],
 [28, 61, 32],
 [28, 60, 31.1],
 [28, 55, 26.48],
 [29, 999, 48.85],
 [29, 68, 37.04],
 [29, 67, 36.22],
 [29, 66, 35.38],
 [29, 65, 34.53],
 [29, 64, 33.67],
 [29, 63, 32.8],
 [29, 62, 31.92],
 [29, 61, 31.03],
 [29, 60, 30.13],
 [29, 55, 25.5],
 [30, 999, 48.22],
 [30, 68, 36.07],
 [30, 67, 35.25],
 [30, 66, 34.41],
 [30, 65, 33.56],
 [30, 64, 32.7],
 [30, 63, 31.83],
 [30, 62, 30.94],
 [30, 61, 30.05],
 [30, 60, 29.15],
 [30, 55, 24.52],
 [31, 999, 47.58],
 [31, 68, 35.1],
 [31, 67, 34.28],
 [31, 66, 33.44],
 [31, 65, 32.59],
 [31, 64, 31.73],
 [31, 63, 30.85],
 [31, 62, 29.97],
 [31, 61, 29.08],
 [31, 60, 28.17],
 [31, 55, 23.54],
 [32, 999, 46.92],
 [32, 68, 34.13],
 [32, 67, 33.3],
 [32, 66, 32.47],
 [32, 65, 31.61],
 [32, 64, 30.75],
 [32, 63, 29.88],
 [32, 62, 28.99],
 [32, 61, 28.1],
 [32, 60, 27.2],
 [32, 55, 22.56],
 [33, 999, 46.26],
 [33, 68, 33.16],
 [33, 67, 32.33],
 [33, 66, 31.49],
 [33, 65, 30.64],
 [33, 64, 29.78],
 [33, 63, 28.9],
 [33, 62, 28.02],
 [33, 61, 27.13],
 [33, 60, 26.22],
 [33, 55, 21.58],
 [34, 999, 45.58],
 [34, 68, 32.19],
 [34, 67, 31.36],
 [34, 66, 30.52],
 [34, 65, 29.67],
 [34, 64, 28.81],
 [34, 63, 27.93],
 [34, 62, 27.05],
 [34, 61, 26.15],
 [34, 60, 25.25],
 [34, 55, 20.6],
 [35, 999, 44.89],
 [35, 68, 31.22],
 [35, 67, 30.39],
 [35, 66, 29.55],
 [35, 65, 28.7],
 [35, 64, 27.83],
 [35, 63, 26.96],
 [35, 62, 26.07],
 [35, 61, 25.18],
 [35, 60, 24.27],
 [35, 55, 19.62],
 [36, 999, 44.18],
 [36, 68, 30.25],
 [36, 67, 29.42],
 [36, 66, 28.58],
 [36, 65, 27.73],
 [36, 64, 26.86],
 [36, 63, 25.99],
 [36, 62, 25.1],
 [36, 61, 24.2],
 [36, 60, 23.29],
 [36, 55, 18.64],
 [37, 999, 43.25],
 [37, 68, 29.29],
 [37, 67, 28.46],
 [37, 66, 27.61],
 [37, 65, 26.76],
 [37, 64, 25.89],
 [37, 63, 25.01],
 [37, 62, 24.12],
 [37, 61, 23.23],
 [37, 60, 22.32],
 [37, 55, 17.66],
 [38, 999, 42.3],
 [38, 68, 28.32],
 [38, 67, 27.49],
 [38, 66, 26.65],
 [38, 65, 25.79],
 [38, 64, 24.92],
 [38, 63, 24.04],
 [38, 62, 23.15],
 [38, 61, 22.25],
 [38, 60, 21.35],
 [38, 55, 16.68],
 [39, 999, 41.35],
 [39, 68, 27.36],
 [39, 67, 26.52],
 [39, 66, 25.68],
 [39, 65, 24.82],
 [39, 64, 23.95],
 [39, 63, 23.07],
 [39, 62, 22.18],
 [39, 61, 21.28],
 [39, 60, 20.37],
 [39, 55, 15.7],
 [40, 999, 40.41],
 [40, 68, 26.4],
 [40, 67, 25.56],
 [40, 66, 24.71],
 [40, 65, 23.86],
 [40, 64, 22.99],
 [40, 63, 22.11],
 [40, 62, 21.21],
 [40, 61, 20.31],
 [40, 60, 19.4],
 [40, 55, 14.72],
 [41, 999, 39.47],
 [41, 68, 25.43],
 [41, 67, 24.6],
 [41, 66, 23.75],
 [41, 65, 22.89],
 [41, 64, 22.02],
 [41, 63, 21.14],
 [41, 62, 20.24],
 [41, 61, 19.34],
 [41, 60, 18.43],
 [41, 55, 13.75],
 [42, 999, 38.53],
 [42, 68, 24.47],
 [42, 67, 23.64],
 [42, 66, 22.79],
 [42, 65, 21.93],
 [42, 64, 21.05],
 [42, 63, 20.17],
 [42, 62, 19.28],
 [42, 61, 18.37],
 [42, 60, 17.46],
 [42, 55, 12.77],
 [43, 999, 37.6],
 [43, 68, 23.52],
 [43, 67, 22.68],
 [43, 66, 21.83],
 [43, 65, 20.97],
 [43, 64, 20.09],
 [43, 63, 19.21],
 [43, 62, 18.31],
 [43, 61, 17.4],
 [43, 60, 16.49],
 [43, 55, 11.79],
 [44, 999, 36.68],
 [44, 68, 22.56],
 [44, 67, 21.72],
 [44, 66, 20.87],
 [44, 65, 20.01],
 [44, 64, 19.13],
 [44, 63, 18.24],
 [44, 62, 17.35],
 [44, 61, 16.44],
 [44, 60, 15.52],
 [44, 55, 10.81],
 [45, 999, 35.76],
 [45, 68, 21.61],
 [45, 67, 20.77],
 [45, 66, 19.92],
 [45, 65, 19.05],
 [45, 64, 18.17],
 [45, 63, 17.28],
 [45, 62, 16.38],
 [45, 61, 15.47],
 [45, 60, 14.55],
 [45, 55, 9.84],
 [46, 999, 34.84],
 [46, 68, 20.67],
 [46, 67, 19.82],
 [46, 66, 18.96],
 [46, 65, 18.1],
 [46, 64, 17.22],
 [46, 63, 16.32],
 [46, 62, 15.42],
 [46, 61, 14.51],
 [46, 60, 13.59],
 [46, 55, 8.86],
 [47, 999, 33.94],
 [47, 68, 19.72],
 [47, 67, 18.87],
 [47, 66, 18.02],
 [47, 65, 17.14],
 [47, 64, 16.26],
 [47, 63, 15.37],
 [47, 62, 14.46],
 [47, 61, 13.55],
 [47, 60, 12.63],
 [47, 55, 7.88],
 [48, 999, 33.04],
 [48, 68, 18.78],
 [48, 67, 17.93],
 [48, 66, 17.07],
 [48, 65, 16.2],
 [48, 64, 15.31],
 [48, 63, 14.41],
 [48, 62, 13.51],
 [48, 61, 12.59],
 [48, 60, 11.66],
 [48, 55, 6.9],
 [49, 999, 32.15],
 [49, 68, 17.84],
 [49, 67, 16.99],
 [49, 66, 16.12],
 [49, 65, 15.25],
 [49, 64, 14.36],
 [49, 63, 13.46],
 [49, 62, 12.55],
 [49, 61, 11.63],
 [49, 60, 10.7],
 [49, 55, 5.93],
 [50, 999, 31.26],
 [50, 68, 16.9],
 [50, 67, 16.05],
 [50, 66, 15.18],
 [50, 65, 14.3],
 [50, 64, 13.41],
 [50, 63, 12.51],
 [50, 62, 11.59],
 [50, 61, 10.67],
 [50, 60, 9.74],
 [50, 55, 4.95],
 [51, 999, 30.38],
 [51, 68, 15.97],
 [51, 67, 15.11],
 [51, 66, 14.24],
 [51, 65, 13.35],
 [51, 64, 12.46],
 [51, 63, 11.55],
 [51, 62, 10.64],
 [51, 61, 9.71],
 [51, 60, 8.77],
 [51, 55, 3.96],
 [52, 999, 29.51],
 [52, 68, 15.03],
 [52, 67, 14.17],
 [52, 66, 13.3],
 [52, 65, 12.41],
 [52, 64, 11.51],
 [52, 63, 10.6],
 [52, 62, 9.68],
 [52, 61, 8.75],
 [52, 60, 7.81],
 [52, 55, 2.98],
 [53, 999, 28.65],
 [53, 68, 14.1],
 [53, 67, 13.24],
 [53, 66, 12.36],
 [53, 65, 11.47],
 [53, 64, 10.56],
 [53, 63, 9.65],
 [53, 62, 8.73],
 [53, 61, 7.79],
 [53, 60, 6.84],
 [53, 55, 1.99],
 [54, 999, 27.79],
 [54, 68, 13.17],
 [54, 67, 12.3],
 [54, 66, 11.42],
 [54, 65, 10.53],
 [54, 64, 9.62],
 [54, 63, 8.7],
 [54, 62, 7.77],
 [54, 61, 6.83],
 [54, 60, 5.88],
 [54, 55, 1],
 [55, 999, 26.96],
 [55, 68, 12.25],
 [55, 67, 11.37],
 [55, 66, 10.49],
 [55, 65, 9.58],
 [55, 64, 8.67],
 [55, 63, 7.75],
 [55, 62, 6.81],
 [55, 61, 5.87],
 [55, 60, 4.91],
 [56, 999, 26.13],
 [56, 68, 11.33],
 [56, 67, 10.45],
 [56, 66, 9.55],
 [56, 65, 8.64],
 [56, 64, 7.73],
 [56, 63, 6.8],
 [56, 62, 5.85],
 [56, 61, 4.9],
 [56, 60, 3.94],
 [57, 999, 25.31],
 [57, 68, 10.41],
 [57, 67, 9.52],
 [57, 66, 8.62],
 [57, 65, 7.7],
 [57, 64, 6.78],
 [57, 63, 5.84],
 [57, 62, 4.89],
 [57, 61, 3.93],
 [57, 60, 2.97],
 [58, 999, 24.51],
 [58, 68, 9.48],
 [58, 67, 8.59],
 [58, 66, 7.68],
 [58, 65, 6.76],
 [58, 64, 5.83],
 [58, 63, 4.88],
 [58, 62, 3.93],
 [58, 61, 2.96],
 [58, 60, 1.99],
 [59, 999, 23.71],
 [59, 68, 8.56],
 [59, 67, 7.66],
 [59, 66, 6.74],
 [59, 65, 5.82],
 [59, 64, 4.88],
 [59, 63, 3.92],
 [59, 62, 2.96],
 [59, 61, 1.98],
 [59, 60, 1],
 [60, 999, 22.93],
 [60, 68, 7.64],
 [60, 67, 6.73],
 [60, 66, 5.8],
 [60, 65, 4.87],
 [60, 64, 3.92],
 [60, 63, 2.96],
 [60, 62, 1.98],
 [60, 61, 1],
 [61, 999, 22.15],
 [61, 68, 6.71],
 [61, 67, 5.79],
 [61, 66, 4.86],
 [61, 65, 3.91],
 [61, 64, 2.95],
 [61, 63, 1.98],
 [61, 62, 1],
 [62, 999, 21.39],
 [62, 68, 5.78],
 [62, 67, 4.85],
 [62, 66, 3.9],
 [62, 65, 2.95],
 [62, 64, 1.98],
 [62, 63, 1],
 [63, 999, 20.63],
 [63, 68, 4.84],
 [63, 67, 3.9],
 [63, 66, 2.95],
 [63, 65, 1.98],
 [63, 64, 1],
 [64, 999, 19.88],
 [64, 68, 3.89],
 [64, 67, 2.94],
 [64, 66, 1.98],
 [64, 65, 1],
 [65, 999, 19.13],
 [65, 68, 2.94],
 [65, 67, 1.98],
 [65, 66, 1],
 [66, 999, 18.38],
 [66, 68, 1.97],
 [66, 67, 1],
 [67, 999, 17.64],
 [67, 68, 1],
 [68, 999, 16.9],
 [69, 999, 16.18],
 [70, 999, 15.46],
 [71, 999, 14.75],
 [72, 999, 14.04],
 [73, 999, 13.34],
 [74, 999, 12.65],
 [75, 999, 11.98],
 [76, 999, 11.31],
 [77, 999, 10.65],
 [78, 999, 10],
 [79, 999, 9.38],
 [80, 999, 8.78],
 [81, 999, 8.19],
 [82, 999, 7.63],
 [83, 999, 7.09],
 [84, 999, 6.57],
 [85, 999, 6.08],
 [86, 999, 5.63],
 [87, 999, 5.19],
 [88, 999, 4.8],
 [89, 999, 4.42],
 [90, 999, 4.08],
 [91, 999, 3.76],
 [92, 999, 3.45],
 [93, 999, 3.18],
 [94, 999, 2.94],
 [95, 999, 2.72],
 [96, 999, 2.51],
 [97, 999, 2.33],
 [98, 999, 2.18],
 [99, 999, 2.03],
 [100, 999, 1.86],
 [101, 999, 1.67],
 [102, 999, 1.41],
 [103, 999, 1.03],
]);
bcriv2021.set("F", [
 [0, 999, 64.98],
 [0, 68, 57.74],
 [0, 67, 57.33],
 [0, 66, 56.91],
 [0, 65, 56.47],
 [0, 64, 56.03],
 [0, 63, 55.56],
 [0, 62, 55.09],
 [0, 61, 54.59],
 [0, 60, 54.09],
 [0, 55, 51.35],
 [0, 25, 24.88],
 [0, 21, 20.91],
 [0, 20, 19.91],
 [0, 18, 17.92],
 [0, 16, 15.93],
 [1, 999, 64.87],
 [1, 68, 57.46],
 [1, 67, 57.04],
 [1, 66, 56.6],
 [1, 65, 56.16],
 [1, 64, 55.7],
 [1, 63, 55.22],
 [1, 62, 54.73],
 [1, 61, 54.23],
 [1, 60, 53.71],
 [1, 55, 50.9],
 [1, 25, 23.97],
 [1, 21, 19.98],
 [1, 20, 18.98],
 [1, 18, 16.98],
 [1, 16, 14.99],
 [2, 999, 64.55],
 [2, 68, 56.98],
 [2, 67, 56.55],
 [2, 66, 56.11],
 [2, 65, 55.65],
 [2, 64, 55.18],
 [2, 63, 54.69],
 [2, 62, 54.19],
 [2, 61, 53.68],
 [2, 60, 53.15],
 [2, 55, 50.28],
 [2, 25, 22.97],
 [2, 21, 18.98],
 [2, 20, 17.98],
 [2, 18, 15.99],
 [2, 16, 13.99],
 [3, 999, 64.22],
 [3, 68, 56.49],
 [3, 67, 56.05],
 [3, 66, 55.6],
 [3, 65, 55.13],
 [3, 64, 54.65],
 [3, 63, 54.15],
 [3, 62, 53.64],
 [3, 61, 53.11],
 [3, 60, 52.57],
 [3, 55, 49.63],
 [3, 25, 21.98],
 [3, 21, 17.99],
 [3, 20, 16.99],
 [3, 18, 14.99],
 [3, 16, 12.99],
 [4, 999, 63.88],
 [4, 68, 55.98],
 [4, 67, 55.53],
 [4, 66, 55.07],
 [4, 65, 54.59],
 [4, 64, 54.1],
 [4, 63, 53.59],
 [4, 62, 53.07],
 [4, 61, 52.53],
 [4, 60, 51.98],
 [4, 55, 48.97],
 [4, 25, 20.98],
 [4, 21, 16.99],
 [4, 20, 15.99],
 [4, 18, 13.99],
 [4, 16, 11.99],
 [5, 999, 63.53],
 [5, 68, 55.46],
 [5, 67, 55],
 [5, 66, 54.53],
 [5, 65, 54.04],
 [5, 64, 53.54],
 [5, 63, 53.02],
 [5, 62, 52.48],
 [5, 61, 51.93],
 [5, 60, 51.37],
 [5, 55, 48.3],
 [5, 25, 19.98],
 [5, 21, 15.99],
 [5, 20, 14.99],
 [5, 18, 12.99],
 [5, 16, 11],
 [6, 999, 63.17],
 [6, 68, 54.93],
 [6, 67, 54.46],
 [6, 66, 53.98],
 [6, 65, 53.48],
 [6, 64, 52.96],
 [6, 63, 52.43],
 [6, 62, 51.89],
 [6, 61, 51.32],
 [6, 60, 50.75],
 [6, 55, 47.61],
 [6, 25, 18.98],
 [6, 21, 14.99],
 [6, 20, 13.99],
 [6, 18, 11.99],
 [6, 16, 10],
 [7, 999, 62.8],
 [7, 68, 54.39],
 [7, 67, 53.91],
 [7, 66, 53.42],
 [7, 65, 52.9],
 [7, 64, 52.38],
 [7, 63, 51.83],
 [7, 62, 51.28],
 [7, 61, 50.7],
 [7, 60, 50.11],
 [7, 55, 46.91],
 [7, 25, 17.98],
 [7, 21, 13.99],
 [7, 20, 12.99],
 [7, 18, 11],
 [7, 16, 9],
 [8, 999, 62.43],
 [8, 68, 53.84],
 [8, 67, 53.35],
 [8, 66, 52.84],
 [8, 65, 52.32],
 [8, 64, 51.78],
 [8, 63, 51.22],
 [8, 62, 50.65],
 [8, 61, 50.07],
 [8, 60, 49.46],
 [8, 55, 46.19],
 [8, 25, 16.98],
 [8, 21, 12.99],
 [8, 20, 11.99],
 [8, 18, 10],
 [8, 16, 8],
 [9, 999, 62.05],
 [9, 68, 53.27],
 [9, 67, 52.77],
 [9, 66, 52.25],
 [9, 65, 51.72],
 [9, 64, 51.17],
 [9, 63, 50.6],
 [9, 62, 50.02],
 [9, 61, 49.42],
 [9, 60, 48.8],
 [9, 55, 45.46],
 [9, 25, 15.99],
 [9, 21, 11.99],
 [9, 20, 10.99],
 [9, 18, 9],
 [9, 16, 7],
 [10, 999, 61.66],
 [10, 68, 52.7],
 [10, 67, 52.18],
 [10, 66, 51.65],
 [10, 65, 51.11],
 [10, 64, 50.54],
 [10, 63, 49.96],
 [10, 62, 49.37],
 [10, 61, 48.75],
 [10, 60, 48.12],
 [10, 55, 44.65],
 [10, 25, 14.99],
 [10, 21, 10.99],
 [10, 20, 10],
 [10, 18, 8],
 [10, 16, 6],
 [11, 999, 61.26],
 [11, 68, 52.11],
 [11, 67, 51.58],
 [11, 66, 51.04],
 [11, 65, 50.48],
 [11, 64, 49.91],
 [11, 63, 49.31],
 [11, 62, 48.7],
 [11, 61, 48.08],
 [11, 60, 47.43],
 [11, 55, 43.65],
 [11, 25, 13.99],
 [11, 21, 9.99],
 [11, 20, 9],
 [11, 18, 7],
 [11, 16, 5],
 [12, 999, 60.86],
 [12, 68, 51.51],
 [12, 67, 50.97],
 [12, 66, 50.41],
 [12, 65, 49.84],
 [12, 64, 49.25],
 [12, 63, 48.65],
 [12, 62, 48.02],
 [12, 61, 47.38],
 [12, 60, 46.73],
 [12, 55, 42.66],
 [12, 25, 12.99],
 [12, 21, 9],
 [12, 20, 8],
 [12, 18, 6],
 [12, 16, 4],
 [13, 999, 60.45],
 [13, 68, 50.89],
 [13, 67, 50.34],
 [13, 66, 49.77],
 [13, 65, 49.19],
 [13, 64, 48.59],
 [13, 63, 47.97],
 [13, 62, 47.33],
 [13, 61, 46.68],
 [13, 60, 46.01],
 [13, 55, 41.66],
 [13, 25, 11.99],
 [13, 21, 8],
 [13, 20, 7],
 [13, 18, 5],
 [13, 16, 3],
 [14, 999, 60.03],
 [14, 68, 50.26],
 [14, 67, 49.7],
 [14, 66, 49.12],
 [14, 65, 48.53],
 [14, 64, 47.91],
 [14, 63, 47.28],
 [14, 62, 46.63],
 [14, 61, 45.96],
 [14, 60, 45.27],
 [14, 55, 40.66],
 [14, 25, 10.99],
 [14, 21, 7],
 [14, 20, 6],
 [14, 18, 4],
 [14, 16, 2],
 [15, 999, 59.6],
 [15, 68, 49.62],
 [15, 67, 49.05],
 [15, 66, 48.46],
 [15, 65, 47.85],
 [15, 64, 47.22],
 [15, 63, 46.57],
 [15, 62, 45.91],
 [15, 61, 45.22],
 [15, 60, 44.47],
 [15, 55, 39.67],
 [15, 25, 9.99],
 [15, 21, 6],
 [15, 20, 5],
 [15, 18, 3],
 [15, 16, 1],
 [16, 999, 59.16],
 [16, 68, 48.97],
 [16, 67, 48.39],
 [16, 66, 47.78],
 [16, 65, 47.16],
 [16, 64, 46.51],
 [16, 63, 45.85],
 [16, 62, 45.18],
 [16, 61, 44.42],
 [16, 60, 43.47],
 [16, 55, 38.67],
 [16, 25, 8.99],
 [16, 21, 5],
 [16, 20, 4],
 [16, 18, 2],
 [17, 999, 58.72],
 [17, 68, 48.31],
 [17, 67, 47.71],
 [17, 66, 47.09],
 [17, 65, 46.45],
 [17, 64, 45.8],
 [17, 63, 45.12],
 [17, 62, 44.37],
 [17, 61, 43.43],
 [17, 60, 42.48],
 [17, 55, 37.68],
 [17, 25, 7.99],
 [17, 21, 4],
 [17, 20, 3],
 [17, 18, 1],
 [18, 999, 58.27],
 [18, 68, 47.63],
 [18, 67, 47.02],
 [18, 66, 46.38],
 [18, 65, 45.73],
 [18, 64, 45.06],
 [18, 63, 44.32],
 [18, 62, 43.38],
 [18, 61, 42.43],
 [18, 60, 41.48],
 [18, 55, 36.68],
 [18, 25, 6.99],
 [18, 21, 3],
 [18, 20, 2],
 [19, 999, 57.81],
 [19, 68, 46.94],
 [19, 67, 46.31],
 [19, 66, 45.67],
 [19, 65, 45],
 [19, 64, 44.26],
 [19, 63, 43.32],
 [19, 62, 42.38],
 [19, 61, 41.44],
 [19, 60, 40.49],
 [19, 55, 35.69],
 [19, 25, 6],
 [19, 21, 2],
 [19, 20, 1],
 [20, 999, 57.34],
 [20, 68, 46.24],
 [20, 67, 45.6],
 [20, 66, 44.93],
 [20, 65, 44.2],
 [20, 64, 43.27],
 [20, 63, 42.33],
 [20, 62, 41.39],
 [20, 61, 40.45],
 [20, 60, 39.5],
 [20, 55, 34.7],
 [20, 25, 5],
 [20, 21, 1],
 [21, 999, 56.87],
 [21, 68, 45.52],
 [21, 67, 44.86],
 [21, 66, 44.13],
 [21, 65, 43.21],
 [21, 64, 42.28],
 [21, 63, 41.34],
 [21, 62, 40.4],
 [21, 61, 39.46],
 [21, 60, 38.51],
 [21, 55, 33.7],
 [21, 25, 4],
 [22, 999, 56.38],
 [22, 68, 44.79],
 [22, 67, 44.06],
 [22, 66, 43.14],
 [22, 65, 42.22],
 [22, 64, 41.29],
 [22, 63, 40.35],
 [22, 62, 39.41],
 [22, 61, 38.46],
 [22, 60, 37.51],
 [22, 55, 32.71],
 [22, 25, 3],
 [23, 999, 55.88],
 [23, 68, 43.98],
 [23, 67, 43.07],
 [23, 66, 42.15],
 [23, 65, 41.22],
 [23, 64, 40.29],
 [23, 63, 39.36],
 [23, 62, 38.42],
 [23, 61, 37.47],
 [23, 60, 36.52],
 [23, 55, 31.72],
 [23, 25, 2],
 [24, 999, 55.37],
 [24, 68, 42.99],
 [24, 67, 42.08],
 [24, 66, 41.16],
 [24, 65, 40.23],
 [24, 64, 39.3],
 [24, 63, 38.37],
 [24, 62, 37.43],
 [24, 61, 36.48],
 [24, 60, 35.53],
 [24, 55, 30.73],
 [24, 25, 1],
 [25, 999, 54.85],
 [25, 68, 42],
 [25, 67, 41.09],
 [25, 66, 40.17],
 [25, 65, 39.24],
 [25, 64, 38.31],
 [25, 63, 37.37],
 [25, 62, 36.43],
 [25, 61, 35.49],
 [25, 60, 34.54],
 [25, 55, 29.73],
 [26, 999, 54.33],
 [26, 68, 41.01],
 [26, 67, 40.1],
 [26, 66, 39.18],
 [26, 65, 38.25],
 [26, 64, 37.32],
 [26, 63, 36.38],
 [26, 62, 35.44],
 [26, 61, 34.5],
 [26, 60, 33.55],
 [26, 55, 28.74],
 [27, 999, 53.79],
 [27, 68, 40.02],
 [27, 67, 39.11],
 [27, 66, 38.19],
 [27, 65, 37.26],
 [27, 64, 36.33],
 [27, 63, 35.39],
 [27, 62, 34.45],
 [27, 61, 33.5],
 [27, 60, 32.55],
 [27, 55, 27.75],
 [28, 999, 53.24],
 [28, 68, 39.03],
 [28, 67, 38.12],
 [28, 66, 37.2],
 [28, 65, 36.27],
 [28, 64, 35.34],
 [28, 63, 34.4],
 [28, 62, 33.46],
 [28, 61, 32.51],
 [28, 60, 31.56],
 [28, 55, 26.75],
 [29, 999, 52.67],
 [29, 68, 38.04],
 [29, 67, 37.13],
 [29, 66, 36.21],
 [29, 65, 35.28],
 [29, 64, 34.35],
 [29, 63, 33.41],
 [29, 62, 32.47],
 [29, 61, 31.52],
 [29, 60, 30.57],
 [29, 55, 25.76],
 [30, 999, 52.1],
 [30, 68, 37.05],
 [30, 67, 36.14],
 [30, 66, 35.22],
 [30, 65, 34.29],
 [30, 64, 33.36],
 [30, 63, 32.42],
 [30, 62, 31.48],
 [30, 61, 30.53],
 [30, 60, 29.58],
 [30, 55, 24.77],
 [31, 999, 51.52],
 [31, 68, 36.06],
 [31, 67, 35.15],
 [31, 66, 34.23],
 [31, 65, 33.3],
 [31, 64, 32.37],
 [31, 63, 31.43],
 [31, 62, 30.49],
 [31, 61, 29.54],
 [31, 60, 28.59],
 [31, 55, 23.77],
 [32, 999, 50.92],
 [32, 68, 35.08],
 [32, 67, 34.16],
 [32, 66, 33.24],
 [32, 65, 32.31],
 [32, 64, 31.38],
 [32, 63, 30.44],
 [32, 62, 29.5],
 [32, 61, 28.55],
 [32, 60, 27.6],
 [32, 55, 22.78],
 [33, 999, 50.32],
 [33, 68, 34.09],
 [33, 67, 33.17],
 [33, 66, 32.25],
 [33, 65, 31.32],
 [33, 64, 30.39],
 [33, 63, 29.45],
 [33, 62, 28.51],
 [33, 61, 27.56],
 [33, 60, 26.61],
 [33, 55, 21.79],
 [34, 999, 49.7],
 [34, 68, 33.1],
 [34, 67, 32.19],
 [34, 66, 31.26],
 [34, 65, 30.34],
 [34, 64, 29.4],
 [34, 63, 28.46],
 [34, 62, 27.52],
 [34, 61, 26.57],
 [34, 60, 25.62],
 [34, 55, 20.8],
 [35, 999, 49.07],
 [35, 68, 32.11],
 [35, 67, 31.2],
 [35, 66, 30.28],
 [35, 65, 29.35],
 [35, 64, 28.41],
 [35, 63, 27.47],
 [35, 62, 26.53],
 [35, 61, 25.58],
 [35, 60, 24.63],
 [35, 55, 19.81],
 [36, 999, 48.43],
 [36, 68, 31.13],
 [36, 67, 30.21],
 [36, 66, 29.29],
 [36, 65, 28.36],
 [36, 64, 27.43],
 [36, 63, 26.49],
 [36, 62, 25.54],
 [36, 61, 24.59],
 [36, 60, 23.64],
 [36, 55, 18.82],
 [37, 999, 47.77],
 [37, 68, 30.14],
 [37, 67, 29.23],
 [37, 66, 28.3],
 [37, 65, 27.37],
 [37, 64, 26.44],
 [37, 63, 25.5],
 [37, 62, 24.55],
 [37, 61, 23.61],
 [37, 60, 22.65],
 [37, 55, 17.83],
 [38, 999, 47.11],
 [38, 68, 29.16],
 [38, 67, 28.24],
 [38, 66, 27.32],
 [38, 65, 26.39],
 [38, 64, 25.45],
 [38, 63, 24.51],
 [38, 62, 23.57],
 [38, 61, 22.62],
 [38, 60, 21.66],
 [38, 55, 16.84],
 [39, 999, 46.43],
 [39, 68, 28.18],
 [39, 67, 27.26],
 [39, 66, 26.34],
 [39, 65, 25.41],
 [39, 64, 24.47],
 [39, 63, 23.53],
 [39, 62, 22.58],
 [39, 61, 21.63],
 [39, 60, 20.68],
 [39, 55, 15.85],
 [40, 999, 45.75],
 [40, 68, 27.2],
 [40, 67, 26.28],
 [40, 66, 25.35],
 [40, 65, 24.42],
 [40, 64, 23.49],
 [40, 63, 22.54],
 [40, 62, 21.6],
 [40, 61, 20.65],
 [40, 60, 19.69],
 [40, 55, 14.86],
 [41, 999, 44.98],
 [41, 68, 26.22],
 [41, 67, 25.3],
 [41, 66, 24.37],
 [41, 65, 23.44],
 [41, 64, 22.5],
 [41, 63, 21.56],
 [41, 62, 20.61],
 [41, 61, 19.66],
 [41, 60, 18.71],
 [41, 55, 13.87],
 [42, 999, 44.02],
 [42, 68, 25.24],
 [42, 67, 24.32],
 [42, 66, 23.39],
 [42, 65, 22.46],
 [42, 64, 21.52],
 [42, 63, 20.58],
 [42, 62, 19.63],
 [42, 61, 18.68],
 [42, 60, 17.72],
 [42, 55, 12.88],
 [43, 999, 43.06],
 [43, 68, 24.26],
 [43, 67, 23.34],
 [43, 66, 22.41],
 [43, 65, 21.48],
 [43, 64, 20.54],
 [43, 63, 19.6],
 [43, 62, 18.65],
 [43, 61, 17.69],
 [43, 60, 16.74],
 [43, 55, 11.89],
 [44, 999, 42.1],
 [44, 68, 23.29],
 [44, 67, 22.36],
 [44, 66, 21.44],
 [44, 65, 20.5],
 [44, 64, 19.56],
 [44, 63, 18.62],
 [44, 62, 17.67],
 [44, 61, 16.71],
 [44, 60, 15.75],
 [44, 55, 10.9],
 [45, 999, 41.15],
 [45, 68, 22.31],
 [45, 67, 21.39],
 [45, 66, 20.46],
 [45, 65, 19.52],
 [45, 64, 18.58],
 [45, 63, 17.64],
 [45, 62, 16.69],
 [45, 61, 15.73],
 [45, 60, 14.77],
 [45, 55, 9.91],
 [46, 999, 40.2],
 [46, 68, 21.34],
 [46, 67, 20.42],
 [46, 66, 19.49],
 [46, 65, 18.55],
 [46, 64, 17.61],
 [46, 63, 16.66],
 [46, 62, 15.71],
 [46, 61, 14.75],
 [46, 60, 13.79],
 [46, 55, 8.93],
 [47, 999, 39.26],
 [47, 68, 20.37],
 [47, 67, 19.44],
 [47, 66, 18.51],
 [47, 65, 17.58],
 [47, 64, 16.63],
 [47, 63, 15.68],
 [47, 62, 14.73],
 [47, 61, 13.77],
 [47, 60, 12.81],
 [47, 55, 7.94],
 [48, 999, 38.32],
 [48, 68, 19.4],
 [48, 67, 18.48],
 [48, 66, 17.54],
 [48, 65, 16.6],
 [48, 64, 15.66],
 [48, 63, 14.71],
 [48, 62, 13.75],
 [48, 61, 12.79],
 [48, 60, 11.83],
 [48, 55, 6.95],
 [49, 999, 37.39],
 [49, 68, 18.44],
 [49, 67, 17.51],
 [49, 66, 16.57],
 [49, 65, 15.63],
 [49, 64, 14.68],
 [49, 63, 13.73],
 [49, 62, 12.78],
 [49, 61, 11.81],
 [49, 60, 10.85],
 [49, 55, 5.96],
 [50, 999, 36.46],
 [50, 68, 17.47],
 [50, 67, 16.54],
 [50, 66, 15.6],
 [50, 65, 14.66],
 [50, 64, 13.71],
 [50, 63, 12.76],
 [50, 62, 11.8],
 [50, 61, 10.84],
 [50, 60, 9.87],
 [50, 55, 4.97],
 [51, 999, 35.53],
 [51, 68, 16.5],
 [51, 67, 15.57],
 [51, 66, 14.63],
 [51, 65, 13.69],
 [51, 64, 12.74],
 [51, 63, 11.78],
 [51, 62, 10.82],
 [51, 61, 9.86],
 [51, 60, 8.89],
 [51, 55, 3.98],
 [52, 999, 34.61],
 [52, 68, 15.54],
 [52, 67, 14.61],
 [52, 66, 13.67],
 [52, 65, 12.72],
 [52, 64, 11.77],
 [52, 63, 10.81],
 [52, 62, 9.85],
 [52, 61, 8.88],
 [52, 60, 7.91],
 [52, 55, 2.99],
 [53, 999, 33.69],
 [53, 68, 14.58],
 [53, 67, 13.64],
 [53, 66, 12.7],
 [53, 65, 11.75],
 [53, 64, 10.79],
 [53, 63, 9.83],
 [53, 62, 8.87],
 [53, 61, 7.9],
 [53, 60, 6.92],
 [53, 55, 2],
 [54, 999, 32.78],
 [54, 68, 13.61],
 [54, 67, 12.67],
 [54, 66, 11.73],
 [54, 65, 10.78],
 [54, 64, 9.82],
 [54, 63, 8.86],
 [54, 62, 7.89],
 [54, 61, 6.92],
 [54, 60, 5.94],
 [54, 55, 1],
 [55, 999, 31.87],
 [55, 68, 12.65],
 [55, 67, 11.71],
 [55, 66, 10.76],
 [55, 65, 9.81],
 [55, 64, 8.85],
 [55, 63, 7.88],
 [55, 62, 6.91],
 [55, 61, 5.94],
 [55, 60, 4.96],
 [56, 999, 30.97],
 [56, 68, 11.69],
 [56, 67, 10.74],
 [56, 66, 9.79],
 [56, 65, 8.84],
 [56, 64, 7.87],
 [56, 63, 6.91],
 [56, 62, 5.93],
 [56, 61, 4.95],
 [56, 60, 3.97],
 [57, 999, 30.07],
 [57, 68, 10.73],
 [57, 67, 9.78],
 [57, 66, 8.82],
 [57, 65, 7.86],
 [57, 64, 6.9],
 [57, 63, 5.93],
 [57, 62, 4.95],
 [57, 61, 3.97],
 [57, 60, 2.98],
 [58, 999, 29.18],
 [58, 68, 9.76],
 [58, 67, 8.81],
 [58, 66, 7.86],
 [58, 65, 6.89],
 [58, 64, 5.92],
 [58, 63, 4.95],
 [58, 62, 3.97],
 [58, 61, 2.98],
 [58, 60, 1.99],
 [59, 999, 28.29],
 [59, 68, 8.8],
 [59, 67, 7.84],
 [59, 66, 6.88],
 [59, 65, 5.92],
 [59, 64, 4.94],
 [59, 63, 3.96],
 [59, 62, 2.98],
 [59, 61, 1.99],
 [59, 60, 1],
 [60, 999, 27.4],
 [60, 68, 7.84],
 [60, 67, 6.88],
 [60, 66, 5.91],
 [60, 65, 4.94],
 [60, 64, 3.96],
 [60, 63, 2.98],
 [60, 62, 1.99],
 [60, 61, 1],
 [61, 999, 26.52],
 [61, 68, 6.87],
 [61, 67, 5.9],
 [61, 66, 4.94],
 [61, 65, 3.96],
 [61, 64, 2.98],
 [61, 63, 1.99],
 [61, 62, 1],
 [62, 999, 25.64],
 [62, 68, 5.9],
 [62, 67, 4.93],
 [62, 66, 3.96],
 [62, 65, 2.98],
 [62, 64, 1.99],
 [62, 63, 1],
 [63, 999, 24.77],
 [63, 68, 4.93],
 [63, 67, 3.95],
 [63, 66, 2.97],
 [63, 65, 1.99],
 [63, 64, 1],
 [64, 999, 23.9],
 [64, 68, 3.95],
 [64, 67, 2.97],
 [64, 66, 1.99],
 [64, 65, 1],
 [65, 999, 23.04],
 [65, 68, 2.97],
 [65, 67, 1.99],
 [65, 66, 1],
 [66, 999, 22.18],
 [66, 68, 1.99],
 [66, 67, 1],
 [67, 999, 21.32],
 [67, 68, 1],
 [68, 999, 20.46],
 [69, 999, 19.61],
 [70, 999, 18.77],
 [71, 999, 17.93],
 [72, 999, 17.1],
 [73, 999, 16.29],
 [74, 999, 15.47],
 [75, 999, 14.67],
 [76, 999, 13.88],
 [77, 999, 13.1],
 [78, 999, 12.33],
 [79, 999, 11.58],
 [80, 999, 10.85],
 [81, 999, 10.14],
 [82, 999, 9.45],
 [83, 999, 8.78],
 [84, 999, 8.14],
 [85, 999, 7.53],
 [86, 999, 6.95],
 [87, 999, 6.4],
 [88, 999, 5.89],
 [89, 999, 5.4],
 [90, 999, 4.95],
 [91, 999, 4.54],
 [92, 999, 4.15],
 [93, 999, 3.79],
 [94, 999, 3.46],
 [95, 999, 3.16],
 [96, 999, 2.89],
 [97, 999, 2.64],
 [98, 999, 2.4],
 [99, 999, 2.18],
 [100, 999, 1.93],
 [101, 999, 1.68],
 [102, 999, 1.39],
 [103, 999, 0.99],
]);

export const gazPal2022txm1 = new Map();
gazPal2022txm1.set("F", [
 [0,999,136.868],
[0,69,97.468],
[0,68,95.670],
[0,67,93.876],
[0,66,92.088],
[0,65,90.306],
[0,64,88.530],
[0,63,86.762],
[0,62,85.003],
[0,61,83.252],
[0,60,81.510],
[0,59,79.778],
[0,55,72.955],
[0,50,64.695],
[0,29,33.669],
[0,25,28.431],
[0,21,23.395],
[0,20,22.167],
[0,18,19.747],
[0,16,17.374],
 [1,999,134.953],
[1,69,95.817],
[1,68,94.031],
[1,67,92.249],
[1,66,90.473],
[1,65,88.702],
[1,64,86.939],
[1,63,85.183],
[1,62,83.435],
[1,61,81.695],
[1,60,79.965],
[1,59,78.245],
[1,55,71.468],
[1,50,63.262],
[1,29,32.444],
[1,25,27.241],
[1,21,22.239],
[1,20,21.019],
[1,18,18.615],
[1,16,16.258],
 [2,999,132.634],
[2,69,93.881],
[2,68,92.112],
[2,67,90.348],
[2,66,88.589],
[2,65,86.836],
[2,64,85.089],
[2,63,83.350],
[2,62,81.619],
[2,61,79.897],
[2,60,78.184],
[2,59,76.480],
[2,55,69.770],
[2,50,61.644],
[2,29,31.127],
[2,25,25.975],
[2,21,21.022],
[2,20,19.814],
[2,18,17.433],
[2,16,15.099],
 [3,999,130.325],
[3,69,91.954],
[3,68,90.203],
[3,67,88.456],
[3,66,86.714],
[3,65,84.978],
[3,64,83.249],
[3,63,81.527],
[3,62,79.814],
[3,61,78.108],
[3,60,76.412],
[3,59,74.725],
[3,55,68.081],
[3,50,60.036],
[3,29,29.819],
[3,25,24.718],
[3,21,19.814],
[3,20,18.618],
[3,18,16.261],
[3,16,13.950],
 [4,999,128.037],
[4,69,90.045],
[4,68,88.312],
[4,67,86.582],
[4,66,84.857],
[4,65,83.139],
[4,64,81.427],
[4,63,79.722],
[4,62,78.025],
[4,61,76.337],
[4,60,74.657],
[4,59,72.987],
[4,55,66.408],
[4,50,58.442],
[4,29,28.525],
[4,25,23.474],
[4,21,18.619],
[4,20,17.434],
[4,18,15.100],
[4,16,12.812],
 [5,999,125.767],
[5,69,88.152],
[5,68,86.436],
[5,67,84.723],
[5,66,83.016],
[5,65,81.314],
[5,64,79.619],
[5,63,77.931],
[5,62,76.251],
[5,61,74.579],
[5,60,72.917],
[5,59,71.263],
[5,55,64.749],
[5,50,56.863],
[5,29,27.242],
[5,25,22.241],
[5,21,17.434],
[5,20,16.261],
[5,18,13.950],
[5,16,11.685],
 [6,999,123.520],
[6,69,86.278],
[6,68,84.578],
[6,67,82.882],
[6,66,81.192],
[6,65,79.507],
[6,64,77.829],
[6,63,76.158],
[6,62,74.495],
[6,61,72.840],
[6,60,71.193],
[6,59,69.556],
[6,55,63.107],
[6,50,55.299],
[6,29,25.972],
[6,25,21.021],
[6,21,16.261],
[6,20,15.100],
[6,18,12.812],
[6,16,10.569],
 [7,999,121.293],
[7,69,84.421],
[7,68,82.738],
[7,67,81.059],
[7,66,79.386],
[7,65,77.718],
[7,64,76.056],
[7,63,74.402],
[7,62,72.755],
[7,61,71.116],
[7,60,69.486],
[7,59,67.865],
[7,55,61.480],
[7,50,53.749],
[7,29,24.714],
[7,25,19.812],
[7,21,15.099],
[7,20,13.950],
[7,18,11.685],
[7,16,9.464],
 [8,999,119.086],
[8,69,82.581],
[8,68,80.915],
[8,67,79.253],
[8,66,77.596],
[8,65,75.944],
[8,64,74.299],
[8,63,72.661],
[8,62,71.031],
[8,61,69.409],
[8,60,67.795],
[8,59,66.190],
[8,55,59.869],
[8,50,52.215],
[8,29,23.468],
[8,25,18.615],
[8,21,13.949],
[8,20,12.811],
[8,18,10.569],
[8,16,8.370],
 [9,999,116.901],
[9,69,80.759],
[9,68,79.110],
[9,67,77.464],
[9,66,75.824],
[9,65,74.189],
[9,64,72.560],
[9,63,70.938],
[9,62,69.324],
[9,61,67.718],
[9,60,66.120],
[9,59,64.531],
[9,55,58.273],
[9,50,50.695],
[9,29,22.234],
[9,25,17.430],
[9,21,12.810],
[9,20,11.684],
[9,18,9.463],
[9,16,7.287],
 [10,999,114.740],
[10,69,78.957],
[10,68,77.324],
[10,67,75.695],
[10,66,74.071],
[10,65,72.452],
[10,64,70.839],
[10,63,69.234],
[10,62,67.636],
[10,61,66.045],
[10,60,64.464],
[10,59,62.890],
[10,55,56.694],
[10,50,49.192],
[10,29,21.013],
[10,25,16.257],
[10,21,11.683],
[10,20,10.568],
[10,18,8.369],
[10,16,6.214],
 [11,999,112.599],
[11,69,77.172],
[11,68,75.555],
[11,67,73.942],
[11,66,72.334],
[11,65,70.731],
[11,64,69.135],
[11,63,67.545],
[11,62,65.963],
[11,61,64.388],
[11,60,62.822],
[11,59,61.265],
[11,55,55.130],
[11,50,47.702],
[11,29,19.804],
[11,25,15.095],
[11,21,10.567],
[11,20,9.462],
[11,18,7.286],
[11,16,5.152],
 [12,999,110.479],
[12,69,75.404],
[12,68,73.804],
[12,67,72.207],
[12,66,70.615],
[12,65,69.028],
[12,64,67.447],
[12,63,65.874],
[12,62,64.307],
[12,61,62.748],
[12,60,61.198],
[12,59,59.656],
[12,55,53.582],
[12,50,46.228],
[12,29,18.607],
[12,25,13.945],
[12,21,9.462],
[12,20,8.368],
[12,18,6.214],
[12,16,4.101],
 [13,999,108.382],
[13,69,73.656],
[13,68,72.071],
[13,67,70.490],
[13,66,68.913],
[13,65,67.343],
[13,64,65.778],
[13,63,64.219],
[13,62,62.668],
[13,61,61.125],
[13,60,59.590],
[13,59,58.063],
[13,55,52.050],
[13,50,44.769],
[13,29,17.423],
[13,25,12.806],
[13,21,8.368],
[13,20,7.285],
[13,18,5.152],
[13,16,3.060],
 [14,999,106.308],
[14,69,71.926],
[14,68,70.357],
[14,67,68.791],
[14,66,67.230],
[14,65,65.675],
[14,64,64.126],
[14,63,62.583],
[14,62,61.047],
[14,61,59.519],
[14,60,57.999],
[14,59,56.488],
[14,55,50.534],
[14,50,43.325],
[14,29,16.250],
[14,25,11.679],
[14,21,7.285],
[14,20,6.213],
[14,18,4.101],
[14,16,2.030],
 [15,999,104.253],
[15,69,70.212],
[15,68,68.659],
[15,67,67.109],
[15,66,65.564],
[15,65,64.024],
[15,64,62.490],
[15,63,60.962],
[15,62,59.442],
[15,61,57.929],
[15,60,56.424],
[15,59,54.927],
[15,55,49.033],
[15,50,41.895],
[15,29,15.089],
[15,25,10.563],
[15,21,6.213],
[15,20,5.151],
[15,18,3.060],
[15,16,1.010],
 [16,999,102.222],
[16,69,68.518],
[16,68,66.980],
[16,67,65.445],
[16,66,63.915],
[16,65,62.390],
[16,64,60.872],
[16,63,59.359],
[16,62,57.854],
[16,61,56.356],
[16,60,54.866],
[16,59,53.384],
[16,55,47.548],
[16,50,40.481],
[16,29,13.940],
[16,25,9.459],
[16,21,5.151],
[16,20,4.101],
[16,18,2.030],
 [17,999,100.213],
[17,69,66.841],
[17,68,65.318],
[17,67,63.799],
[17,66,62.284],
[17,65,60.775],
[17,64,59.271],
[17,63,57.773],
[17,62,56.283],
[17,61,54.800],
[17,60,53.324],
[17,59,51.857],
[17,55,46.079],
[17,50,39.082],
[17,29,12.802],
[17,25,8.366],
[17,21,4.100],
[17,20,3.060],
[17,18,1.010],
 [18,999,98.223],
[18,69,65.181],
[18,68,63.673],
[18,67,62.169],
[18,66,60.669],
[18,65,59.174],
[18,64,57.685],
[18,63,56.202],
[18,62,54.727],
[18,61,53.258],
[18,60,51.798],
[18,59,50.345],
[18,55,44.623],
[18,50,37.695],
[18,29,11.675],
[18,25,7.283],
[18,21,3.060],
[18,20,2.030],
 [19,999,96.260],
[19,69,63.542],
[19,68,62.049],
[19,67,60.559],
[19,66,59.074],
[19,65,57.594],
[19,64,56.120],
[19,63,54.652],
[19,62,53.190],
[19,61,51.736],
[19,60,50.290],
[19,59,48.851],
[19,55,43.186],
[19,50,36.326],
[19,29,10.561],
[19,25,6.212],
[19,21,2.030],
[19,20,1.010],
 [20,999,94.313],
[20,69,61.917],
[20,68,60.438],
[20,67,58.963],
[20,66,57.493],
[20,65,56.027],
[20,64,54.567],
[20,63,53.114],
[20,62,51.667],
[20,61,50.227],
[20,60,48.795],
[20,59,47.371],
[20,55,41.761],
[20,50,34.968],
[20,29,9.457],
[20,25,5.150],
[20,21,1.010],
 [21,999,92.388],
[21,69,60.309],
[21,68,58.845],
[21,67,57.385],
[21,66,55.929],
[21,65,54.478],
[21,64,53.032],
[21,63,51.593],
[21,62,50.160],
[21,61,48.734],
[21,60,47.316],
[21,59,45.906],
[21,55,40.351],
[21,50,33.625],
[21,29,8.364],
[21,25,4.100],
 [22,999,90.483],
[22,69,58.719],
[22,68,57.269],
[22,67,55.823],
[22,66,54.381],
[22,65,52.944],
[22,64,51.513],
[22,63,50.088],
[22,62,48.669],
[22,61,47.257],
[22,60,45.853],
[22,59,44.456],
[22,55,38.956],
[22,50,32.296],
[22,29,7.282],
[22,25,3.060],
 [23,999,88.596],
[23,69,57.143],
[23,68,55.707],
[23,67,54.275],
[23,66,52.848],
[23,65,51.425],
[23,64,50.007],
[23,63,48.596],
[23,62,47.191],
[23,61,45.794],
[23,60,44.403],
[23,59,43.020],
[23,55,37.574],
[23,50,30.979],
[23,29,6.211],
[23,25,2.030],
 [24,999,86.728],
[24,69,55.583],
[24,68,54.162],
[24,67,52.744],
[24,66,51.330],
[24,65,49.921],
[24,64,48.518],
[24,63,47.120],
[24,62,45.729],
[24,61,44.345],
[24,60,42.968],
[24,59,41.599],
[24,55,36.206],
[24,50,29.676],
[24,29,5.150],
[24,25,1.010],
 [25,999,84.877],
[25,69,54.038],
[25,68,52.631],
[25,67,51.227],
[25,66,49.827],
[25,65,48.432],
[25,64,47.042],
[25,63,45.658],
[25,62,44.281],
[25,61,42.910],
[25,60,41.547],
[25,59,40.191],
[25,55,34.851],
[25,50,28.385],
[25,29,4.100],
 [26,999,83.048],
[26,69,52.510],
[26,68,51.116],
[26,67,49.726],
[26,66,48.340],
[26,65,46.958],
[26,64,45.582],
[26,63,44.212],
[26,62,42.848],
[26,61,41.491],
[26,60,40.141],
[26,59,38.798],
[26,55,33.510],
[26,50,27.108],
[26,29,3.060],
 [27,999,81.236],
[27,69,50.996],
[27,68,49.616],
[27,67,48.240],
[27,66,46.867],
[27,65,45.499],
[27,64,44.136],
[27,63,42.779],
[27,62,41.429],
[27,61,40.085],
[27,60,38.748],
[27,59,37.419],
[27,55,32.183],
[27,50,25.842],
[27,29,2.030],
 [28,999,79.443],
[28,69,49.499],
[28,68,48.132],
[28,67,46.769],
[28,66,45.410],
[28,65,44.055],
[28,64,42.706],
[28,63,41.362],
[28,62,40.025],
[28,61,38.694],
[28,60,37.370],
[28,59,36.054],
[28,55,30.869],
[28,50,24.590],
[28,29,1.010],
 [29,999,77.668],
[29,69,48.016],
[29,68,46.663],
[29,67,45.313],
[29,66,43.967],
[29,65,42.625],
[29,64,41.289],
[29,63,39.959],
[29,62,38.634],
[29,61,37.317],
[29,60,36.006],
[29,59,34.702],
[29,55,29.568],
[29,50,23.350],
 [30,999,75.915],
[30,69,46.551],
[30,68,45.211],
[30,67,43.874],
[30,66,42.541],
[30,65,41.212],
[30,64,39.889],
[30,63,38.571],
[30,62,37.260],
[30,61,35.955],
[30,60,34.657],
[30,59,33.366],
[30,55,28.281],
[30,50,22.124],
 [31,999,74.178],
[31,69,45.098],
[31,68,43.771],
[31,67,42.447],
[31,66,41.127],
[31,65,39.812],
[31,64,38.501],
[31,63,37.196],
[31,62,35.898],
[31,61,34.605],
[31,60,33.320],
[31,59,32.041],
[31,55,27.006],
[31,50,20.909],
 [32,999,72.459],
[32,69,43.661],
[32,68,42.347],
[32,67,41.036],
[32,66,39.729],
[32,65,38.426],
[32,64,37.128],
[32,63,35.836],
[32,62,34.550],
[32,61,33.270],
[32,60,31.997],
[32,59,30.731],
[32,55,25.744],
[32,50,19.706],
 [33,999,70.757],
[33,69,42.239],
[33,68,40.937],
[33,67,39.639],
[33,66,38.344],
[33,65,37.054],
[33,64,35.769],
[33,63,34.489],
[33,62,33.215],
[33,61,31.948],
[33,60,30.687],
[33,59,29.433],
[33,55,24.495],
[33,50,18.516],
 [34,999,69.076],
[34,69,40.832],
[34,68,39.543],
[34,67,38.257],
[34,66,36.975],
[34,65,35.697],
[34,64,34.424],
[34,63,33.157],
[34,62,31.895],
[34,61,30.640],
[34,60,29.392],
[34,59,28.150],
[34,55,23.259],
[34,50,17.337],
 [35,999,67.413],
[35,69,39.440],
[35,68,38.164],
[35,67,36.890],
[35,66,35.620],
[35,65,34.355],
[35,64,33.094],
[35,63,31.839],
[35,62,30.590],
[35,61,29.346],
[35,60,28.110],
[35,59,26.880],
[35,55,22.036],
[35,50,16.171],  
 [36,999,65.768],
[36,69,38.063],
[36,68,36.798],
[36,67,35.537],
[36,66,34.279],
[36,65,33.026],
[36,64,31.777],
[36,63,30.534],
[36,62,29.297],
[36,61,28.066],
[36,60,26.841],
[36,59,25.623],
[36,55,20.825],
[36,50,15.016],  
 [37,999,64.143],
[37,69,36.701],
[37,68,35.448],
[37,67,34.199],
[37,66,32.953],
[37,65,31.712],
[37,64,30.475],
[37,63,29.244],
[37,62,28.018],
[37,61,26.799],
[37,60,25.586],
[37,59,24.379],
[37,55,19.627],
[37,50,13.873],  
 [38,999,62.537],
[38,69,35.354],
[38,68,34.113],
[38,67,32.876],
[38,66,31.642],
[38,65,30.412],
[38,64,29.187],
[38,63,27.968],
[38,62,26.753],
[38,61,25.545],
[38,60,24.344],
[38,59,23.149],
[38,55,18.442],
[38,50,12.742],  
 [39,999,60.948],
[39,69,34.021],
[39,68,32.792],
[39,67,31.566],
[39,66,30.344],
[39,65,29.126],
[39,64,27.912],
[39,63,26.704],
[39,62,25.501],
[39,61,24.305],
[39,60,23.114],
[39,59,21.931],
[39,55,17.268],
[39,50,11.622],  
 [40,999,59.377],
[40,69,32.702],
[40,68,31.485],
[40,67,30.270],
[40,66,29.060],
[40,65,27.853],
[40,64,26.651],
[40,63,25.454],
[40,62,24.263],
[40,61,23.077],
[40,60,21.898],
[40,59,20.725],
[40,55,16.106],
[40,50,10.513],  
 [41,999,57.824],
[41,69,31.398],
[41,68,30.192],
[41,67,28.989],
[41,66,27.789],
[41,65,26.594],
[41,64,25.403],
[41,63,24.217],
[41,62,23.037],
[41,61,21.862],
[41,60,20.694],
[41,59,19.532],
[41,55,14.956],
[41,50,9.416],  
 [42,999,56.292],
[42,69,30.109],
[42,68,28.914],
[42,67,27.722],
[42,66,26.533],
[42,65,25.349],
[42,64,24.169],
[42,63,22.994],
[42,62,21.825],
[42,61,20.661],
[42,60,19.504],
[42,59,18.352],
[42,55,13.819],
[42,50,8.329],  
 [43,999,54.776],
[43,69,28.833],
[43,68,27.649],
[43,67,26.468],
[43,66,25.290],
[43,65,24.117],
[43,64,22.948],
[43,63,21.783],
[43,62,20.625],
[43,61,19.472],
[43,60,18.325],
[43,59,17.184],
[43,55,12.692],
[43,50,7.253],  
 [44,999,53.283],
[44,69,27.573],
[44,68,26.400],
[44,67,25.230],
[44,66,24.063],
[44,65,22.900],
[44,64,21.741],
[44,63,20.587],
[44,62,19.439],
[44,61,18.297],
[44,60,17.160],
[44,59,16.030],
[44,55,11.578],
[44,50,6.187],  
 [45,999,51.806],
[45,69,26.327],
[45,68,25.164],
[45,67,24.004],
[45,66,22.847],
[45,65,21.695],
[45,64,20.547],
[45,63,19.403],
[45,62,18.265],
[45,61,17.133],
[45,60,16.006],
[45,59,14.886],
[45,55,10.474],
[45,50,5.132],  
 [46,999,50.349],
[46,69,25.095],
[46,68,23.942],
[46,67,22.792],
[46,66,21.646],
[46,65,20.503],
[46,64,19.365],
[46,63,18.232],
[46,62,17.104],
[46,61,15.982],
[46,60,14.865],
[46,59,13.755],
[46,55,9.382],
[46,50,4.087],  
 [47,999,48.912],
[47,69,23.877],
[47,68,22.734],
[47,67,21.594],
[47,66,20.458],
[47,65,19.325],
[47,64,18.197],
[47,63,17.074],
[47,62,15.956],
[47,61,14.843],
[47,60,13.736],
[47,59,12.636],
[47,55,8.300],
[47,50,3.051],  
 [48,999,47.495],
[48,69,22.673],
[48,68,21.540],
[48,67,20.410],
[48,66,19.284],
[48,65,18.161],
[48,64,17.042],
[48,63,15.928],
[48,62,14.820],
[48,61,13.717],
[48,60,12.619],
[48,59,11.528],
[48,55,7.230],
[48,50,2.025],  
 [49,999,46.097],
[49,69,21.483],
[49,68,20.360],
[49,67,19.239],
[49,66,18.122],
[49,65,17.008],
[49,64,15.899],
[49,63,14.795],
[49,62,13.695],
[49,61,12.602],
[49,60,11.513],
[49,59,10.431],
[49,55,6.169],
[49,50,1.008],  
 [50,999,44.718],
[50,69,20.306],
[50,68,19.192],
[50,67,18.081],
[50,66,16.973],
[50,65,15.869],
[50,64,14.768],
[50,63,13.673],
[50,62,12.583],
[50,61,11.498],
[50,60,10.419],
[50,59,9.345],
[50,55,5.118],
 [51,999,43.361],
[51,69,19.144],
[51,68,18.039],
[51,67,16.937],
[51,66,15.837],
[51,65,14.742],
[51,64,13.650],
[51,63,12.564],
[51,62,11.482],
[51,61,10.406],
[51,60,9.335],
[51,59,8.271],
[51,55,4.077],
 [52,999,42.023],
[52,69,17.995],
[52,68,16.899],
[52,67,15.805],
[52,66,14.714],
[52,65,13.627],
[52,64,12.544],
[52,63,11.466],
[52,62,10.393],
[52,61,9.325],
[52,60,8.263],
[52,59,7.206],
[52,55,3.046],
 [53,999,40.702],
[53,69,16.857],
[53,68,15.769],
[53,67,14.684],
[53,66,13.601],
[53,65,12.523],
[53,64,11.448],
[53,63,10.378],
[53,62,9.313],
[53,61,8.254],
[53,60,7.200],
[53,59,6.151],
[53,55,2.022],
 [54,999,39.397],
[54,69,15.731],
[54,68,14.651],
[54,67,13.574],
[54,66,12.500],
[54,65,11.429],
[54,64,10.363],
[54,63,9.301],
[54,62,8.244],
[54,61,7.192],
[54,60,6.146],
[54,59,5.105],
[54,55,1.007],
 [55,999,38.111],
[55,69,14.617],
[55,68,13.545],
[55,67,12.475],
[55,66,11.409],
[55,65,10.346],
[55,64,9.287],
[55,63,8.233],
[55,62,7.184],
[55,61,6.140],
[55,60,5.101],
[55,59,4.068],
 [56,999,36.845],
[56,69,13.515],
[56,68,12.450],
[56,67,11.388],
[56,66,10.329],
[56,65,9.274],
[56,64,8.222],
[56,63,7.176],
[56,62,6.134],
[56,61,5.097],
[56,60,4.065],
[56,59,3.040],
 [57,999,35.597],
[57,69,12.424],
[57,68,11.367],
[57,67,10.312],
[57,66,9.260],
[57,65,8.211],
[57,64,7.167],
[57,63,6.127],
[57,62,5.092],
[57,61,4.063],
[57,60,3.038],
[57,59,2.019],
 [58,999,34.368],
[58,69,11.344],
[58,68,10.293],
[58,67,9.245],
[58,66,8.200],
[58,65,7.159],
[58,64,6.121],
[58,63,5.088],
[58,62,4.060],
[58,61,3.036],
[58,60,2.019],
[58,59,1.006],
 [59,999,33.153],
[59,69,10.273],
[59,68,9.229],
[59,67,8.187],
[59,66,7.149],
[59,65,6.114],
[59,64,5.083],
[59,63,4.056],
[59,62,3.034],
[59,61,2.017],
[59,60,1.006],
 [60,999,31.958],
[60,69,9.213],
[60,68,8.175],
[60,67,7.139],
[60,66,6.107],
[60,65,5.078],
[60,64,4.053],
[60,63,3.032],
[60,62,2.016],
[60,61,1.006],
 [61,999,30.783],
[61,69,8.162],
[61,68,7.130],
[61,67,6.100],
[61,66,5.073],
[61,65,4.050],
[61,64,3.031],
[61,63,2.016],
[61,62,1.005],
 [62,999,29.621],
[62,69,7.119],
[62,68,6.092],
[62,67,5.068],
[62,66,4.047],
[62,65,3.029],
[62,64,2.015],
[62,63,1.005],
 [63,999,28.474],
[63,69,6.084],
[63,68,5.062],
[63,67,4.043],
[63,66,3.027],
[63,65,2.014],
[63,64,1.005],
 [64,999,27.342],
[64,69,5.056],
[64,68,4.039],
[64,67,3.024],
[64,66,2.012],
[64,65,1.004],
 [65,999,26.225],
[65,69,4.034],
[65,68,3.021],
[65,67,2.011],
[65,66,1.004],
 [66,999,25.125],
[66,69,3.019],
[66,68,2.010],
[66,67,1.003],
 [67,999,24.038],
[67,69,2.008],
[67,68,1.003],
 [68,999,22.967],
[68,69,1.002],
 [69,999,21.912],
 [70,999,20.870],
 [71,999,19.844],
 [72,999,18.838],
 [73,999,17.851],
 [74,999,16.879],
 [75,999,15.928],     	
 [76,999,14.996],     	
 [77,999,14.082],     	
 [78,999,13.191],     	
 [79,999,12.322],     	
 [80,999,11.480],     	
 [81,999,10.667],     	
 [82,999,9.886],     	
 [83,999,9.130],     	
 [84,999,8.408],     	
 [85,999,7.721],     	
 [86,999,7.068],     	
 [87,999,6.455],     	
 [88,999,5.883],     	
 [89,999,5.349],     	
 [90,999,4.857],     	
 [91,999,4.405],     	
 [92,999,3.989],     	
 [93,999,3.600],     	
 [94,999,3.251],     	
 [95,999,2.934],     	
 [96,999,2.646],     	
 [97,999,2.382],     	
 [98,999,2.129],     	
 [99,999,1.895],     	
 [100,999,1.640],     	
 [101,999,1.371],     	
 [102,999,1.050],     	
 [103,999,0.624],     	
 ]);
gazPal2022txm1.set("M", [
 [0,999,123.701],
[0,69,95.097],
[0,68,93.491],
[0,67,91.876],
[0,66,90.252],
[0,65,88.622],
[0,64,86.985],
[0,63,85.345],
[0,62,83.701],
[0,61,82.055],
[0,60,80.409],
[0,59,78.764],
[0,55,72.221],
[0,50,64.197],
[0,29,33.588],
[0,25,28.379],
[0,21,23.361],
[0,20,22.136],
[0,18,19.721],
[0,16,17.352],
 [1,999,121.994],
[1,69,93.553],
[1,68,91.957],
[1,67,90.351],
[1,66,88.737],
[1,65,87.115],
[1,64,85.488],
[1,63,83.857],
[1,62,82.223],
[1,61,80.586],
[1,60,78.950],
[1,59,77.314],
[1,55,70.808],
[1,50,62.830],
[1,29,32.396],
[1,25,27.216],
[1,21,22.228],
[1,20,21.010],
[1,18,18.609],
[1,16,16.253],
 [2,999,119.812],
[2,69,91.647],
[2,68,90.066],
[2,67,88.475],
[2,66,86.877],
[2,65,85.271],
[2,64,83.660],
[2,63,82.044],
[2,62,80.426],
[2,61,78.805],
[2,60,77.184],
[2,59,75.565],
[2,55,69.122],
[2,50,61.221],
[2,29,31.082],
[2,25,25.953],
[2,21,21.012],
[2,20,19.806],
[2,18,17.428],
[2,16,15.096],
 [3,999,117.637],
[3,69,89.748],
[3,68,88.183],
[3,67,86.608],
[3,66,85.025],
[3,65,83.435],
[3,64,81.840],
[3,63,80.240],
[3,62,78.637],
[3,61,77.033],
[3,60,75.428],
[3,59,73.824],
[3,55,67.444],
[3,50,59.621],
[3,29,29.777],
[3,25,24.698],
[3,21,19.807],
[3,20,18.612],
[3,18,16.257],
[3,16,13.948],
 [4,999,115.477],
[4,69,87.863],
[4,68,86.314],
[4,67,84.754],
[4,66,83.187],
[4,65,81.612],
[4,64,80.033],
[4,63,78.449],
[4,62,76.862],
[4,61,75.273],
[4,60,73.684],
[4,59,72.096],
[4,55,65.779],
[4,50,58.034],
[4,29,28.484],
[4,25,23.455],
[4,21,18.611],
[4,20,17.429],
[4,18,15.097],
[4,16,12.810],
 [5,999,113.335],
[5,69,85.994],
[5,68,84.460],
[5,67,82.916],
[5,66,81.364],
[5,65,79.805],
[5,64,78.241],
[5,63,76.673],
[5,62,75.102],
[5,61,73.529],
[5,60,71.955],
[5,59,70.383],
[5,55,64.129],
[5,50,56.460],
[5,29,27.202],
[5,25,22.223],
[5,21,17.427],
[5,20,16.256],
[5,18,13.948],
[5,16,11.683],
 [6,999,111.213],
[6,69,84.143],
[6,68,82.624],
[6,67,81.095],
[6,66,79.558],
[6,65,78.015],
[6,64,76.466],
[6,63,74.914],
[6,62,73.358],
[6,61,71.801],
[6,60,70.243],
[6,59,68.686],
[6,55,62.494],
[6,50,54.901],
[6,29,25.933],
[6,25,21.003],
[6,21,16.255],
[6,20,15.095],
[6,18,12.810],
[6,16,10.568],
 [7,999,109.111],
[7,69,82.309],
[7,68,80.805],
[7,67,79.291],
[7,66,77.770],
[7,65,76.242],
[7,64,74.709],
[7,63,73.171],
[7,62,71.631],
[7,61,70.089],
[7,60,68.547],
[7,59,67.006],
[7,55,60.874],
[7,50,53.356],
[7,29,24.676],
[7,25,19.795],
[7,21,15.094],
[7,20,13.946],
[7,18,11.683],
[7,16,9.463],
 [8,999,107.029],
[8,69,80.493],
[8,68,79.003],
[8,67,77.505],
[8,66,75.998],
[8,65,74.486],
[8,64,72.968],
[8,63,71.446],
[8,62,69.921],
[8,61,68.394],
[8,60,66.867],
[8,59,65.341],
[8,55,59.271],
[8,50,51.827],
[8,29,23.431],
[8,25,18.598],
[8,21,13.944],
[8,20,12.807],
[8,18,10.567],
[8,16,8.369],
 [9,999,104.966],
[9,69,78.693],
[9,68,77.219],
[9,67,75.735],
[9,66,74.244],
[9,65,72.746],
[9,64,71.243],
[9,63,69.736],
[9,62,68.226],
[9,61,66.715],
[9,60,65.203],
[9,59,63.692],
[9,55,57.682],
[9,50,50.312],
[9,29,22.198],
[9,25,17.414],
[9,21,12.805],
[9,20,11.680],
[9,18,9.462],
[9,16,7.286],
 [10,999,102.923],
[10,69,76.912],
[10,68,75.452],
[10,67,73.983],
[10,66,72.506],
[10,65,71.024],
[10,64,69.535],
[10,63,68.044],
[10,62,66.549],
[10,61,65.052],
[10,60,63.555],
[10,59,62.059],
[10,55,56.109],
[10,50,48.813],
[10,29,20.978],
[10,25,16.241],
[10,21,11.678],
[10,20,10.564],
[10,18,8.368],
[10,16,6.214],
 [11,999,100.901],
[11,69,75.148],
[11,68,73.703],
[11,67,72.248],
[11,66,70.786],
[11,65,69.318],
[11,64,67.845],
[11,63,66.368],
[11,62,64.888],
[11,61,63.406],
[11,60,61.924],
[11,59,60.443],
[11,55,54.552],
[11,50,47.328],
[11,29,19.770],
[11,25,15.079],
[11,21,10.562],
[11,20,9.459],
[11,18,7.285],
[11,16,5.152],
 [12,999,98.901],
[12,69,73.403],
[12,68,71.972],
[12,67,70.532],
[12,66,69.085],
[12,65,67.631],
[12,64,66.173],
[12,63,64.710],
[12,62,63.245],
[12,61,61.778],
[12,60,60.310],
[12,59,58.844],
[12,55,53.011],
[12,50,45.859],
[12,29,18.574],
[12,25,13.930],
[12,21,9.458],
[12,20,8.366],
[12,18,6.213],
[12,16,4.101],
 [13,999,96.921],
[13,69,71.676],
[13,68,70.259],
[13,67,68.833],
[13,66,67.400],
[13,65,65.961],
[13,64,64.517],
[13,63,63.069],
[13,62,61.618],
[13,61,60.166],
[13,60,58.713],
[13,59,57.261],
[13,55,51.486],
[13,50,44.405],
[13,29,17.390],
[13,25,12.792],
[13,21,8.364],
[13,20,7.283],
[13,18,5.151],
[13,16,3.060],
 [14,999,94.962],
[14,69,69.966],
[14,68,68.563],
[14,67,67.152],
[14,66,65.733],
[14,65,64.308],
[14,64,62.878],
[14,63,61.444],
[14,62,60.008],
[14,61,58.570],
[14,60,57.132],
[14,59,55.694],
[14,55,49.976],
[14,50,42.965],
[14,29,16.217],
[14,25,11.665],
[14,21,7.281],
[14,20,6.211],
[14,18,4.100],
[14,16,2.030],
 [15,999,93.025],
[15,69,68.276],
[15,68,66.887],
[15,67,65.490],
[15,66,64.085],
[15,65,62.674],
[15,64,61.258],
[15,63,59.839],
[15,62,58.416],
[15,61,56.992],
[15,60,55.568],
[15,59,54.145],
[15,55,48.483],
[15,50,41.541],
[15,29,15.057],
[15,25,10.550],
[15,21,6.209],
[15,20,5.149],
[15,18,3.060],
[15,16,1.010],
 [16,999,91.113],
[16,69,66.606],
[16,68,65.231],
[16,67,63.847],
[16,66,62.456],
[16,65,61.059],
[16,64,59.657],
[16,63,58.252],
[16,62,56.843],
[16,61,55.433],
[16,60,54.023],
[16,59,52.614],
[16,55,47.008],
[16,50,40.134],
[16,29,13.910],
[16,25,9.447],
[16,21,5.148],
[16,20,4.099],
[16,18,2.030],	
 [17,999,89.223],
[17,69,64.956],
[17,68,63.594],
[17,67,62.224],
[17,66,60.846],
[17,65,59.463],
[17,64,58.075],
[17,63,56.683],
[17,62,55.288],
[17,61,53.892],
[17,60,52.496],
[17,59,51.100],
[17,55,45.549],
[17,50,38.742],
[17,29,12.774],
[17,25,8.354],
[17,21,4.098],
[17,20,3.059],
[17,18,1.010],	
 [18,999,87.357],
[18,69,63.326],
[18,68,61.977],
[18,67,60.620],
[18,66,59.256],
[18,65,57.886],
[18,64,56.511],
[18,63,55.133],
[18,62,53.751],
[18,61,52.369],
[18,60,50.986],
[18,59,49.604],
[18,55,44.107],
[18,50,37.366],
[18,29,11.650],
[18,25,7.273],
[18,21,3.058],
[18,20,2.029], 
 [19,999,85.517],
[19,69,61.717],
[19,68,60.381],
[19,67,59.037],
[19,66,57.686],
[19,65,56.329],
[19,64,54.968],
[19,63,53.603],
[19,62,52.235],
[19,61,50.866],
[19,60,49.496],
[19,59,48.127],
[19,55,42.683],
[19,50,36.007],
[19,29,10.538],
[19,25,6.203],
[19,21,2.029],
[19,20,1.010], 
 [20,999,83.706],
[20,69,60.131],
[20,68,58.808],
[20,67,57.477],
[20,66,56.139],
[20,65,54.795],
[20,64,53.446],
[20,63,52.094],
[20,62,50.739],
[20,61,49.383],
[20,60,48.026],
[20,59,46.670],
[20,55,41.278],
[20,50,34.665],
[20,29,9.438],
[20,25,5.145],
[20,21,1.010], 	
 [21,999,81.913],
[21,69,58.562],
[21,68,57.251],
[21,67,55.932],
[21,66,54.607],
[21,65,53.276],
[21,64,51.940],
[21,63,50.600],
[21,62,49.259],
[21,61,47.915],
[21,60,46.571],
[21,59,45.228],
[21,55,39.887],
[21,50,33.337],
[21,29,8.349],
[21,25,4.096], 
 [22,999,80.143],
[22,69,57.011],
[22,68,55.713],
[22,67,54.407],
[22,66,53.094],
[22,65,51.775],
[22,64,50.451],
[22,63,49.125],
[22,62,47.795],
[22,61,46.465],
[22,60,45.133],
[22,59,43.803],
[22,55,38.512],
[22,50,32.023],
[22,29,7.270],
[22,25,3.057], 
 [23,999,78.388],
[23,69,55.474],
[23,68,54.188],
[23,67,52.894],
[23,66,51.593],
[23,65,50.287],
[23,64,48.976],
[23,63,47.662],
[23,62,46.345],
[23,61,45.027],
[23,60,43.708],
[23,59,42.391],
[23,55,37.149],
[23,50,30.722],
[23,29,6.202],
[23,25,2.029], 
 [24,999,76.651],
[24,69,53.953],
[24,68,52.679],
[24,67,51.397],
[24,66,50.108],
[24,65,48.814],
[24,64,47.516],
[24,63,46.214],
[24,62,44.910],
[24,61,43.604],
[24,60,42.297],
[24,59,40.992],
[24,55,35.800],
[24,50,29.433],
[24,29,5.143],
[24,25,1.009], 
 [25,999,74.931],
[25,69,52.446],
[25,68,51.184],
[25,67,49.914],
[25,66,48.638],
[25,65,47.356],
[25,64,46.070],
[25,63,44.780],
[25,62,43.488],
[25,61,42.194],
[25,60,40.900],
[25,59,39.607],
[25,55,34.464],
[25,50,28.156],
[25,29,4.095], 	
 [26,999,73.231],
[26,69,50.956],
[26,68,49.705],
[26,67,48.448],
[26,66,47.183],
[26,65,45.913],
[26,64,44.639],
[26,63,43.361],
[26,62,42.081],
[26,61,40.800],
[26,60,39.518],
[26,59,38.237],
[26,55,33.141],
[26,50,26.893],
[26,29,3.057], 	
 [27,999,71.551],
[27,69,49.483],
[27,68,48.244],
[27,67,46.998],
[27,66,45.745],
[27,65,44.487],
[27,64,43.225],
[27,63,41.959],
[27,62,40.691],
[27,61,39.421],
[27,60,38.151],
[27,59,36.882],
[27,55,31.834],
[27,50,25.644],
[27,29,2.028], 	
 [28,999,69.882],
[28,69,48.020],
[28,68,46.793],
[28,67,45.559],
[28,66,44.318],
[28,65,43.071],
[28,64,41.821],
[28,63,40.567],
[28,62,39.310],
[28,61,38.053],
[28,60,36.795],
[28,59,35.537],
[28,55,30.536],
[28,50,24.404],
[28,29,1.009], 	
 [29,999,68.233],
[29,69,46.574],
[29,68,45.359],
[29,67,44.136],
[29,66,42.906],
[29,65,41.671],
[29,64,40.432],
[29,63,39.190],
[29,62,37.945],
[29,61,36.699],
[29,60,35.453],
[29,59,34.207],
[29,55,29.253],
[29,50,23.177],  
 [30,999,66.602],
[30,69,45.144],
[30,68,43.939],
[30,67,42.727],
[30,66,41.509],
[30,65,40.286],
[30,64,39.058],
[30,63,37.828],
[30,62,36.594],
[30,61,35.360],
[30,60,34.125],
[30,59,32.891],
[30,55,27.982],
[30,50,21.963],  
 [31,999,64.988],
[31,69,43.727],
[31,68,42.534],
[31,67,41.333],
[31,66,40.126],
[31,65,38.914],
[31,64,37.698],
[31,63,36.479],
[31,62,35.257],
[31,61,34.034],
[31,60,32.810],
[31,59,31.587],
[31,55,26.724],
[31,50,20.760],  
 [32,999,63.393],
[32,69,42.327],
[32,68,41.144],
[32,67,39.955],
[32,66,38.759],
[32,65,37.558],
[32,64,36.353],
[32,63,35.145],
[32,62,33.934],
[32,61,32.722],
[32,60,31.510],
[32,59,30.298],
[32,55,25.479],
[32,50,19.570],  
 [33,999,61.814],
[33,69,40.941],
[33,68,39.769],
[33,67,38.590],
[33,66,37.405],
[33,65,36.215],
[33,64,35.021],
[33,63,33.824],
[33,62,32.624],
[33,61,31.424],
[33,60,30.222],
[33,59,29.022],
[33,55,24.247],
[33,50,18.392],  
 [34,999,60.252],
[34,69,39.568],
[34,68,38.407],
[34,67,37.239],
[34,66,36.065],
[34,65,34.886],
[34,64,33.703],
[34,63,32.516],
[34,62,31.328],
[34,61,30.138],
[34,60,28.947],
[34,59,27.758],
[34,55,23.026],
[34,50,17.224],  
 [35,999,58.706],
[35,69,38.210],
[35,68,37.059],
[35,67,35.902],
[35,66,34.738],
[35,65,33.570],
[35,64,32.397],
[35,63,31.222],
[35,62,30.044],
[35,61,28.865],
[35,60,27.685],
[35,59,26.506],
[35,55,21.818],
[35,50,16.068],  
 [36,999,57.180],
[36,69,36.867],
[36,68,35.727],
[36,67,34.580],
[36,66,33.427],
[36,65,32.269],
[36,64,31.107],
[36,63,29.942],
[36,62,28.774],
[36,61,27.606],
[36,60,26.437],
[36,59,25.269],
[36,55,20.622],
[36,50,14.924],  
 [37,999,55.670],
[37,69,35.539],
[37,68,34.409],
[37,67,33.272],
[37,66,32.129],
[37,65,30.981],
[37,64,29.830],
[37,63,28.675],
[37,62,27.518],
[37,61,26.360],
[37,60,25.201],
[37,59,24.044],
[37,55,19.438],
[37,50,13.791],  
 [38,999,54.180],
[38,69,34.226],
[38,68,33.106],
[38,67,31.979],
[38,66,30.846],
[38,65,29.708],
[38,64,28.567],
[38,63,27.422],
[38,62,26.276],
[38,61,25.128],
[38,60,23.979],
[38,59,22.832],
[38,55,18.267],
[38,50,12.670],  
 [39,999,52.705],
[39,69,32.926],
[39,68,31.816],
[39,67,30.698],
[39,66,29.576],
[39,65,28.448],
[39,64,27.317],
[39,63,26.182],
[39,62,25.045],
[39,61,23.907],
[39,60,22.769],
[39,59,21.632],
[39,55,17.107],
[39,50,11.559],  
 [40,999,51.250],
[40,69,31.641],
[40,68,30.541],
[40,67,29.433],
[40,66,28.320],
[40,65,27.202],
[40,64,26.081],
[40,63,24.956],
[40,62,23.829],
[40,61,22.701],
[40,60,21.572],
[40,59,20.445],
[40,55,15.959],
[40,50,10.459],  
 [41,999,49.810],
[41,69,30.369],
[41,68,29.278],
[41,67,28.180],
[41,66,27.077],
[41,65,25.969],
[41,64,24.857],
[41,63,23.742],
[41,62,22.624],
[41,61,21.506],
[41,60,20.387],
[41,59,19.269],
[41,55,14.822],
[41,50,9.369],  
 [42,999,48.388],
[42,69,29.112],
[42,68,28.031],
[42,67,26.942],
[42,66,25.848],
[42,65,24.749],
[42,64,23.646],
[42,63,22.541],
[42,62,21.433],
[42,61,20.324],
[42,60,19.215],
[42,59,18.106],
[42,55,13.697],
[42,50,8.290],  
 [43,999,46.985],
[43,69,27.870],
[43,68,26.797],
[43,67,25.717],
[43,66,24.632],
[43,65,23.543],
[43,64,22.449],
[43,63,21.353],
[43,62,20.254],
[43,61,19.154],
[43,60,18.054],
[43,59,16.955],
[43,55,12.582],
[43,50,7.221],  
 [44,999,45.604],
[44,69,26.644],
[44,68,25.579],
[44,67,24.509],
[44,66,23.432],
[44,65,22.352],
[44,64,21.267],
[44,63,20.179],
[44,62,19.090],
[44,61,17.999],
[44,60,16.908],
[44,59,15.818],
[44,55,11.480],
[44,50,6.162],  
 [45,999,44.240],
[45,69,25.431],
[45,68,24.376],
[45,67,23.314],
[45,66,22.246],
[45,65,21.174],
[45,64,20.098],
[45,63,19.019],
[45,62,17.938],
[45,61,16.856],
[45,60,15.773],
[45,59,14.692],
[45,55,10.389],
[45,50,5.113],  
 [46,999,42.900],
[46,69,24.235],
[46,68,23.188],
[46,67,22.134],
[46,66,21.075],
[46,65,20.010],
[46,64,18.943],
[46,63,17.872],
[46,62,16.800],
[46,61,15.726],
[46,60,14.652],
[46,59,13.578],
[46,55,9.309],
[46,50,4.074],  
 [47,999,41.576],
[47,69,23.053],
[47,68,22.013],
[47,67,20.967],
[47,66,19.916],
[47,65,18.860],
[47,64,17.800],
[47,63,16.737],
[47,62,15.673],
[47,61,14.607],
[47,60,13.541],
[47,59,12.476],
[47,55,8.239],
[47,50,3.043],  
 [48,999,40.275],
[48,69,21.886],
[48,68,20.854],
[48,67,19.815],
[48,66,18.771],
[48,65,17.723],
[48,64,16.671],
[48,63,15.616],
[48,62,14.559],
[48,61,13.501],
[48,60,12.443],
[48,59,11.386],
[48,55,7.179],
[48,50,2.021],  
 [49,999,38.992],
[49,69,20.732],
[49,68,19.707],
[49,67,18.676],
[49,66,17.639],
[49,65,16.598],
[49,64,15.554],
[49,63,14.507],
[49,62,13.457],
[49,61,12.407],
[49,60,11.356],
[49,59,10.306],
[49,55,6.129],
[49,50,1.007],  
 [50,999,37.734],
[50,69,19.595],
[50,68,18.577],
[50,67,17.552],
[50,66,16.523],
[50,65,15.488],
[50,64,14.451],
[50,63,13.410],
[50,62,12.368],
[50,61,11.324],
[50,60,10.281],
[50,59,9.237],
[50,55,5.088],  	
 [51,999,36.497],
[51,69,18.472],
[51,68,17.460],
[51,67,16.442],
[51,66,15.419],
[51,65,14.391],
[51,64,13.360],
[51,63,12.326],
[51,62,11.291],
[51,61,10.254],
[51,60,9.216],
[51,59,8.180],
[51,55,4.056],  	
 [52,999,35.279],
[52,69,17.362],
[52,68,16.356],
[52,67,15.344],
[52,66,14.327],
[52,65,13.305],
[52,64,12.280],
[52,63,11.253],
[52,62,10.223],
[52,61,9.192],
[52,60,8.161],
[52,59,7.131],
[52,55,3.032],  	
 [53,999,34.083],
[53,69,16.265],
[53,68,15.265],
[53,67,14.259],
[53,66,13.247],
[53,65,12.231],
[53,64,11.212],
[53,63,10.190],
[53,62,9.166],
[53,61,8.141],
[53,60,7.116],
[53,59,6.091],
[53,55,2.015],  	
 [54,999,32.909],
[54,69,15.182],
[54,68,14.187],
[54,67,13.186],
[54,66,12.180],
[54,65,11.169],
[54,64,10.155],
[54,63,9.138],
[54,62,8.119],
[54,61,7.099],
[54,60,6.079],
[54,59,5.060],
[54,55,1.005],  	
 [55,999,31.753],
[55,69,14.110],
[55,68,13.120],
[55,67,12.123],
[55,66,11.122],
[55,65,10.116],
[55,64,9.107],
[55,63,8.095],
[55,62,7.081],
[55,61,6.066],
[55,60,5.051],
[55,59,4.036],  
 [56,999,30.620],
[56,69,13.051],
[56,68,12.065],
[56,67,11.073],
[56,66,10.075],
[56,65,9.074],
[56,64,8.069],
[56,63,7.061],
[56,62,6.051],
[56,61,5.040],
[56,60,4.029],
[56,59,3.019],  
 [57,999,29.511],
[57,69,12.004],
[57,68,11.022],
[57,67,10.033],
[57,66,9.040],
[57,65,8.041],
[57,64,7.040],
[57,63,6.036],
[57,62,5.030],
[57,61,4.023],
[57,60,3.015],
[57,59,2.008],  
 [58,999,28.422],
[58,69,10.968],
[58,68,9.988],
[58,67,9.003],
[58,66,8.012],
[58,65,7.017],
[58,64,6.019],
[58,63,5.018],
[58,62,4.014],
[58,61,3.010],
[58,60,2.006],
[58,59,1.002],  
 [59,999,27.359],
[59,69,9.944],
[59,68,8.966],
[59,67,7.983],
[59,66,6.994],
[59,65,6.002],
[59,64,5.005],
[59,63,4.006],
[59,62,3.006],
[59,61,2.004],
[59,60,1.001],  	
 [60,999,26.318],
[60,69,8.929],
[60,68,7.953],
[60,67,6.971],
[60,66,5.984],
[60,65,4.993],
[60,64,3.998],
[60,63,3.000],
[60,62,2.001],
[60,61,1.001],   
 [61,999,25.299],
[61,69,7.922],
[61,68,6.947],
[61,67,5.966],
[61,66,4.980],
[61,65,3.989],
[61,64,2.995],
[61,63,1.998],
[61,62,1.000],   	
 [62,999,24.306],
[62,69,6.925],
[62,68,5.949],
[62,67,4.968],
[62,66,3.981],
[62,65,2.990],
[62,64,1.996],
[62,63,0.999],   
 [63,999,23.334],
[63,69,5.933],
[63,68,4.956],
[63,67,3.973],
[63,66,2.986],
[63,65,1.993],
[63,64,0.998],   	
 [64,999,22.381],
[64,69,4.944],
[64,68,3.966],
[64,67,2.981],
[64,66,1.992],
[64,65,0.997],    
 [65,999,21.437],
[65,69,3.957],
[65,68,2.976],
[65,67,1.989],
[65,66,0.997],    	
 [66,999,20.511],
[66,69,2.971],
[66,68,1.986],
[66,67,0.996],    
 [67,999,19.601],
[67,69,1.984],
[67,68,0.995],    	
 [68,999,18.701],
[68,69,0.994],     
 [69,999,17.818],     	
 [70,999,16.949],     	
 [71,999,16.095],     	
 [72,999,15.255],     	
 [73,999,14.429],     	
 [74,999,13.621],     	
 [75,999,12.830],     	
 [76,999,12.054],     	
 [77,999,11.299],     	
 [78,999,10.563],     	
 [79,999,9.850],     	
 [80,999,9.163],     	
 [81,999,8.491],     	
 [82,999,7.852],     	
 [83,999,7.236],     	
 [84,999,6.654],     	
 [85,999,6.105],     	
 [86,999,5.585],     	
 [87,999,5.099],     	
 [88,999,4.648],     	
 [89,999,4.234],     	
 [90,999,3.856],     	
 [91,999,3.512],     	
 [92,999,3.195],     	
 [93,999,2.912],     	
 [94,999,2.648],     	
 [95,999,2.404],     	
 [96,999,2.198],     	
 [97,999,2.021],     	
 [98,999,1.875],     	
 [99,999,1.728],     	
 [100,999,1.598],     	
 [101,999,1.373],     	
 [102,999,1.064],     	
 [103,999,0.663],     	
 
]);

export const gazPal2022tx0 = new Map();
gazPal2022tx0.set("F", [
 [0,999,84.963],
[0,69,67.510],
[0,68,66.611],
[0,67,65.705],
[0,66,64.793],
[0,65,63.875],
[0,64,62.951],
[0,63,62.022],
[0,62,61.088],
[0,61,60.149],
[0,60,59.205],
[0,59,58.257],
[0,55,54.430],
[0,50,49.580],
[0,29,28.857],
[0,25,24.885],
[0,21,20.909],
[0,20,19.914],
[0,18,17.925],
[0,16,15.934],
[1,999,84.248],
[1,69,66.736],
[1,68,65.834],
[1,67,64.925],
[1,66,64.010],
[1,65,63.089],
[1,64,62.162],
[1,63,61.230],
[1,62,60.292],
[1,61,59.350],
[1,60,58.404],
[1,59,57.453],
[1,55,53.612],
[1,50,48.747],
[1,29,27.954],
[1,25,23.968],
[1,21,19.979],
[1,20,18.981],
[1,18,16.985],
[1,16,14.988],
[2,999,83.267],
[2,69,65.751],
[2,68,64.849],
[2,67,63.940],
[2,66,63.025],
[2,65,62.104],
[2,64,61.176],
[2,63,60.244],
[2,62,59.306],
[2,61,58.364],
[2,60,57.417],
[2,59,56.466],
[2,55,52.624],
[2,50,47.758],
[2,29,26.961],
[2,25,22.974],
[2,21,18.983],
[2,20,17.985],
[2,18,15.989],
[2,16,13.991],
[3,999,82.278],
[3,69,64.760],
[3,68,63.858],
[3,67,62.949],
[3,66,62.033],
[3,65,61.112],
[3,64,60.184],
[3,63,59.252],
[3,62,58.314],
[3,61,57.372],
[3,60,56.425],
[3,59,55.473],
[3,55,51.631],
[3,50,46.764],
[3,29,25.964],
[3,25,21.977],
[3,21,17.986],
[3,20,16.988],
[3,18,14.991],
[3,16,12.993],
[4,999,81.288],
[4,69,63.768],
[4,68,62.865],
[4,67,61.956],
[4,66,61.041],
[4,65,60.119],
[4,64,59.192],
[4,63,58.259],
[4,62,57.321],
[4,61,56.378],
[4,60,55.431],
[4,59,54.480],
[4,55,50.637],
[4,50,45.770],
[4,29,24.967],
[4,25,20.979],
[4,21,16.988],
[4,20,15.990],
[4,18,13.993],
[4,16,11.995],
[5,999,80.295],
[5,69,62.773],
[5,68,61.870],
[5,67,60.961],
[5,66,60.046],
[5,65,59.124],
[5,64,58.196],
[5,63,57.264],
[5,62,56.326],
[5,61,55.383],
[5,60,54.436],
[5,59,53.484],
[5,55,49.642],
[5,50,44.773],
[5,29,23.969],
[5,25,19.981],
[5,21,15.989],
[5,20,14.991],
[5,18,12.994],
[5,16,10.996],
[6,999,79.301],
[6,69,61.778],
[6,68,60.875],
[6,67,59.966],
[6,66,59.050],
[6,65,58.129],
[6,64,57.201],
[6,63,56.268],
[6,62,55.330],
[6,61,54.387],
[6,60,53.440],
[6,59,52.489],
[6,55,48.646],
[6,50,43.777],
[6,29,22.971],
[6,25,18.983],
[6,21,14.991],
[6,20,13.992],
[6,18,11.995],
[6,16,9.997],
[7,999,78.307],
[7,69,60.782],
[7,68,59.880],
[7,67,58.970],
[7,66,58.055],
[7,65,57.133],
[7,64,56.205],
[7,63,55.272],
[7,62,54.334],
[7,61,53.391],
[7,60,52.444],
[7,59,51.492],
[7,55,47.649],
[7,50,42.780],
[7,29,21.973],
[7,25,17.984],
[7,21,13.992],
[7,20,12.993],
[7,18,10.996],
[7,16,8.997],
[8,999,77.311],
[8,69,59.785],
[8,68,58.883],
[8,67,57.973],
[8,66,57.057],
[8,65,56.136],
[8,64,55.208],
[8,63,54.275],
[8,62,53.337],
[8,61,52.394],
[8,60,51.447],
[8,59,50.495],
[8,55,46.651],
[8,50,41.782],
[8,29,20.974],
[8,25,16.985],
[8,21,12.993],
[8,20,11.994],
[8,18,9.996],
[8,16,7.998],
[9,999,76.314],
[9,69,58.788],
[9,68,57.886],
[9,67,56.976],
[9,66,56.060],
[9,65,55.138],
[9,64,54.211],
[9,63,53.278],
[9,62,52.339],
[9,61,51.397],
[9,60,50.449],
[9,59,49.497],
[9,55,45.654],
[9,50,40.784],
[9,29,19.975],
[9,25,15.986],
[9,21,11.993],
[9,20,10.995],
[9,18,8.997],
[9,16,6.998],
[10,999,75.320],
[10,69,57.792],
[10,68,56.890],
[10,67,55.980],
[10,66,55.064],
[10,65,54.142],
[10,64,53.214],
[10,63,52.281],
[10,62,51.343],
[10,61,50.400],
[10,60,49.453],
[10,59,48.501],
[10,55,44.657],
[10,50,39.787],
[10,29,18.976],
[10,25,14.987],
[10,21,10.994],
[10,20,9.995],
[10,18,7.997],
[10,16,5.999],
[11,999,74.324],
[11,69,56.795],
[11,68,55.893],
[11,67,54.983],
[11,66,54.067],
[11,65,53.145],
[11,64,52.217],
[11,63,51.284],
[11,62,50.346],
[11,61,49.403],
[11,60,48.455],
[11,59,47.503],
[11,55,43.659],
[11,50,38.789],
[11,29,17.977],
[11,25,13.988],
[11,21,9.995],
[11,20,8.996],
[11,18,6.998],
[11,16,4.999],
[12,999,73.328],
[12,69,55.799],
[12,68,54.896],
[12,67,53.986],
[12,66,53.070],
[12,65,52.148],
[12,64,51.220],
[12,63,50.287],
[12,62,49.349],
[12,61,48.406],
[12,60,47.458],
[12,59,46.506],
[12,55,42.662],
[12,50,37.792],
[12,29,16.978],
[12,25,12.989],
[12,21,8.995],
[12,20,7.996],
[12,18,5.998],
[12,16,3.999],
[13,999,72.333],
[13,69,54.802],
[13,68,53.900],
[13,67,52.990],
[13,66,52.074],
[13,65,51.152],
[13,64,50.224],
[13,63,49.291],
[13,62,48.352],
[13,61,47.409],
[13,60,46.461],
[13,59,45.509],
[13,55,41.665],
[13,50,36.794],
[13,29,15.980],
[13,25,11.989],
[13,21,7.996],
[13,20,6.997],
[13,18,4.999],
[13,16,2.999],
[14,999,71.340],
[14,69,53.807],
[14,68,52.905],
[14,67,51.995],
[14,66,51.079],
[14,65,50.156],
[14,64,49.228],
[14,63,48.295],
[14,62,47.357],
[14,61,46.413],
[14,60,45.466],
[14,59,44.514],
[14,55,40.669],
[14,50,35.798],
[14,29,14.981],
[14,25,10.991],
[14,21,6.997],
[14,20,5.998],
[14,18,3.999],
[14,16,2.000],
[15,999,70.345],
[15,69,52.812],
[15,68,51.909],
[15,67,50.999],
[15,66,50.083],
[15,65,49.160],
[15,64,48.232],
[15,63,47.299],
[15,62,46.360],
[15,61,45.417],
[15,60,44.469],
[15,59,43.517],
[15,55,39.672],
[15,50,34.800],
[15,29,13.982],
[15,25,9.991],
[15,21,5.997],
[15,20,4.998],
[15,18,2.999],
[15,16,1.000],
[16,999,69.353],
[16,69,51.818],
[16,68,50.915],
[16,67,50.005],
[16,66,49.088],
[16,65,48.166],
[16,64,47.238],
[16,63,46.304],
[16,62,45.366],
[16,61,44.422],
[16,60,43.474],
[16,59,42.522],
[16,55,38.676],
[16,50,33.804],
[16,29,12.984],
[16,25,8.993],
[16,21,4.998],
[16,20,3.999],
[16,18,2.000],
[17,999,68.362],
[17,69,50.824],
[17,68,49.921],
[17,67,49.011],
[17,66,48.095],
[17,65,47.172],
[17,64,46.244],
[17,63,45.310],
[17,62,44.371],
[17,61,43.428],
[17,60,42.480],
[17,59,41.528],
[17,55,37.681],
[17,50,32.809],
[17,29,11.985],
[17,25,7.994],
[17,21,3.998],
[17,20,2.999],
[17,18,1.000],
[18,999,67.371],
[18,69,49.831],
[18,68,48.927],
[18,67,48.017],
[18,66,47.101],
[18,65,46.178],
[18,64,45.249],
[18,63,44.316],
[18,62,43.377],
[18,61,42.433],
[18,60,41.485],
[18,59,40.533],
[18,55,36.686],
[18,50,31.813],
[18,29,10.987],
[18,25,6.995],
[18,21,2.999],
[18,20,1.999],
[19,999,66.384],
[19,69,48.841],
[19,68,47.937],
[19,67,47.027],
[19,66,46.110],
[19,65,45.187],
[19,64,44.259],
[19,63,43.325],
[19,62,42.386],
[19,61,41.442],
[19,60,40.493],
[19,59,39.541],
[19,55,35.693],
[19,50,30.819],
[19,29,9.989],
[19,25,5.996],
[19,21,1.999],
[19,20,1.000],
[20,999,65.395],
[20,69,47.848],
[20,68,46.945],
[20,67,46.034],
[20,66,45.117],
[20,65,44.194],
[20,64,43.266],
[20,63,42.332],
[20,62,41.392],
[20,61,40.448],
[20,60,39.500],
[20,59,38.547],
[20,55,34.699],
[20,50,29.824],
[20,29,8.991],
[20,25,4.997],
[20,21,1.000],
[21,999,64.407],
[21,69,46.858],
[21,68,45.954],
[21,67,45.043],
[21,66,44.126],
[21,65,43.203],
[21,64,42.274],
[21,63,41.340],
[21,62,40.400],
[21,61,39.456],
[21,60,38.507],
[21,59,37.554],
[21,55,33.706],
[21,50,28.830],
[21,29,7.992],
[21,25,3.998],
[22,999,63.421],
[22,69,45.867],
[22,68,44.964],
[22,67,44.053],
[22,66,43.135],
[22,65,42.212],
[22,64,41.283],
[22,63,40.348],
[22,62,39.409],
[22,61,38.464],
[22,60,37.516],
[22,59,36.562],
[22,55,32.713],
[22,50,27.836],
[22,29,6.994],
[22,25,2.999],
[23,999,62.433],
[23,69,44.876],
[23,68,43.972],
[23,67,43.061],
[23,66,42.144],
[23,65,41.220],
[23,64,40.291],
[23,63,39.356],
[23,62,38.416],
[23,61,37.472],
[23,60,36.523],
[23,59,35.569],
[23,55,31.719],
[23,50,26.841],
[23,29,5.995],
[23,25,1.999],
[24,999,61.446],
[24,69,43.886],
[24,68,42.981],
[24,67,42.070],
[24,66,41.153],
[24,65,40.229],
[24,64,39.299],
[24,63,38.364],
[24,62,37.424],
[24,61,36.480],
[24,60,35.530],
[24,59,34.577],
[24,55,30.726],
[24,50,25.847],
[24,29,4.997],
[24,25,1.000],
[25,999,60.458],
[25,69,42.894],
[25,68,41.990],
[25,67,41.078],
[25,66,40.160],
[25,65,39.236],
[25,64,38.307],
[25,63,37.372],
[25,62,36.432],
[25,61,35.487],
[25,60,34.537],
[25,59,33.583],
[25,55,29.731],
[25,50,24.852],
[25,29,3.998],
[26,999,59.472],
[26,69,41.904],
[26,68,40.999],
[26,67,40.088],
[26,66,39.170],
[26,65,38.246],
[26,64,37.316],
[26,63,36.380],
[26,62,35.440],
[26,61,34.495],
[26,60,33.545],
[26,59,32.591],
[26,55,28.738],
[26,50,23.857],
[26,29,2.999],
[27,999,58.485],
[27,69,40.913],
[27,68,40.008],
[27,67,39.097],
[27,66,38.178],
[27,65,37.254],
[27,64,36.324],
[27,63,35.388],
[27,62,34.448],
[27,61,33.503],
[27,60,32.553],
[27,59,31.598],
[27,55,27.745],
[27,50,22.863],
[27,29,1.999],
[28,999,57.499],
[28,69,39.923],
[28,68,39.018],
[28,67,38.106],
[28,66,37.188],
[28,65,36.263],
[28,64,35.333],
[28,63,34.397],
[28,62,33.456],
[28,61,32.511],
[28,60,31.561],
[28,59,30.606],
[28,55,26.751],
[28,50,21.868],
[28,29,1.000],
[29,999,56.514],
[29,69,38.933],
[29,68,38.028],
[29,67,37.116],
[29,66,36.197],
[29,65,35.272],
[29,64,34.342],
[29,63,33.406],
[29,62,32.465],
[29,61,31.519],
[29,60,30.568],
[29,59,29.614],
[29,55,25.758],
[29,50,20.874],
[30,999,55.531],
[30,69,37.945],
[30,68,37.040],
[30,67,36.127],
[30,66,35.208],
[30,65,34.283],
[30,64,33.352],
[30,63,32.416],
[30,62,31.475],
[30,61,30.529],
[30,60,29.578],
[30,59,28.623],
[30,55,24.766],
[30,50,19.880],
[31,999,54.547],
[31,69,36.956],
[31,68,36.050],
[31,67,35.137],
[31,66,34.218],
[31,65,33.293],
[31,64,32.362],
[31,63,31.425],
[31,62,30.484],
[31,61,29.537],
[31,60,28.586],
[31,59,27.631],
[31,55,23.773],
[31,50,18.886],
[32,999,53.564],
[32,69,35.968],
[32,68,35.062],
[32,67,34.148],
[32,66,33.229],
[32,65,32.303],
[32,64,31.372],
[32,63,30.435],
[32,62,29.493],
[32,61,28.546],
[32,60,27.595],
[32,59,26.640],
[32,55,22.781],
[32,50,17.892],
[33,999,52.581],
[33,69,34.979],
[33,68,34.073],
[33,67,33.159],
[33,66,32.240],
[33,65,31.314],
[33,64,30.382],
[33,63,29.445],
[33,62,28.503],
[33,61,27.556],
[33,60,26.604],
[33,59,25.648],
[33,55,21.788],
[33,50,16.898],
[34,999,51.601],
[34,69,33.992],
[34,68,33.086],
[34,67,32.172],
[34,66,31.252],
[34,65,30.325],
[34,64,29.393],
[34,63,28.456],
[34,62,27.513],
[34,61,26.566],
[34,60,25.614],
[34,59,24.658],
[34,55,20.796],
[34,50,15.904],
[35,999,50.622],
[35,69,33.006],
[35,68,32.099],
[35,67,31.185],
[35,66,30.265],
[35,65,29.338],
[35,64,28.405],
[35,63,27.468],
[35,62,26.525],
[35,61,25.577],
[35,60,24.625],
[35,59,23.668],
[35,55,19.805],
[35,50,14.910],
[36,999,49.644],
[36,69,32.021],
[36,68,31.113],
[36,67,30.199],
[36,66,29.278],
[36,65,28.351],
[36,64,27.418],
[36,63,26.480],
[36,62,25.536],
[36,61,24.588],
[36,60,23.635],
[36,59,22.678],
[36,55,18.813],
[36,50,13.917],
[37,999,48.669],
[37,69,31.037],
[37,68,30.129],
[37,67,29.214],
[37,66,28.292],
[37,65,27.365],
[37,64,26.431],
[37,63,25.493],
[37,62,24.549],
[37,61,23.600],
[37,60,22.647],
[37,59,21.690],
[37,55,17.823],
[37,50,12.924],
[38,999,47.696],
[38,69,30.054],
[38,68,29.145],
[38,67,28.230],
[38,66,27.308],
[38,65,26.380],
[38,64,25.446],
[38,63,24.507],
[38,62,23.563],
[38,61,22.613],
[38,60,21.660],
[38,59,20.702],
[38,55,16.833],
[38,50,11.931],
[39,999,46.724],
[39,69,29.071],
[39,68,28.162],
[39,67,27.246],
[39,66,26.324],
[39,65,25.395],
[39,64,24.461],
[39,63,23.521],
[39,62,22.576],
[39,61,21.627],
[39,60,20.672],
[39,59,19.714],
[39,55,15.842],
[39,50,10.938],
[40,999,45.754],
[40,69,28.090],
[40,68,27.180],
[40,67,26.264],
[40,66,25.341],
[40,65,24.412],
[40,64,23.477],
[40,63,22.536],
[40,62,21.591],
[40,61,20.640],
[40,60,19.686],
[40,59,18.726],
[40,55,14.853],
[40,50,9.945],
[41,999,44.786],
[41,69,27.110],
[41,68,26.199],
[41,67,25.282],
[41,66,24.358],
[41,65,23.429],
[41,64,22.493],
[41,63,21.552],
[41,62,20.606],
[41,61,19.655],
[41,60,18.699],
[41,59,17.739],
[41,55,13.863],
[41,50,8.952],
[42,999,43.822],
[42,69,26.131],
[42,68,25.220],
[42,67,24.302],
[42,66,23.378],
[42,65,22.447],
[42,64,21.511],
[42,63,20.569],
[42,62,19.622],
[42,61,18.671],
[42,60,17.714],
[42,59,16.754],
[42,55,12.874],
[42,50,7.959],
[43,999,42.859],
[43,69,25.154],
[43,68,24.242],
[43,67,23.323],
[43,66,22.398],
[43,65,21.467],
[43,64,20.529],
[43,63,19.587],
[43,62,18.639],
[43,61,17.687],
[43,60,16.729],
[43,59,15.768],
[43,55,11.885],
[43,50,6.966],
[44,999,41.902],
[44,69,24.179],
[44,68,23.266],
[44,67,22.347],
[44,66,21.420],
[44,65,20.488],
[44,64,19.550],
[44,63,18.606],
[44,62,17.658],
[44,61,16.704],
[44,60,15.746],
[44,59,14.784],
[44,55,10.897],
[44,50,5.973],
[45,999,40.947],
[45,69,23.205],
[45,68,22.291],
[45,67,21.370],
[45,66,20.443],
[45,65,19.510],
[45,64,18.571],
[45,63,17.626],
[45,62,16.677],
[45,61,15.722],
[45,60,14.763],
[45,59,13.800],
[45,55,9.909],
[45,50,4.979],
[46,999,39.996],
[46,69,22.232],
[46,68,21.318],
[46,67,20.396],
[46,66,19.468],
[46,65,18.533],
[46,64,17.593],
[46,63,16.647],
[46,62,15.696],
[46,61,14.741],
[46,60,13.781],
[46,59,12.816],
[46,55,8.920],
[46,50,3.985],
[47,999,39.049],
[47,69,21.262],
[47,68,20.346],
[47,67,19.423],
[47,66,18.494],
[47,65,17.558],
[47,64,16.616],
[47,63,15.669],
[47,62,14.717],
[47,61,13.761],
[47,60,12.799],
[47,59,11.833],
[47,55,7.932],
[47,50,2.990],
[48,999,38.107],
[48,69,20.294],
[48,68,19.376],
[48,67,18.452],
[48,66,17.521],
[48,65,16.584],
[48,64,15.641],
[48,63,14.693],
[48,62,13.739],
[48,61,12.781],
[48,60,11.818],
[48,59,10.851],
[48,55,6.944],
[48,50,1.995],
[49,999,37.169],
[49,69,19.327],
[49,68,18.408],
[49,67,17.482],
[49,66,16.550],
[49,65,15.611],
[49,64,14.667],
[49,63,13.717],
[49,62,12.762],
[49,61,11.802],
[49,60,10.837],
[49,59,9.869],
[49,55,5.955],
[49,50,0.998],
[50,999,36.236],
[50,69,18.361],
[50,68,17.441],
[50,67,16.514],
[50,66,15.579],
[50,65,14.639],
[50,64,13.693],
[50,63,12.741],
[50,62,11.785],
[50,61,10.823],
[50,60,9.857],
[50,59,8.886],
[50,55,4.966],
[51,999,35.310],
[51,69,17.399],
[51,68,16.476],
[51,67,15.547],
[51,66,14.611],
[51,65,13.669],
[51,64,12.721],
[51,63,11.767],
[51,62,10.809],
[51,61,9.845],
[51,60,8.877],
[51,59,7.904],
[51,55,3.976],
[52,999,34.388],
[52,69,16.438],
[52,68,15.513],
[52,67,14.582],
[52,66,13.644],
[52,65,12.699],
[52,64,11.749],
[52,63,10.794],
[52,62,9.833],
[52,61,8.867],
[52,60,7.897],
[52,59,6.922],
[52,55,2.985],
[53,999,33.470],
[53,69,15.477],
[53,68,14.550],
[53,67,13.616],
[53,66,12.676],
[53,65,11.730],
[53,64,10.777],
[53,63,9.819],
[53,62,8.856],
[53,61,7.888],
[53,60,6.915],
[53,59,5.938],
[53,55,1.992],
[54,999,32.555],
[54,69,14.516],
[54,68,13.587],
[54,67,12.651],
[54,66,11.708],
[54,65,10.760],
[54,64,9.805],
[54,63,8.844],
[54,62,7.879],
[54,61,6.908],
[54,60,5.933],
[54,59,4.953],
[54,55,0.997],
[55,999,31.646],
[55,69,13.556],
[55,68,12.625],
[55,67,11.686],
[55,66,10.741],
[55,65,9.789],
[55,64,8.832],
[55,63,7.869],
[55,62,6.900],
[55,61,5.927],
[55,60,4.949],
[55,59,3.967],
[56,999,30.742],
[56,69,12.597],
[56,68,11.663],
[56,67,10.722],
[56,66,9.773],
[56,65,8.819],
[56,64,7.859],
[56,63,6.893],
[56,62,5.921],
[56,61,4.945],
[56,60,3.964],
[56,59,2.979],
[57,999,29.843],
[57,69,11.639],
[57,68,10.702],
[57,67,9.757],
[57,66,8.806],
[57,65,7.848],
[57,64,6.885],
[57,63,5.915],
[57,62,4.941],
[57,61,3.962],
[57,60,2.978],
[57,59,1.989],
[58,999,28.951],
[58,69,10.681],
[58,68,9.740],
[58,67,8.792],
[58,66,7.837],
[58,65,6.876],
[58,64,5.909],
[58,63,4.937],
[58,62,3.959],
[58,61,2.976],
[58,60,1.988],
[58,59,0.996],
[59,999,28.060],
[59,69,9.721],
[59,68,8.777],
[59,67,7.825],
[59,66,6.867],
[59,65,5.902],
[59,64,4.932],
[59,63,3.955],
[59,62,2.974],
[59,61,1.987],
[59,60,0.996],
[60,999,27.177],
[60,69,8.762],
[60,68,7.814],
[60,67,6.858],
[60,66,5.896],
[60,65,4.927],
[60,64,3.952],
[60,63,2.972],
[60,62,1.986],
[60,61,0.995],
[61,999,26.301],
[61,69,7.802],
[61,68,6.849],
[61,67,5.889],
[61,66,4.923],
[61,65,3.949],
[61,64,2.970],
[61,63,1.985],
[61,62,0.995],
[62,999,25.427],
[62,69,6.839],
[62,68,5.882],
[62,67,4.918],
[62,66,3.946],
[62,65,2.968],
[62,64,1.985],
[62,63,0.995],
[63,999,24.557],
[63,69,5.874],
[63,68,4.912],
[63,67,3.943],
[63,66,2.966],
[63,65,1.984],
[63,64,0.995],
[64,999,23.689],
[64,69,4.906],
[64,68,3.939],
[64,67,2.964],
[64,66,1.982],
[64,65,0.994],
[65,999,22.826],
[65,69,3.934],
[65,68,2.961],
[65,67,1.981],
[65,66,0.994],
[66,999,21.969],
[66,69,2.959],
[66,68,1.980],
[66,67,0.993],
[67,999,21.114],
[67,69,1.978],
[67,68,0.993],
[68,999,20.264],
[68,69,0.992],
[69,999,19.420],
[70,999,18.579],
[71,999,17.743],
[72,999,16.917],
[73,999,16.099],
[74,999,15.288],
[75,999,14.486],
[76,999,13.696],
[77,999,12.913],
[78,999,12.145],
[79,999,11.389],
[80,999,10.651],
[81,999,9.935],
[82,999,9.241],
[83,999,8.565],
[84,999,7.915],
[85,999,7.292],
[86,999,6.697],
[87,999,6.134],
[88,999,5.607],
[89,999,5.113],
[90,999,4.655],
[91,999,4.232],
[92,999,3.842],
[93,999,3.476],
[94,999,3.145],
[95,999,2.845],
[96,999,2.571],
[97,999,2.320],
[98,999,2.077],
[99,999,1.853],
[100,999,1.607],
[101,999,1.348],
[102,999,1.036],
[103,999,0.618],
]);
gazPal2022tx0.set("M", [
 [0,999,79.019],
[0,69,66.171],
[0,68,65.368],
[0,67,64.553],
[0,66,63.724],
[0,65,62.884],
[0,64,62.033],
[0,63,61.171],
[0,62,60.298],
[0,61,59.415],
[0,60,58.524],
[0,59,57.624],
[0,55,53.952],
[0,50,49.242],
[0,29,28.792],
[0,25,24.840],
[0,21,20.879],
[0,20,19.887],
[0,18,17.902],
[0,16,15.915],
[1,999,78.361],
[1,69,65.457],
[1,68,64.651],
[1,67,63.832],
[1,66,63.000],
[1,65,62.157],
[1,64,61.301],
[1,63,60.435],
[1,62,59.559],
[1,61,58.673],
[1,60,57.777],
[1,59,56.873],
[1,55,53.186],
[1,50,48.455],
[1,29,27.916],
[1,25,23.948],
[1,21,19.969],
[1,20,18.973],
[1,18,16.979],
[1,16,14.984],
[2,999,77.386],
[2,69,64.477],
[2,68,63.671],
[2,67,62.852],
[2,66,62.020],
[2,65,61.176],
[2,64,60.321],
[2,63,59.454],
[2,62,58.578],
[2,61,57.691],
[2,60,56.795],
[2,59,55.891],
[2,55,52.202],
[2,50,47.470],
[2,29,26.925],
[2,25,22.955],
[2,21,18.975],
[2,20,17.979],
[2,18,15.984],
[2,16,13.988],
[3,999,76.401],
[3,69,63.490],
[3,68,62.684],
[3,67,61.864],
[3,66,61.032],
[3,65,60.188],
[3,64,59.333],
[3,63,58.466],
[3,62,57.589],
[3,61,56.703],
[3,60,55.806],
[3,59,54.902],
[3,55,51.213],
[3,50,46.480],
[3,29,25.930],
[3,25,21.960],
[3,21,17.979],
[3,20,16.983],
[3,18,14.988],
[3,16,12.991],
[4,999,75.412],
[4,69,62.499],
[4,68,61.693],
[4,67,60.873],
[4,66,60.041],
[4,65,59.197],
[4,64,58.341],
[4,63,57.474],
[4,62,56.597],
[4,61,55.710],
[4,60,54.814],
[4,59,53.910],
[4,55,50.220],
[4,50,45.487],
[4,29,24.934],
[4,25,20.963],
[4,21,16.982],
[4,20,15.985],
[4,18,13.990],
[4,16,11.993],
[5,999,74.420],
[5,69,61.506],
[5,68,60.700],
[5,67,59.880],
[5,66,59.048],
[5,65,58.203],
[5,64,57.347],
[5,63,56.481],
[5,62,55.604],
[5,61,54.717],
[5,60,53.820],
[5,59,52.916],
[5,55,49.226],
[5,50,44.492],
[5,29,23.937],
[5,25,19.965],
[5,21,15.984],
[5,20,14.987],
[5,18,12.991],
[5,16,10.994],
[6,999,73.428],
[6,69,60.512],
[6,68,59.706],
[6,67,58.886],
[6,66,58.054],
[6,65,57.209],
[6,64,56.353],
[6,63,55.487],
[6,62,54.609],
[6,61,53.722],
[6,60,52.826],
[6,59,51.921],
[6,55,48.231],
[6,50,43.496],
[6,29,22.939],
[6,25,18.967],
[6,21,14.985],
[6,20,13.988],
[6,18,11.993],
[6,16,9.995],
[7,999,72.435],
[7,69,59.518],
[7,68,58.711],
[7,67,57.891],
[7,66,57.059],
[7,65,56.214],
[7,64,55.358],
[7,63,54.492],
[7,62,53.614],
[7,61,52.727],
[7,60,51.831],
[7,59,50.926],
[7,55,47.235],
[7,50,42.500],
[7,29,21.941],
[7,25,17.969],
[7,21,13.987],
[7,20,12.989],
[7,18,10.994],
[7,16,8.996],
[8,999,71.440],
[8,69,58.523],
[8,68,57.716],
[8,67,56.896],
[8,66,56.063],
[8,65,55.219],
[8,64,54.363],
[8,63,53.496],
[8,62,52.619],
[8,61,51.731],
[8,60,50.835],
[8,59,49.930],
[8,55,46.239],
[8,50,41.503],
[8,29,20.943],
[8,25,16.971],
[8,21,12.988],
[8,20,11.990],
[8,18,9.995],
[8,16,7.997],
[9,999,70.445],
[9,69,57.527],
[9,68,56.720],
[9,67,55.900],
[9,66,55.067],
[9,65,54.223],
[9,64,53.367],
[9,63,52.500],
[9,62,51.622],
[9,61,50.735],
[9,60,49.838],
[9,59,48.933],
[9,55,45.242],
[9,50,40.506],
[9,29,19.944],
[9,25,15.972],
[9,21,11.989],
[9,20,10.991],
[9,18,8.995],
[9,16,6.997],
[10,999,69.450],
[10,69,56.531],
[10,68,55.724],
[10,67,54.904],
[10,66,54.071],
[10,65,53.227],
[10,64,52.370],
[10,63,51.503],
[10,62,50.626],
[10,61,49.738],
[10,60,48.842],
[10,59,47.937],
[10,55,44.245],
[10,50,39.509],
[10,29,18.946],
[10,25,14.973],
[10,21,10.989],
[10,20,9.992],
[10,18,7.996],
[10,16,5.998],
[11,999,68.455],
[11,69,55.535],
[11,68,54.728],
[11,67,53.908],
[11,66,53.075],
[11,65,52.230],
[11,64,51.374],
[11,63,50.507],
[11,62,49.629],
[11,61,48.742],
[11,60,47.845],
[11,59,46.940],
[11,55,43.248],
[11,50,38.512],
[11,29,17.947],
[11,25,13.974],
[11,21,9.990],
[11,20,8.993],
[11,18,6.996],
[11,16,4.998],
[12,999,67.461],
[12,69,54.540],
[12,68,53.733],
[12,67,52.913],
[12,66,52.080],
[12,65,51.235],
[12,64,50.379],
[12,63,49.512],
[12,62,48.634],
[12,61,47.746],
[12,60,46.850],
[12,59,45.944],
[12,55,42.252],
[12,50,37.515],
[12,29,16.949],
[12,25,12.975],
[12,21,8.991],
[12,20,7.994],
[12,18,5.997],
[12,16,3.999],
[13,999,66.468],
[13,69,53.545],
[13,68,52.738],
[13,67,51.917],
[13,66,51.085],
[13,65,50.240],
[13,64,49.383],
[13,63,48.516],
[13,62,47.638],
[13,61,46.751],
[13,60,45.854],
[13,59,44.949],
[13,55,41.256],
[13,50,36.519],
[13,29,15.950],
[13,25,11.976],
[13,21,7.992],
[13,20,6.994],
[13,18,4.998],
[13,16,2.999],
[14,999,65.474],
[14,69,52.550],
[14,68,51.743],
[14,67,50.923],
[14,66,50.090],
[14,65,49.245],
[14,64,48.388],
[14,63,47.521],
[14,62,46.643],
[14,61,45.755],
[14,60,44.858],
[14,59,43.953],
[14,55,40.260],
[14,50,35.523],
[14,29,14.952],
[14,25,10.978],
[14,21,6.993],
[14,20,5.995],
[14,18,3.998],
[14,16,2.000],
[15,999,64.483],
[15,69,51.557],
[15,68,50.750],
[15,67,49.930],
[15,66,49.097],
[15,65,48.252],
[15,64,47.395],
[15,63,46.528],
[15,62,45.650],
[15,61,44.762],
[15,60,43.865],
[15,59,42.959],
[15,55,39.266],
[15,50,34.528],
[15,29,13.954],
[15,25,9.979],
[15,21,5.994],
[15,20,4.996],
[15,18,2.999],
[15,16,1.000],
[16,999,63.496],
[16,69,50.567],
[16,68,49.760],
[16,67,48.939],
[16,66,48.106],
[16,65,47.261],
[16,64,46.404],
[16,63,45.537],
[16,62,44.658],
[16,61,43.770],
[16,60,42.873],
[16,59,41.968],
[16,55,38.273],
[16,50,33.534],
[16,29,12.957],
[16,25,8.981],
[16,21,4.995],
[16,20,3.997],
[16,18,1.999],	
[17,999,62.511],
[17,69,49.580],
[17,68,48.772],
[17,67,47.951],
[17,66,47.118],
[17,65,46.272],
[17,64,45.415],
[17,63,44.548],
[17,62,43.669],
[17,61,42.781],
[17,60,41.884],
[17,59,40.978],
[17,55,37.283],
[17,50,32.542],
[17,29,11.960],
[17,25,7.983],
[17,21,3.996],
[17,20,2.998],
[17,18,1.000],	
[18,999,61.529],
[18,69,48.594],
[18,68,47.786],
[18,67,46.965],
[18,66,46.132],
[18,65,45.286],
[18,64,44.429],
[18,63,43.561],
[18,62,42.682],
[18,61,41.793],
[18,60,40.896],
[18,59,39.990],
[18,55,36.294],
[18,50,31.552],
[18,29,10.963],
[18,25,6.986],
[18,21,2.997],
[18,20,1.999], 
[19,999,60.554],
[19,69,47.613],
[19,68,46.805],
[19,67,45.984],
[19,66,45.150],
[19,65,44.304],
[19,64,43.446],
[19,63,42.578],
[19,62,41.699],
[19,61,40.810],
[19,60,39.912],
[19,59,39.005],
[19,55,35.308],
[19,50,30.564],
[19,29,9.968],
[19,25,5.988],
[19,21,1.998],
[19,20,0.999], 
[20,999,59.585],
[20,69,46.638],
[20,68,45.829],
[20,67,45.007],
[20,66,44.173],
[20,65,43.326],
[20,64,42.468],
[20,63,41.600],
[20,62,40.720],
[20,61,39.831],
[20,60,38.932],
[20,59,38.025],
[20,55,34.326],
[20,50,29.580],
[20,29,8.973],
[20,25,4.991],
[20,21,0.999], 	
[21,999,58.616],
[21,69,45.662],
[21,68,44.854],
[21,67,44.031],
[21,66,43.196],
[21,65,42.349],
[21,64,41.491],
[21,63,40.622],
[21,62,39.742],
[21,61,38.852],
[21,60,37.953],
[21,59,37.046],
[21,55,33.344],
[21,50,28.596],
[21,29,7.978],
[21,25,3.994], 
[22,999,57.652],
[22,69,44.690],
[22,68,43.881],
[22,67,43.058],
[22,66,42.223],
[22,65,41.375],
[22,64,40.516],
[22,63,39.646],
[22,62,38.766],
[22,61,37.876],
[22,60,36.976],
[22,59,36.068],
[22,55,32.364],
[22,50,27.613],
[22,29,6.982],
[22,25,2.996], 
[23,999,56.686],
[23,69,43.716],
[23,68,42.906],
[23,67,42.083],
[23,66,41.247],
[23,65,40.399],
[23,64,39.540],
[23,63,38.670],
[23,62,37.789],
[23,61,36.898],
[23,60,35.998],
[23,59,35.089],
[23,55,31.383],
[23,50,26.629],
[23,29,5.986],
[23,25,1.998], 
[24,999,55.720],
[24,69,42.743],
[24,68,41.932],
[24,67,41.109],
[24,66,40.272],
[24,65,39.424],
[24,64,38.564],
[24,63,37.693],
[24,62,36.812],
[24,61,35.920],
[24,60,35.020],
[24,59,34.111],
[24,55,30.402],
[24,50,25.645],
[24,29,4.990],
[24,25,0.999], 
[25,999,54.754],
[25,69,41.769],
[25,68,40.958],
[25,67,40.134],
[25,66,39.297],
[25,65,38.448],
[25,64,37.588],
[25,63,36.716],
[25,62,35.834],
[25,61,34.942],
[25,60,34.041],
[25,59,33.132],
[25,55,29.421],
[25,50,24.661],
[25,29,3.993], 	
[26,999,53.790],
[26,69,40.797],
[26,68,39.985],
[26,67,39.160],
[26,66,38.323],
[26,65,37.473],
[26,64,36.612],
[26,63,35.740],
[26,62,34.858],
[26,61,33.965],
[26,60,33.064],
[26,59,32.153],
[26,55,28.440],
[26,50,23.677],
[26,29,2.996], 	
[27,999,52.830],
[27,69,39.826],
[27,68,39.014],
[27,67,38.189],
[27,66,37.351],
[27,65,36.501],
[27,64,35.639],
[27,63,34.766],
[27,62,33.883],
[27,61,32.990],
[27,60,32.088],
[27,59,31.177],
[27,55,27.461],
[27,50,22.695],
[27,29,1.998], 	
[28,999,51.864],
[28,69,38.853],
[28,68,38.040],
[28,67,37.214],
[28,66,36.375],
[28,65,35.525],
[28,64,34.662],
[28,63,33.789],
[28,62,32.905],
[28,61,32.012],
[28,60,31.109],
[28,59,30.197],
[28,55,26.479],
[28,50,21.709],
[28,29,0.999], 	
[29,999,50.902],
[29,69,37.880],
[29,68,37.067],
[29,67,36.241],
[29,66,35.402],
[29,65,34.550],
[29,64,33.687],
[29,63,32.814],
[29,62,31.929],
[29,61,31.035],
[29,60,30.131],
[29,59,29.219],
[29,55,25.498],
[29,50,20.725],  
[30,999,49.940],
[30,69,36.909],
[30,68,36.095],
[30,67,35.268],
[30,66,34.429],
[30,65,33.577],
[30,64,32.713],
[30,63,31.839],
[30,62,30.953],
[30,61,30.058],
[30,60,29.154],
[30,59,28.241],
[30,55,24.518],
[30,50,19.741],  
[31,999,48.979],
[31,69,35.938],
[31,68,35.124],
[31,67,34.296],
[31,66,33.455],
[31,65,32.603],
[31,64,31.739],
[31,63,30.863],
[31,62,29.978],
[31,61,29.082],
[31,60,28.177],
[31,59,27.263],
[31,55,23.537],
[31,50,18.756],  
[32,999,48.021],
[32,69,34.969],
[32,68,34.154],
[32,67,33.325],
[32,66,32.484],
[32,65,31.631],
[32,64,30.766],
[32,63,29.890],
[32,62,29.003],
[32,61,28.107],
[32,60,27.201],
[32,59,26.287],
[32,55,22.557],
[32,50,17.772],  
[33,999,47.064],
[33,69,34.000],
[33,68,33.184],
[33,67,32.355],
[33,66,31.513],
[33,65,30.659],
[33,64,29.793],
[33,63,28.916],
[33,62,28.029],
[33,61,27.132],
[33,60,26.225],
[33,59,25.310],
[33,55,21.577],
[33,50,16.788],  
[34,999,46.107],
[34,69,33.031],
[34,68,32.214],
[34,67,31.384],
[34,66,30.542],
[34,65,29.687],
[34,64,28.820],
[34,63,27.943],
[34,62,27.055],
[34,61,26.156],
[34,60,25.249],
[34,59,24.333],
[34,55,20.597],
[34,50,15.803],  
[35,999,45.150],
[35,69,32.062],
[35,68,31.245],
[35,67,30.414],
[35,66,29.571],
[35,65,28.715],
[35,64,27.847],
[35,63,26.969],
[35,62,26.080],
[35,61,25.181],
[35,60,24.273],
[35,59,23.356],
[35,55,19.616],
[35,50,14.818],  
[36,999,44.197],
[36,69,31.096],
[36,68,30.277],
[36,67,29.446],
[36,66,28.601],
[36,65,27.745],
[36,64,26.876],
[36,63,25.997],
[36,62,25.107],
[36,61,24.207],
[36,60,23.298],
[36,59,22.380],
[36,55,18.637],
[36,50,13.834],  
[37,999,43.246],
[37,69,30.130],
[37,68,29.311],
[37,67,28.478],
[37,66,27.633],
[37,65,26.775],
[37,64,25.906],
[37,63,25.026],
[37,62,24.135],
[37,61,23.234],
[37,60,22.324],
[37,59,21.405],
[37,55,17.657],
[37,50,12.849],  
[38,999,42.298],
[38,69,29.166],
[38,68,28.346],
[38,67,27.512],
[38,66,26.666],
[38,65,25.808],
[38,64,24.937],
[38,63,24.056],
[38,62,23.164],
[38,61,22.262],
[38,60,21.351],
[38,59,20.431],
[38,55,16.678],
[38,50,11.864],  
[39,999,41.351],
[39,69,28.203],
[39,68,27.381],
[39,67,26.547],
[39,66,25.699],
[39,65,24.840],
[39,64,23.968],
[39,63,23.086],
[39,62,22.193],
[39,61,21.290],
[39,60,20.377],
[39,59,19.456],
[39,55,15.699],
[39,50,10.879],  
[40,999,40.408],
[40,69,27.241],
[40,68,26.419],
[40,67,25.583],
[40,66,24.735],
[40,65,23.874],
[40,64,23.001],
[40,63,22.118],
[40,62,21.223],
[40,61,20.319],
[40,60,19.405],
[40,59,18.483],
[40,55,14.721],
[40,50,9.894],  
[41,999,39.466],
[41,69,26.280],
[41,68,25.457],
[41,67,24.620],
[41,66,23.770],
[41,65,22.908],
[41,64,22.034],
[41,63,21.149],
[41,62,20.254],
[41,61,19.348],
[41,60,18.433],
[41,59,17.509],
[41,55,13.742],
[41,50,8.908],  
[42,999,38.527],
[42,69,25.321],
[42,68,24.496],
[42,67,23.658],
[42,66,22.807],
[42,65,21.944],
[42,64,21.068],
[42,63,20.182],
[42,62,19.285],
[42,61,18.378],
[42,60,17.462],
[42,59,16.537],
[42,55,12.763],
[42,50,7.922],  
[43,999,37.591],
[43,69,24.363],
[43,68,23.537],
[43,67,22.698],
[43,66,21.845],
[43,65,20.980],
[43,64,20.104],
[43,63,19.216],
[43,62,18.318],
[43,61,17.409],
[43,60,16.491],
[43,59,15.564],
[43,55,11.785],
[43,50,6.935],  
[44,999,36.663],
[44,69,23.410],
[44,68,22.582],
[44,67,21.741],
[44,66,20.887],
[44,65,20.020],
[44,64,19.142],
[44,63,18.253],
[44,62,17.353],
[44,61,16.442],
[44,60,15.522],
[44,59,14.594],
[44,55,10.807],
[44,50,5.949],  
[45,999,35.739],
[45,69,22.458],
[45,68,21.629],
[45,67,20.786],
[45,66,19.930],
[45,65,19.062],
[45,64,18.181],
[45,63,17.290],
[45,62,16.388],
[45,61,15.476],
[45,60,14.554],
[45,59,13.624],
[45,55,9.829],
[45,50,4.961],  
[46,999,34.822],
[46,69,21.510],
[46,68,20.679],
[46,67,19.834],
[46,66,18.976],
[46,65,18.106],
[46,64,17.224],
[46,63,16.330],
[46,62,15.426],
[46,61,14.512],
[46,60,13.588],
[46,59,12.656],
[46,55,8.852],
[46,50,3.972],  
[47,999,33.908],
[47,69,20.564],
[47,68,19.730],
[47,67,18.883],
[47,66,18.023],
[47,65,17.151],
[47,64,16.266],
[47,63,15.371],
[47,62,14.465],
[47,61,13.548],
[47,60,12.622],
[47,59,11.687],
[47,55,7.874],
[47,50,2.982],  
[48,999,33.002],
[48,69,19.621],
[48,68,18.785],
[48,67,17.936],
[48,66,17.073],
[48,65,16.198],
[48,64,15.312],
[48,63,14.414],
[48,62,13.505],
[48,61,12.586],
[48,60,11.657],
[48,59,10.720],
[48,55,6.896],
[48,50,1.991],  
[49,999,32.102],
[49,69,18.680],
[49,68,17.842],
[49,67,16.990],
[49,66,16.125],
[49,65,15.247],
[49,64,14.358],
[49,63,13.457],
[49,62,12.545],
[49,61,11.623],
[49,60,10.692],
[49,59,9.752],
[49,55,5.917],
[49,50,0.997],  
[50,999,31.211],
[50,69,17.743],
[50,68,16.902],
[50,67,16.047],
[50,66,15.180],
[50,65,14.299],
[50,64,13.407],
[50,63,12.503],
[50,62,11.588],
[50,61,10.663],
[50,60,9.728],
[50,59,8.785],
[50,55,4.937],  	
[51,999,30.329],
[51,69,16.810],
[51,68,15.966],
[51,67,15.108],
[51,66,14.237],
[51,65,13.353],
[51,64,12.457],
[51,63,11.550],
[51,62,10.632],
[51,61,9.703],
[51,60,8.765],
[51,59,7.818],
[51,55,3.956],  	
[52,999,29.452],
[52,69,15.879],
[52,68,15.031],
[52,67,14.170],
[52,66,13.295],
[52,65,12.407],
[52,64,11.508],
[52,63,10.597],
[52,62,9.675],
[52,61,8.743],
[52,60,7.801],
[52,59,6.850],
[52,55,2.972],  	
[53,999,28.584],
[53,69,14.950],
[53,68,14.098],
[53,67,13.233],
[53,66,12.354],
[53,65,11.463],
[53,64,10.559],
[53,63,9.644],
[53,62,8.718],
[53,61,7.782],
[53,60,6.836],
[53,59,5.881],
[53,55,1.985],  	
[54,999,27.725],
[54,69,14.024],
[54,68,13.168],
[54,67,12.298],
[54,66,11.415],
[54,65,10.520],
[54,64,9.612],
[54,63,8.692],
[54,62,7.761],
[54,61,6.820],
[54,60,5.870],
[54,59,4.910],
[54,55,0.995],  	
[55,999,26.873],
[55,69,13.098],
[55,68,12.238],
[55,67,11.364],
[55,66,10.476],
[55,65,9.576],
[55,64,8.663],
[55,63,7.738],
[55,62,6.803],
[55,61,5.857],
[55,60,4.901],
[55,59,3.936],  
[56,999,26.031],
[56,69,12.175],
[56,68,11.310],
[56,67,10.431],
[56,66,9.538],
[56,65,8.632],
[56,64,7.714],
[56,63,6.784],
[56,62,5.843],
[56,61,4.891],
[56,60,3.930],
[56,59,2.959],  
[57,999,25.200],
[57,69,11.255],
[57,68,10.384],
[57,67,9.498],
[57,66,8.600],
[57,65,7.688],
[57,64,6.764],
[57,63,5.828],
[57,62,4.881],
[57,61,3.923],
[57,60,2.955],
[57,59,1.978],  
[58,999,24.377],
[58,69,10.334],
[58,68,9.457],
[58,67,8.565],
[58,66,7.660],
[58,65,6.742],
[58,64,5.811],
[58,63,4.869],
[58,62,3.915],
[58,61,2.951],
[58,60,1.976],
[58,59,0.992],  
[59,999,23.569],
[59,69,9.415],
[59,68,8.531],
[59,67,7.633],
[59,66,6.721],
[59,65,5.795],
[59,64,4.857],
[59,63,3.907],
[59,62,2.946],
[59,61,1.974],
[59,60,0.991],  	
[60,999,22.772],
[60,69,8.496],
[60,68,7.604],
[60,67,6.698],
[60,66,5.778],
[60,65,4.845],
[60,64,3.899],
[60,63,2.941],
[60,62,1.971],
[60,61,0.991],   
[61,999,21.985],
[61,69,7.576],
[61,68,6.676],
[61,67,5.761],
[61,66,4.832],
[61,65,3.890],
[61,64,2.935],
[61,63,1.968],
[61,62,0.990],   	
[62,999,21.213],
[62,69,6.654],
[62,68,5.745],
[62,67,4.821],
[62,66,3.883],
[62,65,2.931],
[62,64,1.966],
[62,63,0.989],   
[63,999,20.452],
[63,69,5.729],
[63,68,4.810],
[63,67,3.875],
[63,66,2.926],
[63,65,1.964],
[63,64,0.988],   	
[64,999,19.700],
[64,69,4.799],
[64,68,3.868],
[64,67,2.922],
[64,66,1.962],
[64,65,0.988],    
[65,999,18.949],
[65,69,3.859],
[65,68,2.917],
[65,67,1.959],
[65,66,0.987],    	
[66,999,18.207],
[66,69,2.912],
[66,68,1.957],
[66,67,0.986],    
[67,999,17.472],
[67,69,1.954],
[67,68,0.985],    	
[68,999,16.738],
[68,69,0.984],     
[69,999,16.013],     	
[70,999,15.294],     	
[71,999,14.581],     	
[72,999,13.874],     	
[73,999,13.174],     	
[74,999,12.485],     	
[75,999,11.804],     	
[76,999,11.132],     	
[77,999,10.472],     	
[78,999,9.825],     	
[79,999,9.195],     	
[80,999,8.582],     	
[81,999,7.980],     	
[82,999,7.403],     	
[83,999,6.844],     	
[84,999,6.312],     	
[85,999,5.808],     	
[86,999,5.328],     	
[87,999,4.876],     	
[88,999,4.457],     	
[89,999,4.068],     	
[90,999,3.713],     	
[91,999,3.389],     	
[92,999,3.089],     	
[93,999,2.821],     	
[94,999,2.569],     	
[95,999,2.336],     	
[96,999,2.139],     	
[97,999,1.970],     	
[98,999,1.830],     	
[99,999,1.690],     	
[100,999,1.566],     	
[101,999,1.350],     	
[102,999,1.049],     	
[103,999,0.657],     	
]);

export const bcriv2023 = new Map();
bcriv2023.set("M", [
[0,999,62.79],
[0,16,15.92],
[0,18,17.91],
[0,20,19.89],
[0,21,20.88],
[0,25,24.85],
[0,55,50.68],
[0,60,53.32],
[0,61,53.79],
[0,62,54.25],
[0,63,54.69],
[0,64,55.12],
[0,65,55.52],
[0,66,55.91],
[0,67,56.29],
[0,68,56.64],
[1,999,62.68],
[1,16,14.98],
[1,18,16.98],
[1,20,18.97],
[1,21,19.97],
[1,25,23.95],
[1,55,50.26],
[1,60,52.96],
[1,61,53.45],
[1,62,53.92],
[1,63,54.38],
[1,64,54.81],
[1,65,55.23],
[1,66,55.63],
[1,67,56.02],
[1,68,56.38],
[2,999,62.32],
[2,16,13.99],
[2,18,15.99],
[2,20,17.98],
[2,21,18.98],
[2,25,22.96],
[2,55,49.63],
[2,60,52.39],
[2,61,52.89],
[2,62,53.37],
[2,63,53.83],
[2,64,54.28],
[2,65,54.71],
[2,66,55.12],
[2,67,55.51],
[2,68,55.89],
[3,999,61.94],
[3,16,12.99],
[3,18,14.99],
[3,20,16.98],
[3,21,17.98],
[3,25,21.96],
[3,55,48.97],
[3,60,51.8],
[3,61,52.31],
[3,62,52.8],
[3,63,53.28],
[3,64,53.73],
[3,65,54.17],
[3,66,54.59],
[3,67,54.99],
[3,68,55.38],
[4,999,61.55],
[4,16,11.99],
[4,18,13.99],
[4,20,15.99],
[4,21,16.98],
[4,25,20.96],
[4,55,48.3],
[4,60,51.19],
[4,61,51.71],
[4,62,52.22],
[4,63,52.7],
[4,64,53.17],
[4,65,53.62],
[4,66,54.05],
[4,67,54.46],
[4,68,54.85],
[5,999,61.15],
[5,16,10.99],
[5,18,12.99],
[5,20,14.99],
[5,21,15.98],
[5,25,19.97],
[5,55,47.62],
[5,60,50.57],
[5,61,51.11],
[5,62,51.62],
[5,63,52.12],
[5,64,52.6],
[5,65,53.06],
[5,66,53.5],
[5,67,53.92],
[5,68,54.32],
[6,999,60.75],
[6,16,10],
[6,18,11.99],
[6,20,13.99],
[6,21,14.99],
[6,25,18.97],
[6,55,46.92],
[6,60,49.94],
[6,61,50.48],
[6,62,51.01],
[6,63,51.52],
[6,64,52.01],
[6,65,52.48],
[6,66,52.93],
[6,67,53.36],
[6,68,53.77],
[7,999,60.33],
[7,16,9],
[7,18,10.99],
[7,20,12.99],
[7,21,13.99],
[7,25,17.97],
[7,55,46.21],
[7,60,49.29],
[7,61,49.85],
[7,62,50.39],
[7,63,50.91],
[7,64,51.41],
[7,65,51.89],
[7,66,52.35],
[7,67,52.79],
[7,68,53.21],
[8,999,59.91],
[8,16,8],
[8,18,10],
[8,20,11.99],
[8,21,12.99],
[8,25,16.97],
[8,55,45.48],
[8,60,48.63],
[8,61,49.2],
[8,62,49.75],
[8,63,50.28],
[8,64,50.79],
[8,65,51.29],
[8,66,51.76],
[8,67,52.21],
[8,68,52.64],
[9,999,59.47],
[9,16,7],
[9,18,9],
[9,20,10.99],
[9,21,11.99],
[9,25,15.97],
[9,55,44.74],
[9,60,47.95],
[9,61,48.54],
[9,62,49.1],
[9,63,49.64],
[9,64,50.17],
[9,65,50.67],
[9,66,51.15],
[9,67,51.62],
[9,68,52.06],
[10,999,59.03],
[10,16,6],
[10,18,8],
[10,20,9.99],
[10,21,10.99],
[10,25,14.97],
[10,55,44.2],
[10,60,47.26],
[10,61,47.86],
[10,62,48.44],
[10,63,48.99],
[10,64,49.53],
[10,65,50.04],
[10,66,50.54],
[10,67,51.01],
[10,68,51.46],
[11,999,58.58],
[11,16,5],
[11,18,7],
[11,20,8.99],
[11,21,9.99],
[11,25,13.98],
[11,55,43.27],
[11,60,46.56],
[11,61,47.17],
[11,62,47.76],
[11,63,48.33],
[11,64,48.87],
[11,65,49.4],
[11,66,49.91],
[11,67,50.39],
[11,68,50.86],
[12,999,58.12],
[12,16,4],
[12,18,6],
[12,20,7.99],
[12,21,8.99],
[12,25,12.98],
[12,55,42.27],
[12,60,45.84],
[12,61,46.47],
[12,62,47.07],
[12,63,47.65],
[12,64,48.21],
[12,65,48.75],
[12,66,49.26],
[12,67,49.76],
[12,68,50.24],
[13,999,57.65],
[13,16,3],
[13,18,5],
[13,20,7],
[13,21,7.99],
[13,25,11.98],
[13,55,41.27],
[13,60,45.11],
[13,61,45.75],
[13,62,46.36],
[13,63,46.96],
[13,64,47.53],
[13,65,48.08],
[13,66,48.61],
[13,67,49.12],
[13,68,49.6],
[14,999,57.17],
[14,16,2],
[14,18,4],
[14,20,6],
[14,21,6.99],
[14,25,10.98],
[14,55,40.28],
[14,60,44.37],
[14,61,45.02],
[14,62,45.64],
[14,63,46.25],
[14,64,46.84],
[14,65,47.4],
[14,66,47.94],
[14,67,48.46],
[14,68,48.96],
[15,999,56.69],
[15,16,1],
[15,18,3],
[15,20,5],
[15,21,5.99],
[15,25,9.98],
[15,55,39.28],
[15,60,43.83],
[15,61,44.27],
[15,62,44.92],
[15,63,45.54],
[15,64,46.13],
[15,65,46.71],
[15,66,47.26],
[15,67,47.79],
[15,68,48.3],
[16,999,56.19],
[16,18,2],
[16,20,4],
[16,21,5],
[16,25,8.98],
[16,55,38.29],
[16,60,42.9],
[16,61,43.74],
[16,62,44.17],
[16,63,44.81],
[16,64,45.42],
[16,65,46.01],
[16,66,46.57],
[16,67,47.12],
[16,68,47.64],
[17,999,55.69],
[17,18,1],
[17,20,3],
[17,21,4],
[17,25,7.98],
[17,55,37.3],
[17,60,41.91],
[17,61,42.81],
[17,62,43.64],
[17,63,44.06],
[17,64,44.69],
[17,65,45.29],
[17,66,45.87],
[17,67,46.43],
[17,68,46.96],
[18,999,55.19],
[18,20,2],
[18,21,3],
[18,25,6.99],
[18,55,36.31],
[18,60,40.92],
[18,61,41.82],
[18,62,42.71],
[18,63,43.53],
[18,64,43.95],
[18,65,44.56],
[18,66,45.16],
[18,67,45.73],
[18,68,46.27],
[19,999,54.67],
[19,20,1],
[19,21,2],
[19,25,5.99],
[19,55,35.33],
[19,60,39.94],
[19,61,40.84],
[19,62,41.73],
[19,63,42.61],
[19,64,43.42],
[19,65,43.83],
[19,66,44.43],
[19,67,45.02],
[19,68,45.58],
[20,999,54.16],
[20,21,1],
[20,25,4.99],
[20,55,34.34],
[20,60,38.96],
[20,61,39.86],
[20,62,40.75],
[20,63,41.63],
[20,64,42.5],
[20,65,43.3],
[20,66,43.7],
[20,67,44.3],
[20,68,44.87],
[21,999,53.63],
[21,25,3.99],
[21,55,33.36],
[21,60,37.98],
[21,61,38.88],
[21,62,39.77],
[21,63,40.65],
[21,64,41.52],
[21,65,42.38],
[21,66,43.17],
[21,67,43.56],
[21,68,44.15],
[22,999,53.09],
[22,25,3],
[22,55,32.38],
[22,60,37],
[22,61,37.9],
[22,62,38.79],
[22,63,39.67],
[22,64,40.54],
[22,65,41.4],
[22,66,42.25],
[22,67,43.03],
[22,68,43.42],
[23,999,52.54],
[23,25,2],
[23,55,31.4],
[23,60,36.02],
[23,61,36.92],
[23,62,37.81],
[23,63,38.7],
[23,64,39.57],
[23,65,40.43],
[23,66,41.28],
[23,67,42.12],
[23,68,42.88],
[24,999,51.99],
[24,25,1],
[24,55,30.42],
[24,60,35.04],
[24,61,35.95],
[24,62,36.84],
[24,63,37.72],
[24,64,38.59],
[24,65,39.45],
[24,66,40.3],
[24,67,41.14],
[24,68,41.97],
[25,999,51.42],
[25,55,29.44],
[25,60,34.07],
[25,61,34.97],
[25,62,35.86],
[25,63,36.74],
[25,64,37.62],
[25,65,38.48],
[25,66,39.33],
[25,67,40.17],
[25,68,40.99],
[26,999,50.83],
[26,55,28.46],
[26,60,33.09],
[26,61,33.99],
[26,62,34.88],
[26,63,35.77],
[26,64,36.64],
[26,65,37.5],
[26,66,38.36],
[26,67,39.19],
[26,68,40.02],
[27,999,50.24],
[27,55,27.48],
[27,60,32.11],
[27,61,33.01],
[27,62,33.91],
[27,63,34.79],
[27,64,35.67],
[27,65,36.53],
[27,66,37.38],
[27,67,38.22],
[27,68,39.05],
[28,999,49.64],
[28,55,26.5],
[28,60,31.13],
[28,61,32.04],
[28,62,32.93],
[28,63,33.82],
[28,64,34.69],
[28,65,35.55],
[28,66,36.41],
[28,67,37.25],
[28,68,38.08],
[29,999,49.02],
[29,55,25.52],
[29,60,30.15],
[29,61,31.06],
[29,62,31.95],
[29,63,32.84],
[29,64,33.72],
[29,65,34.58],
[29,66,35.43],
[29,67,36.27],
[29,68,37.1],
[30,999,48.39],
[30,55,24.54],
[30,60,29.18],
[30,61,30.08],
[30,62,30.98],
[30,63,31.87],
[30,64,32.74],
[30,65,33.61],
[30,66,34.46],
[30,67,35.3],
[30,68,36.13],
[31,999,47.75],
[31,55,23.55],
[31,60,28.2],
[31,61,29.11],
[31,62,30],
[31,63,30.89],
[31,64,31.77],
[31,65,32.63],
[31,66,33.49],
[31,67,34.33],
[31,68,35.16],
[32,999,47.1],
[32,55,22.57],
[32,60,27.22],
[32,61,28.13],
[32,62,29.03],
[32,63,29.91],
[32,64,30.79],
[32,65,31.66],
[32,66,32.51],
[32,67,33.36],
[32,68,34.19],
[33,999,46.43],
[33,55,21.59],
[33,60,26.24],
[33,61,27.15],
[33,62,28.05],
[33,63,28.94],
[33,64,29.82],
[33,65,30.68],
[33,66,31.54],
[33,67,32.38],
[33,68,33.21],
[34,999,45.75],
[34,55,20.61],
[34,60,25.27],
[34,61,26.18],
[34,62,27.08],
[34,63,27.97],
[34,64,28.84],
[34,65,29.71],
[34,66,30.57],
[34,67,31.41],
[34,68,32.25],
[35,999,45.06],
[35,55,19.63],
[35,60,24.29],
[35,61,25.2],
[35,62,26.1],
[35,63,26.99],
[35,64,27.87],
[35,65,28.74],
[35,66,29.6],
[35,67,30.44],
[35,68,31.28],
[36,999,44.36],
[36,55,18.65],
[36,60,23.32],
[36,61,24.23],
[36,62,25.13],
[36,63,26.02],
[36,64,26.9],
[36,65,27.77],
[36,66,28.63],
[36,67,29.47],
[36,68,30.31],
[37,999,43.48],
[37,55,17.67],
[37,60,22.34],
[37,61,23.25],
[37,62,24.16],
[37,63,25.05],
[37,64,25.93],
[37,65,26.8],
[37,66,27.66],
[37,67,28.51],
[37,68,29.34],
[38,999,42.53],
[38,55,16.69],
[38,60,21.37],
[38,61,22.28],
[38,62,23.18],
[38,63,24.08],
[38,64,24.96],
[38,65,25.83],
[38,66,26.69],
[38,67,27.54],
[38,68,28.38],
[39,999,41.58],
[39,55,15.71],
[39,60,20.39],
[39,61,21.31],
[39,62,22.21],
[39,63,23.11],
[39,64,23.99],
[39,65,24.86],
[39,66,25.72],
[39,67,26.57],
[39,68,27.41],
[40,999,40.64],
[40,55,14.73],
[40,60,19.42],
[40,61,20.34],
[40,62,21.24],
[40,63,22.14],
[40,64,23.02],
[40,65,23.9],
[40,66,24.76],
[40,67,25.61],
[40,68,26.45],
[41,999,39.7],
[41,55,13.75],
[41,60,18.45],
[41,61,19.37],
[41,62,20.27],
[41,63,21.17],
[41,64,22.06],
[41,65,22.93],
[41,66,23.79],
[41,67,24.65],
[41,68,25.48],
[42,999,38.76],
[42,55,12.77],
[42,60,17.48],
[42,61,18.4],
[42,62,19.3],
[42,63,20.2],
[42,64,21.09],
[42,65,21.97],
[42,66,22.83],
[42,67,23.68],
[42,68,24.52],
[43,999,37.83],
[43,55,11.8],
[43,60,16.51],
[43,61,17.43],
[43,62,18.34],
[43,63,19.24],
[43,64,20.13],
[43,65,21],
[43,66,21.87],
[43,67,22.73],
[43,68,23.57],
[44,999,36.9],
[44,55,10.82],
[44,60,15.54],
[44,61,16.46],
[44,62,17.37],
[44,63,18.27],
[44,64,19.17],
[44,65,20.05],
[44,66,20.91],
[44,67,21.77],
[44,68,22.61],
[45,999,35.98],
[45,55,9.84],
[45,60,14.57],
[45,61,15.49],
[45,62,16.41],
[45,63,17.31],
[45,64,18.2],
[45,65,19.09],
[45,66,19.96],
[45,67,20.81],
[45,68,21.66],
[46,999,35.06],
[46,55,8.86],
[46,60,13.6],
[46,61,14.53],
[46,62,15.45],
[46,63,16.35],
[46,64,17.25],
[46,65,18.13],
[46,66,19],
[46,67,19.86],
[46,68,20.71],
[47,999,34.15],
[47,55,7.88],
[47,60,12.64],
[47,61,13.57],
[47,62,14.48],
[47,63,15.39],
[47,64,16.29],
[47,65,17.17],
[47,66,18.05],
[47,67,18.91],
[47,68,19.76],
[48,999,33.24],
[48,55,6.9],
[48,60,11.67],
[48,61,12.6],
[48,62,13.52],
[48,63,14.43],
[48,64,15.33],
[48,65,16.22],
[48,66,17.1],
[48,67,17.96],
[48,68,18.81],
[49,999,32.34],
[49,55,5.93],
[49,60,10.71],
[49,61,11.64],
[49,62,12.56],
[49,63,13.48],
[49,64,14.38],
[49,65,15.27],
[49,66,16.15],
[49,67,17.02],
[49,68,17.87],
[50,999,31.46],
[50,55,4.94],
[50,60,9.74],
[50,61,10.68],
[50,62,11.61],
[50,63,12.52],
[50,64,13.43],
[50,65,14.32],
[50,66,15.21],
[50,67,16.08],
[50,68,16.93],
[51,999,30.57],
[51,55,3.96],
[51,60,8.78],
[51,61,9.72],
[51,62,10.65],
[51,63,11.57],
[51,64,12.48],
[51,65,13.38],
[51,66,14.26],
[51,67,15.14],
[51,68,16],
[52,999,29.7],
[52,55,2.98],
[52,60,7.81],
[52,61,8.76],
[52,62,9.69],
[52,63,10.62],
[52,64,11.53],
[52,65,12.43],
[52,66,13.32],
[52,67,14.2],
[52,68,15.06],
[53,999,28.83],
[53,55,1.99],
[53,60,6.85],
[53,61,7.8],
[53,62,8.73],
[53,63,9.66],
[53,64,10.58],
[53,65,11.48],
[53,66,12.38],
[53,67,13.26],
[53,68,14.13],
[54,999,27.98],
[54,55,1],
[54,60,5.88],
[54,61,6.83],
[54,62,7.78],
[54,63,8.71],
[54,64,9.63],
[54,65,10.54],
[54,66,11.44],
[54,67,12.32],
[54,68,13.2],
[55,999,27.13],
[55,60,4.91],
[55,61,5.87],
[55,62,6.82],
[55,63,7.75],
[55,64,8.68],
[55,65,9.6],
[55,66,10.5],
[55,67,11.39],
[55,68,12.27],
[56,999,26.29],
[56,60,3.94],
[56,61,4.9],
[56,62,5.86],
[56,63,6.8],
[56,64,7.73],
[56,65,8.65],
[56,66,9.56],
[56,67,10.46],
[56,68,11.34],
[57,999,25.46],
[57,60,2.96],
[57,61,3.93],
[57,62,4.89],
[57,63,5.84],
[57,64,6.78],
[57,65,7.71],
[57,66,8.62],
[57,67,9.52],
[57,68,10.41],
[58,999,24.65],
[58,60,1.98],
[58,61,2.96],
[58,62,3.93],
[58,63,4.88],
[58,64,5.83],
[58,65,6.76],
[58,66,7.68],
[58,67,8.59],
[58,68,9.49],
[59,999,23.85],
[59,60,0.99],
[59,61,1.98],
[59,62,2.96],
[59,63,3.92],
[59,64,4.87],
[59,65,5.81],
[59,66,6.74],
[59,67,7.66],
[59,68,8.56],
[60,999,23.05],
[60,61,0.99],
[60,62,1.98],
[60,63,2.95],
[60,64,3.91],
[60,65,4.86],
[60,66,5.8],
[60,67,6.72],
[60,68,7.63],
[61,999,22.27],
[61,62,0.99],
[61,63,1.98],
[61,64,2.95],
[61,65,3.91],
[61,66,4.85],
[61,67,5.78],
[61,68,6.7],
[62,999,21.5],
[62,63,0.99],
[62,64,1.97],
[62,65,2.94],
[62,66,3.9],
[62,67,4.84],
[62,68,5.77],
[63,999,20.75],
[63,64,0.99],
[63,65,1.97],
[63,66,2.94],
[63,67,3.89],
[63,68,4.83],
[64,999,20],
[64,65,0.99],
[64,66,1.97],
[64,67,2.94],
[64,68,3.89],
[65,999,19.25],
[65,66,0.99],
[65,67,1.97],
[65,68,2.93],
[66,999,18.51],
[66,67,0.99],
[66,68,1.97],
[67,999,17.77],
[67,68,0.99],
[68,999,17.04],    	
[69,999,16.31],    	
[70,999,15.59],    	
[71,999,14.88],    	
[72,999,14.17],    	
[73,999,13.47],    	
[74,999,12.78],    	
[75,999,12.1],    	
[76,999,11.43],    	
[77,999,10.77],    	
[78,999,10.12],    	
[79,999,9.5],    	
[80,999,8.88],    	
[81,999,8.28],    	
[82,999,7.71],    	
[83,999,7.16],    	
[84,999,6.63],    	
[85,999,6.13],    	
[86,999,5.66],    	
[87,999,5.21],    	
[88,999,4.8],    	
[89,999,4.41],    	
[90,999,4.07],    	
[91,999,3.74],    	
[92,999,3.44],    	
[93,999,3.16],    	
[94,999,2.91],    	
[95,999,2.68],    	
[96,999,2.48],    	
[97,999,2.31],    	
[98,999,2.15],    	
[99,999,2.01],    	
[100,999,1.9],    	
[101,999,1.71],    	
[102,999,1.41],    	
[103,999,1.02],    	


]);
bcriv2023.set("F", [
  [0,999,65.01],
[0,16,15.93],
[0,18,17.92],
[0,20,19.91],
[0,21,20.9],
[0,25,24.88],
[0,55,51.32],
[0,60,54.07],
[0,61,54.57],
[0,62,55.06],
[0,63,55.54],
[0,64,56],
[0,65,56.45],
[0,66,56.89],
[0,67,57.31],
[0,68,57.718],
[1,999,64.91],
[1,16,14.99],
[1,18,16.98],
[1,20,18.98],
[1,21,19.98],
[1,25,23.97],
[1,55,50.88],
[1,60,53.7],
[1,61,54.21],
[1,62,54.72],
[1,63,55.21],
[1,64,55.68],
[1,65,56.14],
[1,66,56.59],
[1,67,57.02],
[1,68,57.44],
[2,999,64.59],
[2,16,13.99],
[2,18,15.99],
[2,20,17.99],
[2,21,18.98],
[2,25,22.97],
[2,55,50.26],
[2,60,53.13],
[2,61,53.66],
[2,62,54.18],
[2,63,54.68],
[2,64,55.17],
[2,65,55.64],
[2,66,56.09],
[2,67,56.54],
[2,68,56.97],
[3,999,64.25],
[3,16,12.99],
[3,18,14.99],
[3,20,16.99],
[3,21,17.99],
[3,25,21.98],
[3,55,49.61],
[3,60,52.55],
[3,61,53.1],
[3,62,53.62],
[3,63,54.13],
[3,64,54.63],
[3,65,55.11],
[3,66,55.58],
[3,67,56.03],
[3,68,56.47],
[4,999,63.91],
[4,16,11.99],
[4,18,13.99],
[4,20,15.99],
[4,21,16.99],
[4,25,20.98],
[4,55,48.96],
[4,60,51.96],
[4,61,52.51],
[4,62,53.05],
[4,63,53.58],
[4,64,54.08],
[4,65,54.58],
[4,66,55.06],
[4,67,55.52],
[4,68,55.97],
[5,999,63.56],
[5,16,11],
[5,18,12.99],
[5,20,14.99],
[5,21,15.99],
[5,25,19.98],
[5,55,48.28],
[5,60,51.35],
[5,61,51.92],
[5,62,52.47],
[5,63,53],
[5,64,53.52],
[5,65,54.03],
[5,66,54.52],
[5,67,54.99],
[5,68,55.45],
[6,999,63.21],
[6,16,10],
[6,18,11.99],
[6,20,13.99],
[6,21,14.99],
[6,25,18.98],
[6,55,47.59],
[6,60,50.73],
[6,61,51.31],
[6,62,51.87],
[6,63,52.42],
[6,64,52.95],
[6,65,53.47],
[6,66,53.97],
[6,67,54.45],
[6,68,54.92],
[7,999,62.84],
[7,16,9],
[7,18,11],
[7,20,12.99],
[7,21,13.99],
[7,25,17.98],
[7,55,46.89],
[7,60,50.1],
[7,61,50.69],
[7,62,51.26],
[7,63,51.82],
[7,64,52.36],
[7,65,52.89],
[7,66,53.4],
[7,67,53.9],
[7,68,54.38],
[8,999,62.47],
[8,16,8],
[8,18,10],
[8,20,11.99],
[8,21,12.99],
[8,25,16.99],
[8,55,46.17],
[8,60,49.45],
[8,61,50.05],
[8,62,50.64],
[8,63,51.21],
[8,64,51.77],
[8,65,52.3],
[8,66,52.83],
[8,67,53.33],
[8,68,53.83],
[9,999,62.09],
[9,16,7],
[9,18,9],
[9,20,10.99],
[9,21,11.99],
[9,25,15.99],
[9,55,45.44],
[9,60,48.78],
[9,61,49.4],
[9,62,50],
[9,63,50.59],
[9,64,51.15],
[9,65,51.7],
[9,66,52.24],
[9,67,52.76],
[9,68,53.26],
[10,999,61.7],
[10,16,6],
[10,18,8],
[10,20,10],
[10,21,10.99],
[10,25,14.99],
[10,55,44.66],
[10,60,48.11],
[10,61,48.74],
[10,62,49.35],
[10,63,49.95],
[10,64,50.53],
[10,65,51.09],
[10,66,51.64],
[10,67,52.17],
[10,68,52.68],
[11,999,61.3],
[11,16,5],
[11,18,7],
[11,20,9],
[11,21,9.99],
[11,25,13.99],
[11,55,43.66],
[11,60,47.42],
[11,61,48.06],
[11,62,48.69],
[11,63,49.3],
[11,64,49.89],
[11,65,50.47],
[11,66,51.03],
[11,67,51.57],
[11,68,52.09],
[12,999,60.9],
[12,16,4],
[12,18,6],
[12,20,8],
[12,21,9],
[12,25,12.99],
[12,55,42.67],
[12,60,46.71],
[12,61,47.37],
[12,62,48.01],
[12,63,48.63],
[12,64,49.24],
[12,65,49.83],
[12,66,50.4],
[12,67,50.95],
[12,68,51.49],
[13,999,60.49],
[13,16,3],
[13,18,5],
[13,20,7],
[13,21,8],
[13,25,11.99],
[13,55,41.67],
[13,60,45.99],
[13,61,46.66],
[13,62,47.32],
[13,63,47.96],
[13,64,48.58],
[13,65,49.18],
[13,66,49.76],
[13,67,50.33],
[13,68,50.88],
[14,999,60.07],
[14,16,2],
[14,18,4],
[14,20,6],
[14,21,7],
[14,25,10.99],
[14,55,40.67],
[14,60,45.26],
[14,61,45.94],
[14,62,46.61],
[14,63,47.26],
[14,64,47.9],
[14,65,48.51],
[14,66,49.11],
[14,67,49.69],
[14,68,50.25],
[15,999,59.64],
[15,16,1],
[15,18,3],
[15,20,5],
[15,21,6],
[15,25,9.99],
[15,55,39.67],
[15,60,44.48],
[15,61,45.21],
[15,62,45.89],
[15,63,46.56],
[15,64,47.21],
[15,65,47.83],
[15,66,48.44],
[15,67,49.04],
[15,68,49.61],
[16,999,59.21],
[16,18,2],
[16,20,4],
[16,21,5],
[16,25,8.99],
[16,55,38.68],
[16,60,43.48],
[16,61,44.43],
[16,62,45.16],
[16,63,45.84],
[16,64,46.5],
[16,65,47.14],
[16,66,47.77],
[16,67,48.37],
[16,68,48.96],
[17,999,58.77],
[17,18,1],
[17,20,3],
[17,21,4],
[17,25,7.99],
[17,55,37.68],
[17,60,42.49],
[17,61,43.44],
[17,62,44.38],
[17,63,45.11],
[17,64,45.78],
[17,65,46.44],
[17,66,47.08],
[17,67,47.69],
[17,68,48.29],
[18,999,58.31],
[18,20,2],
[18,21,3],
[18,25,6.99],
[18,55,36.69],
[18,60,41.49],
[18,61,42.44],
[18,62,43.39],
[18,63,44.33],
[18,64,45.05],
[18,65,45.72],
[18,66,46.37],
[18,67,47],
[18,68,47.62],
[19,999,57.86],
[19,20,1],
[19,21,2],
[19,25,6],
[19,55,35.7],
[19,60,40.5],
[19,61,41.45],
[19,62,42.39],
[19,63,43.33],
[19,64,44.27],
[19,65,44.99],
[19,66,45.65],
[19,67,46.3],
[19,68,46.93],
[20,999,57.39],
[20,21,1],
[20,25,5],
[20,55,34.7],
[20,60,39.51],
[20,61,40.46],
[20,62,41.4],
[20,63,42.34],
[20,64,43.28],
[20,65,44.21],
[20,66,44.92],
[20,67,45.58],
[20,68,46.22],
[21,999,56.91],
[21,25,4],
[21,55,33.71],
[21,60,38.52],
[21,61,39.47],
[21,62,40.41],
[21,63,41.35],
[21,64,42.29],
[21,65,43.22],
[21,66,44.14],
[21,67,44.85],
[21,68,45.5],
[22,999,56.43],
[22,25,3],
[22,55,32.72],
[22,60,37.52],
[22,61,38.47],
[22,62,39.42],
[22,63,40.36],
[22,64,41.29],
[22,65,42.23],
[22,66,43.15],
[22,67,44.07],
[22,68,44.77],
[23,999,55.93],
[23,25,2],
[23,55,31.72],
[23,60,36.53],
[23,61,37.48],
[23,62,38.43],
[23,63,39.37],
[23,64,40.3],
[23,65,41.23],
[23,66,42.16],
[23,67,43.08],
[23,68,43.99],
[24,999,55.42],
[24,25,1],
[24,55,30.73],
[24,60,35.54],
[24,61,36.49],
[24,62,37.44],
[24,63,38.38],
[24,64,39.31],
[24,65,40.24],
[24,66,41.17],
[24,67,42.09],
[24,68,43],
[25,999,54.91],
[25,55,29.74],
[25,60,34.55],
[25,61,35.5],
[25,62,36.44],
[25,63,37.39],
[25,64,38.32],
[25,65,39.25],
[25,66,40.18],
[25,67,41.1],
[25,68,42.01],
[26,999,54.38],
[26,55,28.74],
[26,60,33.56],
[26,61,34.51],
[26,62,35.45],
[26,63,36.39],
[26,64,37.33],
[26,65,38.26],
[26,66,39.19],
[26,67,40.11],
[26,68,41.02],
[27,999,53.84],
[27,55,27.75],
[27,60,32.56],
[27,61,33.51],
[27,62,34.46],
[27,63,35.4],
[27,64,36.34],
[27,65,37.27],
[27,66,38.2],
[27,67,39.12],
[27,68,40.03],
[28,999,53.29],
[28,55,26.76],
[28,60,31.57],
[28,61,32.52],
[28,62,33.47],
[28,63,34.41],
[28,64,35.35],
[28,65,36.28],
[28,66,37.21],
[28,67,38.13],
[28,68,39.04],
[29,999,52.73],
[29,55,25.76],
[29,60,30.58],
[29,61,31.53],
[29,62,32.48],
[29,63,33.42],
[29,64,34.36],
[29,65,35.29],
[29,66,36.22],
[29,67,37.14],
[29,68,38.05],
[30,999,52.16],
[30,55,24.77],
[30,60,29.59],
[30,61,30.54],
[30,62,31.49],
[30,63,32.43],
[30,64,33.37],
[30,65,34.3],
[30,66,35.23],
[30,67,36.15],
[30,68,37.06],
[31,999,51.57],
[31,55,23.78],
[31,60,28.6],
[31,61,29.55],
[31,62,30.5],
[31,63,31.44],
[31,64,32.38],
[31,65,33.31],
[31,66,34.24],
[31,67,35.16],
[31,68,36.07],
[32,999,50.98],
[32,55,22.79],
[32,60,27.61],
[32,61,28.56],
[32,62,29.51],
[32,63,30.45],
[32,64,31.39],
[32,65,32.32],
[32,66,33.25],
[32,67,34.17],
[32,68,35.09],
[33,999,50.37],
[33,55,21.8],
[33,60,26.62],
[33,61,27.57],
[33,62,28.52],
[33,63,29.46],
[33,64,30.4],
[33,65,31.33],
[33,66,32.26],
[33,67,33.18],
[33,68,34.1],
[34,999,49.76],
[34,55,20.8],
[34,60,25.63],
[34,61,26.58],
[34,62,27.53],
[34,63,28.47],
[34,64,29.41],
[34,65,30.34],
[34,66,31.27],
[34,67,32.19],
[34,68,33.11],
[35,999,49.13],
[35,55,19.81],
[35,60,24.64],
[35,61,25.59],
[35,62,26.54],
[35,63,27.48],
[35,64,28.42],
[35,65,29.36],
[35,66,30.29],
[35,67,31.21],
[35,68,32.12],
[36,999,48.49],
[36,55,18.82],
[36,60,23.65],
[36,61,24.6],
[36,62,25.55],
[36,63,26.5],
[36,64,27.44],
[36,65,28.37],
[36,66,29.3],
[36,67,30.22],
[36,68,31.14],
[37,999,47.83],
[37,55,17.83],
[37,60,22.66],
[37,61,23.61],
[37,62,24.56],
[37,63,25.51],
[37,64,26.45],
[37,65,27.38],
[37,66,28.31],
[37,67,29.24],
[37,68,30.15],
[38,999,47.17],
[38,55,16.84],
[38,60,21.67],
[38,61,22.63],
[38,62,23.58],
[38,63,24.52],
[38,64,25.46],
[38,65,26.4],
[38,66,27.33],
[38,67,28.25],
[38,68,29.17],
[39,999,46.49],
[39,55,15.85],
[39,60,20.68],
[39,61,21.64],
[39,62,22.59],
[39,63,23.54],
[39,64,24.48],
[39,65,25.41],
[39,66,26.34],
[39,67,27.27],
[39,68,28.19],
[40,999,45.81],
[40,55,14.86],
[40,60,19.7],
[40,61,20.65],
[40,62,21.6],
[40,63,22.55],
[40,64,23.49],
[40,65,24.43],
[40,66,25.36],
[40,67,26.29],
[40,68,27.2],
[41,999,45.07],
[41,55,13.87],
[41,60,18.71],
[41,61,19.67],
[41,62,20.62],
[41,63,21.57],
[41,64,22.51],
[41,65,23.45],
[41,66,24.38],
[41,67,25.3],
[41,68,26.22],
[42,999,44.11],
[42,55,12.88],
[42,60,17.73],
[42,61,18.68],
[42,62,19.64],
[42,63,20.58],
[42,64,21.53],
[42,65,22.47],
[42,66,23.4],
[42,67,24.32],
[42,68,25.24],
[43,999,43.14],
[43,55,11.89],
[43,60,16.74],
[43,61,17.7],
[43,62,18.65],
[43,63,19.6],
[43,64,20.55],
[43,65,21.48],
[43,66,22.42],
[43,67,23.34],
[43,68,24.27],
[44,999,42.19],
[44,55,10.9],
[44,60,15.76],
[44,61,16.72],
[44,62,17.67],
[44,63,18.62],
[44,64,19.57],
[44,65,20.51],
[44,66,21.44],
[44,67,22.37],
[44,68,23.29],
[45,999,41.23],
[45,55,9.91],
[45,60,14.77],
[45,61,15.73],
[45,62,16.69],
[45,63,17.64],
[45,64,18.59],
[45,65,19.53],
[45,66,20.46],
[45,67,21.39],
[45,68,22.32],
[46,999,40.29],
[46,55,8.93],
[46,60,13.79],
[46,61,14.75],
[46,62,15.71],
[46,63,16.66],
[46,64,17.61],
[46,65,18.55],
[46,66,19.49],
[46,67,20.42],
[46,68,21.34],
[47,999,39.34],
[47,55,7.94],
[47,60,12.81],
[47,61,13.77],
[47,62,14.73],
[47,63,15.69],
[47,64,16.63],
[47,65,17.58],
[47,66,18.51],
[47,67,19.45],
[47,68,20.37],
[48,999,38.4],
[48,55,6.95],
[48,60,11.83],
[48,61,12.79],
[48,62,13.75],
[48,63,14.71],
[48,64,15.66],
[48,65,16.6],
[48,66,17.54],
[48,67,18.48],
[48,68,19.4],
[49,999,37.46],
[49,55,5.96],
[49,60,10.85],
[49,61,11.81],
[49,62,12.78],
[49,63,13.73],
[49,64,14.68],
[49,65,15.63],
[49,66,16.57],
[49,67,17.51],
[49,68,18.43],
[50,999,36.53],
[50,55,4.97],
[50,60,9.87],
[50,61,10.84],
[50,62,11.8],
[50,63,12.76],
[50,64,13.71],
[50,65,14.66],
[50,66,15.6],
[50,67,16.54],
[50,68,17.47],
[51,999,35.61],
[51,55,3.98],
[51,60,8.89],
[51,61,9.86],
[51,62,10.82],
[51,63,11.78],
[51,64,12.74],
[51,65,13.69],
[51,66,14.63],
[51,67,15.57],
[51,68,16.5],
[52,999,34.68],
[52,55,2.99],
[52,60,7.91],
[52,61,8.88],
[52,62,9.85],
[52,63,10.81],
[52,64,11.77],
[52,65,12.72],
[52,66,13.66],
[52,67,14.6],
[52,68,15.54],
[53,999,33.76],
[53,55,1.99],
[53,60,6.92],
[53,61,7.9],
[53,62,8.87],
[53,63,9.83],
[53,64,10.79],
[53,65,11.75],
[53,66,12.69],
[53,67,13.64],
[53,68,14.57],
[54,999,32.85],
[54,55,1],
[54,60,5.94],
[54,61,6.92],
[54,62,7.89],
[54,63,8.86],
[54,64,9.82],
[54,65,10.77],
[54,66,11.73],
[54,67,12.67],
[54,68,13.61],
[55,999,31.94],
[55,60,4.96],
[55,61,5.93],
[55,62,6.91],
[55,63,7.88],
[55,64,8.84],
[55,65,9.8],
[55,66,10.76],
[55,67,11.7],
[55,68,12.65],
[56,999,31.03],
[56,60,3.97],
[56,61,4.95],
[56,62,5.93],
[56,63,6.9],
[56,64,7.87],
[56,65,8.83],
[56,66,9.79],
[56,67,10.74],
[56,68,11.68],
[57,999,30.14],
[57,60,2.98],
[57,61,3.97],
[57,62,4.95],
[57,63,5.92],
[57,64,6.9],
[57,65,7.86],
[57,66,8.82],
[57,67,9.77],
[57,68,10.72],
[58,999,29.25],
[58,60,1.99],
[58,61,2.98],
[58,62,3.96],
[58,63,4.94],
[58,64,5.92],
[58,65,6.89],
[58,66,7.85],
[58,67,8.81],
[58,68,9.76],
[59,999,28.36],
[59,60,1],
[59,61,1.99],
[59,62,2.98],
[59,63,3.96],
[59,64,4.94],
[59,65,5.91],
[59,66,6.88],
[59,67,7.84],
[59,68,8.79],
[60,999,27.47],
[60,61,1],
[60,62,1.99],
[60,63,2.98],
[60,64,3.96],
[60,65,4.94],
[60,66,5.91],
[60,67,6.87],
[60,68,7.83],
[61,999,26.6],
[61,62,1],
[61,63,1.99],
[61,64,2.98],
[61,65,3.96],
[61,66,4.93],
[61,67,5.9],
[61,68,6.86],
[62,999,25.72],
[62,63,1],
[62,64,1.99],
[62,65,2.97],
[62,66,3.95],
[62,67,4.93],
[62,68,5.9],
[63,999,24.85],
[63,64,1],
[63,65,1.99],
[63,66,2.97],
[63,67,3.95],
[63,68,4.92],
[64,999,23.98],
[64,65,1],
[64,66,1.99],
[64,67,2.97],
[64,68,3.95],
[65,999,23.12],
[65,66,1],
[65,67,1.99],
[65,68,2.969],
[66,999,22.26],
[66,67,1],
[66,68,1.985],
[67,999,21.41],
[67,68,0.996],
[68,999,20.56],		
[69,999,19.71],		
[70,999,18.87],		
[71,999,18.03],		
[72,999,17.21],		
[73,999,16.39],		
[74,999,15.58],		
[75,999,14.78],		
[76,999,13.98],		
[77,999,13.2],		
[78,999,12.44],		
[79,999,11.69],		
[80,999,10.95],		
[81,999,10.24],		
[82,999,9.54],		
[83,999,8.87],		
[84,999,8.22],		
[85,999,7.61],		
[86,999,7.02],		
[87,999,6.46],		
[88,999,5.94],		
[89,999,5.45],		
[90,999,4.99],		
[91,999,4.58],		
[92,999,4.19],		
[93,999,3.83],		
[94,999,3.49],		
[95,999,3.19],		
[96,999,2.91],		
[97,999,2.66],		
[98,999,2.42],		
[99,999,2.2],		
[100,999,1.96],		
[101,999,1.71],		
[102,999,1.39],		
[103,999,0.98],		


]);


export const tableList = [
 {
  id: "gazPal2018",
  author: "Gaz Pal",
  year: 18,
  table: gazPal2018
 },
 {
  id: "gazPal2020tx0",
  author: "Gaz Pal",
  year: 20,
  rate: "0 %",
  table: gazPal2020tx0
 },
 {
  id: "gazPal2020tx03",
  author: "Gaz Pal",
  year: 20,
  rate: "0,3 %",
  table: gazPal2020tx03
 },
 {
  id: "bcriv2017",
  author: "BCRIV",
  year: 18,
  table: bcriv2017
 },
 {
  id: "bcriv2021",
  author: "BCRIV",
  year: 21,
  table: bcriv2021
 },
 {
  id: "gazPal2022txm1",
  author: "Gaz Pal",
  year: 22,
  rate: "-1 %",
  table: gazPal2022txm1
 },
 {
  id: "gazPal2022tx0",
  author: "Gaz Pal",
  year: 22,
  rate: "0 %",
  table: gazPal2022tx0
 },
 {
  id: "bcriv2023",
  author: "BCRIV",
  year: 23,
  table: bcriv2023
 },
 
]