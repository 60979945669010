export const refIndexes = {
    inflation:{
        label:"Indice des prix à la consommation - Ensemble hors tabac",
        sourceLabel:"INSEE - Indice des prix à la consommation - Base 2015 - Ensemble des ménages - France - Ensemble hors tabac",
        sourceUrl:"https://www.insee.fr/fr/statistiques/serie/001763852",
        data:[
            [2024,12,118.83],
            [2024,11,118.83],
            [2024,10,118.83],
            [2024,9,118.50],
            [2024,8,120.01],
            [2024,7,119.37],
            [2024,6,119.14],
            [2024,5,119.05],
            [2024,4,119.01],
            [2024,3,118.40],
            [2024,2,118.15],
            [2024,1,117.16],
            [2023,12,117.5],
            [2023,11,117.33],
            [2023,10,117.54],
            [2023,9,117.37],
            [2023,8,118],
            [2023,7,116.81],
            [2023,6,116.75],
            [2023,5,116.54],
            [2023,4,116.61],
            [2023,3,115.92],
            [2023,2,115.06],
            [2023,1,113.86],
            [2022,12,113.42],
            [2022,11,113.53],
            [2022,10,113.16],
            [2022,9,111.99],
            [2022,8,112.63],
            [2022,7,112.11],
            [2022,6,111.8],
            [2022,5,110.95],
            [2022,4,110.19],
            [2022,3,109.7],
            [2022,2,108.14],
            [2022,1,107.3],
            [2021,12,107.03],
            [2021,11,106.82],
            [2021,10,106.42],
            [2021,9,105.97],
            [2021,8,106.21],
            [2021,7,105.55],
            [2021,6,105.48],
            [2021,5,105.34],
            [2021,4,105.0],
            [2021,3,104.89],
            [2021,2,104.24],
            [2021,1,104.24],
            [2020,12,104.09],
            [2020,11,103.86],
            [2020,10,103.75],
            [2020,9,103.8],
            [2020,8,104.34],
            [2020,7,104.44],
            [2020,6,104.04],
            [2020,5,103.95],
            [2020,4,103.81],
            [2020,3,103.85],
            [2020,2,103.93],
            [2020,1,103.94],
            [2019,12,104.39],
            [2019,11,103.92],
            [2019,10,103.99],
            [2019,9,104.04],
            [2019,8,104.4],
            [2019,7,103.91],
            [2019,6,104.12],
            [2019,5,103.86],
            [2019,4,103.76],
            [2019,3,103.43],
            [2019,2,102.73],
            [2019,1,102.67],
            [2018,12,103.16],
            [2018,11,103.14],
            [2018,10,103.37],
            [2018,9,103.25],
            [2018,8,103.48],
            [2018,7,102.96],
            [2018,6,103.07],
            [2018,5,103.06],
            [2018,4,102.59],
            [2018,3,102.42],
            [2018,2,101.64],
            [2018,1,101.67],
            [2017,12,101.76],
            [2017,11,101.47],
            [2017,10,101.4],
            [2017,9,101.3],
            [2017,8,101.47],
            [2017,7,100.94],
            [2017,6,101.3],
            [2017,5,101.28],
            [2017,4,101.23],
            [2017,3,101.14],
            [2017,2,100.52],
            [2017,1,100.41],
            [2016,12,100.66],
            [2016,11,100.36],
            [2016,10,100.37],
            [2016,9,100.35],
            [2016,8,100.59],
            [2016,7,100.26],
            [2016,6,100.64],
            [2016,5,100.51],
            [2016,4,100.09],
            [2016,3,100.02],
            [2016,2,99.32],
            [2016,1,99.07],
            [2015,12,100.04],
            [2015,11,99.81],
            [2015,10,100.01],
            [2015,9,99.95],
            [2015,8,100.36],
            [2015,7,100.03],
            [2015,6,100.45],
            [2015,5,100.53],
            [2015,4,100.29],
            [2015,3,100.17],
            [2015,2,99.51],
            [2015,1,98.85],
            [2014,12,99.86],
            [2014,11,99.78],
            [2014,10,99.95],
            [2014,9,99.92],
            [2014,8,100.31],
            [2014,7,99.87],
            [2014,6,100.19],
            [2014,5,100.23],
            [2014,4,100.2],
            [2014,3,100.25],
            [2014,2,99.79],
            [2014,1,99.26],
            [2013,12,99.87],
            [2013,11,99.52],
            [2013,10,99.57],
            [2013,9,99.7],
            [2013,8,99.94],
            [2013,7,99.5],
            [2013,6,99.84],
            [2013,5,99.67],
            [2013,4,99.62],
            [2013,3,99.77],
            [2013,2,99.0],
            [2013,1,98.71],
            [2012,12,99.23],
            [2012,11,98.91],
            [2012,10,99.07],
            [2012,9,99.01],
            [2012,8,99.27],
            [2012,7,98.6],
            [2012,6,99.04],
            [2012,5,99.0],
            [2012,4,99.06],
            [2012,3,98.93],
            [2012,2,98.09],
            [2012,1,97.68],
            [2011,12,98.04],
            [2011,11,97.64],
            [2011,10,97.42],
            [2011,9,97.23],
            [2011,8,97.31],
            [2011,7,96.79],
            [2011,6,97.23],
            [2011,5,97.16],
            [2011,4,97.1],
            [2011,3,96.76],
            [2011,2,95.97],
            [2011,1,95.51],
            [2010,12,95.74],
            [2010,11,95.32],
            [2010,10,95.28],
            [2010,9,95.15],
            [2010,8,95.23],
            [2010,7,95.0],
            [2010,6,95.27],
            [2010,5,95.28],
            [2010,4,95.18],
            [2010,3,94.92],
            [2010,2,94.45],
            [2010,1,93.92],
            [2009,12,94.14],
            [2009,11,93.91],
            [2009,10,93.85],
            [2009,9,93.76],
            [2009,8,93.99],
            [2009,7,93.5],
            [2009,6,93.93],
            [2009,5,93.81],
            [2009,4,93.66],
            [2009,3,93.52],
            [2009,2,93.34],
            [2009,1,92.98],
            [2008,12,93.37],
            [2008,11,93.6],
            [2008,10,94.05],
            [2008,9,94.11],
            [2008,8,94.17],
            [2008,7,94.21],
            [2008,6,94.42],
            [2008,5,94.07],
            [2008,4,93.56],
            [2008,3,93.24],
            [2008,2,92.53],
            [2008,1,92.33],
            [2007,12,92.44],
            [2007,11,92.09],
            [2007,10,91.58],
            [2007,9,91.34],
            [2007,8,91.26],
            [2007,7,90.97],
            [2007,6,91.2],
            [2007,5,91.09],
            [2007,4,90.86],
            [2007,3,90.41],
            [2007,2,90.02],
            [2007,1,89.85],
            [2006,12,90.16],
            [2006,11,89.96],
            [2006,10,89.85],
            [2006,9,90.05],
            [2006,8,90.26],
            [2006,7,89.95],
            [2006,6,90.1],
            [2006,5,90.11],
            [2006,4,89.71],
            [2006,3,89.33],
            [2006,2,89.07],
            [2006,1,88.73],
            [2005,12,88.82],
            [2005,11,88.71],
            [2005,10,88.89],
            [2005,9,88.91],
            [2005,8,88.56],
            [2005,7,88.22],
            [2005,6,88.35],
            [2005,5,88.25],
            [2005,4,88.19],
            [2005,3,88.02],
            [2005,2,87.46],
            [2005,1,86.91],
            [2004,12,87.4],
            [2004,11,87.3],
            [2004,10,87.26],
            [2004,9,87.02],
            [2004,8,86.94],
            [2004,7,86.75],
            [2004,6,86.89],
            [2004,5,86.85],
            [2004,4,86.53],
            [2004,3,86.3],
            [2004,2,85.96],
            [2004,1,85.61],
            [2003,12,85.76],
            [2003,11,85.67],
            [2003,10,85.78],
            [2003,9,85.73],
            [2003,8,85.39],
            [2003,7,85.19],
            [2003,6,85.28],
            [2003,5,85.12],
            [2003,4,85.21],
            [2003,3,85.33],
            [2003,2,84.99],
            [2003,1,84.42],
            [2002,12,84.41],
            [2002,11,84.27],
            [2002,10,84.27],
            [2002,9,84.14],
            [2002,8,83.98],
            [2002,7,83.78],
            [2002,6,83.78],
            [2002,5,83.79],
            [2002,4,83.68],
            [2002,3,83.36],
            [2002,2,82.99],
            [2002,1,82.89],
            [2001,12,82.63],
            [2001,11,82.53],
            [2001,10,82.76],
            [2001,9,82.71],
            [2001,8,82.56],
            [2001,7,82.56],
            [2001,6,82.73],
            [2001,5,82.68],
            [2001,4,82.16],
            [2001,3,81.76],
            [2001,2,81.39],
            [2001,1,81.18],
            [2000,12,81.6],
            [2000,11,81.63],
            [2000,10,81.45],
            [2000,9,81.55],
            [2000,8,81.13],
            [2000,7,80.97],
            [2000,6,81.1],
            [2000,5,80.9],
            [2000,4,80.73],
            [2000,3,80.73],
            [2000,2,80.35],
            [2000,1,80.25],
            [1999,12,80.32],
            [1999,11,79.96],
            [1999,10,79.93],
            [1999,9,79.87],
            [1999,8,79.74],
            [1999,7,79.61],
            [1999,6,79.83],
            [1999,5,79.83],
            [1999,4,79.83],
            [1999,3,79.6],
            [1999,2,79.33],
            [1999,1,79.08],
            [1998,12,79.38],
            [1998,11,79.29],
            [1998,10,79.34],
            [1998,9,79.39],
            [1998,8,79.42],
            [1998,7,79.36],
            [1998,6,79.63],
            [1998,5,79.57],
            [1998,4,79.55],
            [1998,3,79.39],
            [1998,2,79.25],
            [1998,1,78.95],
            [1997,12,79.24],
            [1997,11,79.21],
            [1997,10,79.07],
            [1997,9,79.11],
            [1997,8,78.97],
            [1997,7,78.76],
            [1997,6,78.87],
            [1997,5,78.88],
            [1997,4,78.77],
            [1997,3,78.74],
            [1997,2,78.67],
            [1997,1,78.52],
            [1996,12,78.41],
            [1996,11,78.3],
            [1996,10,78.37],
            [1996,9,78.16],
            [1996,8,77.92],
            [1996,7,78.07],
            [1996,6,78.22],
            [1996,5,78.31],
            [1996,4,78.15],
            [1996,3,78.06],
            [1996,2,77.55],
            [1996,1,77.26],
            [1995,12,77.21],
            [1995,11,77.15],
            [1995,10,77.08],
            [1995,9,77.0],
            [1995,8,76.72],
            [1995,7,76.44],
            [1995,6,76.58],
            [1995,5,76.57],
            [1995,4,76.46],
            [1995,3,76.37],
            [1995,2,76.13],
            [1995,1,75.89],
            [1994,12,75.69],
            [1994,11,75.73],
            [1994,10,75.8],
            [1994,9,75.64],
            [1994,8,75.4],
            [1994,7,75.4],
            [1994,6,75.42],
            [1994,5,75.45],
            [1994,4,75.28],
            [1994,3,75.07],
            [1994,2,74.9],
            [1994,1,74.73],
            [1993,12,74.75],
            [1993,11,74.78],
            [1993,10,74.71],
            [1993,9,74.57],
            [1993,8,74.33],
            [1993,7,74.34],
            [1993,6,74.27],
            [1993,5,74.37],
            [1993,4,74.28],
            [1993,3,74.19],
            [1993,2,73.81],
            [1993,1,73.62],
            [1992,12,73.39],
            [1992,11,73.43],
            [1992,10,73.35],
            [1992,9,73.16],
            [1992,8,72.94],
            [1992,7,72.99],
            [1992,6,73.02],
            [1992,5,73.07],
            [1992,4,72.91],
            [1992,3,72.77],
            [1992,2,72.53],
            [1992,1,72.22],
            [1991,12,72.1],
            [1991,11,72.33],
            [1991,10,72.06],
            [1991,9,71.73],
            [1991,8,71.62],
            [1991,7,71.53],
            [1991,6,71.34],
            [1991,5,71.17],
            [1991,4,70.94],
            [1991,3,70.72],
            [1991,2,70.58],
            [1991,1,70.39],
            [1990,12,70.0],
            [1990,11,70.05],
            [1990,10,70.17],
            [1990,9,69.86],
            [1990,8,69.36],
            [1990,7,68.9],
            [1990,6,68.88],
            [1990,5,68.89],
            [1990,4,68.78],
            [1990,3,68.4],
            [1990,2,68.23],
            [1990,1,68.09],
        ]
    },
    smic:{
        label:"SMIC horaire brut",
        sourceLabel:"INSEE - Salaire minimum interprofessionnel de croissance (Smic)",
        sourceUrl:"https://www.insee.fr/fr/statistiques/1375188",
        latest:11.88,
        data:[
            [2024,12,11.88],
            [2024,11,11.88],
            [2024,10,11.65],
            [2024,9,11.65],
            [2024,8,11.65],
            [2024,7,11.65],
            [2024,6,11.65],
            [2024,5,11.65],
            [2024,4,11.65],
            [2024,3,11.65],
            [2024,2,11.65],
            [2024,1,11.65],
            [2023,12,11.52],
            [2023,11,11.52],
            [2023,10,11.52],
            [2023,9,11.52],
            [2023,8,11.52],
            [2023,7,11.52],
            [2023,6,11.52],
            [2023,5,11.52],
            [2023,4,11.27],
            [2023,3,11.27],
            [2023,2,11.27],
            [2023,1,11.27],
            [2022,12,11.07],
            [2022,11,11.07],
            [2022,10,11.07],
            [2022,9,11.07],
            [2022,8,11.07],
            [2022,7,10.85],
            [2022,6,10.85],
            [2022,5,10.85],
            [2022,4,10.57],
            [2022,3,10.57],
            [2022,2,10.57],
            [2022,1,10.57],
            [2021,12,10.48],
            [2021,11,10.48],
            [2021,10,10.48],
            [2021,9,10.25],
            [2021,8,10.25],
            [2021,7,10.25],
            [2021,6,10.25],
            [2021,5,10.25],
            [2021,4,10.25],
            [2021,3,10.25],
            [2021,2,10.25],
            [2021,1,10.25],
            [2020,12,10.15],
            [2020,11,10.15],
            [2020,10,10.15],
            [2020,9,10.15],
            [2020,8,10.15],
            [2020,7,10.15],
            [2020,6,10.15],
            [2020,5,10.15],
            [2020,4,10.15],
            [2020,3,10.15],
            [2020,2,10.15],
            [2020,1,10.15],
            [2019,12,10.03],
            [2019,11,10.03],
            [2019,10,10.03],
            [2019,9,10.03],
            [2019,8,10.03],
            [2019,7,10.03],
            [2019,6,10.03],
            [2019,5,10.03],
            [2019,4,10.03],
            [2019,3,10.03],
            [2019,2,10.03],
            [2019,1,10.03],
            [2018,12,9.88],
            [2018,11,9.88],
            [2018,10,9.88],
            [2018,9,9.88],
            [2018,8,9.88],
            [2018,7,9.88],
            [2018,6,9.88],
            [2018,5,9.88],
            [2018,4,9.88],
            [2018,3,9.88],
            [2018,2,9.88],
            [2018,1,9.88],
            [2017,12,9.76],
            [2017,11,9.76],
            [2017,10,9.76],
            [2017,9,9.76],
            [2017,8,9.76],
            [2017,7,9.76],
            [2017,6,9.76],
            [2017,5,9.76],
            [2017,4,9.76],
            [2017,3,9.76],
            [2017,2,9.76],
            [2017,1,9.76],
            [2016,12,9.67],
            [2016,11,9.67],
            [2016,10,9.67],
            [2016,9,9.67],
            [2016,8,9.67],
            [2016,7,9.67],
            [2016,6,9.67],
            [2016,5,9.67],
            [2016,4,9.67],
            [2016,3,9.67],
            [2016,2,9.67],
            [2016,1,9.67],
            [2015,12,9.61],
            [2015,11,9.61],
            [2015,10,9.61],
            [2015,9,9.61],
            [2015,8,9.61],
            [2015,7,9.61],
            [2015,6,9.61],
            [2015,5,9.61],
            [2015,4,9.61],
            [2015,3,9.61],
            [2015,2,9.61],
            [2015,1,9.61],
            [2014,12,9.53],
            [2014,11,9.53],
            [2014,10,9.53],
            [2014,9,9.53],
            [2014,8,9.53],
            [2014,7,9.53],
            [2014,6,9.53],
            [2014,5,9.53],
            [2014,4,9.53],
            [2014,3,9.53],
            [2014,2,9.53],
            [2014,1,9.53],
            [2013,12,9.43],
            [2013,11,9.43],
            [2013,10,9.43],
            [2013,9,9.43],
            [2013,8,9.43],
            [2013,7,9.43],
            [2013,6,9.43],
            [2013,5,9.43],
            [2013,4,9.43],
            [2013,3,9.43],
            [2013,2,9.43],
            [2013,1,9.43],
            [2012,12,9.4],
            [2012,11,9.4],
            [2012,10,9.4],
            [2012,9,9.4],
            [2012,8,9.4],
            [2012,7,9.4],
            [2012,6,9.22],
            [2012,5,9.22],
            [2012,4,9.22],
            [2012,3,9.22],
            [2012,2,9.22],
            [2012,1,9.22],
            [2011,12,9.19],
            [2011,11,9],
            [2011,10,9],
            [2011,9,9],
            [2011,8,9],
            [2011,7,9],
            [2011,6,9],
            [2011,5,9],
            [2011,4,9],
            [2011,3,9],
            [2011,2,9],
            [2011,1,9],
            [2010,12,8.86],
            [2010,11,8.86],
            [2010,10,8.86],
            [2010,9,8.86],
            [2010,8,8.86],
            [2010,7,8.86],
            [2010,6,8.86],
            [2010,5,8.86],
            [2010,4,8.86],
            [2010,3,8.86],
            [2010,2,8.86],
            [2010,1,8.86],
            [2009,12,8.82],
            [2009,11,8.82],
            [2009,10,8.82],
            [2009,9,8.82],
            [2009,8,8.82],
            [2009,7,8.82],
            [2009,6,8.71],
            [2009,5,8.71],
            [2009,4,8.71],
            [2009,3,8.71],
            [2009,2,8.71],
            [2009,1,8.71],
            [2008,12,8.71],
            [2008,11,8.71],
            [2008,10,8.71],
            [2008,9,8.71],
            [2008,8,8.71],
            [2008,7,8.71],
            [2008,6,8.63],
            [2008,5,8.63],
            [2008,4,8.44],
            [2008,3,8.44],
            [2008,2,8.44],
            [2008,1,8.44],
            [2007,12,8.44],
            [2007,11,8.44],
            [2007,10,8.44],
            [2007,9,8.44],
            [2007,8,8.44],
            [2007,7,8.44],
            [2007,6,8.27],
            [2007,5,8.27],
            [2007,4,8.27],
            [2007,3,8.27],
            [2007,2,8.27],
            [2007,1,8.27],
            [2006,12,8.27],
            [2006,11,8.27],
            [2006,10,8.27],
            [2006,9,8.27],
            [2006,8,8.27],
            [2006,7,8.27],
            [2006,6,8.03],
            [2006,5,8.03],
            [2006,4,8.03],
            [2006,3,8.03],
            [2006,2,8.03],
            [2006,1,8.03],
            [2005,12,8.03],
            [2005,11,8.03],
            [2005,10,8.03],
            [2005,9,8.03],
            [2005,8,8.03],
            [2005,7,8.03],
            [2005,6,7.61],
            [2005,5,7.61],
            [2005,4,7.61],
            [2005,3,7.61],
            [2005,2,7.61],
            [2005,1,7.61],
            [2004,12,7.61],
            [2004,11,7.61],
            [2004,10,7.61],
            [2004,9,7.61],
            [2004,8,7.61],
            [2004,7,7.61],
            [2004,6,7.19],
            [2004,5,7.19],
            [2004,4,7.19],
            [2004,3,7.19],
            [2004,2,7.19],
            [2004,1,7.19],
            [2003,12,7.19],
            [2003,11,7.19],
            [2003,10,7.19],
            [2003,9,7.19],
            [2003,8,7.19],
            [2003,7,7.19],
            [2003,6,6.83],
            [2003,5,6.83],
            [2003,4,6.83],
            [2003,3,6.83],
            [2003,2,6.83],
            [2003,1,6.83],
            [2002,12,6.83],
            [2002,11,6.83],
            [2002,10,6.83],
            [2002,9,6.83],
            [2002,8,6.83],
            [2002,7,6.83],
            [2002,6,6.67],
            [2002,5,6.67],
            [2002,4,6.67],
            [2002,3,6.67],
            [2002,2,6.67],
            [2002,1,6.67],
            [2001,12,6.67],
            [2001,11,6.67],
            [2001,10,6.67],
            [2001,9,6.67],
            [2001,8,6.67],
            [2001,7,6.67],
            [2001,6,6.41],
            [2001,5,6.41],
            [2001,4,6.41],
            [2001,3,6.41],
            [2001,2,6.41],
            [2001,1,6.41],
            [2000,12,6.41],
            [2000,11,6.41],
            [2000,10,6.41],
            [2000,9,6.41],
            [2000,8,6.41],
            [2000,7,6.41],
            [2000,6,6.21],
            [2000,5,6.21],
            [2000,4,6.21],
            [2000,3,6.21],
            [2000,2,6.21],
            [2000,1,6.21],
            [1999,12,6.21],
            [1999,11,6.21],
            [1999,10,6.21],
            [1999,9,6.21],
            [1999,8,6.21],
            [1999,7,6.21],
            [1999,6,6.13],
            [1999,5,6.13],
            [1999,4,6.13],
            [1999,3,6.13],
            [1999,2,6.13],
            [1999,1,6.13],
            [1998,12,6.13],
            [1998,11,6.13],
            [1998,10,6.13],
            [1998,9,6.13],
            [1998,8,6.13],
            [1998,7,6.13],
            [1998,6,6.01],
            [1998,5,6.01],
            [1998,4,6.01],
            [1998,3,6.01],
            [1998,2,6.01],
            [1998,1,6.01],
            [1997,12,6.01],
            [1997,11,6.01],
            [1997,10,6.01],
            [1997,9,6.01],
            [1997,8,6.01],
            [1997,7,6.01],
            [1997,6,5.78],
            [1997,5,5.78],
            [1997,4,5.78],
            [1997,3,5.78],
            [1997,2,5.78],
            [1997,1,5.78],
            [1996,12,5.78],
            [1996,11,5.78],
            [1996,10,5.78],
            [1996,9,5.78],
            [1996,8,5.78],
            [1996,7,5.78],
            [1996,6,5.75],
            [1996,5,5.75],
            [1996,4,5.64],
            [1996,3,5.64],
            [1996,2,5.64],
            [1996,1,5.64],
            [1995,12,5.64],
            [1995,11,5.64],
            [1995,10,5.64],
            [1995,9,5.64],
            [1995,8,5.64],
            [1995,7,5.64],
            [1995,6,5.42],
            [1995,5,5.42],
            [1995,4,5.42],
            [1995,3,5.42],
            [1995,2,5.42],
            [1995,1,5.42],
            [1994,12,5.42],
            [1994,11,5.42],
            [1994,10,5.42],
            [1994,9,5.42],
            [1994,8,5.42],
            [1994,7,5.42],
            [1994,6,5.31],
            [1994,5,5.31],
            [1994,4,5.31],
            [1994,3,5.31],
            [1994,2,5.31],
            [1994,1,5.31],
            [1993,12,5.31],
            [1993,11,5.31],
            [1993,10,5.31],
            [1993,9,5.31],
            [1993,8,5.31],
            [1993,7,5.31],
            [1993,6,5.19],
            [1993,5,5.19],
            [1993,4,5.19],
            [1993,3,5.19],
            [1993,2,5.19],
            [1993,1,5.19],
            [1992,12,5.19],
            [1992,11,5.19],
            [1992,10,5.19],
            [1992,9,5.19],
            [1992,8,5.19],
            [1992,7,5.19],
            [1992,6,5.08],
            [1992,5,5.08],
            [1992,4,5.08],
            [1992,3,5.08],
            [1992,2,4.98],
            [1992,1,4.98],
            [1991,12,4.98],
            [1991,11,4.98],
            [1991,10,4.98],
            [1991,9,4.98],
            [1991,8,4.98],
            [1991,7,4.98],
            [1991,6,4.87],
            [1991,5,4.87],
            [1991,4,4.87],
            [1991,3,4.87],
            [1991,2,4.87],
            [1991,1,4.87],
            [1990,12,4.87],
            [1990,11,4.77],
            [1990,10,4.77],
            [1990,9,4.77],
            [1990,8,4.77],
            [1990,7,4.77],
            [1990,6,4.65],
            [1990,5,4.65],
            [1990,4,4.65],
            [1990,3,4.65],
            [1990,2,4.65],
            [1990,1,4.65],
            [1990,12,4.65],
            [1990,11,4.65],
            [1990,10,4.65],
            [1990,9,4.65],
            [1990,8,4.65],
            [1990,7,4.65],
            [1990,6,4.65],
            [1990,5,4.65],
            [1990,4,4.65],
            [1990,3,],
            [1990,2,],
            [1990,1,],
            [1989,7,4.56],
            [1989,3,4.48],
            [1988,7,4.38],
            [1988,6,4.34],
            [1987,7,4.24],
            [1987,3,4.2],
            [1986,7,4.1],
            [1986,6,4.05],
            [1985,7,3.97],
            [1985,5,3.89],
            [1985,4,3.8],
            [1984,11,3.71],
            [1984,7,3.63],
            [1984,5,3.59],
            [1984,1,3.47],
            [1983,10,3.4],
            [1983,7,3.34],
            [1983,6,3.3],
            [1983,3,3.2],
            [1982,12,3.09],
            [1982,7,2.99],
            [1982,5,2.9],
            [1982,3,2.84],
            [1982,1,2.77],
            [1981,11,2.71],
            [1981,9,2.64],
            [1981,6,2.55],
            [1981,3,2.32],
            [1980,12,2.25],
            [1980,9,2.18],
            [1980,7,2.13],
            [1980,5,2.08],
            [1980,3,2.04],            
        ]
    },
    medical:{
        label:"Indice des prix à la consommation - Appareils et matériel thérapeutiques",
        sourceLabel:"INSEE - Indice des prix à la consommation - Base 2015 - Ensemble des ménages - France métropolitaine - Nomenclature Coicop : 06.1.3 - Appareils et matériel thérapeutiques",
        sourceUrl:"https://www.insee.fr/fr/statistiques/serie/001764076",
        data:[
            [2024,12,103.65],
            [2024,11,103.65],
            [2024,10,103.65],
            [2024,9,103.73],
            [2024,8,103.60],
            [2024,7,103.44],
            [2024,6,103.60],
            [2024,5,103.53],
            [2024,4,103.34],
            [2024,3,103.23],
            [2024,2,103.31],
            [2024,1,103.08],
            [2023,12,102.95],
            [2023,11,102.83],
            [2023,10,102.74],
            [2023,9,102.76],
            [2023,8,102.79],
            [2023,7,102.77],
            [2023,6,102.69],
            [2023,5,102.71],
            [2023,4,102.62],
            [2023,3,102.38],
            [2023,2,102.10],
            [2023,1,101.78],
            [2022,12,101.31],
            [2022,11,101.2],
            [2022,10,101.17],
            [2022,9,101.12],
            [2022,8,100.53],
            [2022,7,100.46],
            [2022,6,100.66],
            [2022,5,100.56],
            [2022,4,100.67],
            [2022,3,100.57],
            [2022,2,100.36],
            [2022,1,100.21],
            [2021,12,100.35],
            [2021,11,100.42],
            [2021,10,100.29],
            [2021,9,100.38],
            [2021,8,100.28],
            [2021,7,100.18],
            [2021,6,100.27],
            [2021,5,100.05],
            [2021,4,100.09],
            [2021,3,100.1],
            [2021,2,100.21],
            [2021,1,100.26],
            [2020,12,100.45],
            [2020,11,100.55],
            [2020,10,100.59],
            [2020,9,100.66],
            [2020,8,100.55],
            [2020,7,100.52],
            [2020,6,100.37],
            [2020,5,100.36],
            [2020,4,100.39],
            [2020,3,100.5],
            [2020,2,100.23],
            [2020,1,100.23],
            [2019,12,100.47],
            [2019,11,100.56],
            [2019,10,100.46],
            [2019,9,100.46],
            [2019,8,100.4],
            [2019,7,100.21],
            [2019,6,100.46],
            [2019,5,100.44],
            [2019,4,100.35],
            [2019,3,100.38],
            [2019,2,100.08],
            [2019,1,99.99],
            [2018,12,100.32],
            [2018,11,100.33],
            [2018,10,100.26],
            [2018,9,100.22],
            [2018,8,100.21],
            [2018,7,100.13],
            [2018,6,100.28],
            [2018,5,100.11],
            [2018,4,100.06],
            [2018,3,100.08],
            [2018,2,99.81],
            [2018,1,99.69],
            [2017,12,99.6],
            [2017,11,99.66],
            [2017,10,99.68],
            [2017,9,99.79],
            [2017,8,99.68],
            [2017,7,99.41],
            [2017,6,99.78],
            [2017,5,99.67],
            [2017,4,99.62],
            [2017,3,99.7],
            [2017,2,99.29],
            [2017,1,99.49],
            [2016,12,99.54],
            [2016,11,99.52],
            [2016,10,99.55],
            [2016,9,99.76],
            [2016,8,99.71],
            [2016,7,99.52],
            [2016,6,99.72],
            [2016,5,99.84],
            [2016,4,99.75],
            [2016,3,99.69],
            [2016,2,99.68],
            [2016,1,99.65],
            [2015,12,99.76],
            [2015,11,99.81],
            [2015,10,99.94],
            [2015,9,100.06],
            [2015,8,99.89],
            [2015,7,99.84],
            [2015,6,100.0],
            [2015,5,100.16],
            [2015,4,100.17],
            [2015,3,100.19],
            [2015,2,100.1],
            [2015,1,100.09],
            [2014,12,100.43],
            [2014,11,100.37],
            [2014,10,100.26],
            [2014,9,100.23],
            [2014,8,100.41],
            [2014,7,99.79],
            [2014,6,100.2],
            [2014,5,100.7],
            [2014,4,100.79],
            [2014,3,100.72],
            [2014,2,100.3],
            [2014,1,99.82],
            [2013,12,100.37],
            [2013,11,100.11],
            [2013,10,99.99],
            [2013,9,99.95],
            [2013,8,99.75],
            [2013,7,99.44],
            [2013,6,99.89],
            [2013,5,99.99],
            [2013,4,99.86],
            [2013,3,100.0],
            [2013,2,99.67],
            [2013,1,99.66],
            [2012,12,99.87],
            [2012,11,99.84],
            [2012,10,99.72],
            [2012,9,99.7],
            [2012,8,99.61],
            [2012,7,99.15],
            [2012,6,99.42],
            [2012,5,99.5],
            [2012,4,99.39],
            [2012,3,99.41],
            [2012,2,99.3],
            [2012,1,98.92],
            [2011,12,99.12],
            [2011,11,99.1],
            [2011,10,98.96],
            [2011,9,99.28],
            [2011,8,99.22],
            [2011,7,99.0],
            [2011,6,99.1],
            [2011,5,99.18],
            [2011,4,99.1],
            [2011,3,99.19],
            [2011,2,98.91],
            [2011,1,98.79],
            [2010,12,98.93],
            [2010,11,99.04],
            [2010,10,99.07],
            [2010,9,99.04],
            [2010,8,99.06],
            [2010,7,98.85],
            [2010,6,98.95],
            [2010,5,98.99],
            [2010,4,98.84],
            [2010,3,98.91],
            [2010,2,98.8],
            [2010,1,98.7],
            [2009,12,98.56],
            [2009,11,98.64],
            [2009,10,98.71],
            [2009,9,98.54],
            [2009,8,98.78],
            [2009,7,98.59],
            [2009,6,98.63],
            [2009,5,98.37],
            [2009,4,98.25],
            [2009,3,98.03],
            [2009,2,98.0],
            [2009,1,98.15],
            [2008,12,98.08],
            [2008,11,98.19],
            [2008,10,98.23],
            [2008,9,98.12],
            [2008,8,97.92],
            [2008,7,97.85],
            [2008,6,97.85],
            [2008,5,97.81],
            [2008,4,97.82],
            [2008,3,97.82],
            [2008,2,97.68],
            [2008,1,97.62],
            [2007,12,97.23],
            [2007,11,97.21],
            [2007,10,97.18],
            [2007,9,97.11],
            [2007,8,97.01],
            [2007,7,97.01],
            [2007,6,97.02],
            [2007,5,96.9],
            [2007,4,96.79],
            [2007,3,96.88],
            [2007,2,96.71],
            [2007,1,96.44],
            [2006,12,96.68],
            [2006,11,96.6],
            [2006,10,96.5],
            [2006,9,96.38],
            [2006,8,96.36],
            [2006,7,96.41],
            [2006,6,96.53],
            [2006,5,96.45],
            [2006,4,96.64],
            [2006,3,96.7],
            [2006,2,96.52],
            [2006,1,96.25],
            [2005,12,96.44],
            [2005,11,96.47],
            [2005,10,96.47],
            [2005,9,96.42],
            [2005,8,96.34],
            [2005,7,96.26],
            [2005,6,96.34],
            [2005,5,96.41],
            [2005,4,96.44],
            [2005,3,96.34],
            [2005,2,96.12],
            [2005,1,96.05],
            [2004,12,95.73],
            [2004,11,95.78],
            [2004,10,95.63],
            [2004,9,95.5],
            [2004,8,95.38],
            [2004,7,95.36],
            [2004,6,95.34],
            [2004,5,95.09],
            [2004,4,94.95],
            [2004,3,94.73],
            [2004,2,94.75],
            [2004,1,94.53],
            [2003,12,94.34],
            [2003,11,94.14],
            [2003,10,94.23],
            [2003,9,94.13],
            [2003,8,93.87],
            [2003,7,93.69],
            [2003,6,93.73],
            [2003,5,93.4],
            [2003,4,93.3],
            [2003,3,93.27],
            [2003,2,93.08],
            [2003,1,92.68],
            [2002,12,92.54],
            [2002,11,92.5],
            [2002,10,92.23],
            [2002,9,92.21],
            [2002,8,92.0],
            [2002,7,92.06],
            [2002,6,92.12],
            [2002,5,92.12],
            [2002,4,92.06],
            [2002,3,91.83],
            [2002,2,91.48],
            [2002,1,91.54],
            [2001,12,91.28],
            [2001,11,91.39],
            [2001,10,91.26],
            [2001,9,91.22],
            [2001,8,91.18],
            [2001,7,91.08],
            [2001,6,91.07],
            [2001,5,90.98],
            [2001,4,90.93],
            [2001,3,90.92],
            [2001,2,90.64],
            [2001,1,90.61],
            [2000,12,90.73],
            [2000,11,90.72],
            [2000,10,90.66],
            [2000,9,90.54],
            [2000,8,90.34],
            [2000,7,90.23],
            [2000,6,90.2],
            [2000,5,90.18],
            [2000,4,89.97],
            [2000,3,89.87],
            [2000,2,89.64],
            [2000,1,89.71],
            [1999,12,89.72],
            [1999,11,89.68],
            [1999,10,89.75],
            [1999,9,89.77],
            [1999,8,89.73],
            [1999,7,89.7],
            [1999,6,89.73],
            [1999,5,89.68],
            [1999,4,89.7],
            [1999,3,89.67],
            [1999,2,89.43],
            [1999,1,89.27],
            [1998,12,89.12],
            [1998,11,89.09],
            [1998,10,89.07],
            [1998,9,88.98],
            [1998,8,88.94],
            [1998,7,88.95],
            [1998,6,88.92],
            [1998,5,88.82],
            [1998,4,88.73],
            [1998,3,88.68],
            [1998,2,88.58],
            [1998,1,88.31],
            [1997,12,88.38],
            [1997,11,88.37],
            [1997,10,88.31],
            [1997,9,88.3],
            [1997,8,88.29],
            [1997,7,88.35],
            [1997,6,88.26],
            [1997,5,88.38],
            [1997,4,88.3],
            [1997,3,88.23],
            [1997,2,88.34],
            [1997,1,88.75],
            [1996,12,88.78],
            [1996,11,88.64],
            [1996,10,88.53],
            [1996,9,88.51],
            [1996,8,88.49],
            [1996,7,88.44],
            [1996,6,88.45],
            [1996,5,88.32],
            [1996,4,88.26],
            [1996,3,88.12],
            [1996,2,87.62],
            [1996,1,87.53],
            [1995,12,87.42],
            [1995,11,87.42],
            [1995,10,87.29],
            [1995,9,87.19],
            [1995,8,87.02],
            [1995,7,87.01],
            [1995,6,86.95],
            [1995,5,86.91],
            [1995,4,86.76],
            [1995,3,86.77],
            [1995,2,86.46],
            [1995,1,86.17],
            [1994,12,86.07],
            [1994,11,85.97],
            [1994,10,85.87],
            [1994,9,85.87],
            [1994,8,85.87],
            [1994,7,85.87],
            [1994,6,85.8],
            [1994,5,85.75],
            [1994,4,85.75],
            [1994,3,85.67],
            [1994,2,85.46],
            [1994,1,85.01],
            [1993,12,84.72],
            [1993,11,84.71],
            [1993,10,84.65],
            [1993,9,84.8],
            [1993,8,84.66],
            [1993,7,84.53],
            [1993,6,84.46],
            [1993,5,84.27],
            [1993,4,84.15],
            [1993,3,83.82],
            [1993,2,83.06],
            [1993,1,82.66],
            [1992,12,82.3],
            [1992,11,82.14],
            [1992,10,81.97],
            [1992,9,81.93],
            [1992,8,81.79],
            [1992,7,81.71],
            [1992,6,81.56],
            [1992,5,81.19],
            [1992,4,81.26],
            [1992,3,81.0],
            [1992,2,80.17],
            [1992,1,79.8],
            [1991,12,79.61],
            [1991,11,79.39],
            [1991,10,79.1],
            [1991,9,78.75],
            [1991,8,78.61],
            [1991,7,78.48],
            [1991,6,78.27],
            [1991,5,77.85],
            [1991,4,77.43],
            [1991,3,77.01],
            [1991,2,76.3],
            [1991,1,76.0],
            [1990,12,75.64],
            [1990,11,75.63],
            [1990,10,75.54],
            [1990,9,75.3],
            [1990,8,75.12],
            [1990,7,75.06],
            [1990,6,74.82],
            [1990,5,74.6],
            [1990,4,74.16],
            [1990,3,73.61],
            [1990,2,72.73],
            [1990,1,72.18],
        ]
    },
    vehicles:{
        label:"Indice des prix à la consommation - Voitures automobiles",
        sourceLabel:"INSEE - Indice des prix à la consommation - Base 2015 - Ensemble des ménages - France métropolitaine - Nomenclature Coicop : 07.1.1 - Voitures automobiles",
        sourceUrl:"https://www.insee.fr/fr/statistiques/serie/001764092",
        data:[
            [2024,12,114.02],
            [2024,11,114.02],
            [2024,10,114.02],
            [2024,9,113.83],
            [2024,8,114.21],
            [2024,7,114.30],
            [2024,6,114.05],
            [2024,5,113.71],
            [2024,4,113.65],
            [2024,3,113.28],
            [2024,2,112.91],
            [2024,1,112.76],
            [2023,12,112.36],
            [2023,11,112.53],
            [2023,10,112.53],
            [2023,9,112.97],
            [2023,8,112.94],
            [2023,7,112.72],
            [2023,6,113.05],
            [2023,5,113.16],
            [2023,4,113.75],
            [2023,3,113.68],
            [2023,2,113.16],
            [2023,1,113.21],
            [2022,12,112.13],
            [2022,11,111.58],
            [2022,10,110.91],
            [2022,9,110.05],
            [2022,8,109.8],
            [2022,7,109.25],
            [2022,6,108.43],
            [2022,5,107.95],
            [2022,4,107.19],
            [2022,3,106.42],
            [2022,2,105.92],
            [2022,1,105.52],
            [2021,12,104.07],
            [2021,11,104.08],
            [2021,10,103.87],
            [2021,9,103.92],
            [2021,8,103.1],
            [2021,7,103.96],
            [2021,6,103.14],
            [2021,5,103.02],
            [2021,4,103.07],
            [2021,3,103.06],
            [2021,2,103.12],
            [2021,1,103.45],
            [2020,12,103.09],
            [2020,11,103.0],
            [2020,10,102.85],
            [2020,9,102.78],
            [2020,8,102.13],
            [2020,7,102.07],
            [2020,6,103.12],
            [2020,5,103.12],
            [2020,4,102.9],
            [2020,3,102.84],
            [2020,2,103.65],
            [2020,1,103.74],
            [2019,12,103.88],
            [2019,11,102.67],
            [2019,10,102.72],
            [2019,9,102.41],
            [2019,8,101.88],
            [2019,7,102.05],
            [2019,6,102.82],
            [2019,5,102.87],
            [2019,4,102.27],
            [2019,3,102.26],
            [2019,2,102.39],
            [2019,1,102.55],
            [2018,12,102.61],
            [2018,11,102.32],
            [2018,10,102.27],
            [2018,9,101.87],
            [2018,8,102.41],
            [2018,7,102.17],
            [2018,6,101.61],
            [2018,5,101.94],
            [2018,4,101.34],
            [2018,3,101.32],
            [2018,2,101.39],
            [2018,1,101.25],
            [2017,12,101.0],
            [2017,11,100.84],
            [2017,10,100.72],
            [2017,9,100.53],
            [2017,8,100.48],
            [2017,7,100.48],
            [2017,6,100.38],
            [2017,5,100.35],
            [2017,4,100.31],
            [2017,3,100.08],
            [2017,2,100.18],
            [2017,1,100.17],
            [2016,12,99.21],
            [2016,11,99.13],
            [2016,10,99.29],
            [2016,9,99.68],
            [2016,8,99.37],
            [2016,7,99.42],
            [2016,6,99.58],
            [2016,5,99.26],
            [2016,4,99.78],
            [2016,3,100.93],
            [2016,2,100.87],
            [2016,1,100.99],
            [2015,12,100.14],
            [2015,11,100.14],
            [2015,10,100.76],
            [2015,9,99.35],
            [2015,8,99.31],
            [2015,7,100.72],
            [2015,6,100.38],
            [2015,5,99.71],
            [2015,4,99.63],
            [2015,3,100.12],
            [2015,2,99.97],
            [2015,1,99.77],
            [2014,12,98.15],
            [2014,11,98.03],
            [2014,10,99.82],
            [2014,9,99.99],
            [2014,8,98.93],
            [2014,7,99.16],
            [2014,6,98.84],
            [2014,5,98.81],
            [2014,4,99.67],
            [2014,3,98.76],
            [2014,2,100.08],
            [2014,1,99.89],
            [2013,12,98.18],
            [2013,11,97.98],
            [2013,10,98.02],
            [2013,9,97.15],
            [2013,8,97.87],
            [2013,7,98.03],
            [2013,6,96.82],
            [2013,5,96.94],
            [2013,4,97.89],
            [2013,3,97.62],
            [2013,2,97.87],
            [2013,1,97.89],
            [2012,12,96.68],
            [2012,11,95.95],
            [2012,10,96.32],
            [2012,9,96.99],
            [2012,8,96.52],
            [2012,7,97.13],
            [2012,6,96.3],
            [2012,5,96.17],
            [2012,4,96.02],
            [2012,3,95.38],
            [2012,2,95.05],
            [2012,1,96.12],
            [2011,12,95.27],
            [2011,11,93.41],
            [2011,10,95.02],
            [2011,9,94.87],
            [2011,8,93.52],
            [2011,7,94.78],
            [2011,6,94.18],
            [2011,5,93.4],
            [2011,4,94.7],
            [2011,3,94.01],
            [2011,2,94.59],
            [2011,1,94.16],
            [2010,12,93.4],
            [2010,11,92.32],
            [2010,10,92.71],
            [2010,9,93.36],
            [2010,8,92.24],
            [2010,7,93.71],
            [2010,6,91.78],
            [2010,5,92.35],
            [2010,4,92.88],
            [2010,3,93.9],
            [2010,2,92.77],
            [2010,1,93.11],
            [2009,12,93.23],
            [2009,11,92.19],
            [2009,10,92.41],
            [2009,9,91.85],
            [2009,8,93.17],
            [2009,7,93.62],
            [2009,6,93.92],
            [2009,5,93.41],
            [2009,4,92.91],
            [2009,3,93.23],
            [2009,2,92.4],
            [2009,1,92.6],
            [2008,12,93.5],
            [2008,11,93.59],
            [2008,10,93.1],
            [2008,9,93.38],
            [2008,8,93.02],
            [2008,7,92.24],
            [2008,6,91.99],
            [2008,5,91.71],
            [2008,4,91.94],
            [2008,3,92.19],
            [2008,2,92.21],
            [2008,1,92.05],
            [2007,12,93.22],
            [2007,11,92.27],
            [2007,10,91.33],
            [2007,9,90.87],
            [2007,8,92.28],
            [2007,7,91.91],
            [2007,6,90.92],
            [2007,5,92.1],
            [2007,4,91.76],
            [2007,3,90.54],
            [2007,2,90.61],
            [2007,1,89.77],
            [2006,12,90.46],
            [2006,11,90.33],
            [2006,10,89.61],
            [2006,9,90.14],
            [2006,8,90.7],
            [2006,7,90.11],
            [2006,6,90.14],
            [2006,5,90.89],
            [2006,4,90.79],
            [2006,3,89.06],
            [2006,2,90.02],
            [2006,1,89.65],
            [2005,12,89.44],
            [2005,11,89.84],
            [2005,10,90.15],
            [2005,9,90.05],
            [2005,8,90.11],
            [2005,7,89.48],
            [2005,6,89.19],
            [2005,5,90.03],
            [2005,4,90.19],
            [2005,3,89.15],
            [2005,2,89.59],
            [2005,1,88.28],
            [2004,12,89.83],
            [2004,11,89.9],
            [2004,10,89.82],
            [2004,9,89.42],
            [2004,8,89.4],
            [2004,7,88.5],
            [2004,6,89.28],
            [2004,5,89.31],
            [2004,4,89.29],
            [2004,3,88.24],
            [2004,2,88.99],
            [2004,1,88.48],
            [2003,12,88.86],
            [2003,11,88.3],
            [2003,10,88.86],
            [2003,9,87.23],
            [2003,8,88.15],
            [2003,7,88.11],
            [2003,6,87.01],
            [2003,5,88.18],
            [2003,4,87.68],
            [2003,3,86.98],
            [2003,2,87.28],
            [2003,1,86.85],
            [2002,12,86.43],
            [2002,11,86.24],
            [2002,10,86.23],
            [2002,9,86.01],
            [2002,8,86.88],
            [2002,7,86.44],
            [2002,6,85.82],
            [2002,5,86.8],
            [2002,4,86.48],
            [2002,3,86.36],
            [2002,2,86.54],
            [2002,1,85.9],
            [2001,12,85.48],
            [2001,11,85.25],
            [2001,10,85.32],
            [2001,9,85.17],
            [2001,8,85.98],
            [2001,7,85.57],
            [2001,6,85.57],
            [2001,5,85.89],
            [2001,4,85.44],
            [2001,3,85.15],
            [2001,2,85.41],
            [2001,1,85.36],
            [2000,12,85.39],
            [2000,11,85.35],
            [2000,10,85.09],
            [2000,9,84.47],
            [2000,8,84.79],
            [2000,7,84.66],
            [2000,6,84.5],
            [2000,5,84.38],
            [2000,4,84.28],
            [2000,3,84.57],
            [2000,2,85.1],
            [2000,1,85.13],
            [1999,12,84.72],
            [1999,11,84.95],
            [1999,10,84.86],
            [1999,9,84.59],
            [1999,8,84.58],
            [1999,7,84.63],
            [1999,6,84.79],
            [1999,5,84.36],
            [1999,4,84.5],
            [1999,3,84.8],
            [1999,2,84.92],
            [1999,1,84.98],
            [1998,12,85.18],
            [1998,11,85.33],
            [1998,10,85.04],
            [1998,9,85.78],
            [1998,8,86.0],
            [1998,7,85.81],
            [1998,6,85.17],
            [1998,5,85.51],
            [1998,4,85.85],
            [1998,3,85.69],
            [1998,2,85.52],
            [1998,1,84.08],
            [1997,12,84.34],
            [1997,11,84.17],
            [1997,10,84.64],
            [1997,9,84.68],
            [1997,8,84.15],
            [1997,7,84.09],
            [1997,6,84.1],
            [1997,5,84.41],
            [1997,4,85.36],
            [1997,3,85.6],
            [1997,2,84.69],
            [1997,1,84.94],
            [1996,12,85.46],
            [1996,11,85.4],
            [1996,10,88.74],
            [1996,9,86.33],
            [1996,8,86.63],
            [1996,7,87.27],
            [1996,6,87.96],
            [1996,5,87.43],
            [1996,4,87.08],
            [1996,3,87.59],
            [1996,2,86.08],
            [1996,1,86.42],
            [1995,12,86.54],
            [1995,11,88.09],
            [1995,10,87.7],
            [1995,9,88.12],
            [1995,8,88.53],
            [1995,7,87.17],
            [1995,6,85.59],
            [1995,5,85.98],
            [1995,4,85.97],
            [1995,3,86.16],
            [1995,2,86.65],
            [1995,1,86.84],
            [1994,12,86.01],
            [1994,11,86.01],
            [1994,10,86.31],
            [1994,9,86.48],
            [1994,8,86.32],
            [1994,7,86.64],
            [1994,6,85.57],
            [1994,5,85.52],
            [1994,4,85.22],
            [1994,3,84.45],
            [1994,2,85.17],
            [1994,1,87.21],
            [1993,12,86.29],
            [1993,11,86.29],
            [1993,10,86.13],
            [1993,9,86.04],
            [1993,8,86.13],
            [1993,7,86.29],
            [1993,6,85.87],
            [1993,5,85.87],
            [1993,4,85.87],
            [1993,3,85.71],
            [1993,2,85.87],
            [1993,1,85.62],
            [1992,12,83.54],
            [1992,11,83.54],
            [1992,10,83.62],
            [1992,9,84.12],
            [1992,8,84.12],
            [1992,7,84.04],
            [1992,6,82.95],
            [1992,5,82.95],
            [1992,4,83.87],
            [1992,3,85.3],
            [1992,2,85.3],
            [1992,1,85.13],
            [1991,12,83.79],
            [1991,11,83.79],
            [1991,10,83.79],
            [1991,9,83.71],
            [1991,8,83.63],
            [1991,7,83.46],
            [1991,6,82.34],
            [1991,5,82.34],
            [1991,4,82.34],
            [1991,3,82.34],
            [1991,2,82.25],
            [1991,1,82.01],
            [1990,12,80.64],
            [1990,11,80.56],
            [1990,10,80.56],
            [1990,9,81.28],
            [1990,8,82.49],
            [1990,7,82.25],
            [1990,6,81.2],
            [1990,5,81.2],
            [1990,4,81.2],
            [1990,3,81.2],
            [1990,2,80.48],
            [1990,1,80.24],            
        ]
    },
    custom:{
        label:"Libre - Saisir une valeur d'indice d'actualisation",
        //sourceLabel:"INSEE - Indice des prix à la consommation - Base 2015 - Ensemble des ménages - France métropolitaine - Nomenclature Coicop : 07.1.1 - Voitures automobiles",
        //sourceUrl:"https://www.insee.fr/fr/statistiques/serie/001764092",
        data:[]
    },
}

export const firstYear = 1990;
export const lastYear = [2024,1];
