import React, {Component, Fragment, memo} from "react";
import PropTypes from "prop-types";
import {connect, Provider} from "react-redux";
import store from "../../../../store";
import {formValueSelector, getFormValues} from "redux-form";
import CaseVictimAccident from "./CaseVictimAccident"
import CaseMetadata from "./CaseMetadata";
import CaseData from "../case_data_form/CaseData";
import CaseFooter from "./CaseFooter";
import {deleteCase, getCase, updateCase} from "../../../../actions/cases";
import SideMenu from "../../../layout/SideMenu";
import SmallScreens from "./SmallScreens";
import DisplayWarningMsg from "./DisplayWarningMsg";
import CaseTitle from "./CaseTitle";
import {LoadingComponent} from "../../../common/LoadingComponent";

export class Case extends Component {
    static propTypes = {
        getCase: PropTypes.func.isRequired,
        deleteCase: PropTypes.func.isRequired,
        case: PropTypes.object,
        victim: PropTypes.object,
        caseValues: PropTypes.object
    };


    state = {
        saveCounter: [],
        saveCounterTitle: [],
        isSaving: false
    }


    processNextSave = () => {
        const {saveCounter, saveCounterTitle} = this.state;

        if (saveCounter.length === 0 && saveCounterTitle.length === 0 ) {
            return
        }

        this.setState({isSaving: true},  () => {
            if (saveCounterTitle.length > 0) {
                this.caseTitleSubmit(saveCounterTitle[0]).finally(() => {
                    this.setState(prevState => ({
                        isSaving: false,
                        saveCounterTitle: prevState.saveCounterTitle.slice(1)
                    }), this.processNextSave)
                })
                return
            }
            if (saveCounter.length > 0) {
                this.caseDataSubmit(saveCounter[0]).finally(() => {
                    this.setState(prevState => ({
                        isSaving: false,
                        saveCounter: prevState.saveCounter.slice(1)
                    }), this.processNextSave)
                })
                return
            }
        })
    };

    enqueueSave = (values) => {
        try {
            this.setState(prevState => ({
                saveCounter: [...prevState.saveCounter, values]
            }), () => {
                if (!this.state.isSaving) {
                    this.processNextSave();
                }
            })
        } catch (e) {
            console.error(e)
        }
    }

    componentDidMount() {
        this.props.getCase(this.props.match.params.caseId);
    }

    caseDataSubmit = values => {
        const caseData = values;

        let c = {
            id: this.props.case.id,
            title: this.props.caseTitle,
            version: this.props.case.version,
            json_data: {
                ...this.props.case.json_data,
                caseData,
                victim: {...this.props.case.json_data.victim, ...this.props.victimData}
            }
        };

        return this.props.updateCase(c);
    };


    caseTitleSubmit = (title) => {
        let c = {
            id: this.props.case.id,
            version: this.props.case.version,
            title: title,
        };

        return this.props.updateCase(c);
    };

    enqueueSaveTitle = () => {
        try {
            this.setState(prevState => ({
                saveCounterTitle: [...prevState.saveCounterTitle, this.props.caseTitle]
            }), () => {
                if (!this.state.isSaving) {
                    this.processNextSave();
                }
            })
        } catch (e) {
            console.error(e)
        }
    }

    render() {
        const {date_created, date_modified} = this.props.case || {};
        const dateCascadeReverse = new Date("2023-01-20")
        const dateCreated = new Date(date_created)

        return (
            <>
                {dateCreated < dateCascadeReverse && <DisplayWarningMsg/>}
                <div className="mobile"><SmallScreens/></div>
                <div className="desktop">
                    {this.props.isLoading && "Chargement du dossier en cours..."}

                    <Provider store={store}>
                        {Object.keys(this.props.case).length > 0 ?
                            <>
                                <div className="wrapper">
                                    <SideMenu victim={this.props.victim}/>
                                    <div className="container-fluid">
                                        <div className="sticky-top sticky-offset">
                                            <CaseTitle onSubmit={this.enqueueSaveTitle} title={this.props.case.title}/>
                                            <CaseMetadata date_created={date_created} date_modified={date_modified}/>
                                            <CaseVictimAccident/>
                                        </div>
                                        <CaseData onSubmit={this.enqueueSave} victim={this.props.victim}/>
                                    </div>
                                </div>
                                <CaseFooter caseId={this.props.case.id}/>
                            </>
                            : <LoadingComponent/>
                        }
                    </Provider>
                </div>
            </>

        );
    }
}

const victimSelector = formValueSelector("victim");
const titleSelector = formValueSelector("caseTitleForm");
const dataSelector = formValueSelector("caseDataForm");

const mapStateToProps = state => {
    let victim = {};
    let victimData = {};
    let caseTitle = "";

    let c = {};
    let caseValues = {};
    let indirectVictims = [];

    if (state.cases && state.cases.case) {
        c = state.cases.case;
        if (state.cases.case && state.cases.case.title) {
            caseTitle = titleSelector(state, "title")
        }
        if (state.cases && state.cases.case && state.cases.case.json_data) {
            victimData.last_name = victimSelector(state, "last_name");
            victimData.first_name = victimSelector(state, "first_name");

            victimData.rateOfCompensationEntitlement = victimSelector(
                state,
                "rateOfCompensationEntitlement"
            );
            victimData.disableCascade = victimSelector(state, "disableCascade");
            victimData.consolidationDate = victimSelector(state, "consolidationDate");
            victimData.deathDate = victimSelector(state, "deathDate");
            victimData.date = victimSelector(state, "date");
            victimData.accidentType = victimSelector(state, "accidentType");
            victimData.birthDate = victimSelector(state, "birth_date");
            victimData.gender = victimData.victimGender = victimSelector(state, "victimGender"); // to compensate for bad choice of variable names in direct victim. To be fixed!!!
            victim = victimData;

            caseValues = getFormValues("caseDataForm")(state);
            if (caseValues) indirectVictims = caseValues.indirectVictims
        }
    }

    return {caseValues, victim, victimData, caseTitle, case: c, indirectVictims};
};

export default connect(mapStateToProps, {getCase, deleteCase, updateCase})(memo(Case));
