import React from 'react';
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import TableChartIcon from '@material-ui/icons/TableChart';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { compensationScales } from "./constants";
import FullWidthTabsDFP from "./TabsDFP"

const useStyles = makeStyles((theme) => ({
  modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
  paper: {
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      textAlign: 'center',
  },

}));

const BtnDisplayDFPScales = props => {
  const { victim, lineIndex, disabled, dfpRate } = props;
  const compensationScale = compensationScales["DFP"];
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);


  const handleOpen = () => {
      setOpen(true);
  };

  const handleClose = () => {
      setOpen(false);
  };

  return (
    <>
    <button
      className="btn btn-outline-primary btn-sm ml-1 p-0"
      title="Afficher les tableaux indicatifs d'indemnisation du DFP"
      style={{height: "24px"}}
      onClick={handleOpen}
    >
      <TableChartIcon />
    </button>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
          timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">{compensationScale.link[0].charAt(0).toUpperCase() + compensationScale.link[0].slice(1)} {compensationScale.link[1]}</h2>
          <FullWidthTabsDFP
            tables={compensationScale.tables} 
            victim={victim} 
            damageRate={dfpRate} 
            lineIndex={lineIndex}
            handleClose={() => handleClose()}
          />
        </div>
      </Fade>
    </Modal>
</>


  )
}

const victimSelector = formValueSelector("victim");
const caseSelector = formValueSelector("caseDataForm");

const mapStateToProps = (state, props) => {
  let victim = {};
  const { lineIndex } = props;
  let dfpRate;
  if (state.cases.case.json_data) {
    victim.birthDate = victimSelector(state, "birth_date");
    victim.gender = victimSelector(state, "victimGender");
    victim.consolidationDate = victimSelector(state, "consolidationDate");
    dfpRate = caseSelector(state, `DFP0[${lineIndex}].damageRate`)
  }
  return { victim, dfpRate };
};

export default connect(mapStateToProps)(BtnDisplayDFPScales);