import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import AmountField from "../../fields/AmountField";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { tppTotal } from "../calculations";
import DatePicker, {
  formatDates,
  normalizeDates,
} from "../creation_wizard/RenderDateInput";

const RenderDsfTPP = props => {
  const { fields, colSpan, line, lineIndex, tppValues, annCap, tppCap } = props;


  let suffix = line + ".arr"
  let tppSuffix = ".past_tpp"

  const removeTPP = index => {
    fields.remove(index);
  };

  return (
    <tr>
      <td className="col-num" />
      <td colSpan={colSpan} className="pl-2 pt-0">
        <table className="table table-sm mb-0 tpp">
          <caption>Paiements de tiers soumis à recours sur les arrérages échus de la dépense n° {lineIndex + 1}</caption>
          <thead>
            <tr>
              <th className="text-nowrap pl-0">Organisme payeur</th>
              <th className="container-fluid">Intitulé</th>
              <th className="text-nowrap datePeriod">Date ou période du paiement</th>
              <th colSpan="2" className="text-center">Montant</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((tpp, index) => (
              <tr key={index}>
                <td className="pl-0 pr-1 pb-0 pt-1">
                  <div className="input-group input-group-sm">
                    <Field
                      name={`${suffix}${tppSuffix}[${index}].organisation`}
                      type="text"
                      component="input"
                      className="form-control pl-0"
                    />
                  </div>
                </td>
                <td className="pl-1 pr-1 pb-0 pt-1">
                  <div className="input-group input-group-sm">
                    <Field
                      name={`${suffix}${tppSuffix}[${index}].label`}
                      type="text"
                      component="input"
                      className="form-control"
                    />
                  </div>
                </td>
                <td className="pl-1 pr-1 pb-0 pt-1">
                  <div className="d-flex">
                    <div className="input-group input-group-sm date">
                      <Field
                        name={`${suffix}${tppSuffix}[${index}].startDate`}
                        component={DatePicker}
                        parse={normalizeDates}
                        format={formatDates}
                        className="form-control mr-1"
                      />
                    </div>
                    <div className="input-group input-group-sm date">
                      <Field
                        name={`${suffix}${tppSuffix}[${index}].endDate`}
                        component={DatePicker}
                        parse={normalizeDates}
                        format={formatDates}
                        className="form-control ml-1"
                      />
                    </div>
                  </div>
                </td>
                <td className="col-amount-tpp pl-1 pr-0 pb-0 pt-1">
                  <Field name={`${suffix}${tppSuffix}[${index}].amount`} component={AmountField} />
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm p-0"
                    title="Supprimer ce paiement de tiers"
                    onClick={() => removeTPP(index)}
                  >
                    <DeleteForeverIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
          {fields.length > 1 && (
            <tfoot>
              <tr>
                <td />
                <th className="text-right" colSpan={annCap ? "1" : "2"}>
                  Total des paiements de tiers limités à la dépense n° {lineIndex + 1}
                </th>
                <td className="col-amount-tpp d-flex pl-1 pr-0 pb-0 pt-1">
                  <AmountField fieldValue={tppTotal(tppValues)} />
                </td>
                <td />
              </tr>
            </tfoot>
          )}
        </table>
      </td>
      <td className="col-amount text-right align-bottom">{annCap && !tppCap && <Fragment><em>Préjudice annuel après paiements de tiers :</em><AmountField fieldValue={Math.max(annCapBaseAmount, 0)} /></Fragment>}</td>
      <td className="col-act" />
    </tr>
  );
};

RenderDsfTPP.propTypes = {
  fields: PropTypes.object,
  tppValues: PropTypes.array,
  entryMeta: PropTypes.array,
  colSpan: PropTypes.number,
  line: PropTypes.string,
  lineIndex: PropTypes.number,
  dispatch: PropTypes.func,
  annCap: PropTypes.bool
};
export default connect()(RenderDsfTPP);
